<template>
        <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            item-key="id"
            disable-sort
            no-data-text="Aucune comparaison"
        >
            <template v-slot:item.first_analysis="{ item }">
                <div v-if="type === 'simple'">
                    {{ format(head(item.analyses)) }}
                </div>
                <div v-if="type === 'pair'">
                    {{ formatPair(head(item.pair_analyses), 'tumoral') }}<span class="font-weight-bold">(T)</span><br>
                    {{ formatPair(head(item.pair_analyses), 'non-tumoral') }}<span class="font-weight-bold">(NT)</span><br>
                </div>
            </template>
            <template v-slot:item.second_analysis="{ item }">
                <div v-if="type === 'simple'">
                    {{ format(last(item.analyses)) }}
                </div>
                <div v-if="type === 'pair'">
                    {{ formatPair(last(item.pair_analyses), 'tumoral') }}<span class="font-weight-bold">(T)</span><br>
                    {{ formatPair(last(item.pair_analyses), 'non-tumoral') }}<span class="font-weight-bold">(NT)</span><br>
                </div>
            </template>
            <template v-slot:item.action="{ item }">
                <btn
                    @click="$visit('ngs_comparisons.show', {
                        ngs_comparison: item.id,
                        _query: {
                            'show-common-variants': true
                        }
                    })"
                    icon="mdi-chevron-right"
                    color="primary"
                    :iconOnly="true"
                >
                    Voir la comparaison
                </btn>
            </template>
            <template v-slot:body.append="{ headers }">
                <td :colspan="headers.length" class="text-center">
                    <btn :block="true" icon="mdi-plus" color="primary" @click="$emit('add')">
                        Ajouter une nouvelle comparaison
                    </btn>
                </td>
            </template>
        </v-data-table>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {DataTableHeader} from 'vuetify'
import Btn from '@/Components/generic/Btn.vue'
import route from 'ziggy-js'
import {head, last} from "lodash";
import {NgsAnalysis, NgsComparison, NgsPairAnalysis} from "@/models";

@Component({
    components: {
        Btn
    }
})
export default class NgsAnalysisComparisonsTable extends Vue {
    @Prop() readonly value!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'
    @Prop() readonly isDialogOpen!: boolean

    @Watch('isDialogOpen')
    onDialogOpening(val: boolean) {
        if (val) {
            this.load()
        } else {
            this.items = []
        }
    }

    items: Array<NgsComparison> = []
    loading: boolean = false
    comparison: NgsComparison | null = null

    headers: Array<DataTableHeader> = [
        {
            text: 'Nom comparaison',
            value: 'name',
            width: '20%'
        },
        {
            text: '1ère analyse (alias)',
            value: 'first_analysis',
            width: '35%'
        },
        {
            text: '2ème analyse (alias)',
            value: 'second_analysis',
            width: '35%'
        },
        {
            text: 'Action',
            value: 'action',
            width: '10%'
        }
    ]

    load() {
        this.loading = true
        if (this.type === 'simple') {
            this.$axios(route('ngs_analyses.comparisons', {ngs_analysis: this.value.id}))
                .then(({data}) => {
                    this.items = data
                })
                .finally(() => {
                    this.loading = false
                })
        }
        if (this.type === 'pair') {
            this.$axios(route('ngs_pair_analyses.comparisons', {ngs_pair_analysis: this.value.id}))
                .then(({data}) => {
                    this.items = data
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }

    head(array: Array<any>) {
        return head(array)
    }

    last(array: Array<any>) {
        return last(array)
    }

    format(item: NgsAnalysis) {
        return `${item.sample.diamic} - ${item.sample.laboratory_number} - ${item.barcode.name} (${(item as any).pivot.alias})`
    }

    formatPair(item: NgsPairAnalysis, type: 'tumoral' | 'non-tumoral') {
        if (type === 'tumoral') {
            return `${item.tumoral_sample.diamic} - ${item.tumoral_sample.laboratory_number} - ${item.tumoral_barcode.name} (${(item as any).pivot.alias})`
        }
        return `${item.tumoral_sample.diamic} - ${item.tumoral_sample.laboratory_number} - ${item.tumoral_barcode.name} (${(item as any).pivot.alias})`
    }

    mounted() {
        this.load()
    }
}
</script>

<style lang="scss">
</style>
