<template>
    <v-row dense>
        <v-col>
            <v-tooltip bottom :disabled="!comment" color="rgba(0,0,0,0.9)">
                <template v-slot:activator="{ on, attrs }">
                    <v-progress-linear
                        v-if="status"
                        v-bind="attrs"
                        v-on="on"
                        :value="statusList[status].percentage"
                        :height="dense ? '10' : '25'"
                        rounded
                        :color="`${statusList[status].color} darken-4`"
                    >
                        <span v-if="!dense" class="white--text font-weight-bold">{{ statusList[status].text }}</span>
                    </v-progress-linear>
                </template>
                <span>{{ comment }}</span>
            </v-tooltip>
            <div v-if="dense" class="text-center black--text">{{ statusList[status].text }}</div>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class NgsLibraryStatusProgress extends Vue {
    @Prop() readonly status!: string
    @Prop() readonly comment?: string
    @Prop() readonly dense?: boolean

    statusList: { [key: string]: { text: string; percentage: number; color: string; } } = {
        planned: {
            text: 'Planifié',
            percentage: 33,
            color: 'secondary'
        },
        analysed: {
            text: 'Analysé',
            percentage: 66,
            color: 'secondary'
        },
        reported: {
            text: 'Rapporté',
            percentage: 100,
            color: 'secondary'
        },
        qc_fail: {
            text: 'Échec QC',
            percentage: 100,
            color: 'error'
        },
    }
}
</script>

<style scoped lang="scss">
</style>
