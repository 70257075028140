<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        title="Supprimer l'annotation"
        :max-width="600"
        @validate="$emit('delete')"
        :loading="loading"
        button-class="error"
    >
        <div class="pa-5">
            <v-row>
                <v-col cols="12">
                    <p>Êtes-vous sûr(e) de vouloir supprimer cette annotation? Cette annotation sera définitivement supprimée et l'annotateur sera averti.</p>
                </v-col>
            </v-row>
        </div>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, VModel, Vue } from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'

@Component({
    components: { DialogConfirmation }
})
export default class CnvDeleteDialogDialog extends Vue {
    @VModel() isDialogOpen!: boolean

    loading: boolean = false
}
</script>

<style lang="scss" scoped>

</style>
