<template>
    <div>
        <panel title="Pipeline status" :expandable="true">
            <template>
                <v-row no-gutters>
                    <v-col cols="12" md="3">
                        <steps
                            category="UPLOAD"
                            :analysis-id="ngsAnalysis.id"
                            :items="filter(uploadItems)"
                            :disabled="true"
                            type="ngs_analysis"
                            @reload="$emit('reload')"
                        ></steps>
                    </v-col>
                    <v-col cols="12" md="3">
                        <steps
                            category="PARSING"
                            :analysis-id="ngsAnalysis.id"
                            :items="filter(parsingItems)"
                            :disabled="true"
                            type="ngs_analysis"
                            @reload="$emit('reload')"
                        ></steps>
                    </v-col>
                    <v-col cols="12" md="3">
                        <steps
                            category="CLUSTER"
                            :analysis-id="ngsAnalysis.id"
                            :items="filter(clusterItems)"
                            :disabled="true"
                            type="ngs_analysis"
                            @reload="$emit('reload')"
                        ></steps>
                    </v-col>
                    <v-col cols="12" md="3">
                        <steps
                            category="PROCESSING RESULTS"
                            :analysis-id="ngsAnalysis.id"
                            :items="filter(processingResultsItems)"
                            :disabled="true"
                            type="ngs_analysis"
                            @reload="$emit('reload')"
                        ></steps>
                    </v-col>
                </v-row>
            </template>
        </panel>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Panel from '@/Components/generic/Panel.vue'
import Steps from '@/Components/generic/Steps.vue'
import {NgsAnalysis, NgsAnalysisStep} from "@/models";
import {AnalysisStep} from "@/laravel";

@Component({
    components: {
        Steps,
        Panel
    }
})
export default class NgsAnalysisPipelineCard extends Vue {
    @Prop() readonly ngsAnalysis!: NgsAnalysis
    @Prop() readonly steps!: NgsAnalysisStep

    get uploadItems (): Array<AnalysisStep> {
        return [
            {
                value: this.steps.qc_file_uploaded,
                title: 'QC File',
                label: 'Upload of the QC file',
                route: 'ngs_analyses.upload_qc_file',
                show: true
            },
            {
                value: this.steps.bam_file_uploaded,
                title: 'BAM File',
                label: 'Upload of the BAM file',
                route: 'ngs_analyses.upload_bam_file',
                show: true
            },
            {
                value: this.steps.vcf_file_uploaded,
                title: 'VCF File',
                label: 'Upload of the VCF file',
                route: 'ngs_analyses.upload_vcf_file',
                show: true
            }
        ]
    }

    get parsingItems (): Array<AnalysisStep> {
        return [
            {
                value: this.steps.qc_file_processed,
                title: 'QC File',
                label: 'Parsing of the QC File',
                route: 'ngs_analyses.process_qc_file',
                show: true
            },
            {
                value: this.steps.vcf_file_processed,
                title: 'VCF File',
                label: 'Parsing of the VCF File',
                route: 'ngs_analyses.process_vcf_file',
                show: true
            },
            {
                value: this.steps.bed_files_written,
                title: 'BED Files',
                label: 'Generation of the BED Files',
                route: 'ngs_analyses.write_bed_files',
                show: true
            }
        ]
    }

    get clusterItems (): Array<AnalysisStep> {
        return [
            {
                value: this.steps.tabix_generated,
                title: 'Tabix Index',
                label: 'Indexing of the BAM File',
                route: 'ngs_analyses.generate_tabix',
                show: true
            },
            {
                value: this.steps.annotate_with_vep,
                title: 'VEP annotation',
                label: 'Annotate VCF file with VEP',
                route: 'ngs_analyses.annotate_with_vep',
                show: true
            },
            {
                value: this.steps.bedtools_getfasta,
                title: 'BEDTools getfasta',
                label: 'Get the fasta sequences',
                route: 'ngs_analyses.launch_bedtools_getfasta',
                show: true
            },
            {
                value: this.steps.bedtools_coverage,
                title: 'BEDTools coverage',
                label: 'Get the coverage',
                route: 'ngs_analyses.launch_bedtools_coverage',
                show: true
            },
            {
                value: this.steps.bam_readcount_completed,
                title: 'BAM-readcount',
                label: 'Get low-level info about nucleotides',
                route: 'ngs_analyses.launch_bam_readcount',
                show: true
            }
        ]
    }

    get processingResultsItems (): Array<AnalysisStep> {
        return [
            {
                value: this.steps.bed_file_processed,
                title: 'BEDTools getfasta',
                label: 'Process results files',
                route: 'ngs_analyses.process_bed_file',
                show: true
            },
            {
                value: this.steps.coverage_file_processed,
                title: 'BEDTools coverage',
                label: 'Process results files',
                route: 'ngs_analyses.process_coverage_file',
                show: true
            },
            {
                value: this.steps.json_file_processed,
                title: 'VEP annotation',
                label: 'Process results files',
                route: 'ngs_analyses.process_json_file',
                show: true
            },
            {
                value: this.steps.bam_readcount_processed,
                title: 'BAM-readcount',
                label: 'Process results files',
                route: 'ngs_analyses.process_bam_readcount',
                show: true
            },
        ]
    }

    filter (items: Array<AnalysisStep>): Array<AnalysisStep> {
        return items.filter((item) => item.show)
    }
}
</script>

<style lang="scss" scoped>

</style>
