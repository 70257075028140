<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        title="Mettre à jour le commentaire"
        :max-width="600"
        @cancel="cancel()"
        @validate="save()"
        :loading="form.processing"
    >
        <div class="pa-5">
            <v-row>
                <v-col cols="12">
                    <v-textarea
                        v-model="form.comment"
                        label="Commentaire"
                        placeholder="Entrez votre commentaire"
                        :error-messages="form.errors.comment"
                        outlined
                    ></v-textarea>
                </v-col>
            </v-row>
        </div>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import {TYPE} from 'vue-toastification'
import route from 'ziggy-js'
import get from 'lodash/get'
import {Variant} from "@/models";

@Component({
    components: { DialogConfirmation }
})
export default class NgsEditVariantCommentDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly variant!: Variant

    loading: boolean = false
    form: InertiaForm<{ comment: string | null }> = this.$inertia.form({
        comment: get(this, 'variant.pivot.comment')
    })

    cancel () {
        this.form.reset()
    }

    save () {
        this.form.put(
            route('ngs_analysis_variants.comment.update', { ngs_analysis_variant: (this.variant as any).pivot.id }),
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: () => {
                    this.isDialogOpen = false
                    this.$toast('Commentaire mis à jour', {
                        type: TYPE.SUCCESS
                    })
                }
            }
        )
    }
}
</script>

<style lang="scss" scoped>

</style>
