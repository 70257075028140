<template>
    <layout>
        <v-container>
            <v-row>
                <v-col>
                    <h1>Recherche</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-tabs
                        v-model="tab"
                        background-color="transparent"
                        color="black"
                        fixed-tabs
                    >
                        <v-tab
                            v-for="item in items"
                            :key="item.value"
                        >
                            {{ item.text }}
                            <v-chip v-if="search" small class="ml-1">
                                {{ counters[item.value] }}
                            </v-chip>
                        </v-tab>
                    </v-tabs>
                    <v-card flat color="grey lighten-2" :loading="loading">
                        <v-card-text>
                            <v-row dense>
                                <v-text-field
                                    v-model="form.search"
                                    outlined
                                    dense
                                    hide-details
                                    background-color="white"
                                    placeholder="Rechercher..."
                                    prepend-inner-icon="mdi-magnify"
                                >
                                </v-text-field>
                                <v-btn class="ml-3" height="40" color="white" depressed @click="queryAnReset"
                                       :loading="loading">
                                    Rechercher
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-data-table
                        v-if="!loading && search"
                        :headers="headers"
                        :items="results.data"
                        item-key="id"
                        :footer-props="footerProps"
                        :server-items-length="results.total"
                        :loading="loading"
                        :items-per-page="Number(results.per_page)"
                        :page="Number(results.current_page)"
                        disable-sort
                        disable-filtering
                        hide-default-header
                        no-data-text="Aucun résultat ne correspond à votre recherche"
                        @update:page="updatePage"
                        @update:items-per-page="updateItemsPerPage"
                    >
                        <template v-slot:item.primary="{ item }">
                            <v-list-item v-if="form.scope === 'samples'"
                                         @click="$visit('samples.show', { sample: item.id })">
                                <template v-slot:default="{ active }">
                                    <v-list-item-content>
                                        <v-list-item-title class="text-body-2 font-weight-bold">
                                            {{ item.full_diamic }}
                                        </v-list-item-title>

                                        <v-list-item-subtitle
                                            class="text-body-2"
                                        >
                                            {{ $get(item, 'tumor_type.name', 'NA') }} -
                                            {{ $defaultTo(item.tumor_cellularity_percentage, 'NA') }}% -
                                            Ajouté le {{ formatDate(item.created_at) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-list-item-action-text>Mis à jour le
                                            {{ formatDate(item.updated_at) }}
                                        </v-list-item-action-text>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>
                            <v-list-item v-if="form.scope === 'variants'"
                                         @click="$visit('variants.show', { variant: item.id })">
                                <template v-slot:default="{ active }">
                                    <v-list-item-content>
                                        <v-list-item-title class="text-body-2 font-weight-bold">
                                            chr{{ item.chromosome.name }}:{{ item.position }}
                                            {{ item.reference }}>{{ item.alternate }}
                                            <v-chip label class="py-0 ml-2" color="primary" small>
                                                {{ $get(item, 'gene.gencode_name') }}
                                            </v-chip>
                                        </v-list-item-title>

                                        <v-list-item-subtitle
                                            class="text-body-2"
                                        >
                                            {{ $get(item, 'vep_annotation.hgvsc', 'NA') }} -
                                            {{ $get(item, 'vep_annotation.hgvsp', 'NA') }} -
                                            Ajouté le {{ formatDate(item.created_at) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-list-item-action-text>Mis à jour le
                                            {{ formatDate(item.updated_at) }}
                                        </v-list-item-action-text>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>
                            <v-list-item v-if="form.scope === 'ngs_runs'"
                                         @click="$visit('ngs_runs.show', { ngs_run: item.id })">
                                <template v-slot:default="{ active }">
                                    <v-list-item-content>
                                        <v-list-item-title class="text-body-2 font-weight-bold">
                                            Run NGS #{{ item.id }}
                                        </v-list-item-title>

                                        <v-list-item-subtitle
                                            class="text-body-2"
                                        >
                                            Panel {{ item.panels.map(item => item.name).join(', ') }} -
                                            Créée le {{ formatDate(item.created_at) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-list-item-action-text>
                                            {{ item.libraries_count }} librairies
                                        </v-list-item-action-text>
                                        <v-list-item-action-text>
                                            Mise à jour le
                                            {{ formatDate(item.updated_at) }}
                                        </v-list-item-action-text>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>
                            <v-list-item v-if="form.scope === 'ngs_analyses'"
                                         @click="$visit('ngs_analyses.show', { ngs_analysis: item.id })">
                                <template v-slot:default="{ active }">
                                    <v-list-item-content>
                                        <v-list-item-title class="text-body-2 font-weight-bold">
                                            Run NGS #{{ item.run.id }} - Analyse NGS #{{ item.id }}
                                        </v-list-item-title>

                                        <v-list-item-subtitle
                                            class="text-body-2"
                                        >
                                            Panel {{ item.panel.name }} -
                                            Créée le {{ formatDate(item.created_at) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-list-item-action-text>
                                            {{ item.sample.full_diamic }}
                                        </v-list-item-action-text>
                                        <v-list-item-action-text>
                                            <v-icon
                                                small
                                                :color="qualityControl[item.has_passed_quality_control].color"
                                                class="my-auto mr-1">
                                                {{ qualityControl[item.has_passed_quality_control].icon }}
                                            </v-icon>
                                            Mise à jour le
                                            {{ formatDate(item.updated_at) }}
                                        </v-list-item-action-text>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>
                            <v-list-item v-if="form.scope === 'ngs_pair_analyses'"
                                         @click="$visit('ngs_pair_analyses.show', { ngs_pair_analysis: item.id })">
                                <template v-slot:default="{ active }">
                                    <v-list-item-content>
                                        <v-list-item-title class="text-body-2 font-weight-bold">
                                            Run NGS #{{ item.tumoral_run.id }} - Analyse NGS T/NT #{{ item.id }}
                                        </v-list-item-title>

                                        <v-list-item-subtitle
                                            class="text-body-2"
                                        >
                                            Panel {{ item.tumoral_panel.name }} -
                                            Créée le {{ formatDate(item.created_at) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-list-item-action-text>
                                            {{ item.tumoral_sample.full_diamic }}
                                        </v-list-item-action-text>
                                        <v-list-item-action-text>
                                            <v-icon
                                                small
                                                :color="qualityControl[item.has_passed_quality_control].color"
                                                class="my-auto mr-1">
                                                {{ qualityControl[item.has_passed_quality_control].icon }}
                                            </v-icon>
                                            Mise à jour le
                                            {{ formatDate(item.updated_at) }}
                                        </v-list-item-action-text>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>
                            <v-list-item v-if="form.scope === 'cnv_analyses'"
                                         @click="$visit('cnv_analyses.show', { cnv_analysis: item.id })">
                                <template v-slot:default="{ active }">
                                    <v-list-item-content>
                                        <v-list-item-title class="text-body-2 font-weight-bold">
                                            {{ item.name }} - Analyse CNV #{{ item.id }}
                                        </v-list-item-title>

                                        <v-list-item-subtitle
                                            class="text-body-2"
                                        >
                                            Créée le {{ formatDate(item.created_at) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-list-item-action-text>
                                            {{ item.sample.full_diamic }}
                                        </v-list-item-action-text>
                                        <v-list-item-action-text>
                                            <v-icon
                                                :color="qualityControl[item.has_passed_quality_control].color"
                                                class="my-auto mr-1">
                                                {{ qualityControl[item.has_passed_quality_control].icon }}
                                            </v-icon>
                                            Mise à jour le
                                            {{ formatDate(item.updated_at) }}
                                        </v-list-item-action-text>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-container>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import Layout from "@/Layouts/Layout.vue";
import route from "ziggy-js";
import pickBy from "lodash/pickBy";
import throttle from "lodash/throttle";
import {DataTableHeader} from "vuetify";
import {CnvAnalysis, NgsAnalysis, NgsPairAnalysis, NgsRun, Sample, Variant} from "@/models";
import {Pagination} from '@/laravel';

@Component({
    components: {Layout},
    metaInfo: {
        title: 'Recherche'
    }
})
export default class Search extends Vue {
    @Prop() readonly search!: null | string
    @Prop() readonly scope!: 'samples' | 'variants' | 'ngs_runs' | 'ngs_analyses' | 'ngs_pair_analyses' | 'cnv_analyses'
    @Prop() readonly itemsPerPage!: null | string
    @Prop() readonly page!: null | string
    @Prop() readonly counters!: { samples: number; variants: number; ngs_analyses: number; cnv_analyses: number; }
    @Prop() readonly results!: Pagination<Sample | Variant | NgsAnalysis | NgsPairAnalysis | CnvAnalysis | NgsRun>

    @Watch('tab', {immediate: true})
    onTabSelection(val: number) {
        this.form.scope = Object.keys(this.counters)[val]
        this.queryAnReset()
    }

    loading: boolean = false

    form: { search: null | string; scope: string; 'items-per-page': null | string; page: null | string; } = {
        search: this.search,
        scope: this.scope,
        'items-per-page': this.itemsPerPage,
        page: this.page,
    }

    items: { value: string; text: string; }[] = [
        {
            value: 'samples',
            text: 'Échantillons',
        },
        {
            value: 'variants',
            text: 'Variants',
        },
        {
            value: 'ngs_runs',
            text: 'Runs NGS'
        },
        {
            value: 'ngs_analyses',
            text: 'Analyses NGS T-only',
        },
        {
            value: 'ngs_pair_analyses',
            text: 'Analyses NGS T/NT',
        },
        {
            value: 'cnv_analyses',
            text: 'Analyses CNV',
        }
    ]

    qualityControl: { [key: string]: { color: string; icon: string; text: string; }} = {
        true: {
            color: 'success',
            icon: 'mdi-check-circle',
            text: 'PASS'
        },
        false: {
            color: 'error',
            icon: 'mdi-close-circle',
            text: 'FAILED'
        },
        null: {
            color: 'grey',
            icon: 'mdi-help-circle',
            text: 'NA'
        }
    }

    tab: number = 0

    footerProps: object = {
        'items-per-page-options': [10, 25, 50, 100]
    }

    headers: Array<DataTableHeader> = [
        {
            text: 'Primary',
            value: 'primary',
            width: '100%'
        }
    ]

    updatePage(e: string): void {
        this.form.page = e
        this.query()
    }

    updateItemsPerPage(e: string): void {
        this.form["items-per-page"] = e
        this.query()
    }

    query() {
        this.$inertia.get(
            route('search'),
            pickBy(this.form),
            {
                preserveState: true,
                onStart: () => {
                    this.loading = true
                },
                onFinish: () => {
                    this.loading = false
                }
            }
        )
    }

    queryAnReset() {
        this.form.page = '1'
        this.loading = true
        this.query()
    }

    created() {
        this.query = throttle(this.query, 3000)
    }
}
</script>

<style scoped>

</style>
