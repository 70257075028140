<template>
  <v-btn color="white" :text="$vuetify.breakpoint.mdAndUp" :icon="$vuetify.breakpoint.mdAndDown" href @click="$emit('click')">
    <v-icon>{{ icon }}</v-icon>
    <span class="ml-1 d-none d-lg-flex text-subtitle-2">
      <slot></slot>
    </span>
  </v-btn>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class NavbarBtn extends Vue {
  @Prop() readonly icon!: string
  @Prop() readonly to!: string
}
</script>

<style scoped lang="scss">
</style>
