<template>
    <layout>
        <v-container>
            <v-row>
                <v-col cols="12" md="3">
                    <p class="text-h5 font-weight-bold">
                        Jetons d'accès personnels
                    </p>
                    <p>
                        Vous pouvez générer un jeton d'accès personnel pour chaque application que vous utilisez et qui
                        a besoin d'accéder à l'API d'OncoBench.
                    </p>
                </v-col>
                <v-col cols="12" md="9">
                    <div v-if="accessToken">
                        <p class="font-weight-bold mb-0">Votre nouveau jeton d'accès personnel</p>
                        <p>Assurez-vous de l'enregistrer - vous ne pourrez plus y accéder.</p>
                        <v-row dense>
                            <v-col cols="6">
                                <v-text-field outlined :value="accessToken" dense readonly>
                                    <template v-slot:append>
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="pb-1" icon v-bind="attrs" v-on="on" @click="copyAccessToken" @mouseleave="restoreCopyMessage">
                                                    <v-icon>mdi-content-copy</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ copyMessage }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    <p class="font-weight-bold mb-0">Ajouter un jeton d'accès personnel</p>
                    <p>Saisissez le nom de votre application, et nous vous renverrons un jeton d'accès personnel
                        unique.</p>
                    <v-row dense>
                        <v-col cols="3">
                            <label class="font-weight-bold">Nom du jeton</label>
                            <v-text-field outlined v-model="form.name" dense
                                          :error-messages="form.errors.name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="3">
                            <label class="font-weight-bold">Date d'expiration</label>
                            <date-picker :outlined="true" v-model="form.expired_at" :dense="true"
                                         :error-messages="form.errors.name"></date-picker>
                        </v-col>
                    </v-row>
                    <v-btn color="primary" @click="submit" :disabled="!form.isDirty" depressed class="mb-5"
                           :loading="form.processing">
                        Créer un jeton d'accès personnel
                    </v-btn>
                    <p class="font-weight-bold mb-0">
                        Jetons d'accès personnels actifs ({{ accessTokens.length }})
                    </p>
                    <p>
                        Les jetons d'accès personnels ne sont pas révoqués à leur expiration.
                    </p>
                    <v-row dense>
                        <v-col cols="6">
                            <v-data-table
                                :headers="headers"
                                :items="accessTokens"
                                hide-default-footer
                                no-data-text="Aucun jeton d'accès personnel"
                                disable-sort
                                disable-filtering
                                disable-pagination
                            >
                                <template v-slot:item.created_at="{ item }">
                                    {{ formatDate(item.created_at) }}
                                </template>
                                <template v-slot:item.last_used_at="{ item }">
                                    {{ item.last_used_at !== null ? formatDate(item.last_used_at) : 'Jamais' }}
                                </template>
                                <template v-slot:item.expired_at="{ item }">
                                    {{ item.expired_at !== null ? formatDate(item.expired_at) : 'Jamais' }}
                                </template>
                                <template v-slot:item.action="{ item }">
                                    <v-btn x-small outlined color="error" @click="revoke(item)">Révoquer</v-btn>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Layout from '@/Layouts/Layout.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import DatePicker from '@/Components/generic/DatePicker.vue'
import {DataTableHeader} from 'vuetify'
import route from 'ziggy-js'
import {TYPE} from 'vue-toastification'
import {AccessToken} from "@/models";
import {AccessTokenForm} from "@/forms";
import {Page} from "@inertiajs/inertia";

@Component({
    components: {
        DatePicker,
        Layout
    },
    metaInfo: {
        title: 'Jetons d\'accès personnel'
    }
})
export default class AccessTokens extends Vue {
    @Prop() readonly accessTokens: Array<AccessToken>

    accessToken: string | null = null

    form: InertiaForm<AccessTokenForm> = this.$inertia.form({
        name: '',
        expired_at: null
    })

    headers: Array<DataTableHeader> = [
        {
            value: 'name',
            text: 'Nom du token'
        },
        {
            value: 'created_at',
            text: 'Créé'
        },
        {
            value: 'last_used_at',
            text: 'Dernière utilisation'
        },
        {
            value: 'expired_at',
            text: 'Expire'
        },
        {
            value: 'action',
            text: null
        }
    ]

    copyMessage: string = 'Copie du jeton d\'accès personnel'

    submit () {
        this.form.post(route('access_tokens.store'), {
            preserveScroll: true,
            onSuccess: (page: Page) => {
                this.form.reset()
                this.accessToken = (page.props.flash as any).data
                this.$toast('Votre nouveau jeton d\'accès personnel a été créé.', {
                    type: TYPE.SUCCESS
                })
            }
        })
    }

    revoke (item: AccessToken) {
        const answer = confirm('Êtes-vous sûr de vouloir révoquer ce jeton d\'accès personnel ? Cette action ne peut être annulée.')

        if (answer) {
            this.$inertia.delete(route('access_tokens.destroy', { access_token: item.id }), {
                preserveScroll: true,
                onSuccess: () => {
                    this.$toast('Le jeton d\'accès personnel a été supprimé.', {
                        type: TYPE.ERROR
                    })
                }
            })
        }
    }

    copyAccessToken () {
        this.copyMessage = 'Copié'
        navigator.clipboard.writeText(this.accessToken)
    }

    restoreCopyMessage () {
        this.copyMessage = 'Copie du jeton d\'accès personnel'
    }
}
</script>

<style lang="scss" scoped>

</style>
