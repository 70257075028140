<template>
    <div class="pa-5">
        <div class="mb-5 font-weight-bold">Informations générales</div>
        <element-row label="Analyse">
            {{ cnvAnalysis.name }}
        </element-row>
        <element-row label="Statut">
            <cnv-status-progress :status="cnvAnalysis.cnv_analysis_status"></cnv-status-progress>
        </element-row>
        <element-row label="Date de l'analyse">
            {{ formatDate(cnvAnalysis.date) }} ({{ cnvAnalysis.operator.name }})
        </element-row>
        <element-row label="Dernière modification">
            {{ formatDate(cnvAnalysis.updated_at, true) }}
        </element-row>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import CnvStatusProgress from '@/Components/domain-specific/cnv/helpers/CnvStatusProgress.vue'
import { CnvAnalysis } from '@/models'

@Component({
    components: {
        CnvStatusProgress,
        ElementRow
    }
})
export default class CnvGeneralInfoSection extends Vue {
    @Prop() readonly cnvAnalysis!: CnvAnalysis
}
</script>

<style lang="scss" scoped>

</style>
