<template>
    <div>
        <span class="font-weight-medium">Demande de lecture en cours:</span>
        <ul>
            <li v-for="reviewRequest in reviewRequests" :key="reviewRequest.id" >
                {{ reviewRequest.recipient.first_name + ' ' + reviewRequest.recipient.last_name + ' (' + formatDate(reviewRequest.created_at) + ')' }}
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'
import {ReviewRequest} from "@/models";

@Component
export default class CnvReviewRequestList extends Vue {
    @Prop() readonly reviewRequests!: Array<ReviewRequest>
}
</script>

<style scoped lang="scss">
</style>
