<template>
    <div class="pa-5" v-if="cnvAnalysis.quality_control_content">
        <div class="mb-5 font-weight-bold">Contrôle qualité</div>
        <element-row label="Fichier de QC">
            <v-btn color="primary" icon :href="$route('cnv_analyses.qc_file', cnvAnalysis.id)">
                <v-icon>mdi-download</v-icon>
            </v-btn>
        </element-row>
        <element-row label="Passe les critères de QC"
        >
            <v-icon
                    :color="qualityControl[cnvAnalysis.has_passed_quality_control].color" class="my-auto mr-1">
                {{ qualityControl[cnvAnalysis.has_passed_quality_control].icon }}
            </v-icon>
            <span class="my-auto text--disabled">{{ qualityControl[cnvAnalysis.has_passed_quality_control].text }}</span>
        </element-row>
        <element-row v-if="cnvAnalysis.has_passed_quality_control === false" label="Commentaire de QC"
        >
            {{ $get(cnvAnalysis, 'review.validation_comment') }}
        </element-row>
        <element-row label="Scores de qualité">
            <span class="font-weight-bold mr-1">MAPD:</span>
            <span
            :class="`font-weight-bold ${Number.parseFloat(cnvAnalysis.quality_control_content.mapd).toFixed(1) <= mapdLimit ? 'success' : 'error'}--text`">{{
                Number.parseFloat(cnvAnalysis.quality_control_content.mapd).toFixed(1)
            }}</span>
        </element-row>
        <element-row>
            <span class="font-weight-bold my-0 mr-1">ndSNPQC:</span>
            <span
                :class="`font-weight-bold ${Math.round(cnvAnalysis.quality_control_content.nd_snp_qc) >= ndSnpQcLimit ? 'success' : 'error'}--text`">{{
                    Math.round(cnvAnalysis.quality_control_content.nd_snp_qc)
                }}</span>
        </element-row>
        <element-row>
            <span class="font-weight-bold my-0 mr-1">ndWavinessSD:</span>
            <span
                :class="`font-weight-bold ${Number.parseFloat(cnvAnalysis.quality_control_content.nd_waviness_sd).toFixed(2) <= ndWavinessSdLimit ? 'success' : 'error'}--text`">{{
                    Number.parseFloat(cnvAnalysis.quality_control_content.nd_waviness_sd).toFixed(2)
                }}</span>
        </element-row>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import {CnvAnalysis} from "@/models";

@Component({
    components: {
        ElementRow
    }
})
export default class CnvQualityControlSection extends Vue {
    @Prop() readonly cnvAnalysis!: CnvAnalysis

    mapdLimit: number = 0.3
    ndSnpQcLimit: number = 26
    ndWavinessSdLimit: number = 0.12
    qualityControl: { [key: string]: { color: string; icon: string; text: string; }} = {
        true: {
            color: 'success',
            icon: 'mdi-check-circle',
            text: 'PASS'
        },
        false: {
            color: 'error',
            icon: 'mdi-close-circle',
            text: 'FAILED'
        },
        null: {
            color: 'grey',
            icon: 'mdi-help-circle',
            text: 'NA'
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
