<template>
    <div>
        <slot name="activator">
        </slot>
        <v-dialog
            class="fill-height"
            v-model="dialogOpen"
            :max-width="maxWidth"
            :persistent="persistent"
            transition="dialog-bottom-transition"
            open-delay="1500"
            :fullscreen="fullscreen"
        >
            <v-card :flat="hideTitle" class="overflow-y-auto">

                <v-card-title :class="titleColor" v-if="!hideTitle">
                    <v-row no-gutters>
                        <v-col cols="8">
                            <slot name="header" v-if="!hideTitle">
                                <span class="headline font-weight-bold">{{ title }}</span>
                            </slot>
                        </v-col>
                        <v-spacer></v-spacer>
                        <slot name="actions"></slot>
                        <v-btn @click="close()" color="primary" text>
                            <v-icon small class="mr-1">mdi-close</v-icon>
                            {{ closeLabel }}
                        </v-btn>
                    </v-row>
                </v-card-title>

                <v-divider v-if="!hideTitle"></v-divider>

                <slot></slot>
            </v-card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
import {Vue, Component, Prop, VModel} from 'vue-property-decorator'

@Component
export default class DialogDisplayAction extends Vue {
    @VModel() dialogOpen!: boolean
    @Prop({default: 'false'}) readonly action!: boolean
    @Prop({default: false}) persistent!: boolean
    @Prop({default: 'Informations'}) title!: string
    @Prop({default: 'Fermer'}) closeLabel!: string
    @Prop({default: 450}) maxWidth!: number | string
    @Prop() readonly titleColor?: string
    @Prop({default: false}) readonly hideTitle?: boolean
    @Prop({default: false}) readonly fullscreen?: boolean

    close() {
        this.dialogOpen = false
    }
}
</script>

<style scoped lang="scss">
:deep(.v-dialog) {
    overflow-y: hidden;
    overflow-x: hidden;
}
</style>
