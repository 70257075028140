<template>
    <dialog-confirmation
        v-model="dialog"
        title="Mettre à jour l'échantillon"
        width="600"
        :persistent="true"
        @validate="submit"
        @cancel="resetForm"
        :loading="sampleForm.processing"
    >
        <v-form ref="newSampleForm" class="ma-3">
            <v-alert
                text
                dense
                color="warning"
                border="left"
            >
                Les modifications que vous êtes sur le point de faire seront appliquées sur l'ensemble des analyses
                utilisant cet échantillon.
            </v-alert>
            <v-text-field
                v-model="sampleForm.diamic"
                label="N° DIAMIC"
                outlined
                color="primary"
                :error-messages="sampleForm.errors.diamic"
            ></v-text-field>
            <v-text-field
                v-model="sampleForm.diamic_suffix"
                label="Bloc"
                outlined
                color="primary"
                :error-messages="sampleForm.errors.diamic_suffix"
            ></v-text-field>
            <v-text-field
                v-model="sampleForm.laboratory_number"
                label="N° prélèvement"
                outlined
                color="primary"
                :error-messages="sampleForm.errors.laboratory_number"
            ></v-text-field>
            <v-text-field
                v-model="sampleForm.nip"
                label="NIP"
                outlined
                color="primary"
                :error-messages="sampleForm.errors.nip"
            ></v-text-field>
            <v-text-field
                v-model="sampleForm.genno_number"
                label="N° demande GENNO"
                outlined
                color="primary"
                :error-messages="sampleForm.errors.genno_number"
            ></v-text-field>
            <v-select
                v-model="sampleForm.tumor_type_id"
                :items="tumorTypes"
                item-value="id"
                item-text="name"
                label="Diagnostic"
                outlined
                color="primary"
                :error-messages="sampleForm.errors.tumor_type_id"
                @change="applyNonTumoralIfNeeded"
            ></v-select>
            <v-text-field
                v-model.number="sampleForm.tumor_cellularity_percentage"
                label="Pourcentage de la tumeur"
                type="number" prefix="%" outlined color="primary"
                :error-messages="sampleForm.errors.tumor_cellularity_percentage"
                :disabled="sampleForm.tumor_type_id === 15"
            ></v-text-field>
        </v-form>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import route from 'ziggy-js'
import {TYPE} from 'vue-toastification'
import {Sample, TumorType} from "@/models";
import {SampleForm} from "@/forms";

@Component({
    components: {
        DialogConfirmation
    }
})
export default class UpdateSampleDialog extends Vue {
    @VModel() dialog!: boolean
    @Prop() readonly sample!: Sample
    @Prop() readonly tumorTypes!: Array<TumorType>
    @Watch('dialog')
    onDialogOpening(val: boolean) {
        if (val) {
            this.resetForm()
            this.$axios.get<any>(route('samples.index')).then(({ data }) => {
                this.samples = data
            })
            this.sampleForm.diamic = this.sample.diamic
            this.sampleForm.diamic_suffix = this.sample.diamic_suffix
            this.sampleForm.laboratory_number = this.sample.laboratory_number
            this.sampleForm.nip = this.sample.nip
            this.sampleForm.genno_number = this.sample.genno_number
            this.sampleForm.tumor_type_id = this.sample.tumor_type_id
            this.sampleForm.patient_id = null
            this.sampleForm.tumor_cellularity_percentage = this.sample.tumor_cellularity_percentage
        }
    }

    samples: any[] = []

    sampleForm: InertiaForm<SampleForm> = this.$inertia.form({
        diamic: this.sample.diamic,
        diamic_suffix: this.sample.diamic_suffix,
        laboratory_number: this.sample.laboratory_number,
        nip: this.sample.nip,
        genno_number: this.sample.genno_number,
        tumor_type_id: this.sample.tumor_type_id,
        patient_id: null,
        tumor_cellularity_percentage: this.sample.tumor_cellularity_percentage
    })

    resetForm() {
        this.sampleForm.reset()
        this.sampleForm.clearErrors()
    }

    submit() {
        this.$nextTick(() => {
            this.sampleForm.put(
                route('samples.update', {sample: this.sample.id}),
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: () => {
                        this.$toast('Échantillon mis à jour', {
                            type: TYPE.SUCCESS
                        })
                        this.dialog = false
                        this.$emit('updated')
                        this.resetForm()
                    }
                }
            )
        })
    }

    applyNonTumoralIfNeeded() {
        if (this.sampleForm.tumor_type_id === 15) {
            this.sampleForm.tumor_cellularity_percentage = 0
        }
    }
}
</script>

<style scoped lang="scss">
</style>
