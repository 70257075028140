<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        title="Ajouter une publication à l'annotation"
        max-width="1100"
        width="1100"
        @validate="submit()"
        @cancel="resetForm()"
        :loading="loadingForm"
        :validateDisabled="$get(publication, 'uid', null) === null"
    >
        <v-form class="ma-3">
            <v-row class="mt-3">
                <v-col>
                    <v-text-field
                        label="PMID"
                        v-model="form.publication_id"
                        :error-messages="form.errors.publication_id"
                        outlined
                        clearable
                        dense
                    >
                        <template v-slot:append-outer>
                            <v-btn
                                @click="load()"
                                :loading="loadingPublication"
                                color="primary"
                                block
                                depressed
                                class="mb-2"
                            >
                                Charger informations
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <div v-if="$get(publication, 'uid', null) !== null" class="mx-3">
                <element-row label="URL" :leftColSize="1" :righttColSize="11">
                    <v-btn link color="primary" text :href="`https://pubmed.ncbi.nlm.nih.gov/${publication.uid}`" target="_blank">
                        {{ publication.uid }}
                    </v-btn>
                </element-row>
                <element-row label="Titre" :leftColSize="1" :righttColSize="11">
                    {{ publication.title }}
                </element-row>
                <element-row label="Journal" :leftColSize="1" :righttColSize="11">
                    {{ publication.fulljournalname }}
                </element-row>
                <element-row label="Auteurs" :leftColSize="1" :righttColSize="11">
                    {{
                        isEmpty(publication.authors) ? null : publication.authors.map((author) => author.name).toString()
                    }} <!-- .toString() -->
                </element-row>
                <v-row>
                    <v-col>
                        <element-row label="Date" :leftColSize="2" :righttColSize="10" :no-gutters="true">
                            {{ publication.pubdate }}
                        </element-row>
                    </v-col>
                    <v-col>
                        <element-row label="Volume" :leftColSize="2" :righttColSize="10" :no-gutters="true">
                            {{ publication.volume }}
                        </element-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-row no-gutters>
                            <v-col cols="1" class="grey--text text-start">
                                <label>Evidence *</label>
                            </v-col>
                            <v-col cols="11">
                                <label class="black--text">
                                    <v-textarea
                                        v-model="form.evidence"
                                        :error-messages="form.errors.evidence"
                                        class="mt-1"
                                        rows="3"
                                        dense
                                        outlined
                                    ></v-textarea>
                                </label>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-form>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import ElementRow from '@/Components/generic/ElementRow.vue'
import {isEmpty} from 'lodash'
import {InertiaForm} from '@inertiajs/inertia-vue'
import route from 'ziggy-js'
import {Page} from "@inertiajs/inertia";

@Component({
    components: {
        ElementRow,
        DialogConfirmation
    }
})
export default class AddPublicationDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop({ default: null }) readonly itemId?: number | null
    @Prop({ default: null }) readonly tumorTypeId?: number | null

    @Watch('isDialogOpen', {
        immediate: true
    })
    onDialogOpening (val: boolean) {
        if (val) {
            this.resetForm()
            this.form.tumor_type_id = this.tumorTypeId
        }
    }

    private loadingForm: boolean = false
    private loadingPublication: boolean = false

    form: InertiaForm<{publication_id: null | string; evidence: null | string; tumor_type_id: null | number;}> = this.$inertia.form({
        publication_id: null,
        evidence: null,
        tumor_type_id: this.tumorTypeId
    })

    publication: {
        uid: string | null,
        issue: string | null,
        pages: string | null,
        title: string | null,
        source: string | null,
        volume: string | null,
        authors: Array<string>,
        pubdate: string | null,
        fulljournalname: string | null,
        lang: Array<string>,
        references: string | null,
        elocationid: string | null
    } = {
        uid: null,
        issue: null,
        pages: null,
        title: null,
        source: null,
        volume: null,
        authors: [],
        pubdate: null,
        fulljournalname: null,
        lang: [],
        references: null,
        elocationid: null
    }

    resetForm () {
        this.form.reset()
        this.form.clearErrors()
        this.publication = {
            uid: null,
            issue: null,
            pages: null,
            title: null,
            source: null,
            volume: null,
            authors: [],
            pubdate: null,
            fulljournalname: null,
            lang: [],
            references: null,
            elocationid: null
        }
    }

    submit () {
        this.form.post(
            route('variants.publications.store', [this.itemId]),
            {
                preserveState: true,
                preserveScroll: true,
                onStart: () => {
                    this.loadingForm = true
                },
                onSuccess: () => {
                    this.$emit('publication-added', this.publication)
                    this.isDialogOpen = false
                    this.resetForm()
                },
                onFinish: () => {
                    this.loadingForm = false
                }
            }
        )
    }

    load () {
        this.$inertia.get(route('publications.show', [this.form.publication_id]), {},
            {
                preserveScroll: true,
                preserveState: true,
                onStart: () => {
                    this.loadingPublication = true
                },
                onSuccess: (page: Page) => {
                    this.publication = (page.props.flash as any).data.content ? (page.props.flash as any).data.content : (page.props.flash as any).data
                },
                onFinish: () => {
                    this.loadingPublication = false
                }
            })
    }

    isEmpty (value?: any) {
        return isEmpty(value)
    }
}
</script>

<style scoped lang="css">
:deep(.v-input__append-outer) {
    margin-top: 1px !important;
}
</style>
