<template>
    <panel title="Liste des librairies" :expandable="true">
        <template>
            <ngs-libraries-table
                :items="items"
                :tumor-types="tumorTypes"
            ></ngs-libraries-table>
        </template>
    </panel>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Panel from '@/Components/generic/Panel.vue'
import NgsLibrariesTable from "@/Components/domain-specific/ngs/tables/NgsLibrariesTable.vue";
import {NgsLibrary, TumorType} from "@/models";

@Component({
    components: {
        NgsLibrariesTable,
        Panel,
    }
})
export default class NgsRunLibrariesCard extends Vue {
    @Prop() readonly items!: Array<NgsLibrary>
    @Prop() readonly tumorTypes!: Array<TumorType>
}
</script>

<style scoped lang="scss">
</style>
