<template>
    <layout :fill="true">
        <v-row align="center"
               justify="center">
            <v-col class="text-center">
                <p class="icon-big">🔬</p>
                <p class="text-h5 font-weight-bold">Chargement de l'analyse CNV #{{ cnvAnalysis.id }} en cours ...</p>
                <p>Si rien ne se passe au bout de quelques minutes, veuillez rafraîchir la page.</p>
                <v-progress-circular
                    indeterminate
                    color="primary"

                ></v-progress-circular>

            </v-col>
        </v-row>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Layout from '@/Layouts/Layout.vue'
import {CnvAnalysis} from "@/models";

@Component({
    components: {
        Layout
    },
    metaInfo: {
        title: 'Chargement'
    }
})
export default class LoadingCnvAnalysis extends Vue {
    @Prop() readonly cnvAnalysis!: CnvAnalysis

    loader: number

    mounted () {
        this.loader = window.setInterval(() => {
            this.$inertia.reload()
        }, 5000)

    }

    beforeDestroy() {
        clearInterval(this.loader)
    }
}
</script>

<style scoped>
.icon-big {
    font-size: 5rem;
}
</style>
