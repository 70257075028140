<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date"
                :label="label"
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                :outlined="outlined"
                color="primary"
                :hide-details="hideDetails"
                :dense="dense"
                clearable
                :error-messages="errorMessages"
                :readonly="readonly"
                :disabled="disabled"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            no-title
            scrollable
            color="primary"
            @input="saveSelection($event)"
        >
        </v-date-picker>
    </v-menu>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Vue, Component, Prop, VModel, Ref} from 'vue-property-decorator'

@Component
export default class DatePicker extends Vue {
    @Prop() label?: string
    @Prop({ default: false }) hideDetails!: boolean;
    @Prop({ default: false }) dense!: boolean;
    @Prop({ default: false }) outlined!: boolean;
    @Prop({ default: null }) errorMessages!: string | null;
    @Prop({ default: false }) readonly!: boolean;
    @Prop({ default: false }) disabled!: boolean;
    @Ref('menu') readonly menuComponent!: HTMLFormElement
    @VModel() date!: Date

    menu: boolean = false;

    saveSelection (date: Event) {
        this.menuComponent.save(date)
        this.menu = false
    }
}
</script>

<style scoped lang="scss">
</style>
