<template>
    <v-card :flat="hideTitle" :loading="loading">

        <!-- Title -->
        <v-card-title :class="titleColor" v-if="!hideTitle">
            <slot name="header" v-if="!hideTitle">
                <span class="headline font-weight-bold">{{ title }}</span>
            </slot>
        </v-card-title>

        <v-divider v-if="!hideTitle"></v-divider>

        <!-- Body -->
        <slot></slot>

        <!-- Actions -->
        <slot name="actions">
        </slot>
    </v-card>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Card extends Vue {
    @Prop() readonly title?: string
    @Prop() readonly titleColor?: string
    @Prop({ default: false }) readonly hideTitle?: boolean
    @Prop({ default: false }) readonly loading?: boolean
}
</script>

<style scoped lang="scss">
</style>
