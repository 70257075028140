<template>
    <v-dialog
        v-model="isDialogOpen"
        transition="dialog-bottom-transition"
        open-delay="1500"
        width="1100"
        persistent
    >

        <card>
            <template v-slot:header>
                {{ `Liste des annotations ${partialAnnotationsCount}/${annotationsCount}` }}
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-checkbox
                    v-model="partiallyAnnotatedOnly"
                    label="En attente de validation"
                    class="mr-3"
                    dense
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    v-model="armOnly"
                    label="Bras"
                    class="mr-3"
                    dense
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    v-model="segmentOnly"
                    label="Segments"
                    class="mr-3"
                    dense
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    v-model="geneOnly"
                    label="Gènes"
                    class="mr-3"
                    dense
                    hide-details
                ></v-checkbox>
                <v-text-field
                    v-model="search"
                    placeholder="Rechercher..."
                    hide-details
                    dense
                    outlined
                    clearable
                ></v-text-field>
            </template>
            <v-container fluid>
                <cnv-annotations-table
                    :items="filteredAnnotations"
                    :loading="loading"
                    :search="search"
                    :disabled="disabled"
                    @update="reloadAnnotations"
                ></cnv-annotations-table>
            </v-container>
            <v-card-actions class="text-right justify-end">
                <v-spacer></v-spacer>
                <v-btn @click="close()" color="primary" text>
                    <v-icon small class="mr-1">mdi-close</v-icon>
                    Fermer
                </v-btn>
            </v-card-actions>
        </card>
    </v-dialog>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import CnvAnnotationsTable from '@/Components/domain-specific/cnv/tables/CnvAnnotationsTable.vue'
import route from 'ziggy-js'
import Card from '@/Components/generic/Card.vue'
import {CnvAnalysis, CnvAnnotation} from "@/models";

@Component({
    components: {
        Card,
        CnvAnnotationsTable,
    }
})
export default class CnvAnnotationsListDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly cnvAnalysis!: CnvAnalysis
    @Prop({ default: false }) readonly disabled!: boolean

    @Watch('isDialogOpen')
    onDialogChanged (val: boolean) {
        if (val) {
            this.loading = true
            this.$axios.get(route('cnv_analyses.annotations', { cnv_analysis: this.cnvAnalysis.id }))
                .then(({ data }) => {
                    this.annotations = data
                })
                .finally(() => {
                    this.loading = false
                })
        } else {
            this.annotations = []
        }
    }

    annotations: Array<CnvAnnotation> = []

    loading: boolean = false

    search: string | null = null

    partiallyAnnotatedOnly: boolean = false
    geneOnly: boolean = true
    segmentOnly: boolean = true
    armOnly: boolean = true

    reloadAnnotations () {
        this.$axios.get(route('cnv_analyses.annotations', { cnv_analysis: this.cnvAnalysis.id }))
            .then(({ data }) => {
                this.annotations = data
            })
            .finally(() => {
                this.loading = false
            })

        this.$emit('updated')
    }

    get partialAnnotationsCount (): number {
        return this.annotations.filter((item) => item.validator).length
    }

    get annotationsCount (): number {
        return this.annotations.length
    }

    get filteredAnnotations (): Array<CnvAnnotation> {
        const geneAnnotations: Array<CnvAnnotation> = this.geneOnly ? this.annotations.filter((item) => item.source_type === 'gene') : []
        const segmentAnnotations: Array<CnvAnnotation> = this.segmentOnly ? this.annotations.filter((item) => item.source_type === 'segment') : []
        const armAnnotations: Array<CnvAnnotation> = this.armOnly ? this.annotations.filter((item) => item.source_type === 'arm') : []
        const annotations: Array<CnvAnnotation> = [...geneAnnotations, ...segmentAnnotations, ...armAnnotations]
        return this.partiallyAnnotatedOnly ? annotations.filter((item) => item.validator === null) : annotations
    }

    close () {
        this.isDialogOpen = false
    }
}
</script>

<style lang="scss" scoped>

</style>
