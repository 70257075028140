<template>
    <dialog-confirmation
        v-model="dialogOpen"
        :hide-validate-btn="true"
        cancel-label="Fermer"
        title="Annotation des bases de données"
        max-width="900"
        @cancel="resetListStatus()"
    >
        <div class="pa-5" v-if="gene && dialogOpen">
            <v-row>
                <v-col cols="12">
                    <p class="text-h6 font-weight-bold">Informations générales</p>
                    <element-row label="Gène">{{ gene.gencode_name }}</element-row>
                    <element-row label="Type">{{ gene.type }}</element-row>
                    <element-row label="Position">{{ gene.full_location }}</element-row>
                </v-col>
            </v-row>
            <v-divider class="my-3" v-if="gene.oncokb_annotation"></v-divider>
            <v-row v-if="gene.oncokb_annotation">
                <v-col cols="12">
                    <p class="text-h6 font-weight-bold">OncoKB
                        <v-chip small v-if="gene.has_relevant_oncokb_annotation" class="ml-3" label color="success">
                            Compatible
                        </v-chip>
                    </p>
                    <element-row label="Oncogène">{{ gene.oncokb_annotation.is_oncogene ? 'Oui' : 'Non' }}</element-row>
                    <element-row label="Suppresseur de tumeur">
                        {{ gene.oncokb_annotation.is_tumor_suppressor ? 'Oui' : 'Non' }}
                    </element-row>
                </v-col>
            </v-row>
            <v-divider class="my-3" v-if="gene.cosmic_annotation"></v-divider>
            <v-row v-if="gene.cosmic_annotation">
                <v-col cols="12">
                    <p class="text-h6 font-weight-bold">Cancer Gene Census
                        <v-chip small v-if="gene.has_relevant_cosmic_annotation" class="ml-3" label color="success">
                            Compatible
                        </v-chip>
                    </p>
                    <element-row label="Niveau d'évidence">{{
                            gene.cosmic_annotation.tier === 1 ? 'High' : 'Low'
                        }}
                    </element-row>
                    <element-row label="Rôle dans le cancer">{{ gene.cosmic_annotation.role.toString() }}</element-row>
                    <element-row label="Type d'altération">{{
                            gene.cosmic_annotation.mutation_type.map(item => alterationType[item]).toString()
                        }}
                    </element-row>
                    <element-row label="Niveau des altérations">
                        <span v-if="gene.cosmic_annotation.somatic">Somatic ({{
                                gene.cosmic_annotation.tumortype_somatic.toString()
                            }})</span>
                        <span v-if="gene.cosmic_annotation.germline">Germline ({{
                                gene.cosmic_annotation.tumortype_germline.toString()
                            }})</span>
                    </element-row>
                </v-col>
            </v-row>
            <v-divider class="my-3" v-if="!isEmpty(gene.pubmed_annotations) && !isEmpty(gene.publications)"></v-divider>
            <v-row v-if="!isEmpty(gene.pubmed_annotations) || !isEmpty(gene.publications)">
                <v-col cols="12">
                    <p class="text-h6 font-weight-bold">Publications
                        <v-chip small>{{ (gene.pubmed_annotations.length + gene.publications.length) }}</v-chip>
                        <v-btn icon class="ml-1" color="primary" @click="isPublicationsShown=!isPublicationsShown">
                            <v-icon>{{ isPublicationsShown ? 'mdi-chevron-right' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                    </p>
                    <div v-if="isPublicationsShown">
                        <v-row v-for="annotation in gene.pubmed_annotations" :key="`pubmed-${annotation.id}`" dense>
                            <v-col cols="12">
                                <element-row>
                                    <template #label>
                                        <v-btn
                                            link
                                            color="primary"
                                            text
                                            small
                                            block
                                            class="ml-1"
                                            :href="`https://pubmed.ncbi.nlm.nih.gov/${$get(annotation, 'publication.content.uid')}`"
                                            target="_blank">
                                            {{ $get(annotation, 'publication.content.uid') }}
                                            <v-icon class="ml-1" small>mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </template>
                                    <p><span class="font-weight-medium">
                                        {{ $get(annotation, 'publication.content.title') }}
                                        </span>
                                        <v-chip x-small
                                                v-if="areMatchingAnnotations(annotation.alteration_id, alterationId)"
                                                class="ml-1" label color="success">
                                            Compatible
                                        </v-chip>
                                    </p>
                                </element-row>
                            </v-col>
                        </v-row>
                        <v-row v-for="annotation in publications" :key="`pubmed-${annotation.id}`" dense>
                            <v-col cols="12">
                                <element-row>
                                    <template #label>
                                        <v-btn
                                            link
                                            color="primary"
                                            text
                                            small
                                            block
                                            class="ml-1"
                                            :href="`https://pubmed.ncbi.nlm.nih.gov/${$get(annotation, 'publication.content.uid')}`"
                                            target="_blank">
                                            {{ $get(annotation, 'publication.content.uid') }}
                                            <v-icon class="ml-1" small>mdi-open-in-new</v-icon>
                                        </v-btn>
                                        <v-btn
                                            color="error"
                                            small
                                            block
                                            depressed
                                            outlined
                                            @click="removePublication(annotation.id)"
                                        >
                                            Supprimer
                                        </v-btn>
                                    </template>
                                    <p><span class="font-weight-medium">
                                        {{ $get(annotation, 'publication.content.title') }}
                                        </span>
                                        <v-chip x-small
                                                v-if="areMatchingAnnotations(annotation.alteration_id, alterationId)"
                                                class="ml-1" label color="success">
                                            Compatible
                                        </v-chip>
                                    </p>
                                    <p><span class="text--disabled">Commentaire:
                                        {{ $get(annotation, 'evidence') }}
                                        </span>
                                    </p>
                                    <p class="text-small text-caption text-right">Ajouté par
                                        {{ $get(annotation, 'user.name') }}</p>
                                </element-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn
                        outlined
                        class="my-2"
                        @click="publicationDialogOpen = true"
                        block
                        color="primary"
                    >
                        Ajouter une publication <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="my-3" v-if="!isEmpty(gene.oncobench_annotations)"></v-divider>
            <v-row v-if="!isEmpty(gene.oncobench_annotations)">
                <v-col cols="12">
                    <p class="text-h6 font-weight-bold">OncoBench
                        <v-chip small>{{ gene.oncobench_annotations.length }}</v-chip>
                        <v-btn icon class="ml-1" color="primary" @click="isAnnotationsShown=!isAnnotationsShown">
                            <v-icon>{{ isAnnotationsShown ? 'mdi-chevron-right' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                    </p>
                    <div v-if="isAnnotationsShown">
                        <v-row v-for="annotation in gene.oncobench_annotations" :key="annotation.id" class="pl-5">
                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="12">
                                        <p class="subtitle-1 font-weight-bold">Analyse CNV #{{
                                                annotation.cnv_analysis_id
                                            }}
                                            <v-btn class="ml-3" color="primary" icon
                                                   :href="$route('cnv_analyses.show', { cnv_analysis: annotation.cnv_analysis_id, _query: { gene: gene.gencode_name } })"
                                                   target="_blank">
                                                <v-icon>mdi-chevron-right</v-icon>
                                            </v-btn>
                                        </p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <element-row label="Altération">
                                            {{ alterationIdentifier(annotation.alteration_id) }}
                                            <v-chip small
                                                    v-if="areMatchingAnnotations(annotation.alteration_id, alterationId)"
                                                    class="ml-3"
                                                    label color="success">
                                                Compatible
                                            </v-chip>

                                        </element-row>
                                    </v-col>
                                </v-row>
                                <v-divider class="my-3"></v-divider>
                                <v-row>
                                    <v-col cols="12">
                                        <element-row label="Reporté par">{{ annotation.reporter.name }} <span
                                            class="grey--text">({{ formatDate(annotation.created_at) }})</span>
                                        </element-row>
                                        <element-row label="Validé par">
                                            {{ $get(annotation, 'validator.name', 'Non-validé') }} <span v-if="annotation.validator"
                                                                                           class="grey--text">({{
                                                formatDate(annotation.updated_at)
                                            }})</span>
                                        </element-row>
                                        <element-row label="Justification">{{ annotation.evidence }}</element-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </div>
        <add-publication-dialog
            v-model="publicationDialogOpen"
            :alteration-id="alterationId"
            :item-id="$get(gene, 'id', null)"
            type="gene"
            @publication-added="reloadAnnotations"
        ></add-publication-dialog>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import ElementRow from '@/Components/generic/ElementRow.vue'
import {isEmpty} from 'lodash'
import Btn from '@/Components/generic/Btn.vue'
import AddPublicationDialog from '@/Components/domain-specific/cnv/dialogs/AddPublicationDialog.vue'
import route from 'ziggy-js'
import {TYPE} from 'vue-toastification'
import {CnvAlteration, CnvPublication, Gene} from "@/models";

@Component({
    components: {
        AddPublicationDialog,
        Btn,
        ElementRow,
        DialogConfirmation
    }
})
export default class CnvDatabaseAnnotationDialog extends Vue {
    @VModel() dialogOpen!: boolean
    @Prop() readonly gene!: Gene | null
    @Prop({ default: null }) readonly alterationId?: number | null

    publicationDialogOpen: boolean = false

    isPublicationsShown: boolean = false
    isAnnotationsShown: boolean = false

    alterationType: { [key: string]: string } = {
        F: 'Frameshift',
        D: 'Large deletion',
        N: 'Nonsense',
        Mis: 'Missense',
        S: 'Splice site',
        T: 'Translocation',
        A: 'Amplification',
        O: 'Other'
    }

    alterationIdentifier (id: number): string | null {
        if (this.alterations) {
            const alteration = this.alterations.find(alteration => alteration.id === id)
            return alteration ? alteration.name : null
        }
        return null
    }

    get alterations (): CnvAlteration[] {
        return (this.$page.props.alterations as CnvAlteration[]) ?? []
    }

    get publications (): Array<CnvPublication> {
        return this.gene.publications
    }

    isEmpty (value?: any) {
        return isEmpty(value)
    }

    reloadAnnotations () {
        this.$emit('publication-added')
    }

    removePublication (publicationId: number) {
        this.$inertia.delete(
            route('cnv_publications.destroy', {
                cnv_publication: publicationId
            }),
            {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.$emit('publication-added')
                    this.$toast('Publication supprimée', {
                        type: TYPE.ERROR
                    })
                }
            }
        )
    }

    isAmplificationOrGainAnnotation (alterationId: number): boolean {
        return alterationId === 1 || alterationId === 3
    }

    isOtherTypeAnnotation (alterationId: number): boolean {
        return alterationId === 2 || alterationId === 4 || alterationId === 5
    }

    areMatchingAnnotations (alterationId: number, annotationAlterationId: number): boolean {
        if (this.isAmplificationOrGainAnnotation(alterationId) && this.isAmplificationOrGainAnnotation(annotationAlterationId)) {
            return true
        }

        if (this.isOtherTypeAnnotation(alterationId) && this.isOtherTypeAnnotation(annotationAlterationId)) {
            return true
        }

        return false
    }

    resetListStatus () {
        this.isPublicationsShown = false
        this.isAnnotationsShown = false
    }
}
</script>

<style lang="scss" scoped>

</style>
