<template>
    <div>
        <panel title="Informations générales" :expandable="true">
            <template>
                <v-row dense no-gutters>
                    <v-col cols="12" lg="10">
                        <v-row no-gutters>
                            <v-col cols="12" md="6" lg="6">
                                <ngs-general-info-section :ngs-analysis="ngsAnalysis"
                                                          type="simple"></ngs-general-info-section>
                                <v-divider></v-divider>
                                <ngs-library-section :ngs-analysis="ngsAnalysis" type="simple"></ngs-library-section>
                                <v-divider></v-divider>
                                <ngs-sample-info-section
                                    :ngs-analysis="ngsAnalysis"
                                    type="simple"
                                    :related-cnv-analyses="relatedCnvAnalyses"
                                    :related-ngs-analyses="relatedNgsAnalyses"
                                    :related-ngs-pair-analyses="relatedNgsPairAnalyses"
                                ></ngs-sample-info-section>
                                <v-divider></v-divider>

                                <ngs-comment-reviews-section :ngs-analysis="ngsAnalysis"
                                                             type="simple"></ngs-comment-reviews-section>
                            </v-col>
                            <v-divider
                                v-show="$vuetify.breakpoint.mdAndUp"
                                vertical
                            ></v-divider>
                            <v-col cols="12" md="6" lg="6">
                                <ngs-results-file-section :ngs-analysis="ngsAnalysis"
                                                          type="simple"></ngs-results-file-section>
                                <v-divider></v-divider>
                                <ngs-quality-control-section :ngs-analysis="ngsAnalysis"
                                                             type="simple"></ngs-quality-control-section>
                                <v-divider></v-divider>
                                <ngs-metrics-section :ngs-analysis="ngsAnalysis" type="simple"></ngs-metrics-section>
                                <v-divider></v-divider>
                                <ngs-reports-section :ngs-analysis="ngsAnalysis" type="simple"></ngs-reports-section>
                                <v-divider v-if="missingVariants.length > 0"></v-divider>
                                <ngs-missing-variants-section v-if="missingVariants.length > 0" :missing-variants="missingVariants"></ngs-missing-variants-section>
                            </v-col>
                        </v-row>
                        <v-divider v-show="$vuetify.breakpoint.mdAndDown"></v-divider>
                    </v-col>
                    <v-divider
                        v-show="$vuetify.breakpoint.lgAndUp"
                        vertical
                    ></v-divider>
                    <v-col cols="12" lg="2" class="pa-3">
                        <div class="font-weight-bold text-center">
                            Actions
                        </div>
                        <v-btn
                            @click="openViewerDialog()"
                            color="primary"
                            depressed
                            class="ma-1"
                            large
                            block
                        >
                            <v-icon class="mx-3">mdi-view-sequential</v-icon>
                            IGV viewer
                        </v-btn>
                        <v-btn
                            @click="openCoverageDialog()"
                            color="primary"
                            depressed
                            class="ma-1"
                            large
                            block
                        >
                            <v-icon class="mx-3">mdi-magnify</v-icon>
                            Couverture
                        </v-btn>
                        <v-btn
                            @click="openDialogAnnotatedVariants()"
                            color="primary"
                            depressed
                            class="ma-1"
                            large
                            block
                        >
                            <v-icon class="ma-1">mdi-list-status</v-icon>
                            Annotations
                        </v-btn>
                        <v-btn
                            @click="openQcEditSample()"
                            color="primary"
                            depressed
                            :disabled="readonly || reviewStatus === 'completed'"
                            class="ma-1"
                            large
                            block
                        >
                            <v-icon class="mx-3">mdi-checkbox-outline</v-icon>
                            Contrôle qualité
                        </v-btn>
                        <v-btn
                            @click="openDialogEditSample()"
                            color="primary"
                            depressed
                            :disabled="readonly || reviewStatus === 'completed'"
                            class="ma-1"
                            large
                            block
                        >
                            <v-icon class="ma-1">mdi-beaker-outline</v-icon>
                            Échantillon
                        </v-btn>
                        <v-btn
                            @click="openDialogEditAllelicFrequency()"
                            color="primary"
                            depressed
                            :disabled="readonly"
                            class="ma-1"
                            large
                            block
                        >
                            <v-icon class="ma-1">mdi-filter</v-icon>
                            Fréquence allélique
                        </v-btn>
                        <v-btn
                            @click="openDialogEditGeneInterest()"
                            color="primary"
                            depressed
                            :disabled="readonly"
                            class="ma-1"
                            large
                            block
                        >
                            <v-icon class="ma-1">mdi-format-list-bulleted</v-icon>
                            Gènes d'intérêt
                        </v-btn>
                        <v-btn
                            @click="openDialogAddVariant()"
                            color="primary"
                            depressed
                            :disabled="readonly"
                            :loading="reportLoading"
                            class="ma-1"
                            large
                            block
                        >
                            <v-icon class="ma-1">mdi-plus</v-icon>
                            Ajouter variant
                        </v-btn>
                        <v-btn
                            @click="openDialogReview()"
                            color="primary"
                            depressed
                            :disabled="readonly || reviewStatus === 'completed'"
                            class="ma-1"
                            large
                            block
                        >
                            <v-icon class="ma-1">mdi-text-box-check</v-icon>
                            Lectures
                        </v-btn>
                        <v-btn
                            @click="generateReport()"
                            color="primary"
                            depressed
                            :disabled="readonly || reviewStatus !== 'completed' || ngsAnalysis.is_not_fully_annotated"
                            :loading="reportLoading"
                            class="ma-1"
                            large
                            block
                        >
                            <v-icon class="ma-1">mdi-printer</v-icon>
                            Générer rapport
                        </v-btn>
                        <v-btn
                            @click="openDialogEditComment()"
                            color="primary"
                            depressed
                            :disabled="readonly"
                            class="ma-1"
                            large
                            block
                        >
                            <v-icon class="ma-1">mdi-pen</v-icon>
                            Commentaire
                        </v-btn>
                        <v-btn
                            @click="openDialogIgnoreAnalysis()"
                            color="warning"
                            depressed
                            :disabled="ngsAnalysis.is_archived"
                            class="ma-1"
                            large
                            block
                        >
                            <v-icon class="ma-1">{{ readonly ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                            {{ readonly ? 'Restaurer analyse' : 'Ignorer analyse' }}
                        </v-btn>
                        <v-btn
                            v-if="canDeleteNgsAnalysis"
                            @click="openDialogDeleteAnalysis()"
                            color="error"
                            depressed
                            class="ma-1"
                            large
                            block
                        >
                            <v-icon class="ma-1">mdi-delete</v-icon>
                            Supprimer analyse
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </panel>
        <ngs-edit-allelic-frequency-dialog v-model="allelicFrequencyDialogOpen" :ngs-analysis="ngsAnalysis"
                                           type="simple"></ngs-edit-allelic-frequency-dialog>
        <ngs-edit-gene-interest-dialog v-model="editGeneInterestDialogOpen" :ngs-analysis="ngsAnalysis" :genes="genes"
                                       type="simple"></ngs-edit-gene-interest-dialog>
        <ngs-edit-comment-dialog v-model="editCommentDialogOpen" :ngs-analysis="ngsAnalysis"
                                 type="simple"></ngs-edit-comment-dialog>
        <ngs-edit-qc-dialog v-model="editQcDialogOpen" :ngs-analysis="ngsAnalysis" :users="users"
                            type="simple"></ngs-edit-qc-dialog>
        <ngs-delete-analysis-dialog v-model="deleteDialogOpen" :ngs-analysis="ngsAnalysis"
                                    type="simple"></ngs-delete-analysis-dialog>
        <ngs-review-dialog v-model="reviewDialogOpen" :ngs-analysis="ngsAnalysis"
                           @updated="$emit('created')" analysis-type="simple"></ngs-review-dialog>
        <ngs-add-variant-dialog v-model="addVariantDialogOpen" :ngs-analysis="ngsAnalysis" analysis-type="simple"></ngs-add-variant-dialog>
        <update-sample-dialog v-model="editSampleDialogOpen" :sample="ngsAnalysis.library.sample"
                              :tumor-types="tumorTypes" @updated="$emit('created')"
                              type="simple"></update-sample-dialog>
        <ngs-variants-dialog v-model="annotatedVariantsDialogOpen" :ngs-analysis="ngsAnalysis" type="simple"
                             :readonly="readonly"></ngs-variants-dialog>
        <ngs-ignore-dialog v-model="ignoreDialogOpen" :ngs-analysis="ngsAnalysis" type="simple"></ngs-ignore-dialog>
        <ngs-global-viewer-dialog
            v-model="viewerDialogOpen"
            :ngs-analysis="ngsAnalysis"
            type="simple"
        >
        </ngs-global-viewer-dialog>
        <ngs-amplicon-coverage-dialog
            v-model="coverageDialogOpen"
            :ngs-analysis="ngsAnalysis"
            type="simple"
        ></ngs-amplicon-coverage-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import Btn from '@/Components/generic/Btn.vue'
import Panel from '@/Components/generic/Panel.vue'
import NgsGeneralInfoSection from '@/Components/domain-specific/ngs/sections/NgsGeneralInfoSection.vue'
import NgsSampleInfoSection from '@/Components/domain-specific/ngs/sections/NgsSampleInfoSection.vue'
import NgsCommentReviewsSection from '@/Components/domain-specific/ngs/sections/NgsCommentReviewsSection.vue'
import NgsResultsFileSection from '@/Components/domain-specific/ngs/sections/NgsResultsFileSection.vue'
import NgsQualityControlSection from '@/Components/domain-specific/ngs/sections/NgsQualityControlSection.vue'
import NgsReportsSection from '@/Components/domain-specific/ngs/sections/NgsReportsSection.vue'
import NgsEditCommentDialog from '@/Components/domain-specific/ngs/dialogs/NgsEditCommentDialog.vue'
import NgsEditGeneInterestDialog from '@/Components/domain-specific/ngs/dialogs/NgsEditGeneInterestDialog.vue'
import NgsDeleteAnalysisDialog from '@/Components/domain-specific/ngs/dialogs/NgsDeleteAnalysisDialog.vue'
import NgsMetricsSection from '@/Components/domain-specific/ngs/sections/NgsMetricsSection.vue'
import NgsReviewDialog from "@/Components/domain-specific/ngs/dialogs/NgsReviewDialog.vue";
import NgsEditAllelicFrequencyDialog from "@/Components/domain-specific/ngs/dialogs/NgsEditAllelicFrequencyDialog.vue";
import UpdateSampleDialog from "@/Components/domain-specific/sample/dialogs/UpdateSampleDialog.vue";
import route from "ziggy-js";
import {TYPE} from "vue-toastification";
import NgsEditQcDialog from "@/Components/domain-specific/ngs/dialogs/NgsEditQcDialog.vue";
import NgsIgnoreDialog from "@/Components/domain-specific/ngs/dialogs/NgsIgnoreDialog.vue";
import NgsVariantsDialog from "@/Components/domain-specific/ngs/dialogs/NgsVariantsDialog.vue";
import {Gene, NgsAnalysis, TumorType, User, Variant} from "@/models";
import NgsLibrarySection from "@/Components/domain-specific/ngs/sections/NgsLibrarySection.vue";
import NgsGlobalViewerDialog from "@/Components/domain-specific/ngs/dialogs/NgsGlobalViewerDialog.vue";
import NgsAmpliconCoverageDialog from "@/Components/domain-specific/ngs/dialogs/NgsAmpliconCoverageDialog.vue";
import NgsMissingVariantsSection from "@/Components/domain-specific/ngs/sections/NgsMissingVariantsSection.vue";
import NgsAddVariantDialog from "@/Components/domain-specific/ngs/dialogs/NgsAddVariantDialog.vue";

@Component({
    components: {
        NgsAddVariantDialog,
        NgsMissingVariantsSection,
        NgsAmpliconCoverageDialog,
        NgsGlobalViewerDialog,
        NgsLibrarySection,
        NgsVariantsDialog,
        NgsIgnoreDialog,
        NgsEditQcDialog,
        UpdateSampleDialog,
        NgsEditAllelicFrequencyDialog,
        NgsReviewDialog,
        NgsDeleteAnalysisDialog,
        NgsEditGeneInterestDialog,
        NgsEditCommentDialog,
        NgsReportsSection,
        NgsQualityControlSection,
        NgsResultsFileSection,
        NgsCommentReviewsSection,
        NgsSampleInfoSection,
        NgsGeneralInfoSection,
        NgsMetricsSection,
        Panel,
        ElementRow,
        Btn
    }
})
export default class NgsAnalysisSummaryCard extends Vue {
    @Prop() readonly ngsAnalysis!: NgsAnalysis
    @Prop() readonly genes!: Array<Gene>
    @Prop() readonly tumorTypes!: Array<TumorType>
    @Prop() readonly users!: Array<User>
    @Prop() readonly missingVariants!: Array<Variant>
    @Prop() readonly relatedCnvAnalyses!: Array<{ id: number, created_at: string }>
    @Prop() readonly relatedNgsAnalyses!: Array<{ id: number, created_at: string, panel: { name: string } }>
    @Prop() readonly relatedNgsPairAnalyses!: Array<{ id: number, created_at: string, tumoral_panel: { name: string } }>
    @Prop({default: false}) readonly readonly?: boolean
    @Prop({default: false}) readonly canDeleteNgsAnalysis?: boolean

    editCommentDialogOpen: boolean = false
    editGeneInterestDialogOpen: boolean = false
    editSampleDialogOpen: boolean = false
    deleteDialogOpen: boolean = false
    reviewDialogOpen: boolean = false
    allelicFrequencyDialogOpen: boolean = false
    reportLoading: boolean = false
    editQcDialogOpen: boolean = false
    annotatedVariantsDialogOpen: boolean = false
    ignoreDialogOpen: boolean = false
    viewerDialogOpen: boolean = false
    coverageDialogOpen: boolean = false
    addVariantDialogOpen: boolean = false

    review: { [key: string]: { color: string; icon: string; } } = {
        completed: {
            color: 'success',
            icon: 'mdi-check'
        },
        pending: {
            color: 'warning',
            icon: 'mdi-clock-outline'
        },
        empty: {
            color: 'grey',
            icon: 'mdi-help'
        }
    }

    get reviewStatus(): string {
        if (this.ngsAnalysis.review && Object.prototype.hasOwnProperty.call(this.ngsAnalysis.review, 'reviewer_id')) {
            if (this.ngsAnalysis.review.validator_id !== null) {
                return 'completed'
            }
            return 'pending'
        }
        return 'empty'
    }

    openDialogEditComment() {
        this.editCommentDialogOpen = true
    }

    openDialogEditGeneInterest() {
        this.editGeneInterestDialogOpen = true
    }

    openDialogDeleteAnalysis() {
        this.deleteDialogOpen = true
    }

    openDialogReview() {
        this.reviewDialogOpen = true
    }

    openDialogAddVariant() {
        this.addVariantDialogOpen = true
    }

    openDialogEditAllelicFrequency() {
        this.allelicFrequencyDialogOpen = true
    }

    openDialogEditSample() {
        this.editSampleDialogOpen = true
    }

    generateReport() {
        this.reportLoading = true

        this.$axios.post(
            route('ngs_analyses.generate_report', {ngs_analysis: this.ngsAnalysis.id}),
            {},
            {
                responseType: 'blob',
                timeout: 200000
            }
        ).then(() => {
            this.$toast('Rapport généré', {
                type: TYPE.SUCCESS
            })
            this.$emit('created')
        }).finally(() => {
            this.reportLoading = false
        })
    }

    openQcEditSample() {
        this.editQcDialogOpen = true
    }

    openDialogAnnotatedVariants() {
        this.annotatedVariantsDialogOpen = true
    }

    openDialogIgnoreAnalysis() {
        this.ignoreDialogOpen = true
    }

    openViewerDialog() {
        this.viewerDialogOpen = true
    }

    openCoverageDialog() {
        this.coverageDialogOpen = true
    }
}

</script>

<style scoped lang="scss">
</style>
