<template>
    <div>
        <slot name="activator">
        </slot>
        <v-dialog
            v-model="dialogOpen"
            :width="width"
            :max-width="maxWidth"
            :persistent="persistent"
            transition="dialog-bottom-transition"
            open-delay="1500"
        >

            <card
                :title="title"
            >

                <slot></slot>
            </card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import Card from '@/Components/generic/Card.vue'

@Component({
    components: { Card }
})
export default class DialogContent extends Vue {
    @VModel() dialogOpen!: boolean
    @Prop({ default: true }) persistent!: boolean
    @Prop({ default: 'Fenêtre de dialogue' }) title!: string
    @Prop({ default: null }) width!: number | string | null
    @Prop({ default: 600 }) maxWidth!: number | string
}
</script>

<style scoped lang="scss">

</style>
