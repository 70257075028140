<template>
    <guest-layout :fill="true">
        <v-row align="center"
               justify="center">
            <v-col class="text-center">
                <p class="icon-big">⚠️</p>
                <p class="text-h5 font-weight-bold">Erreur de connexion</p>
                <p>OncoBench n'est actuellement pas en mesure de se connecter à votre compte en utilisant l'authentification unique (SSO). Contactez un administrateur pour obtenir de l'aide.</p>
            </v-col>
        </v-row>
    </guest-layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Vue } from 'vue-property-decorator'
import GuestLayout from '@/Layouts/GuestLayout.vue'

@Component({
    components: { GuestLayout },
    metaInfo: {
        title: 'Erreur'
    }
})
export default class ErrorSaml extends Vue {}
</script>

<style lang="scss" scoped>

</style>
