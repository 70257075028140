<template>
    <guest-layout :fill="true">
        <v-row justify="center">
            <v-col cols="10" sm="8" md="4" lg="3">
                <p class="text--disabled">Vous avez oublié votre mot de passe ? Aucun problème. Il suffit de nous
                    communiquer votre adresse
                    électronique et nous vous enverrons un lien de réinitialisation du mot de passe qui vous permettra
                    d'en choisir un nouveau.</p>
                <p class="success--text">{{ status }}</p>
                <v-text-field
                    v-model="form.email"
                    label="E-mail"
                    :error-messages="form.errors.email"
                    autofocus
                    dense
                    outlined
                ></v-text-field>
                <v-btn
                    color="primary"
                    @click="submit"
                    :loading="form.processing"
                    block
                    depressed
                >Envoyer
                </v-btn>
            </v-col>
        </v-row>
    </guest-layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'
import GuestLayout from '@/Layouts/GuestLayout.vue'
import { InertiaForm } from '@inertiajs/inertia-vue'
import route from 'ziggy-js'
import {ForgotPasswordForm} from "@/forms";

@Component({
    components: { GuestLayout },
    metaInfo: {
        title: 'Avez-vous oublié votre mot de passe?'
    }
})
export default class ForgotPassword extends Vue {
    @Prop() readonly status: string

    form: InertiaForm<ForgotPasswordForm> = this.$inertia.form({
        email: ''
    })

    submit () {
        this.form.post(route('password.email'), {
            preserveScroll: true,
            onFinish: () => {
                this.form.reset()
            }
        })
    }
}
</script>

<style lang="scss" scoped>

</style>
