<template>
    <div>
        <div class="pa-5" v-if="type === 'simple'">
            <div class="mb-5 font-weight-bold">Fichiers</div>
            <element-row label="Fichier VCF">
                <v-btn color="primary" icon :href="$route('ngs_analyses.vcf_file', {ngs_analysis: ngsAnalysis.id})">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
            </element-row>
            <element-row label="Fichier BAM">
                <v-btn color="primary" icon :href="$route('ngs_analyses.bam_file', {ngs_analysis: ngsAnalysis.id})">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
            </element-row>
        </div>
        <div class="pa-5" v-if="type === 'pair'">
            <div class="mb-5 font-weight-bold">Fichiers</div>
            <element-row label="Fichier VCF">
                <v-btn color="primary" icon :href="$route('ngs_pair_analyses.vcf_file', {ngs_pair_analysis: ngsAnalysis.id})">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
            </element-row>
            <element-row label="Fichier tumoral BAM">
                <v-btn color="primary" icon :href="$route('ngs_pair_analyses.tumoral_bam_file', {ngs_pair_analysis: ngsAnalysis.id})">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
            </element-row>
            <element-row label="Fichier non-tumoral BAM">
                <v-btn color="primary" icon :href="$route('ngs_pair_analyses.non_tumoral_bam_file', {ngs_pair_analysis: ngsAnalysis.id})">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
            </element-row>
        </div>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import {NgsAnalysis, NgsPairAnalysis} from "@/models";

@Component({
    components: {
        ElementRow
    }
})
export default class NgsResultsFileSection extends Vue {
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'
}
</script>

<style lang="scss" scoped>

</style>
