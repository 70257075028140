<template>
    <v-container fluid>
        <card title-color="primary white--text" :hide-title="hideTitle">
            <template v-slot:header>
                <btn v-show="expandable" @click="toggleCard()" color="white"
                     class="mr-2"
                     :icon="hideCardContent ? 'mdi-chevron-down' : 'mdi-chevron-right'"
                     :icon-only="true">
                </btn>
                <p class="subtitle-1 font-weight-bold mb-0">{{ title }}</p>
                <v-spacer></v-spacer>
                <slot name="actions">
                </slot>
            </template>
            <div v-show="!hideCardContent">
            <slot></slot>
            </div>
        </card>
    </v-container>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Vue, Prop } from 'vue-property-decorator'
import Card from '@/Components/generic/Card.vue'
import Btn from '@/Components/generic/Btn.vue'

@Component({
    components: {
        Card,
        Btn
    }
})
export default class Panel extends Vue {
    @Prop() readonly title!: string
    @Prop({ default: false }) readonly hideTitle!: boolean
    @Prop({ default: false }) readonly expandable!: boolean
    private hideCardContent: boolean = false

    private toggleCard () {
        this.hideCardContent = !this.hideCardContent
    }
}
</script>

<style scoped lang="scss">
</style>
