<template>
    <div v-if="cnvAnalysis.id">
        <panel title="Informations générales" :expandable="true">
            <template v-slot:actions>

            </template>
            <template>
                <v-row dense no-gutters>
                    <v-col cols="12" md="10">
                <v-row no-gutters>
                    <v-col cols="12" md="6" lg="6">
                        <cnv-general-info-section :cnv-analysis="cnvAnalysis"></cnv-general-info-section>
                        <v-divider></v-divider>
                        <cnv-sample-info-section
                            :cnv-analysis="cnvAnalysis"
                            :tumor-types="tumorTypes"
                            :related-cnv-analyses="relatedCnvAnalyses"
                            :related-ngs-analyses="relatedNgsAnalyses"
                            :related-ngs-pair-analyses="relatedNgsPairAnalyses"
                        ></cnv-sample-info-section>
                        <v-divider v-if="cnvAnalysis.has_passed_quality_control"></v-divider>
                        <cnv-results-file-section v-if="cnvAnalysis.has_passed_quality_control"
                                                  :cnv-analysis="cnvAnalysis"></cnv-results-file-section>
                        <v-divider></v-divider>
                        <cnv-comment-reviews-section :cnv-analysis="cnvAnalysis"></cnv-comment-reviews-section>
                    </v-col>
                    <v-divider
                        v-show="$vuetify.breakpoint.mdAndUp"
                        vertical
                    ></v-divider>
                    <v-col cols="12" md="6" lg="6">
                        <cnv-quality-control-section :cnv-analysis="cnvAnalysis"></cnv-quality-control-section>
                        <v-divider></v-divider>
                        <cnv-oncoscan-info-section :cnv-analysis="cnvAnalysis"></cnv-oncoscan-info-section>
                        <v-divider></v-divider>
                        <cnv-reports-section :cnv-analysis="cnvAnalysis"></cnv-reports-section>
                    </v-col>
                </v-row>
                    </v-col>
                    <v-divider
                        v-show="$vuetify.breakpoint.mdAndUp"
                        vertical
                    ></v-divider>
                    <v-col cols="12" md="2" class="pa-3">
                        <div class="font-weight-bold text-center">
                            Actions
                        </div>
                        <v-btn
                            @click="openDialogEditSample()"
                            color="primary"
                            depressed
                            class="ma-1"
                            large
                            block
                            :disabled="readonly"
                        >
                            <v-icon class="mx-3">mdi-beaker-outline</v-icon>
                            Échantillon
                        </v-btn>
                        <v-btn
                            @click="openDialogEditComment()"
                            color="primary"
                            depressed
                            class="ma-1"
                            large
                            block
                            :disabled="readonly"
                        >
                            <v-icon class="mx-3">mdi-pen</v-icon>
                            Commentaire
                        </v-btn>
                        <v-btn
                            @click="openDialogAnnotations()"
                            color="primary"
                            depressed
                            class="ma-1"
                            large
                            block
                            :disabled="!$get(cnvAnalysis, 'has_passed_quality_control', null)"
                        >
                            <v-icon class="mx-3">mdi-playlist-edit</v-icon>
                            Annotations
                        </v-btn>
                        <v-btn
                            @click="openDialogReview()"
                            color="primary"
                            depressed
                            class="ma-1"
                            large
                            block
                            :disabled="readonly || reviewStatus === 'completed'"
                        >
                            <v-icon class="mx-3">mdi-text-box-check</v-icon>
                            Lectures
                        </v-btn>
                        <v-btn
                            @click="generateReport()"
                            color="primary"
                            depressed
                            class="ma-1"
                            large
                            block
                            :disabled="readonly || reviewStatus !== 'completed' || cnvAnalysis.is_not_fully_annotated"
                            :loading="reportLoading"
                        >
                            <v-icon class="mx-3">mdi-printer</v-icon>
                            Générer rapport
                        </v-btn>
                        <v-btn
                            @click="openDialogIgnoreAnalysis()"
                            color="warning"
                            depressed
                            class="ma-1"
                            large
                            block
                            :disabled="cnvAnalysis.is_archived"
                        >
                            <v-icon class="mx-3">{{ cnvAnalysis.ignored_at ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                            {{ cnvAnalysis.ignored_at ? 'Restaurer analyse' : 'Ignorer analyse' }}
                        </v-btn>
                        <v-btn
                            v-if="canDeleteCnvAnalysis"
                            @click="openDialogDeleteAnalysis()"
                            color="error"
                            depressed
                            class="ma-1"
                            large
                            block
                        >
                            <v-icon class="mx-3">mdi-delete</v-icon>
                            Supprimer analyse
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </panel>
        <cnv-edit-comment-dialog v-model="editCommentDialogOpen" :cnv-analysis="cnvAnalysis"></cnv-edit-comment-dialog>
        <cnv-review-dialog v-model="reviewDialogOpen" :cnv-analysis="cnvAnalysis"
                           @updated="$emit('created')"></cnv-review-dialog>
        <cnv-delete-dialog v-model="deleteDialogOpen" :cnv-analysis="cnvAnalysis"></cnv-delete-dialog>
        <cnv-ignore-dialog v-model="ignoreDialogOpen" :cnv-analysis="cnvAnalysis"></cnv-ignore-dialog>
        <cnv-annotations-list-dialog v-model="annotationsDialogOpen" :cnv-analysis="cnvAnalysis"
                                     :disabled="readonly" @updated="$emit('updated')"></cnv-annotations-list-dialog>
        <update-sample-dialog v-model="editSampleDialogOpen" :sample="cnvAnalysis.sample"
                              :tumor-types="tumorTypes" @updated="$emit('created')"></update-sample-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Btn from '@/Components/generic/Btn.vue'
import Panel from '@/Components/generic/Panel.vue'
import CnvGeneralInfoSection from '@/Components/domain-specific/cnv/sections/CnvGeneralInfoSection.vue'
import CnvSampleInfoSection from '@/Components/domain-specific/cnv/sections/CnvSampleInfoSection.vue'
import CnvCommentReviewsSection from '@/Components/domain-specific/cnv/sections/CnvCommentReviewsSection.vue'
import CnvResultsFileSection from '@/Components/domain-specific/cnv/sections/CnvResultsFileSection.vue'
import CnvQualityControlSection from '@/Components/domain-specific/cnv/sections/CnvQualityControlSection.vue'
import CnvOncoscanInfoSection from '@/Components/domain-specific/cnv/sections/CnvOncoscanInfoSection.vue'
import CnvReportsSection from '@/Components/domain-specific/cnv/sections/CnvReportsSection.vue'
import CnvEditCommentDialog from '@/Components/domain-specific/cnv/dialogs/CnvEditCommentDialog.vue'
import CnvReviewDialog from '@/Components/domain-specific/cnv/dialogs/CnvReviewDialog.vue'
import {TYPE} from 'vue-toastification'
import CnvDeleteDialog from '@/Components/domain-specific/cnv/dialogs/CnvDeleteDialog.vue'
import route from 'ziggy-js'
import CnvIgnoreDialog from '@/Components/domain-specific/cnv/dialogs/CnvIgnoreDialog.vue'
import CnvAnnotationsListDialog from '@/Components/domain-specific/cnv/dialogs/CnvAnnotationsListDialog.vue'
import UpdateSampleDialog from '@/Components/domain-specific/sample/dialogs/UpdateSampleDialog.vue'
import {CnvAnalysis, TumorType, User} from "@/models";

@Component({
    components: {
        UpdateSampleDialog,
        CnvAnnotationsListDialog,
        CnvIgnoreDialog,
        CnvDeleteDialog,
        CnvReviewDialog,
        CnvEditCommentDialog,
        CnvReportsSection,
        CnvOncoscanInfoSection,
        CnvQualityControlSection,
        CnvResultsFileSection,
        CnvCommentReviewsSection,
        CnvSampleInfoSection,
        CnvGeneralInfoSection,
        Panel,
        Btn
    }
})
export default class CnvSummaryCard extends Vue {
    @Prop() readonly cnvAnalysis!: CnvAnalysis
    @Prop() readonly tumorTypes!: Array<TumorType>
    @Prop() readonly relatedCnvAnalyses!: Array<{ id: number, created_at: string }>
    @Prop() readonly relatedNgsAnalyses!: Array<{ id: number, created_at: string, panel: { name: string } }>
    @Prop() readonly relatedNgsPairAnalyses!: Array<{ id: number, created_at: string, tumoral_panel: { name: string } }>
    @Prop({default: false}) readonly canDeleteCnvAnalysis?: boolean
    @Prop({default: false}) readonly readonly?: boolean

    editCommentDialogOpen: boolean = false
    editSampleDialogOpen: boolean = false
    reviewDialogOpen: boolean = false
    deleteDialogOpen: boolean = false
    ignoreDialogOpen: boolean = false
    annotationsDialogOpen: boolean = false

    reportLoading: boolean = false

    review: { [key: string]: { color: string; icon: string; }} = {
        completed: {
            color: 'success',
            icon: 'mdi-check'
        },
        pending: {
            color: 'warning',
            icon: 'mdi-clock-outline'
        },
        empty: {
            color: 'grey',
            icon: 'mdi-help'
        }
    }

    get reviewStatus(): string {
        if (this.cnvAnalysis.review && Object.prototype.hasOwnProperty.call(this.cnvAnalysis.review, 'reviewer_id')) {
            if (this.cnvAnalysis.review.validator_id !== null) {
                return 'completed'
            }
            return 'pending'
        }
        return 'empty'
    }

    openDialogReview() {
        this.reviewDialogOpen = true
    }

    openDialogEditComment() {
        this.editCommentDialogOpen = true
    }

    openDialogEditSample() {
        this.editSampleDialogOpen = true
    }

    openDialogIgnoreAnalysis() {
        this.ignoreDialogOpen = true
    }

    openDialogDeleteAnalysis() {
        this.deleteDialogOpen = true
    }

    openDialogAnnotations() {
        this.annotationsDialogOpen = true
    }

    generateReport() {
        this.reportLoading = true

        this.$axios.post(
            route('cnv_analyses.generate_report', {cnv_analysis: this.cnvAnalysis.id}),
            {},
            {
                responseType: 'blob',
                timeout: 20000
            }
        ).then(() => {
                this.$toast('Rapport généré', {
                    type: TYPE.SUCCESS
                })
                this.$emit('created')
            }).finally(() => {
            this.reportLoading = false
        })
    }
}

</script>

<style scoped lang="scss">
</style>
