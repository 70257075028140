<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        :title="`Ajouter une ${cnvAnalysis.review ? 'deuxième ' : ' '}lecture`"
        :max-width="600"
        @cancel="cancel()"
        @validate="save()"
        :loading="form.processing || loading"
    >
        <div class="pa-5">
            <v-row v-if="!cnvAnalysis.review">
                <v-col cols="12">
                    <v-select
                        @click:clear="cancel()"
                        v-model="form.reviewer_id"
                        label="1er Lecteur *"
                        placeholder="Veuillez sélectionner le 1er lecteur"
                        :items="users"
                        item-text="name"
                        item-value="id"
                        :error-messages="form.errors.reviewer_id"
                        outlined
                        clearable
                        :autofocus="!cnvAnalysis.review"
                    ></v-select>
                    <v-textarea
                        v-model="form.review_comment"
                        label="Commentaire"
                        placeholder="Entrez votre commentaire (optionnel)"
                        :error-messages="form.errors.review_comment"
                        rows="3"
                        outlined
                        hide-details
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <btn
                        @click="type = 'live'"
                        :disabled="type === 'live' || form.reviewer_id === null"
                        icon="mdi-check"
                        class="mt-0"
                        block
                    >
                        Saisir 2ème lecture
                    </btn>
                </v-col>
                <v-col cols="6">
                    <btn
                        @click="type = 'mail'"
                        :disabled="type === 'mail' || form.reviewer_id === null"
                        icon="mdi-email"
                        class="mt-0"
                        block
                    >
                        Demander 2ème lecture
                    </btn>
                </v-col>
            </v-row>
            <v-row v-if="type === 'live' && form.reviewer_id !== null">
                <v-col cols="12">
                    <v-select
                        v-model="form.validator_id"
                        label="2ème lecteur"
                        placeholder="Veuillez sélectionner le 2ème lecteur"
                        :items="filteredUsers"
                        item-text="name"
                        item-value="id"
                        :error-messages="form.errors.validator_id"
                        outlined
                        clearable
                    ></v-select>
                    <v-textarea
                        v-model="form.validation_comment"
                        label="Commentaire"
                        placeholder="Entrez votre commentaire (optionnel)"
                        :error-messages="form.errors.validation_comment"
                        rows="3"
                        outlined
                        hide-details
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row v-if="type === 'mail' && form.reviewer_id !== null">
                <v-col cols="12">
                    <v-autocomplete
                        label="Destinataire"
                        v-model="form.recipient_id"
                        :items="filteredUsers"
                        :error-messages="form.errors.recipient_id"
                        item-text="name"
                        item-value="id"
                        outlined
                        clearable
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </div>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import Btn from '@/Components/generic/Btn.vue'
import {TYPE} from 'vue-toastification'
import route from 'ziggy-js'
import get from 'lodash/get'
import {CnvAnalysis, User} from "@/models";
import {ReviewForm} from "@/forms";

@Component({
    components: {
        Btn,
        DialogConfirmation
    }
})
export default class CnvReviewDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly cnvAnalysis!: CnvAnalysis

    @Watch('isDialogOpen')
    onDialogChanged(val: boolean) {
        if (val) {
            this.form.reviewer_id = get(this, 'cnvAnalysis.review.reviewer_id', (this.$page.props.user as User).id)
            this.form.review_comment = get(this, 'cnvAnalysis.review.review_comment', null)
        } else {
            this.type = null
            this.form.reset()
            this.form.clearErrors()
        }
    }

    @Watch('type')
    onTypeSelection(type: 'live' | 'mail' | null) {
        if (type !== null) {
            this.form.validator_id = null
            this.form.validation_comment = null
            this.form.recipient_id = null
        }
    }

    loading: boolean = false

    users: Array<User> = []

    type: 'live' | 'mail' | null = null

    form: InertiaForm<ReviewForm> = this.$inertia.form({
        reviewer_id: get(this, 'cnvAnalysis.review.reviewer_id', (this.$page.props.user as User).id),
        review_comment: get(this, 'cnvAnalysis.review.review_comment', null),
        validator_id: null,
        validation_comment: null,
        recipient_id: null,
        validation_type: null
    })

    cancel() {
        this.type = null
        this.form.reset()
    }

    save() {
        this.form.validation_type = this.type

        this.form.post(
            route('cnv_analyses.store_review', {cnv_analysis: this.cnvAnalysis.id}),
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: () => {
                    this.isDialogOpen = false

                    if (this.type === 'mail') {
                        this.$toast('Demande de lecture ajoutée', {
                            type: TYPE.SUCCESS
                        })
                    }

                    if (this.type === 'live') {
                        this.$toast('Lecture ajoutée', {
                            type: TYPE.SUCCESS
                        })

                        if (this.form.validator_id !== null) {
                            this.$toast('Rapport généré', {
                                type: TYPE.SUCCESS
                            })
                        }
                    }

                    setTimeout(() => {this.$emit('updated')}, 3000)
                }
            }
        )
    }

    fetchUsers() {
        this.loading = true
        this.$axios.get(route('users.index')).then(({data}) => {
            this.users = data
        }).finally(() => {
            this.loading = false
        })
    }

    get filteredUsers(): Array<User> {
        return this.users.filter(user => user.id !== this.form.reviewer_id)
    }

    created() {
        this.fetchUsers()
    }
}
</script>

<style lang="scss" scoped>

</style>
