<template>
    <td :colspan="headersLength" class="px-0">
        <v-list class="py-0" dense flat>
            <v-list-group
                v-for="(category, index) in categories"
                :key="index"
                :ripple="false"
                no-action
                sub-group
                @click="fetchGenes(category)"
                v-model="category.state"
                class="black--text type-row ml-n2"
                active-class="black--text"
            >
                <template v-slot:prependIcon>
                    <div v-if="!category.loading && category.state === false">
                        <v-icon>mdi-chevron-down</v-icon>
                    </div>
                    <div v-if="!category.loading && category.state === true">
                        <v-icon>mdi-chevron-left</v-icon>
                    </div>
                    <v-progress-circular
                        v-if="category.loading"
                        indeterminate
                        color="primary"
                        size="16"
                    ></v-progress-circular>
                </template>
                <template v-slot:activator>
                    <v-list-item-content class="ml-12">
                        <v-list-item-title>{{ category.label }}
                            <v-chip v-if="!search" class="ml-3" small>{{
                                    item[`${category.type}_genes_count`]
                                }}
                            </v-chip>
                        </v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item
                    v-if="category.genes.length"
                    class="px-0"
                    align="center"
                >
                    <template v-slot:default>
                        <v-row no-gutters class="gene-row">
                            <v-col
                                v-for="(gene,i) in category.genes"
                                :key="`${category}-gene-${i}`"
                                class="py-3 bordered"
                                cols="4"
                            >
                                <v-row justify="space-between" :class="i % 3 === 0 ? 'indent-gene' : ''">
                                    <v-col class="text-left ml-5">
                                        <span class="ml-1 text-button">{{ gene.gencode_name }}</span>
                                        <v-icon small v-if="gene.pivot.partial">mdi-content-cut</v-icon>
                                        <v-chip
                                            v-if="includes(lohSharedGenes, gene.gencode_name)"
                                            label
                                            small
                                            color="purple"
                                            class="font-weight-bold"
                                            dark
                                        >LOH
                                        </v-chip>
                                    </v-col>
                                    <v-col class="text-right mr-5">
                                        <v-row justify="space-around">
                                            <v-spacer></v-spacer>
                                            <v-col cols="1" class="mx-1">
                                                <v-btn
                                                    v-if="gene.has_oncokb_annotation"
                                                    @click="viewDatabaseDetail(gene, category.type)"
                                                    icon
                                                    plain
                                                    ripple
                                                    small
                                                >
                                                    <img
                                                        src="@/assets/oncokb.png"
                                                        alt="oncokb"
                                                        width="25"
                                                        height="25"
                                                        :class="gene.has_relevant_oncokb_annotation === true ? 'no-background-gradient' : 'background-gradient'"
                                                    >
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="1" class="mx-1">
                                                <v-btn
                                                    v-if="gene.has_cosmic_annotation"
                                                    @click="viewDatabaseDetail(gene, category.type)"
                                                    icon
                                                    plain
                                                    ripple
                                                    small
                                                >
                                                    <img
                                                        src="@/assets/cosmic.png"
                                                        alt="cosmic"
                                                        width="25"
                                                        height="25"
                                                        :class="gene.has_relevant_cosmic_annotation === true ? 'no-background-gradient' : 'background-gradient'"
                                                    >
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="1" class="mx-1">
                                                <v-btn
                                                    v-if="gene.has_pubmed_annotations || gene.has_publications"
                                                    @click="viewDatabaseDetail(gene, category.type)"
                                                    icon
                                                    plain
                                                    ripple
                                                    small>
                                                    <img
                                                        src="@/assets/book.png"
                                                        alt="pubmed"
                                                        width="25"
                                                        height="25"
                                                        :class="gene.has_relevant_pubmed_annotations === true || gene.has_relevant_publications === true ? 'no-background-gradient' : 'background-gradient'"
                                                    >
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="1" class="mx-1">
                                                <v-btn
                                                    v-if="gene.has_oncobench_annotations"
                                                    @click="viewDatabaseDetail(gene, category.type)"
                                                    icon
                                                    plain
                                                    ripple
                                                    small
                                                >
                                                    <img
                                                        src="@/assets/oncobench.png"
                                                        alt="oncobench"
                                                        width="25"
                                                        height="25"
                                                        :class="gene.has_relevant_oncobench_annotations === true ? '' : 'background-gradient'"
                                                    >
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="1" class="ml-1 mr-3">
                                                <v-btn @click="annotateGene(gene, category.type)" color="info" icon
                                                       small
                                                       :disabled="readonly">
                                                    <v-rating
                                                        :value="annotationState(gene)"
                                                        background-color="info lighten-1"
                                                        color="info"
                                                        length="1"
                                                        half-increments
                                                        readonly
                                                    ></v-rating>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </template>
                </v-list-item>
            </v-list-group>
        </v-list>
        <cnv-annotate-element-dialog
            v-model="annotationDialogOpen"
            :item="selectedGene"
            :cnv-analysis-id="item.context.cnv_analysis_id"
            :alteration-id="item.context.alteration_id"
            type="gene"
            @annotated="reloadGenes()"
            @updated="reloadGenes()"
            @deleted="reloadGenes()"
            @publication-added="reloadGeneAndAllGenes()"
        ></cnv-annotate-element-dialog>
        <cnv-database-annotation-dialog
            v-model="databaseDialogOpen"
            :alteration-id="item.context.alteration_id"
            :gene="selectedGene"
            @publication-added="reloadGeneAndAllGenes()"
        ></cnv-database-annotation-dialog>
    </td>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import CnvAnnotateElementDialog from '@/Components/domain-specific/cnv/dialogs/CnvAnnotateElementDialog.vue'
import {AxiosResponse} from 'axios'
import throttle from 'lodash/throttle'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import includes from 'lodash/includes'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import ElementRow from '@/Components/generic/ElementRow.vue'
import CnvDatabaseAnnotationDialog from '@/Components/domain-specific/cnv/dialogs/CnvDatabaseAnnotationDialog.vue'
import route from 'ziggy-js'
import {Gene, Segment} from "@/models";

@Component({
    components: {
        CnvDatabaseAnnotationDialog,
        ElementRow,
        DialogConfirmation,
        CnvAnnotateElementDialog
    }
})
export default class SegmentExpandedRow extends Vue {
    @Prop() readonly item!: Segment | null
    @Prop() readonly headersLength!: number
    @Prop({ default: false }) readonly expanded!: boolean
    @Prop({ default: null }) readonly gene!: string | null
    @Prop({ default: false }) readonly readonly?: boolean
    @Prop({ default: false }) readonly search?: boolean

    @Watch('expanded', { immediate: true })
    onExpansion (val: boolean) {
        if (val) {
            if (this.search) {
                this.categories
                    .forEach(category => {
                        category.state = true
                    })
            } else {
                this.categories.filter(category => category.type !== 'other')
                    .forEach(category => {
                        category.state = true
                    })
            }
            this.reloadGenes()
        } else {
            this.categories.forEach(category => {
                category.state = false
            })
            this.resetGenes()
        }
    }

    annotationDialogOpen: boolean = false
    databaseDialogOpen: boolean = false
    selectedGene: Gene | null = null
    selectedCategory: string | null = null
    categories: Array<{ label: string; type: string; state: boolean; loading: boolean; genes: Array<Gene>}> = [
        {
            label: 'Annotés',
            type: 'annotated',
            state: false,
            loading: false,
            genes: []
        },
        {
            label: 'Base de données',
            type: 'database',
            state: false,
            loading: false,
            genes: []
        },
        {
            label: 'Autres',
            type: 'other',
            state: false,
            loading: false,
            genes: []
        }
    ]

    annotateGene (item: Gene, category: string) {
        if (!this.readonly) {
            this.selectedGene = item
            this.selectedCategory = category
            this.annotationDialogOpen = true
        }
    }

    fetchGenes (category: { label: string; type: string; state: boolean; loading: boolean; genes: Array<Gene>}, force: boolean = false) {
        if (!category.state || force) {
            category.loading = true
            const params: any = {
                segment: this.item.id,
                cnv_analysis: (this.item as any).context.cnv_analysis_id,
                _query: {
                    gene: this.gene,
                    annotation_alteration_id: (this.item as any).context.alteration_id,
                    filtered: this.search
                }
            }
            this.$axios.get(route(`segments.cnv_analyses.genes.${category.type}`, params)).then(({ data }: AxiosResponse) => {
                category.genes = data as Array<Gene>
            }).finally(() => {
                category.loading = false
            })
        }
    }

    get lohSharedGenes (): string[] {
        let concatenatedGenes: string = ''
        this.item.loh_segments.forEach(segment => {
            concatenatedGenes += (segment as any).pivot.shared_genes
        })

        const genes = concatenatedGenes.split(',')

        return uniq(genes)
    }

    viewDatabaseDetail (gene: Gene, category: string) {
        this.selectedGene = gene
        this.selectedCategory = category
        this.databaseDialogOpen = true
    }

    reloadGenes () {
        this.categories.filter((category: { label: string; type: string; state: boolean; loading: boolean; genes: Array<Gene>}) => category.state === true).forEach(category => {
            this.fetchGenes(category, true)
        })
    }

    reloadGeneAndGenes () {
        const category = this.categories.find((category: { label: string; type: string; state: boolean; loading: boolean; genes: Array<Gene>}) => category.type === this.selectedCategory)
        const params: any = {
            segment: this.item.id,
            cnv_analysis: (this.item as any).context.cnv_analysis_id,
            _query: {
                gene: this.gene,
                annotation_alteration_id: (this.item as any).context.alteration_id,
                filtered: this.search
            }
        }
        this.$axios.get(route(`segments.cnv_analyses.genes.${category.type}`, params)).then(({ data }: AxiosResponse) => {
            category.genes = data as Array<Gene>
            this.selectedGene = category.genes.find((gene: Gene) => gene.id === this.selectedGene.id)
        }).finally(() => {
            category.loading = false
        })
    }

    reloadGeneAndAllGenes () {
        this.reloadGeneAndGenes()
        if (this.selectedCategory === 'other') {
            const databaseCategory = this.categories[1]
            this.fetchGenes(databaseCategory, true)
        }
    }

    resetGenes () {
        const source = this.$cancelToken.source()
        source.cancel('Operation cancelled by the user.')
        this.categories.forEach((category: { label: string; type: string; state: boolean; loading: boolean; genes: Array<Gene>}) => {
            category.genes = []
        })
    }

    annotationState (gene: Gene) {
        if ((gene as any).is_partially_annotated) {
            return 0.5
        }
        if ((gene as any).is_annotated) {
            return 1
        }
        return 0
    }

    isEmpty (value?: any) {
        return isEmpty(value)
    }

    includes (collection: Array<any> | Object | string, value: any) {
        return includes(collection, value)
    }

    created () {
        this.reloadGenes = throttle(this.reloadGenes, 150)
    }
}
</script>

<style lang="scss" scoped>
.type-row {
    background-color: #F5F5F5;
}

.type-row:hover {
    background-color: #F5F5F5;
}

.indent-gene {
    margin-left: 110px;
}

.gene-row {
    background-color: #FFFFFF;
}

.background-gradient {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    opacity: 25%;
}

.no-background-gradient {
    filter: none;
    -webkit-filter: none;
    opacity: 100%;
}

.bordered {
    border: rgba(0, 0, 0, 0.12) solid thin;
}
</style>
