<template>
    <div class="mb-3">
        <h3 class="my-3">Publications</h3>
        <v-list two-line v-if="items.length > 0">
            <v-list-item-group>
                <template v-for="(publication, index) in items">
                    <v-list-item link :key="`publication-${index}`" :href="`https://pubmed.ncbi.nlm.nih.gov/${publication.id}`"
                                 target="_blank">
                        <template v-slot:default>
                            <v-list-item-content>
                                <v-list-item-title class="full-text">
                                    <span class="primary--text font-weight-medium">{{ publication.id }}</span> -
                                    {{ $get(publication, 'content.title') }} - <span class="grey--text">{{
                                        $get(publication, 'content.pubdate')
                                    }}</span>
                                </v-list-item-title>

                                <v-list-item-subtitle class="overflow text-pre-line">{{
                                        publication.pivot.evidence
                                    }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click.prevent="deletePublication(publication.id)">
                                    <v-icon color="error">
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </template>
                    </v-list-item>

                    <v-divider
                        v-if="index < items.length - 1"
                        :key="`divider-${index}`"
                    ></v-divider>
                </template>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Btn from '@/Components/generic/Btn.vue'
import {CnvPublication} from "@/models";

@Component({
    components: {
        Btn
    }
})
export default class PublicationsListSection extends Vue {
    @Prop() readonly items!: Array<CnvPublication>

    deletePublication (id: number) {
        this.$emit('deleted', id)
    }
}
</script>

<style scoped lang="scss">
.full-text {
    overflow: unset !important;
    text-overflow: unset !important;
    white-space: unset !important;
}
</style>
