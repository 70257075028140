<template>
    <v-row dense no-gutters>
            <v-btn @click="openDialog" icon plain ripple small class="mr-1">
                <img alt="cosmic" src="@/assets/cosmic.png" width="25" height="25"
                     :class="$get(item, 'vep_annotation.colocated_variants', []).length > 0 ? 'no-background-gradient' : 'background-gradient'">
            </v-btn>
            <v-btn @click="openDialog" icon plain ripple small class="mr-1">
                <img alt="clinvar" src="@/assets/clinvar.png" width="25" height="25"
                     :class="$get(item, 'vep_annotation.clinvar_clnsig', 'NA') !== 'NA' ? 'no-background-gradient' : 'background-gradient'">
            </v-btn>
            <v-btn @click="openDialog" icon plain ripple small class="mr-1">
                <img alt="pubmed" src="@/assets/pubmed.png" width="25" height="25"
                     :class="item.has_relevant_publications ? 'no-background-gradient' : 'background-gradient'">
            </v-btn>
            <v-btn @click="openDialog" icon plain ripple small class="mr-1">
                <img alt="gnomad" src="@/assets/gnomad.png" width="25" height="25"
                     :class="$get(item, 'vep_annotation.gnomad_exomes_af', 'NA') !== 'NA' ? 'no-background-gradient' : 'background-gradient'">
            </v-btn>
            <!--<v-btn @click="openDialog" v-if="item.iarc_annotations.length > 0" outlined color="primary" class="mr-1" icon plain ripple small>
                <span style="font-size: xx-small;">IARC</span>
            </v-btn>-->
        <ngs-database-annotation-dialog
            v-if="isDialogOpen"
            v-model="isDialogOpen"
            :item="item"
            :tumor-type-id="tumorTypeId"
            :readonly="readonly"
        ></ngs-database-annotation-dialog>
    </v-row>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import NgsDatabaseAnnotationDialog from "@/Components/domain-specific/ngs/dialogs/NgsDatabaseAnnotationDialog.vue";
import {Variant} from "@/models";

@Component({
    components: {NgsDatabaseAnnotationDialog}
})
export default class VariantDatabasesBtn extends Vue {
    @Prop() readonly item!: Variant
    @Prop() readonly tumorTypeId?: null | number
    @Prop({default: false}) readonly !: boolean

    isDialogOpen: boolean = false

    openDialog() {
        this.isDialogOpen = true
    }
}
</script>

<style lang="scss" scoped>
.background-gradient {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    opacity: 25%;
}

.no-background-gradient {
    filter: none;
    -webkit-filter: none;
    opacity: 100%;
}
</style>
