<template>
    <panel title="Informations générales" :expandable="true">
        <template v-slot:actions>
            <btn
                @click="dialogOpen = true"
                color="white"
                icon="mdi-check"
                :disabled="value.ngs_run_status === 'finished'"
            >
                Marquer le run comme terminé
            </btn>
        </template>
        <template>
            <v-row no-gutters>
                <v-col cols="12" md="6" lg="6">
                    <div class="pa-5">
                        <element-row label="Opérateur">{{ value.operator.name }}</element-row>
                        <element-row label="Date du run">{{ formatDate(value.date) }}</element-row>
                        <element-row label="Statut" right-col-size="4">
                            <ngs-run-status-progress :status="value.ngs_run_status"></ngs-run-status-progress>
                        </element-row>
                    </div>
                </v-col>
                <v-divider
                    v-show="$vuetify.breakpoint.mdAndUp"
                    vertical
                ></v-divider>
                <v-col cols="12" md="6" lg="6">
                    <div class="pa-5">
                        <element-row label="ID librairie">OB{{ value.id }}</element-row>
                        <element-row label="ID externe">{{
                                $defaultTo(value.external_id, 'Non-renseigné')
                            }}
                        </element-row>
                        <element-row label="MiSeq sample sheet">
                            <v-btn color="primary" icon
                                   :href="$route('ngs_runs.miseq_samplesheet_file', {ngs_run: value.id})">
                                <v-icon>mdi-download</v-icon>
                            </v-btn>
                        </element-row>
                    </div>
                </v-col>
            </v-row>
        </template>
        <ngs-run-mark-as-finished-dialog
            v-model="dialogOpen"
            :ngs-run="value"
        ></ngs-run-mark-as-finished-dialog>
    </panel>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import Panel from '@/Components/generic/Panel.vue'
import NgsRunStatusProgress from '@/Components/domain-specific/ngs/helpers/NgsRunStatusProgress.vue'
import {NgsRun} from "@/models";
import Btn from "@/Components/generic/Btn.vue";
import NgsRunMarkAsFinishedDialog from "@/Components/domain-specific/ngs/dialogs/NgsRunMarkAsFinishedDialog.vue";

@Component({
    components: {
        NgsRunMarkAsFinishedDialog,
        Btn,
        Panel,
        ElementRow,
        NgsRunStatusProgress
    }
})
export default class NgsRunSummaryCard extends Vue {
    @Prop() readonly value!: NgsRun

    dialogOpen: boolean = false
}
</script>

<style scoped lang="scss">
</style>
