<template>
    <v-stepper v-model="stepper">
        <v-stepper-header>
            <template v-for="(content,step) in steps">
                <v-stepper-step color="primary" :key="`stepper-${step}`" :complete="isComplete(step)" :step="step"
                                :rules="[() => !content.hasErrors]">
                    {{ content.title }}
                </v-stepper-step>
                <v-divider
                    v-if="Number(step) !== Object.keys(steps).length"
                    :key="`divider-${step}`"
                ></v-divider>
            </template>
        </v-stepper-header>

        <slot></slot>

        <v-stepper-items>
            <v-stepper-content v-for="(content,step) in steps" :key="`content-${step}`" :step="step">
                <p class="headline font-weight-bold">{{ content.title }}</p>
                <v-divider class="mb-3"></v-divider>
                <slot :name="`step-${step}`" :complete="content.complete"></slot>
                <div v-if="hideButtons">
                    <v-divider class="my-3"></v-divider>
                    <v-row align-content="space-between" justify="end" class="mb-5">
                        <v-col cols="6">
                            <v-btn @click="decrementStepper()"
                                   color="primary"
                                   :dark="content.complete"
                                   outlined
                                   left
                                   absolute
                                   v-if="stepper !== 1">
                                <v-icon>mdi-arrow-left</v-icon>
                                {{ $vuetify.breakpoint.lgAndUp ? 'Annuler' : null }}
                            </v-btn>
                        </v-col>
                        <v-col cols="6">

                            <v-btn @click="incrementStepper()"
                                   color="primary"
                                   :dark="content.complete"
                                   depressed
                                   right
                                   absolute
                                   v-if="Number(step) !== Object.keys(steps).length"
                                   :disabled="!content.complete">
                                {{ $vuetify.breakpoint.lgAndUp ? 'Suivant' : null }}
                                <v-icon>mdi-arrow-right</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'

@Component
export default class Stepper extends Vue {
    @VModel() stepper!: number
    @Prop() readonly steps!: { [key: number]: { [key: string]: string | boolean } }
    @Prop() readonly hideButtons?: boolean

    isComplete (step: number) {
        return this.stepper > step
    }

    incrementStepper () {
        this.stepper++
    }

    decrementStepper () {
        this.stepper--
    }
}
</script>

<style lang="scss">
</style>
