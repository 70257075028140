<template>
    <v-row>
        <v-col cols="12">
            <p class="text-h6 font-weight-bold">Gène</p>
        </v-col>
        <v-col cols="12" md="6">
            <element-row label="OncoKB">
                <a
                    :href="`https://www.oncokb.org/gene/${variant.gene.name}`"
                    target="_blank"
                    class="text-decoration-none"
                >
                    {{ variant.gene.name }}
                </a>
            </element-row>
            <element-row label="Cosmic">
                <a
                    :href="`https://cancer.sanger.ac.uk/cosmic/gene/analysis?ln=${variant.gene.name}`"
                    target="_blank"
                    class="text-decoration-none"
                >
                    {{ variant.gene.name }}
                </a>
            </element-row>

        </v-col>
    </v-row>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {Variant} from "@/models";
import ElementRow from "@/Components/generic/ElementRow.vue";

@Component({
    components: {ElementRow}
})
export default class VariantGeneSection extends Vue {
    @Prop() readonly variant!: Variant
}
</script>

<style scoped>

</style>
