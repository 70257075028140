<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            item-key="id"
            disable-sort
            disable-pagination
            hide-default-footer
            no-data-text="Aucune librairie"
            group-by="panel.name"
            ref="table"
        >
            <template v-slot:group.header="{ headers, items, isOpen, toggle, group }">
                <td :colspan="headers.length">
                    <v-btn @click.native="toggle(!isOpen)" icon ref="grpBtn">
                        <v-icon>mdi-{{ isOpen ? 'chevron-right' : 'chevron-down' }}</v-icon>
                    </v-btn>
                    <b>{{ group }}</b>
                    <v-chip class="ml-2" small>{{ items.length }}</v-chip>
                </td>
            </template>
            <template v-slot:item.status="{ item }">
                <ngs-library-status-progress
                    :status="item.status"
                    :comment="item.quality_control_comment"
                ></ngs-library-status-progress>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn color="primary" icon @click="editTumorType(item)">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <update-sample-dialog
            v-if="selectedLibrary"
            v-model="isUpdateSampleDialogOpen"
            :sample="selectedLibrary.sample"
            type="simple"
            :tumor-types="tumorTypes"
            @cancel="reset"
            @updated="reset"
        ></update-sample-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Ref, Vue} from 'vue-property-decorator'
import {DataTableHeader} from 'vuetify'
import NgsLibraryStatusProgress from '@/Components/domain-specific/ngs/helpers/NgsLibraryStatusProgress.vue'
import {NgsLibrary, TumorType} from "@/models";
import UpdateSampleDialog from "@/Components/domain-specific/sample/dialogs/UpdateSampleDialog.vue";

@Component({
    components: {UpdateSampleDialog, NgsLibraryStatusProgress}
})
export default class NgsLibrariesTable extends Vue {
    @Prop() readonly items!: Array<NgsLibrary>
    @Prop() readonly tumorTypes!: Array<TumorType>
    @Ref('table') readonly table!: any

    selectedLibrary: NgsLibrary | null = null

    isUpdateSampleDialogOpen: boolean = false

    headers: Array<DataTableHeader> = [
        {
            text: 'DIAMIC',
            value: 'sample.diamic',
        },
        {
            text: 'N° GENNO',
            value: 'sample.genno_number',
        },
        {
            text: 'N° prélèvement',
            value: 'sample.laboratory_number',
        },
        {
            text: 'Barcode',
            value: 'barcode.name',
        },
        {
            text: 'Diagnostic',
            value: 'sample.tumor_type.name',
        },
        {
            text: 'Commentaire',
            value: 'comment',
        },
        {
            text: 'Statut échantillon',
            value: 'status',
        },
        {
            text: 'Actions',
            value: 'actions',
        }
    ]

    qualityControl: { [key: string]: { color: string; icon: string; text: string; } } = {
        true: {
            color: 'success',
            icon: 'mdi-check-circle',
            text: 'PASS'
        },
        false: {
            color: 'error',
            icon: 'mdi-close-circle',
            text: 'FAILED'
        },
        null: {
            color: 'grey',
            icon: 'mdi-help-circle',
            text: 'NA'
        }
    }

    editTumorType(item: NgsLibrary) {
        this.selectedLibrary = item
        this.isUpdateSampleDialogOpen = true
    }

    reset() {
        this.isUpdateSampleDialogOpen = false
        this.selectedLibrary = null
    }

    mounted() {
        let keys = Object.keys(this.table.$vnode.componentInstance.openCache);
        keys.forEach(x => {
            this.table.$vnode.componentInstance.openCache[x] = false;
        })
    }
}
</script>

<style lang="css" scoped>
:deep(v-row-group__header) {
    background-color: #FFF !important;
}
</style>
