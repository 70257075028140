<template>
    <layout>
        <v-container>
            <v-row>
                <v-col>
                    <h1>Utilisateurs</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col>

                        <v-data-table
                            :headers="headers"
                            :items="users"
                            item-key="id"
                            no-data-text="Aucun résultat ne correspond à votre recherche"
                        >
                            <template v-slot:item.is_admin="{ item }">
                                <v-switch
                                    :input-value="item.is_admin"
                                    inset
                                    @change="toggleAdminStatus($event, item)"
                                ></v-switch>
                            </template>
                            <template v-slot:item.is_active="{ item }">
                                <v-switch
                                    :input-value="item.is_active"
                                    inset
                                    @change="toggleActiveStatus($event, item)"
                                ></v-switch>
                            </template>
                            <template v-slot:item.created_at="{ item }">
                                {{ formatDate(item.created_at) }}
                            </template>
                        </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Layout from "@/Layouts/Layout.vue";
import {DataTableHeader} from "vuetify";
import route from "ziggy-js";
import {TYPE} from "vue-toastification";
import {User} from "@/models";

@Component({
    components: {Layout},
    metaInfo: {
        title: 'Utilisateurs'
    }
})
export default class Users extends Vue {
    @Prop() readonly users!: Array<User>

    headers: Array<DataTableHeader> = [
        {
            text: 'ID',
            value: 'id',
        },
        {
            text: 'Email',
            value: 'email',
        },
        {
            text: 'Prénom',
            value: 'first_name',
        },
        {
            text: 'Nom de famille',
            value: 'last_name',
        },
        {
            text: 'Administrateur',
            value: 'is_admin',
        },
        {
            text: 'Actif',
            value: 'is_active',
        },
        {
            text: 'Première connexion le',
            value: 'created_at',
        },
    ]

    toggleAdminStatus(value: boolean, item: User) {
        this.$inertia.put(route('hug.users.update-admin', {user: item.id}), {
            value: value
        }, {
            preserveScroll: true,
            preserveState: true,
            onSuccess: () => {
                this.$toast("Statut administrateur de l'utilisateur mis à jour", {
                    type: TYPE.SUCCESS
                })
            }
        })
    }

    toggleActiveStatus(value: boolean, item: User) {
        this.$inertia.put(route('hug.users.update-active', {user: item.id}), {
            value: value
        }, {
            preserveScroll: true,
            preserveState: true,
            onSuccess: () => {
                this.$toast("Statut actif de l'utilisateur mis à jour", {
                    type: TYPE.SUCCESS
                })
            }
        })
    }
}
</script>

<style scoped>

</style>
