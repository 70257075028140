<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        :title="`Annotation du variant ${$get(variant, 'gene.gencode_name')} - ${$defaultTo(variant.vep_annotation.hgvsc, 'NA').after(':')} - ${$defaultTo(variant.vep_annotation.hgvsp, 'NA').after(':')}`"
        :width="800"
        :max-width="800"
        @validate="save()"
        @cancel="cancel()"
        :loading="form.processing"
        button-class="primary"
        :validate-disabled="tabs === 1"
        :hide-validate-btn="readonly"
        :cancel-label="readonly ? 'Fermer' : 'Annuler'"
    >
        <v-tabs v-model="tabs">
            <v-tab v-if="!readonly">Annotations</v-tab>
            <v-tab>Historique</v-tab>
        </v-tabs>

        <v-divider></v-divider>

        <v-tabs-items v-model="tabs">
            <v-tab-item v-if="!readonly">
                <div class="pa-5">
                    <v-row dense>
                        <v-col cols="6">
                            <v-radio-group v-model="form.annotation">
                                <v-radio
                                    v-for="annotation in annotations"
                                    :key="annotation.value"
                                    :label="annotation.text"
                                    :value="annotation.value"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="6">
                            <v-textarea
                                label="Justification *"
                                v-model="form.evidence"
                                :error-messages="form.errors.evidence"
                                rows="3"
                                dense
                                outlined
                                auto-grow
                            ></v-textarea>
<!--                            <v-checkbox v-model="form.is_masked" :error-messages="form.errors.is_masked" label="Masqué par défaut?" hide-details dense>
                            </v-checkbox>-->
                            <small v-if="form.errors.last_reporter_id" class="error--text">{{ form.errors.last_reporter_id }}</small>
                        </v-col>
                    </v-row>
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-5">
                    <history-table
                        type="annotations"
                        :items="variantAnnotations"
                        :loading="historicLoading"
                        :readonly="readonly"
                        @reloaded="reload"
                    ></history-table>
                </div>
            </v-tab-item>
        </v-tabs-items>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import HistoryTable from "@/Components/domain-specific/ngs/tables/HistoryTable.vue";
import route from "ziggy-js";
import {TYPE} from "vue-toastification";
import {User, Variant} from "@/models";
import {NgsAnalysisVariantAnnotationForm} from "@/forms";
import get from "lodash/get";

@Component({
    components: {
        HistoryTable,
        DialogConfirmation
    }
})
export default class NgsEditAnnotationDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly variant!: Variant
    @Prop({ default: false }) readonly readonly?: boolean

    @Watch('isDialogOpen', {immediate: true})
    onDialogOpen (isOpen: boolean) {
        if (isOpen) {
            this.fetchUsers()
            this.form.reset()
            this.form.clearErrors()
            this.load()
        }
    }

    tabs: number = 0
    historicLoading: boolean = false
    variantAnnotations: any[] = []
    form: InertiaForm<NgsAnalysisVariantAnnotationForm> = this.$inertia.form({
        annotation: get(this, 'variant.annotation.annotation', 'weak_quality'),
        evidence: get(this, 'variant.annotation.evidence', null),
    })
    users: Array<User> = []

    annotations: any[] = [
        {
            value: 'technical_artifact',
            text: 'Artefact technique',
            icon: 'mdi-wrench'
        },
        {
            value: 'constitutional_variant',
            text: 'Variant constitutionnel',
            icon: 'mdi-account-group'
        },
        {
            value: 'weak_quality',
            text: 'Qualité faible',
            icon: 'mdi-thumb-down'
        }
    ]

    annotationsText: { [key: string]: string } = {
        technical_artifact:'artefact technique',
        constitutional_variant:'variant constitutionnel',
        weak_quality:'qualité faible',
    }

    fetchUsers() {
        this.$axios.get(route('users.index')).then(({data}) => {
            this.users = data
        })
    }

    get filteredUsers (): Array<User> {
        return this.users.filter(user => user.id !== (this.$page.props.user as User).id)
    }

    load () {
        this.historicLoading = true
        this.$axios.get(route('ngs_analysis_variants.annotations', { ngs_analysis_variant: (this.variant as any).pivot.id }))
            .then(({ data }) => {
                this.variantAnnotations = data
            })
            .finally(() => {
                this.historicLoading = false
            })
    }

    reload() {
        this.variantAnnotations = []
        this.load()
    }

    cancel () {
        this.form.reset()
        this.form.clearErrors()
        this.tabs = []
    }

    save () {
        this.form.put(
            route('ngs_analysis_variants.annotations.store', { ngs_analysis_variant: (this.variant  as any).pivot.id }),
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: () => {
                    this.$toast(`Variant annoté comme ${this.annotationsText[this.form.annotation]}`, {
                        type: TYPE.ERROR
                    })
                    this.isDialogOpen = false
                    this.$emit('update')
                }
            }
        )
    }
}
</script>

<style lang="scss" scoped>
.sub-row {
    background-color: #EEEEEE !important;
}

.sub-row:hover {
    background-color: #E0E0E0 !important;
}
</style>
