<template>
    <v-footer app color="#E0E0E0" class="py-0 pl-0 pr-6">
        <v-row no-gutters>
            <v-col
                class="text-right"
                cols="12"
            >
                <div class="primary--text text-subtitle-1 font-weight-regular">
                    © <a href="https://www.hug.ch/" target="_blank" class="text-decoration-none text-subtitle-1 font-weight-regular">HUG</a> & <a href="https://www.sib.swiss/" target="_blank" class="text-decoration-none text-subtitle-1 font-weight-regular">SIB Swiss Institute of Bioinformatics</a> {{ new Date().getFullYear() }}
                </div>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CustomFooter extends Vue {
    //
}
</script>

<style scoped lang="scss">

</style>
