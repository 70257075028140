<template>
    <v-autocomplete
        v-model="sample"
        :items="samples"
        :loading="isLoading"
        :search-input.sync="syncedSearch"
        :autofocus="autofocus"
        color="primary"
        item-value="id"
        item-text="full_diamic"
        :label="`Sélectionnez ${multiple ? 'les échantillons' : 'l\'échantillon'} `"
        placeholder="DIAMIC, N° prélèvement ou N° demande GENNO"
        :outlined="outlined"
        :multiple="multiple"
        clearable
        :return-object="returnObject"
        :error-messages="errorMessages"
        hide-no-data
        :hide-details="hideDetails"
        no-filter
        dusk="sample-select"
    >
        <template v-slot:item="{ item }">
            {{ item.full_diamic }}
        </template>
        <template v-slot:selection="{ item }">
            {{ item.full_diamic }}
        </template>
        <template v-slot:append-outer v-if="!readonly">
            <v-btn x-large color="primary" dark depressed block @click="openDialog()">
                <v-icon class="mr-1">mdi-plus</v-icon>
                <span v-if="$vuetify.breakpoint.mdAndUp">Créer un nouvel échantillon</span>
            </v-btn>
            <create-sample-dialog
                v-model="dialogOpen"
                :tumor-types="tumorTypes"
                :sample-materials="sampleMaterials"
                :input="syncedSearch"
                @created="select($event)"
                @search="updateSearch($event)"
            ></create-sample-dialog>

        </template>
    </v-autocomplete>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, PropSync, VModel, Vue, Watch} from 'vue-property-decorator'
import CreateSampleDialog from '@/Components/domain-specific/sample/dialogs/CreateSampleDialog.vue'
import pickBy from 'lodash/pickBy'
import route from 'ziggy-js'
import {Sample, TumorType} from "@/models";

@Component({
    components: {
        CreateSampleDialog
    }
})
export default class SelectSample extends Vue {
    @VModel() sample!: Sample | number | null
    @Prop() readonly samples!: any[]
    @PropSync('search') syncedSearch!: string | null
    @Prop({ default: false }) readonly outlined!: boolean
    @Prop({ default: false }) readonly multiple!: boolean
    @Prop({ default: false }) readonly readonly!: boolean
    @Prop({ default: false }) readonly returnObject!: boolean
    @Prop({ default: false }) readonly autofocus!: boolean
    @Prop({ default: false }) readonly hideDetails!: boolean
    @Prop({ default: null }) errorMessages!: string | null

    @Watch('syncedSearch')
    onSearchInput () {
        this.fetchSamples({ search_sample: this.syncedSearch })
    }

    @Watch('dialogOpen')
    onDialogOpening () {
        this.$inertia.reload({
            only: ['sampleMaterials', 'tumorTypes']
        })
    }

    isLoading: boolean = false

    dialogOpen: boolean = false

    openDialog () {
        this.fetchSamples()
        this.dialogOpen = true
    }

    fetchSamples (params?: { search_sample: string }) {
        this.isLoading = true
        this.$inertia.get(
            route('cnv_analyses.create'),
            pickBy(params),
            {
                only: ['samples'],
                preserveState: true,
                onFinish: () => {
                    this.isLoading = false
                }
            }
        )
    }

    select (e: Sample) {
        this.updateSearch(null)
        if (this.returnObject) {
            this.sample = e
        } else {
            this.sample = e.id
        }
    }

    updateSearch (e: string | null) {
        this.syncedSearch = e
    }

    get tumorTypes () {
        return (this.$page.props.tumorTypes as Array<TumorType>)
    }

    get sampleMaterials () {
        return (this.$page.props.sampleMaterials as string[])
    }
}
</script>

<style lang="scss">
.v-input__append-outer {
    margin-top: 2px !important;
}
</style>
