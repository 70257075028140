<template>
    <div v-if="cnv.quality_control_content">
        <panel title="Caryotype" :hide-title="hideTitle" :expandable="true">
            <template v-slot:actions>
                <btn @click="downloadCaryotype()" icon="mdi-download" :iconOnly="true" color="white"></btn>
            </template>
            <template>
                <div id="cardKaryotype" class="px-2 py-4">
                    <div id="karyotype"/>
                    <div id="karyotypeFocus" class="pl-5"/>
                </div>
            </template>
        </panel>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Panel from '@/Components/generic/Panel.vue'
import * as d3 from 'd3'
import {Axis, AxisDomain} from 'd3'
import Btn from '@/Components/generic/Btn.vue'
import {CnvAnalysis, Segment} from "@/models";

@Component({
    components: {
        Panel,
        Btn
    }
})
export default class CaryotypeCard extends Vue {
    @Prop() readonly segments!: Array<Segment>
    @Prop() readonly cnv!: CnvAnalysis
    @Prop({ default: false }) readonly hideTitle!: boolean

    private limitTop: number = 12
    private currentChromosome: any
    private dim: any
    private dimension: any
    private newDimension: any
    private focusDimension: any
    private selection: d3.Selection<d3.BaseType, unknown, HTMLElement, any>
    private tooltip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>
    private id: string | undefined
    private svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>
    private header: d3.Selection<SVGGElement, unknown, HTMLElement, any>
    private arm: d3.Selection<SVGGElement, unknown, HTMLElement, any>
    private suffixTextP: string
    private suffixTextQ: string
    private chromosome: d3.Selection<SVGGElement, unknown, HTMLElement, any>
    private chromosomeGroup: d3.Selection<SVGGElement, unknown, SVGGElement, unknown>
    private displayCopyNumber: number
    private sex: string = ''
    private parentContainer: HTMLElement | null
    private data: any
    private max: number = 0
    private min: number = 0
    private kary: d3.Selection<SVGGElement, unknown, HTMLElement, any>
    private d3Chromosomes: any
    private cnvRes: d3.Selection<SVGGElement, unknown, SVGGElement, unknown>
    private message: string
    private yAxis: Axis<AxisDomain>
    private legend: d3.Selection<SVGGElement, unknown, HTMLElement, any>
    private chromosomesLength: number = 0
    private colors: any = {
        loss: '#993399',
        loh: '#993399',
        gain: 'blue',
        amplification: 'blue',
        'perte homozygote': 'red',
        'perte hétérozygote': 'red',
        grey: '#DCDCDC'
    }

    private setupKaryotype () {
        this.sex = this.cnv.quality_control_content.sex
        this.parentContainer = document.getElementById('cardKaryotype')
        this.data = {}
        this.max = 0
        this.min = 0

        for (let i = 0; i < this.segments.length; i++) {
            const segment: any = this.segments[i]
            segment.copy_number = segment.context.copy_number
            segment.normCopyNumber = ((this.sex === 'male' && (segment.chromosome_id === 23 || segment.chromosome_id === 24)) ? segment.context.copy_number - 1 : segment.context.copy_number - 2)
            if (segment.context.copy_number !== null && this.max < segment.normCopyNumber) {
                this.max = segment.normCopyNumber
            }
            if (segment.context.copy_number !== null && segment.context.alteration.class === 'loss' && this.min > segment.normCopyNumber) {
                this.min = segment.normCopyNumber
            }
            if (!this.data[segment.chromosome_id]) {
                this.data[segment.chromosome_id] = []
            }
            this.data[segment.chromosome_id].push(segment)
        }

        this.d3Chromosomes = JSON.parse(JSON.stringify(this.chromosomes))
        if (this.sex === 'female') {
            this.d3Chromosomes.pop()
        }

        this.chromosomesLength = 0
        for (let i = 0; i < this.d3Chromosomes.length; i++) {
            const chromosome = this.d3Chromosomes[i]
            chromosome.position = this.chromosomesLength
            chromosome.centromere_center = chromosome.centromere_start + ((chromosome.centromere_end - chromosome.centromere_start) / 2)
            this.chromosomesLength += chromosome.length
            chromosome.results = (this.data[chromosome.id]) ? this.data[chromosome.id] : []
        }

        if (this.parentContainer !== null) {
            this.dim = {
                width: this.parentContainer.clientWidth * 0.96,
                height: 300,
                chromosomesLength: this.chromosomesLength,
                min: this.min - 0.5,
                max: this.max + 0.5
            }
            this.dim = this.setupDimensionAndFocus(this.dim)
            this.dimension = this.dim

            // Create svg container with good dimension
            this.svg = d3.select('#karyotype').append('svg').attr('width', this.dim.width + this.dim.marginLeft).attr('height', this.dim.height + this.dim.marginTop).attr('id', 'svg_caryotype')
        }
    }

    private drawChromosomeHeader () {
        this.chromosomesLength = 0

        // group.legend : add legend at the top : legend, non couvert, amplification or gain, loss, LOH
        this.legend = this.svg.append('g').attr('class', 'legend').attr('transform', 'translate(' + this.dim.width * 0.3 + ',5)')
        this.legend.append('rect').attr('height', 5).attr('width', 20).attr('fill', 'lightgrey')
        this.legend.append('text').text('Non couvert par l\'Oncoscan').style('font-size', '12px').attr('x', 25).attr('y', 5)
        this.legend.append('rect').attr('height', 5).attr('width', 20).attr('fill', 'blue').attr('x', 200)
        this.legend.append('text').text('Amplification ou Gain').style('font-size', '12px').attr('x', 225).attr('y', 5)
        this.legend.append('rect').attr('height', 5).attr('width', 20).attr('fill', 'red').attr('x', 375)
        this.legend.append('text').text('Loss').style('font-size', '12px').attr('x', 400).attr('y', 5)
        this.legend.append('rect').attr('height', 5).attr('width', 20).attr('fill', 'purple').attr('x', 455)
        this.legend.append('text').text('LOH').style('font-size', '12px').attr('x', 480).attr('y', 5)
        this.svg.append('text').attr('class', 'karyotypeAxis').text('Copy number variation').attr('transform', 'rotate(270)').attr('x', -250).attr('y', 30)
    }

    private linkChromosomeToGroup () {
        const scaleX = d3.scaleLinear().domain([0, this.dim.chromosomesLength]).range([0, this.dim.dataWidth])
        this.kary = this.svg.append('g').attr('class', 'karyotype').attr('transform', 'translate(' + this.dim.marginLeft + ',' + (this.dim.marginTop - 5) + ')')

        // Link d3Chromosome data to the group
        this.chromosomeGroup = this.kary.selectAll('.chromosome').data(this.d3Chromosomes).enter().append('g').attr('class', 'chromosome pointer').attr('id', (d: any) => {
            return 'chromosome_' + d.name
        }).attr('transform', (d: any) => {
            return 'translate(' + scaleX(d.position) + ',0)'
        })
        this.chromosomeGroup.on('click', this.focusChromosome)
    }

    private addChromosomeRectangle () {
        const scaleX = d3.scaleLinear().domain([0, this.dim.chromosomesLength]).range([0, this.dim.dataWidth])

        // Add white rectangle foreach chromosome
        this.chromosomeGroup.append('rect').attr('width', (d: any) => {
            return scaleX(d.length)
        }).attr('height', this.dim.height).attr('fill', 'white').attr('stroke', 'black')

        // Adds 2 white rectangles for p and q part of the chromosome. If one of the arm is not covered by OncoScan, fill it in grey. (Lou: my way of doing it might not be the best)
        // 1st block: if it's the P arm that is not covered
        this.chromosomeGroup.append('rect').attr('class', 'arm_alteration').attr('y', 1)
            .attr('height', (d: any) => {
                return (!d.p_start) ? (this.dim.height - 2) : 0
            })
            .attr('fill', (d: any) => {
                return (!d.p_start) ? this.colors.grey : 'white'
            })
            .attr('width', (d: any) => {
                if (!d.p_start) {
                    return (d.q_start) ? scaleX(d.q_start) : scaleX(d.length)
                } else {
                    return 0
                }
            })

        // 2nd block: if it's the Q arm that is not covered (never the case for the moment)
        this.chromosomeGroup.append('rect').attr('class', 'arm_alteration').attr('y', 1)
            .attr('height', (d: any) => {
                return (!d.q_start) ? (this.dim.height - 2) : 0
            })
            .attr('fill', (d: any) => {
                return (!d.q_start) ? this.colors.grey : 'white'
            })
            .attr('width', (d: any) => {
                if (!d.q_start) {
                    return (d.p_start) ? scaleX(d.p_start) : scaleX(d.length)
                } else {
                    return 0
                }
            })
            .attr('x', (d: any) => {
                if (!d.q_start) {
                    return (d.p_end) ? scaleX(d.p_end) : 0
                } else {
                    return scaleX(d.q_start)
                }
            })
    }

    private addChromosomeName () {
        const scaleX = d3.scaleLinear().domain([0, this.dim.chromosomesLength]).range([0, this.dim.dataWidth])

        // Add chromosome name
        this.chromosomeGroup.append('text').attr('y', 25).style('font-size', 10).text((d: any) => {
            return d.name
        })
            .attr('x', (d: any) => {
                d.name = '' + d.name
                return (d.name.length === 1) ? scaleX(d.length) * 0.45 : scaleX(d.length) * 0.35
            })
    }

    private addYAxis () {
        const scaleX = d3.scaleLinear().domain([0, this.dim.chromosomesLength]).range([0, this.dim.dataWidth])
        const scaleY = d3.scaleLinear().domain([this.dim.min, this.dim.max]).range([this.dim.height, this.dim.paddingTop])

        // Add horizontal line for 0
        this.kary.append('line').attr('x1', scaleX(0)).attr('x2', scaleX(this.dim.chromosomesLength)).attr('y1', scaleY(0)).attr('y2', scaleY(0)).attr('stroke', 'black').attr('stroke-width', '1')

        // @ts-ignore
        this.yAxis = d3.axisLeft(scaleY).tickFormat((d: any) => {
            return (d === 12) ? '+10' : Math.round(d * 100) / 100
        })
        this.kary.append('g').call(this.yAxis)
    }

    private addChromosomeToGroup () {
        const scaleX = d3.scaleLinear().domain([0, this.dim.chromosomesLength]).range([0, this.dim.dataWidth])
        const scaleY = d3.scaleLinear().domain([this.dim.min, this.dim.max]).range([this.dim.height, this.dim.paddingTop])

        // Foreach chromosome, loop on all results and add group.
        this.cnvRes = this.chromosomeGroup.selectAll('.cnv_result').data((d: any) => {
            return d.results
        }).enter().append('g').attr('class', 'cnv_result')
            .attr('transform', (d: any) => {
                const x = scaleX(d.start_position)
                let y
                if (d.copy_number === null) {
                    y = scaleY(0) - (scaleY(0) - scaleY(1)) / 4
                } else {
                    this.displayCopyNumber = (d.normCopyNumber > this.limitTop) ? this.limitTop : d.normCopyNumber
                    y = (this.displayCopyNumber < 0) ? scaleY(0) : scaleY(this.displayCopyNumber)
                }
                return 'translate(' + x + ',' + y + ')'
            })
    }

    private addRectangleToGroup () {
        const scaleX = d3.scaleLinear().domain([0, this.dim.chromosomesLength]).range([0, this.dim.dataWidth])
        const scaleY = d3.scaleLinear().domain([this.dim.min, this.dim.max]).range([this.dim.height, this.dim.paddingTop])

        // On each group of results, add rectangle
        this.cnvRes.append('rect')
            .attr('fill', (d: any) => {
                return this.colors[d.context.alteration.name.toLowerCase()]
            })
            .attr('width', (d: any) => {
                return scaleX(d.end_position - d.start_position)
            })
            .attr('height', (d: any) => {
                if (d.context.alteration_id === 5) {
                    return (scaleY(0) - scaleY(1)) / 2
                } else {
                    this.displayCopyNumber = (d.normCopyNumber > this.limitTop) ? this.limitTop : d.normCopyNumber
                    return Math.abs(scaleY(0) - scaleY(this.displayCopyNumber))
                }
            })
    }

    private drawKaryotype () {
        this.setupKaryotype()
        this.drawChromosomeHeader()
        this.linkChromosomeToGroup()
        this.addChromosomeRectangle()
        this.addChromosomeName()
        this.addYAxis()
        this.addChromosomeToGroup()
        this.addRectangleToGroup()
    }

    private setupDimensionAndFocus (dimension: any, focus?: any): any {
        this.newDimension = dimension
        this.newDimension.dataWidth = dimension.width * 0.96
        this.newDimension.marginLeft = dimension.width * 0.05
        this.newDimension.tooltipLeft = 12
        this.newDimension.tooltiptop = 0
        this.newDimension.marginTop = 40
        this.newDimension.paddingTop = 50

        if (focus) {
            this.min = 0
            this.max = 0

            for (let i = 0; i < focus.results.length; i++) {
                const focusResult = focus.results[i]

                if (this.max < focusResult.normCopyNumber) {
                    this.max = focusResult.normCopyNumber
                }
                if (this.min > focusResult.normCopyNumber) {
                    this.min = focusResult.normCopyNumber
                }
            }
            this.newDimension.min = (this.min) ? this.min - 0.5 : -1
            this.newDimension.max = (this.max) ? this.max + 0.5 : 1
            this.newDimension.marginLeft = dimension.width * 0.05
            this.newDimension.marginTop = 80
        }
        if (this.newDimension.max > 10) {
            this.newDimension.morethan10 = true
            this.newDimension.max = this.limitTop
        }
        return this.newDimension
    }

    private focusChromosome ($event: Event | undefined, chromosome: any) {
        if (this.currentChromosome && this.currentChromosome.id === chromosome.id) {
            this.currentChromosome = null
        } else {
            this.currentChromosome = chromosome
        }
        this.drawFocus()
    }

    private cleanSvgAndTooltip () {
        // Remove existing svg and tooltips
        this.selection = d3.select('#karyotypeFocus')
        d3.select('.focusChromosome').remove()
    }

    private addTooltip () {
        // Add tooltip
        this.tooltip = d3.select('body').append('div').style('position', 'absolute').style('z-index', '10').attr('class', 'd3-tooltip').style('opacity', '0')
        this.id = 'focus_chrom_svg_' + this.currentChromosome.id
    }

    private setupFocusChromosome () {
        // Setup dimension for focus chromosome
        this.focusDimension = JSON.parse(JSON.stringify(this.dimension))
        this.focusDimension.height = this.dimension.height
        this.focusDimension = this.setupDimensionAndFocus(this.focusDimension, this.currentChromosome)

        // Create this.svg
        this.svg = this.selection.append('svg').attr('transform', 'translate(0,20)').attr('width', this.focusDimension.width).attr('height', this.focusDimension.height + this.focusDimension.marginTop).attr('id', this.id).attr('class', 'focusChromosome').attr('id', 'focusChromosome' + this.currentChromosome.chromId)
    }

    private drawLegendAndHeader () {
        // Draw legend and headers
        this.svg.append('text').attr('class', 'karyotypeAxis').text('Copy number variation').attr('transform', 'rotate(270)').attr('x', -300).attr('y', 30)
        this.header = this.svg.append('g').attr('class', 'focusChromHeader').attr('transform', 'translate(0,0)')
        this.header.append('line').attr('x1', this.focusDimension.marginLeft / 2).attr('x2', this.focusDimension.width).attr('y1', 0).attr('y2', 0).attr('stroke', 'grey').attr('stroke-width', '1')
        this.header.append('text').text('Zoom sur le chromosome ' + this.currentChromosome.name).attr('class', 'pointer').attr('transform', 'translate(' + this.focusDimension.width * 0.4 + ',40)').style('font-size', 18).style('font-weight', 500).on('click', this.focusChromosome)
        this.header.append('rect').attr('class', 'test').attr('y', 57).attr('width', this.focusDimension.width).attr('height', 40).attr('fill', this.colors.grey)
        this.header.append('text').html('Couverture').attr('x', 5).attr('y', 72).attr('font-size', 12)
        this.header.append('text').html('OncoScan').attr('x', 5).attr('y', 87).attr('font-size', 12)
    }

    private addArms () {
        const scaleX = d3.scaleLinear().domain([0, this.currentChromosome.length]).range([0, this.dimension.dataWidth])

        // Adds OncoScan coverage for arm p and q
        this.arm = this.header.append('g').attr('transform', 'translate(' + this.focusDimension.marginLeft + ',85)')

        if (this.currentChromosome.p_start) {
            this.suffixTextP = ''
            this.arm.append('rect').attr('x', scaleX(this.currentChromosome.p_start)).attr('width', scaleX(this.currentChromosome.p_end) - scaleX(this.currentChromosome.p_start)).attr('height', 4)
            this.arm.append('text').text('Bras p ' + this.suffixTextP).attr('x', scaleX(this.currentChromosome.p_start + (this.currentChromosome.p_end - this.currentChromosome.p_start) * 0.45)).attr('y', '-5')
        }
        if (this.currentChromosome.q_start) {
            this.suffixTextQ = ''
            this.arm.append('rect').attr('x', scaleX(this.currentChromosome.q_start)).attr('width', scaleX(this.currentChromosome.q_end) - scaleX(this.currentChromosome.q_start)).attr('height', 4)
            this.arm.append('text').text('Bras q ' + this.suffixTextQ).attr('x', scaleX(this.currentChromosome.q_start + (this.currentChromosome.q_end - this.currentChromosome.q_start) * 0.45)).attr('y', '-5')
        }
    }

    private addCentromereRectangle () {
        const scaleX = d3.scaleLinear().domain([0, this.currentChromosome.length]).range([0, this.dimension.dataWidth])

        // Add a group to add results
        this.chromosome = this.svg.append('g').attr('class', 'focusChromData').attr('transform', 'translate(' + this.focusDimension.marginLeft + ',' + (this.focusDimension.marginTop - 20) + ')')

        // Add centromere rectangle
        this.chromosome.append('rect').style('stroke', 'lightgrey').style('stroke-dasharray', '6,8').attr('x', () => scaleX(this.currentChromosome.centromere_start)
        ).attr('width', () => scaleX(this.currentChromosome.centromere_end - this.currentChromosome.centromere_start)
        ).attr('y', 25).attr('height', this.dimension.height).attr('fill', '#EFEFEF')
            .on('mouseover', () => {
                this.message = '<b>Centromère</b>'
                this.tooltip.transition().duration(200).style('opacity', 0.9)
                this.tooltip.html(this.message)
                    .style('width', this.message.length * 5 + 'px')
                    // @ts-ignore
                    .style('left', (event.pageX + this.dimension.tooltipLeft) + 'px')
                    .style('height', 25 + 'px')
                    // @ts-ignore
                    .style('top', (event.pageY + this.dimension.tooltiptop) + 'px')
            })
            .on('mouseout', () => {
                this.tooltip.transition().duration(200).style('opacity', 0)
                this.tooltip.html('')
            })
    }

    private addEachResult () {
        const scaleX = d3.scaleLinear().domain([0, this.currentChromosome.length]).range([0, this.dimension.dataWidth])
        const scaleY = d3.scaleLinear().domain([this.focusDimension.min, this.focusDimension.max]).range([this.focusDimension.height, this.focusDimension.paddingTop])

        // Add each results
        this.chromosomeGroup = this.chromosome.selectAll('.focusCnvResult').data(this.currentChromosome.results).enter().append('g').attr('class', 'focusCnvResult')
            .attr('transform', (d: any) => {
                const x = scaleX(d.start_position)
                let y
                if (d.copy_number === null) {
                    y = scaleY(0) - (scaleY(0) - scaleY(1)) / 4
                } else {
                    this.displayCopyNumber = (d.normCopyNumber > this.limitTop) ? this.limitTop : d.normCopyNumber
                    y = (d.normCopyNumber < 0) ? scaleY(0) : scaleY(this.displayCopyNumber)
                }
                return 'translate(' + x + ',' + y + ')'
            })
        this.chromosomeGroup.append('rect')
            .attr('fill', (d: any) => {
                return this.colors[d.context.alteration.name.toLowerCase()]
            })
            .attr('width', (d: any) => {
                return scaleX(d.end_position - d.start_position)
            })
            .attr('height', (d: any) => {
                if (d.context.alteration_id === 5) {
                    return (scaleY(0) - scaleY(1)) / 2
                } else {
                    this.displayCopyNumber = (d.normCopyNumber > this.limitTop) ? this.limitTop : d.normCopyNumber
                    return Math.abs(scaleY(0) - scaleY(this.displayCopyNumber))
                }
            })
            .on('mouseover', ($event: Event | undefined, result: any) => {
                this.message = '<b>' + result.context.alteration.name + '</b>'
                this.message += ' (' + result.copy_number + ')'
                // @ts-ignore
                this.message += '<br>' + this.formatNumber(result.start_position) + ' - ' + this.formatNumber(result.end_position) + '<br>'
                this.tooltip.transition().duration(200).style('opacity', 0.9)
                this.tooltip.html(this.message)
                    .style('width', this.message.length * 5 + 'px')
                    // @ts-ignore
                    .style('left', (event.pageX + this.dimension.tooltipLeft) + 'px')
                    .style('height', 38 + 'px')
                    // @ts-ignore
                    .style('top', (event.pageY + this.dimension.tooltiptop) + 'px')
            })
            .on('mouseout', () => {
                this.tooltip.transition().duration(200).style('opacity', 0)
                this.tooltip.html('')
            })

        // Add line for the 0
        this.chromosome.append('line').attr('x1', 0).attr('x2', this.focusDimension.dataWidth).attr('y1', scaleY(0)).attr('y2', scaleY(0)).attr('stroke', 'black').attr('stroke-width', '1')

        // Add y axis on the left
        // @ts-ignore
        this.yAxis = d3.axisLeft(scaleY).tickFormat((d: any) => {
            return (d === 12) ? '+10' : Math.round(d * 100) / 100
        })
        this.chromosome.append('g').call(this.yAxis)
    }

    private drawFocus () {
        this.cleanSvgAndTooltip()

        if (!this.currentChromosome || !this.currentChromosome.results.length) {
            return
        }

        this.addTooltip()
        this.setupFocusChromosome()
        this.drawLegendAndHeader()
        this.addArms()
        this.addCentromereRectangle()
        this.addEachResult()
    }

    mounted () {
        setTimeout(() => {
            this.drawKaryotype()
        }, 200)
    }

    private downloadCaryotype () {
        const svg = document.getElementById('svg_caryotype')
        const svgData = new XMLSerializer().serializeToString(svg)
        const height = svg.clientHeight
        const width = svg.clientWidth
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        let dataUri = ''
        try {
            dataUri = 'data:image/svg+xml;base64,' + btoa(svgData)
        } catch (ex) {

        }

        const img = document.createElement('img')

        img.onload = function () {
            canvas.width = width
            canvas.height = height
            ctx.drawImage(img, 0, 0, width, height)

            try {
                const a = document.createElement('a')
                a.download = 'caryotype.png'
                a.href = canvas.toDataURL('image/png')
                document.querySelector('body').appendChild(a)
                a.click()
                document.querySelector('body').removeChild(a)
            } catch (ex) {
                const imgPreview = document.createElement('div')
                imgPreview.appendChild(img)
                document.querySelector('body').appendChild(imgPreview)
            }
        }

        img.src = dataUri
    }

    get chromosomes () {
        return this.$page.props.chromosomes
    }

    resetKaryotype () {
        document.getElementById('svg_caryotype').remove()
        this.drawKaryotype()
    }

    created () {
        window.addEventListener('resize', this.resetKaryotype)
    }

    destroyed () {
        window.removeEventListener('resize', this.resetKaryotype)
    }
}
</script>
<style>
.pointer {
    cursor: pointer
}

.d3-tooltip {
    position: absolute;
    text-align: center;
    vertical-align: center;
    padding: 5px 2px 2px 2px;
    color: black;
    font: 12px sans-serif;
    background-color: white;
    border: 1px solid grey;
    border-radius: 3px;
    pointer-events: none;
}

</style>
