<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        title="Mettre à jour le commentaire"
        :max-width="600"
        @cancel="cancel()"
        @validate="save()"
        :loading="form.processing"
    >
        <div class="pa-5">
            <v-row>
                <v-col cols="12">
                    <v-textarea
                        v-model="form.comment"
                        label="Commentaire"
                        placeholder="Entrez votre commentaire"
                        :error-messages="form.errors.comment"
                        outlined
                    ></v-textarea>
                </v-col>
            </v-row>
        </div>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import {TYPE} from 'vue-toastification'
import route from 'ziggy-js'
import {NgsAnalysis, NgsPairAnalysis} from "@/models";

@Component({
    components: {DialogConfirmation}
})
export default class NgsEditCommentDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'

    loading: boolean = false
    form: InertiaForm<{ comment: string | null }> = this.$inertia.form({
        comment: this.ngsAnalysis.comment
    })

    cancel() {
        this.form.reset()
    }

    save() {
        if (this.type === 'simple') {
            this.form.put(
                route('ngs_analyses.update', {ngs_analysis: this.ngsAnalysis.id}),
                {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.isDialogOpen = false
                        this.$toast('Commentaire mis à jour', {
                            type: TYPE.SUCCESS
                        })
                    }
                }
            )
        }
        if (this.type === 'pair') {
            this.form.put(
                route('ngs_pair_analyses.update', {ngs_pair_analysis: this.ngsAnalysis.id}),
                {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.isDialogOpen = false
                        this.$toast('Commentaire mis à jour', {
                            type: TYPE.SUCCESS
                        })
                    }
                }
            )
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
