<template>
    <layout>
        <sub-menu></sub-menu>
        <v-row>
            <v-col cols="12">
                <ngs-dashboard-card
                    :ngs-runs="ngsRuns"
                    :filters="ngsFilters"
                ></ngs-dashboard-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <cnv-dashboard-card
                    :cnv-analyses="cnvAnalyses"
                    :filters="cnvFilters"
                ></cnv-dashboard-card>
            </v-col>
        </v-row>
    </layout>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator'
import SubMenu from '@/Components/generic/SubMenu.vue'
import Layout from '@/Layouts/Layout.vue'
import CnvDashboardCard from '@/Components/domain-specific/cnv/cards/CnvDashboardCard.vue'
import NgsDashboardCard from '@/Components/domain-specific/ngs/cards/NgsDashboardCard.vue'
import {CnvAnalysis, NgsRun} from "@/models";
import {Pagination} from '@/laravel'

@Component({
    components: {
        CnvDashboardCard,
        NgsDashboardCard,
        Layout,
        SubMenu
    },
    metaInfo: {
        title: 'Dashboard'
    }
})
export default class Dashboard extends Vue {
    @Prop() readonly cnvFilters!: {
        'cnv-from': string | null,
        'cnv-to': string | null,
        'cnv-status': string | null,
        'cnv-search': string | null,
        'cnv-page': string | null,
        'cnv-items-per-page': string | null
    }
    @Prop() readonly cnvAnalyses!: Pagination<CnvAnalysis>
    @Prop() readonly ngsFilters!: {
        'ngs-from': string | null,
        'ngs-to': string | null,
        'ngs-run-status': string | null,
        'ngs-analysis-status': string | null,
        'ngs-search': string | null,
        'ngs-page': string | null,
        'ngs-items-per-page': string | null
    }
    @Prop() readonly ngsRuns!: Pagination<NgsRun>
}
</script>

<style scoped lang="scss">
</style>
