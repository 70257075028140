<template>
    <v-app-bar app color="primary" dark>

        <a @click="$visit('dashboard')">
            <v-toolbar-title class="font-weight-bold text-h5 cursor white--text">
                <v-avatar size="32">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                         class="h-6 w-6" fill="white" viewBox="0 0 24 24" stroke="none">
                        <path
                            d="M4,2H6V4C6,5.44 6.68,6.61 7.88,7.78C8.74,8.61 9.89,9.41 11.09,10.2L9.26,11.39C8.27,10.72 7.31,10 6.5,9.21C5.07,7.82 4,6.1 4,4V2M18,2H20V4C20,6.1 18.93,7.82 17.5,9.21C16.09,10.59 14.29,11.73 12.54,12.84C10.79,13.96 9.09,15.05 7.88,16.22C6.68,17.39 6,18.56 6,20V22H4V20C4,17.9 5.07,16.18 6.5,14.79C7.91,13.41 9.71,12.27 11.46,11.16C13.21,10.04 14.91,8.95 16.12,7.78C17.32,6.61 18,5.44 18,4V2M14.74,12.61C15.73,13.28 16.69,14 17.5,14.79C18.93,16.18 20,17.9 20,20V22H18V20C18,18.56 17.32,17.39 16.12,16.22C15.26,15.39 14.11,14.59 12.91,13.8L14.74,12.61M7,3H17V4L16.94,4.5H7.06L7,4V3M7.68,6H16.32C16.08,6.34 15.8,6.69 15.42,7.06L14.91,7.5H9.07L8.58,7.06C8.2,6.69 7.92,6.34 7.68,6M9.09,16.5H14.93L15.42,16.94C15.8,17.31 16.08,17.66 16.32,18H7.68C7.92,17.66 8.2,17.31 8.58,16.94L9.09,16.5M7.06,19.5H16.94L17,20V21H7V20L7.06,19.5Z"/>
                    </svg>
                </v-avatar>
                OncoBench
            </v-toolbar-title>
        </a>

        <v-spacer></v-spacer>

        <navbar-btn v-for="(link,index) in links" :icon="link.icon" @click="$visit(link.to)" :key="index">{{
                link.label
            }}
        </navbar-btn>

        <user-menu :username="username"></user-menu>
    </v-app-bar>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator'
import NavbarBtn from '@/Components/generic/NavbarBtn.vue'
import UserMenu from '@/Components/generic/UserMenu.vue'

@Component({
    components: {
        UserMenu,
        NavbarBtn
    }
})
export default class Navbar extends Vue {
    links: Array<{
        label: string,
        to: string,
        icon: string
    }> = [
        {
            label: 'Recherche',
            to: 'search',
            icon: 'mdi-magnify'
        }
    ]

    get username () {
        return (this.$page.props.user as any).name
    }
}
</script>

<style lang="scss">
.cursor {
    cursor: pointer;
}
</style>
