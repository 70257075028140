<template>
        <dialog-confirmation
            v-model="isDialogOpen"
            @validate="destroy()"
            button-class="error"
            validate-label="Supprimer"
            title="Supprimer cette paire?"
            :loading="loading"
        >
            <div class="pa-5">
                <v-row>
                    <v-col cols="12">
                        <p>Êtes-vous sûr(e) de vouloir supprimer cette paire de librairies tumorale/non-tumorale?</p>
                        <ul class="text--disabled">
                            <li>Tumorale:
                                {{ `${$get(item, 'tumoral_library.sample.diamic')} ${$get(item, 'tumoral_library.sample.laboratory_number')}` }}
                            </li>
                            <li>Non-tumorale:
                                {{ `${$get(item, 'non_tumoral_library.sample.diamic')} ${$get(item, 'non_tumoral_library.sample.laboratory_number')}` }}
                            </li>
                        </ul>
                    </v-col>
                </v-row>
            </div>
        </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {TYPE} from 'vue-toastification'
import route from 'ziggy-js'
import {NgsLibraryPair} from "@/models";

@Component({
    components: {DialogConfirmation}
})
export default class NgsDeleteLibraryPairDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly item!: NgsLibraryPair

    @Watch('isDialogOpen')
    onDialogOpening(val: boolean) {
        if (val) {
            this.librairy_pair_id = this.item.id
        }
    }

    loading: boolean = false
    librairy_pair_id: number | null = null

    destroy() {
        this.$inertia.delete(
            route('ngs_library_pairs.destroy', {
                ngs_library_pair: this.librairy_pair_id
            }),
            {
                preserveScroll: true,
                preserveState: true,
                onStart: () => {
                    this.loading = true
                },
                onFinish: () => {
                    this.loading = false
                },
                onSuccess: () => {
                    this.isDialogOpen = false
                    this.$toast('La paire de librairies a été supprimée', {
                        type: TYPE.ERROR
                    })
                }
            }
        )
    }
}
</script>

<style lang="scss" scoped>

</style>
