<template>
    <v-row :no-gutters="noGutters">
        <v-col :cols="leftColSize" class="grey--text text-start py-0 my-0">
            <slot name="label">
                <label>{{ label }}</label>
                <v-btn v-if="editable" icon x-small>
                    <v-icon small color="grey" @click="$emit('edited')">
                        mdi-pencil
                    </v-icon>
                </v-btn>
            </slot>
        </v-col>
        <v-col :cols="rightColSize" class="py-0 my-0 align-center`">
            <p :class="`mb-1 black--text overflow ${type === 'text' ?  'text-pre-line' : null}`">
                <slot></slot>
            </p>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ElementRow extends Vue {
    @Prop() label?: string
    @Prop() type?: string
    @Prop({ default: 4 }) leftColSize?: number
    @Prop({ default: 8 }) rightColSize?: number
    @Prop({ default: false }) editable?: number
    @Prop({ default: false }) noGutters?: number
    @Prop({ default: false }) dense?: boolean
}
</script>

<style scoped lang="scss">
</style>
