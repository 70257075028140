<template>
    <div class="chart-container cursor-pointer transparent-chart amplicon-chart rounded-md ma-1" style="position: relative; width:6rem;">
        <Bar
            v-if="isDrawn"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :width="width"
            :height="height"
        ></Bar>
        <v-progress-circular
            v-else
            indeterminate
            color="primary"
            size="16"
        ></v-progress-circular>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {Bar} from 'vue-chartjs/legacy'
import colors from 'vuetify/lib/util/colors'
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from 'chart.js'
import route from "ziggy-js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

@Component({
    components: {
        Bar
    }
})
export default class AmpliconBarChart extends Vue {
    @Prop() readonly ngsAnalysisId!: number
    @Prop() readonly gene!: string
    @Prop({default: 10}) readonly width?: number
    @Prop({default: 10}) readonly height?: number
    @Prop() readonly type!: 'simple' | 'pair'

    data: [number, number, number, number] = [0,0,0,0]

    isLoading: boolean = false
    isDrawn: boolean = false

    chartData: ChartData = {
        labels: this.data,
        datasets: [
            {
                data: this.data,
                backgroundColor: [colors.red.darken3, colors.orange.base, colors.yellow.base, colors.green.lighten3],
            }
        ]
    }

    chartOptions: ChartOptions = {
        responsive: true,
        scales: {
            y: {
                display: false
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false
                },
                ticks: {
                    display: true,
                    font: {
                        size: 10
                    },
                    padding: 0
                },
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        },
        hover: {
            mode: null
        },
        animation: false
    }

    created() {
        this.isLoading = true

        if (this.type === 'simple') {
            this.$axios.get(route('ngs_analyses.amplicons-of-gene', {
                ngs_analysis: this.ngsAnalysisId,
                gene_name: this.gene
            }))
                .then(({data}) => {
                    const values = data.map((amplicon: any) => amplicon.pivot.average_coverage)
                    this.data[0] = values.filter((value: number) => value < 30).length
                    this.data[1] = values.filter((value: number) => value >= 30 && value < 60).length
                    this.data[2] = values.filter((value: number) => value >= 60 && value < 100).length
                    this.data[3] = values.filter((value: number) => value >= 100).length
                    this.isDrawn = true
                })
                .finally(() => {
                    this.isLoading = false
                })
        }

        if (this.type === 'pair') {
            this.$axios.get(route('ngs_pair_analyses.amplicons-of-gene', {
                ngs_pair_analysis: this.ngsAnalysisId,
                gene_name: this.gene
            }))
                .then(({data}) => {
                    const values = data.map((amplicon: any) => amplicon.pivot.average_coverage)
                    this.data[0] = values.filter((value: number) => value < 30).length
                    this.data[1] = values.filter((value: number) => value >= 30 && value < 60).length
                    this.data[2] = values.filter((value: number) => value >= 60 && value < 100).length
                    this.data[3] = values.filter((value: number) => value >= 100).length
                    this.isDrawn = true
                })
                .finally(() => {
                    this.isLoading = false
                })
        }
    }
}
</script>

<style scoped>
.cursor-pointer {
    cursor: default !important;
}

.amplicon-chart {
    float: right;
}
</style>
