<template>
    <v-row>
        <v-col cols="12">
            <p class="text-h6 font-weight-bold">Base de données</p>
        </v-col>
        <v-col cols="12" md="6">
            <element-row label="Numéro d'accès Uniprot">
                <a
                    v-for="(uniprot_entry, i) in uniq($get(variant, 'vep_annotation.uniprot_acc', []))"
                    :key="i"
                    :href="`https://www.uniprot.org/uniprot/${uniprot_entry}`"
                    target="_blank"
                    class="text-decoration-none"
                >
                    {{ uniprot_entry }}<br>
                </a>
            </element-row>
            <element-row label="dbSNP">
                <a
                    v-if="$get(variant, 'vep_annotation.rs_dbsnp') !== 'NA'"
                    :href="`https://www.ncbi.nlm.nih.gov/snp/${$get(variant, 'vep_annotation.rs_dbsnp')}`"
                    target="_blank"
                    class="text-decoration-none"
                >{{ $get(variant, 'vep_annotation.rs_dbsnp') }}</a>
                <span v-else class="text--disabled">NA</span>
            </element-row>
        </v-col>
        <v-col cols="12" md="6">
            <element-row label="ClinVar">
                <a
                    v-if="$get(variant, 'vep_annotation.clinvar_id', 'NA') !== 'NA'"
                    :href="`https://www.ncbi.nlm.nih.gov/clinvar/variation/${$get(variant, 'vep_annotation.clinvar_id')}`"
                    target="_blank">
                    {{ $get(variant, 'vep_annotation.clinvar_id') }} - {{
                        $get(variant, 'vep_annotation.clinvar_clnsig')
                    }}
                </a>
                <span v-else class="text--disabled">NA</span>
            </element-row>
            <element-row label="Variants à la même position">
                <a
                    v-for="(colocated_variant, i) in $get(variant, 'vep_annotation.colocated_variants', [])"
                    :key="colocated_variant.id"
                    :href="`https://cancer.sanger.ac.uk/cosmic/search?q=${colocated_variant.id}`"
                    target="_blank"
                    class="text-decoration-none">
                    {{ colocated_variant.id }}<br>
                </a>
            </element-row>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {Variant} from "@/models";
import ElementRow from "@/Components/generic/ElementRow.vue";
import {uniq} from 'lodash';

@Component({
    components: {ElementRow}
})
export default class VariantDatabaseSection extends Vue {
    @Prop() readonly variant!: Variant

    uniq(list: string[]) {
        return uniq(list)
    }
}
</script>

<style scoped>

</style>
