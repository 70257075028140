<template>
    <div>
        <div class="pa-5" v-if="type === 'simple'">
            <div class="mb-5 font-weight-bold">Échantillon</div>
            <element-row label="N° DIAMIC">{{ $get(ngsAnalysis, 'library.sample.diamic') }}</element-row>
            <element-row label="Bloc">{{ $get(ngsAnalysis, 'library.sample.diamic_suffix') }}</element-row>
            <element-row label="N° prélèvement">{{
                    $get(ngsAnalysis, 'library.sample.laboratory_number')
                }}
            </element-row>
            <element-row label="N° demande GENNO">{{ $get(ngsAnalysis, 'library.sample.genno_number') }}</element-row>
            <element-row label="Panel">{{ $get(ngsAnalysis, 'panel.name') }}</element-row>
            <element-row label="Diagnostic">{{ $get(ngsAnalysis, 'library.sample.tumor_type.name') }}</element-row>
            <element-row label="Pourcentage de la tumeur">
                {{ $get(ngsAnalysis, 'library.sample.tumor_cellularity_percentage', false) ?? 'NA' }}%
            </element-row>
            <element-row label="Gènes d'intérêt">
                <v-tooltip
                    bottom
                    v-for="(gene,index) in genes"
                    :key="`gene-${index}`"
                    color="rgba(0,0,0,1)"
                    content-class="white"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            label
                            class="ma-1"
                            small
                            depressed
                            color="info"

                            v-bind="attrs"
                            v-on="on"
                        >
                            {{ gene }}
                        </v-btn>
                    </template>
                    <amplicon-bar-chart
                        class="white"
                        :ngs-analysis-id="ngsAnalysis.id"
                        :gene="gene"
                        :height="4"
                        :width="8"
                        :type="type"
                    ></amplicon-bar-chart>
                </v-tooltip>
            </element-row>
            <element-row label="Autres analyses CNV">
                <a :href="$route('cnv_analyses.show', analysis.id)" v-for="analysis in relatedCnvAnalyses" :key="analysis.id" target="_blank">
                    {{ `#${analysis.id}, ${formatDate(analysis.created_at)}` }}<br/>
                </a>
                <span v-if="relatedCnvAnalyses.length <= 0">Aucunes</span>
            </element-row>
            <element-row label="Autres analyses NGS">
                <a :href="$route('ngs_analyses.show', analysis.id)" v-for="analysis in filteredRelatedNgsAnalyses" :key="analysis.id" target="_blank">
                    {{ `#${analysis.id}, ${formatDate(analysis.created_at)}, ${analysis.panel.name}` }}<br/>
                </a>
                <a :href="$route('ngs_pair_analyses.show', analysis.id)" v-for="analysis in relatedNgsPairAnalyses" :key="analysis.id" target="_blank">
                    {{ `#${analysis.id}, ${formatDate(analysis.created_at)}, ${analysis.tumoral_panel.name}` }}<br/>
                </a>
                <span v-if="filteredRelatedNgsAnalyses.length <= 0 && relatedNgsPairAnalyses.length <= 0">Aucunes</span>
            </element-row>
        </div>
        <div class="pa-5" v-if="type === 'pair'">
            <div class="mb-5 font-weight-bold">Échantillons</div>
            <v-row dense>
                <v-col cols="12" md="6">
                    <div class="mb-3 font-weight-medium">Tumoral</div>
                    <element-row label="N° DIAMIC" :right-col-size="6" :left-col-size="6">{{
                            $get(ngsAnalysis, 'ngs_tumoral_library.sample.diamic')
                        }}
                    </element-row>
                    <element-row label="Bloc" :right-col-size="6" :left-col-size="6">{{
                            $get(ngsAnalysis, 'ngs_tumoral_library.sample.diamic_suffix')
                        }}
                    </element-row>
                    <element-row label="N° prélèvement" :right-col-size="6" :left-col-size="6">{{
                            $get(ngsAnalysis, 'ngs_tumoral_library.sample.laboratory_number')
                        }}
                    </element-row>
                    <element-row label="N° demande GENNO" :right-col-size="6" :left-col-size="6">
                        {{ $get(ngsAnalysis, 'ngs_tumoral_library.sample.genno_number') }}
                    </element-row>
                    <element-row label="Panel" :right-col-size="6" :left-col-size="6">
                        {{ $get(ngsAnalysis, 'tumoral_panel.name') }}
                    </element-row>
                    <element-row label="Diagnostic" :right-col-size="6" :left-col-size="6">{{
                            $get(ngsAnalysis, 'ngs_tumoral_library.sample.tumor_type.name')
                        }}
                    </element-row>
                    <element-row label="Pourcentage de la tumeur" :right-col-size="6" :left-col-size="6">
                        {{
                            $get(ngsAnalysis, 'ngs_tumoral_library.sample.tumor_cellularity_percentage', false) ?? 'NA'
                        }}%
                    </element-row>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="mb-3 font-weight-medium">Non-tumoral</div>
                    <element-row label="N° DIAMIC" :right-col-size="6" :left-col-size="6">{{
                            $get(ngsAnalysis, 'ngs_non_tumoral_library.sample.diamic')
                        }}
                    </element-row>
                    <element-row label="Bloc" :right-col-size="6" :left-col-size="6">{{
                            $get(ngsAnalysis, 'ngs_non_tumoral_library.sample.diamic_suffix')
                        }}
                    </element-row>
                    <element-row label="N° prélèvement" :right-col-size="6" :left-col-size="6">{{
                            $get(ngsAnalysis, 'ngs_non_tumoral_library.sample.laboratory_number')
                        }}
                    </element-row>
                    <element-row label="N° demande GENNO" :right-col-size="6" :left-col-size="6">
                        {{ $get(ngsAnalysis, 'ngs_non_tumoral_library.sample.genno_number') }}
                    </element-row>
                    <element-row label="Panel" :right-col-size="6" :left-col-size="6">
                        {{ $get(ngsAnalysis, 'tumoral_panel.name') }}
                    </element-row>

                </v-col>
            </v-row>
            <element-row label="Gènes d'intérêt">
                <v-tooltip
                    bottom
                    v-for="(gene,index) in genes"
                    :key="`gene-${index}`"
                    color="rgba(0,0,0,1)"
                    content-class="white"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            label
                            class="ma-1"
                            small
                            depressed
                            color="info"

                            v-bind="attrs"
                            v-on="on"
                        >
                            {{ gene }}
                        </v-btn>
                    </template>
                    <amplicon-bar-chart
                        class="white"
                        :ngs-analysis-id="ngsAnalysis.id"
                        :gene="gene"
                        :height="4"
                        :width="8"
                        :type="type"
                    ></amplicon-bar-chart>
                </v-tooltip>
            </element-row>
            <element-row label="Autres analyses CNV">
                <a :href="$route('cnv_analyses.show', analysis.id)" v-for="analysis in relatedCnvAnalyses" :key="analysis.id" target="_blank">
                    {{ `#${analysis.id}, ${formatDate(analysis.created_at)}` }}<br/>
                </a>
                <span v-if="relatedCnvAnalyses.length <= 0">Aucunes</span>
            </element-row>
            <element-row label="Autres analyses NGS">
                <a :href="$route('ngs_analyses.show', analysis.id)" v-for="analysis in relatedNgsAnalyses" :key="analysis.id" target="_blank">
                    {{ `#${analysis.id}, ${formatDate(analysis.created_at)}, ${analysis.panel.name}` }}<br/>
                </a>
                <a :href="$route('ngs_pair_analyses.show', analysis.id)" v-for="analysis in filteredRelatedNgsPairAnalyses" :key="analysis.id" target="_blank">
                    {{ `#${analysis.id}, ${formatDate(analysis.created_at)}, ${analysis.tumoral_panel.name}` }}<br/>
                </a>
                <span v-if="relatedNgsAnalyses.length <= 0 && filteredRelatedNgsPairAnalyses.length <= 0">Aucunes</span>
            </element-row>
        </div>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import {Gene, NgsAnalysis, NgsPairAnalysis} from "@/models";
import AmpliconBarChart from "@/Components/domain-specific/ngs/charts/AmpliconBarChart.vue";

@Component({
    components: {
        AmpliconBarChart,
        ElementRow
    }
})
export default class NgsSampleInfoSection extends Vue {
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'
    @Prop() readonly relatedCnvAnalyses!: Array<{ id: number, created_at: string }>
    @Prop() readonly relatedNgsAnalyses!: Array<{ id: number, created_at: string, panel: { name: string } }>
    @Prop() readonly relatedNgsPairAnalyses!: Array<{ id: number, created_at: string, tumoral_panel: { name: string } }>

    get genes(): string[] {
        return this.ngsAnalysis.genes.map((gene: Gene) => gene.gencode_name)
    }

    get filteredRelatedNgsAnalyses(): Array<{ id: number, created_at: string, panel: { name: string } }> {
        return this.relatedNgsAnalyses.filter((analysis: any) => analysis.id !== this.ngsAnalysis.id)
    }

    get filteredRelatedNgsPairAnalyses(): Array<{ id: number, created_at: string, tumoral_panel: { name: string } }> {
        return this.relatedNgsPairAnalyses.filter((analysis: any) => analysis.id !== this.ngsAnalysis.id)
    }
}
</script>

<style lang="scss" scoped>
</style>
