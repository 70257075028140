<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            :group-by="['gene.gencode_name']"
            :sort-by="['position', 'chromosome_id']"
            :loading="loading"
            disable-sort
            disable-filtering
            no-data-text="Aucun variant détecté"
        >
            <template v-slot:group.header="{ group, headers, isOpen, toggle, items }">
                <td :colspan="headers.length - 1" class="py-0 grey lighten-4">
                    <v-btn icon @click="toggle">
                        <v-icon>mdi-{{ isOpen ? 'chevron-right' : 'chevron-down' }}</v-icon>
                    </v-btn>
                    <strong>{{ group }}</strong>
                    <span class="ml-1 grey--text">{{ `(${items.length} variants)` }}</span>
                    <v-btn
                        v-if="annotatedSegment(items).length > 0"
                        label
                        small
                        color="blue"
                        class="font-weight-bold ml-2"
                        dark
                        depressed
                        @click="openCnvAnnotationDialog(annotatedSegment(items), group)"
                    >
                        CNV
                    </v-btn>
                </td>
                <td class="row-chart pa-0 ma-0 grey lighten-4">
                    <div class="canvas-container">
                        <amplicon-bar-chart v-if="!loading" :ngs-analysis-id="ngsAnalysis.id" :gene="group" :height="4"
                                            :width="8" :type="type"></amplicon-bar-chart>
                    </div>
                </td>
            </template>
            <template v-slot:item.position="{ item }">
                <v-tooltip top color="rgba(0,0,0,0.9)">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            :color="qualities[$get(item, 'pivot.mapped_quality', 'excellent')].color"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-circle-medium
                        </v-icon>
                    </template>
                    <span class="font-weight-bold">{{
                            qualities[$get(item, 'pivot.mapped_quality', 'excellent')].label
                        }}</span>
                </v-tooltip>
                <span
                    v-if="$get(item, 'vep_annotation.hgvsc', 'NA').includes('del') && !$get(item, 'vep_annotation.hgvsc', 'NA').includes('delins')">chr{{
                        item.chromosome.name
                    }}:{{ (Number(item.position) + 1).toLocaleString('de-CH') }}</span>
                <span v-else>chr{{ item.chromosome.name }}:{{ Number(item.position).toLocaleString('de-CH') }}</span>
                <v-icon small v-if="item.is_masked" color="primary" class="ml-1">mdi-eye-off-outline</v-icon>
                <v-icon small v-if="$get(item, 'vep_annotation.is_silent', false)" color="primary" class="ml-1">
                    mdi-circle-off-outline
                </v-icon>
                <v-tooltip top color="rgba(0,0,0,0.9)">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-if="$get(item, 'pivot.information', '') === 'MANUALLY_ADDED'" color="primary"
                                class="ml-1" v-bind="attrs"
                                v-on="on">
                            mdi-pencil-plus
                        </v-icon>
                    </template>
                    <span class="font-weight-bold">Ce variant a été ajouté manuellement</span>
                </v-tooltip>
            </template>
            <template v-slot:item.hgvs_c="{ item }">
                <span v-if="$get(item, 'vep_annotation.hgvsc', 'NA') === 'NA'" class="text--disabled">NA</span>
                <span
                    v-if="$get(item, 'vep_annotation.hgvsc', 'NA') !== 'NA' && $get(item, 'vep_annotation.hgvsc').after(':').length <= 20">
                    {{ $get(item, 'vep_annotation.hgvsc').after(':') }}
                </span>
                <v-tooltip
                    v-if="$get(item, 'vep_annotation.hgvsc', 'NA') !== 'NA' && $get(item, 'vep_annotation.hgvsc').after(':').length > 20"
                    top>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">
                            {{ truncate(item.vep_annotation.hgvsc.after(':'), 20) }}
                        </span>
                    </template>
                    <span>{{ $get(item, 'vep_annotation.hgvsc').after(':') }}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.hgvs_p="{ item }">
                <span v-if="$get(item, 'vep_annotation.hgvsp', 'NA') === 'NA'" class="text--disabled">NA</span>
                <span
                    v-if="$get(item, 'vep_annotation.hgvsp', 'NA') !== 'NA' && $get(item, 'vep_annotation.hgvsp').after(':').length <= 20">
                    {{ $get(item, 'vep_annotation.hgvsp').after(':') }}
                </span>
                <v-tooltip
                    v-if="$get(item, 'vep_annotation.hgvsp', 'NA') !== 'NA' && $get(item, 'vep_annotation.hgvsp').after(':').length > 20"
                    top>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">
                            {{ truncate(item.vep_annotation.hgvsp.after(':'), 20) }}
                        </span>
                    </template>
                    <span>{{ $get(item, 'vep_annotation.hgvsp').after(':') }}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.effect="{ item }">
                <span :class="$get(item, 'vep_annotation.variant_effect', 'NA') === 'NA' ? 'text--disabled' : ''">
                    {{ $get(item, 'vep_annotation.variant_effect') }}
                </span>
            </template>
            <template v-slot:item.transcript="{ item }">
                <div class="text-center mb-2">{{ $get(item, 'transcript.transcript_id') }}</div>
                <dna-sequence-viewer
                    v-if="$get(item, 'pivot.dna_sequence', []).length <= 25"
                    :data="$get(item, 'pivot.dna_sequence', '')"
                    :strand="$get(item, 'vep_annotation.strand', 1)"
                    :hgvsc="$get(item, 'vep_annotation.hgvsc', 'NA')"
                ></dna-sequence-viewer>
            </template>
            <template v-slot:item.databases="{ item }">
                <variant-databases-btn :item="item"
                                       :tumor-type-id="tumorTypeId"></variant-databases-btn>
            </template>
            <template v-slot:item.pathogenicity="{ item }">
                <add-variant-pathogenicity-btn :item="item" :ngs-analysis="ngsAnalysis"
                                               :readonly="readonly || $get(item, 'annotation.annotation', 'valid') !== 'valid' || item.is_masked"
                                               :value="$get(item, 'pathogenicity', 'NA')"
                                               :type="type"></add-variant-pathogenicity-btn>
            </template>
            <template v-slot:item.average_position="{ item }">
                {{ averagePosition(item) }}%
            </template>
            <template v-slot:item.frequency="{ item }">
                <div @click.alt="openFrequencyPhredDialog(item, 0)">
                    <frequency-pie-chart
                        :data="frequencies(item)"
                        :variant-depth="$get(item, 'pivot.variant_depth', 'NA')"
                        :total-depth="$get(item, 'pivot.total_depth', 'NA')"
                    ></frequency-pie-chart>
                    <div class="text-caption error--text font-weight-bold"
                         v-if="$get(item, 'pivot.non_tumoral_allelic_frequency', 0) >= 0.1">NT:
                        {{ $get(item, 'pivot.non_tumoral_allelic_frequency') * 100 }}%
                    </div>
                </div>
            </template>
            <template v-slot:item.phred="{ item }">
                <div
                    v-if="item.pivot.variant_type !== 'Complex' && item.pivot.bam_readcount_alternate_0 !== null || $get(item, 'pivot.bam_readcount_alternate_0.avg_pos_as_fraction') === 0"
                    @click="openFrequencyPhredDialog(item, 1)">
                    <phred-bar-chart :data="phred(item)"></phred-bar-chart>
                </div>
                <div v-else class="text--disabled cursor-pointer" @click="openFrequencyPhredDialog(item, 1)">
                    NA
                </div>
            </template>
            <template v-slot:item.strand_balance="{ item }">
                <div
                    v-if="item.pivot.variant_type !== 'Complex' && item.pivot.bam_readcount_alternate_0 !== null || $get(item, 'pivot.bam_readcount_alternate_0.avg_pos_as_fraction') === 0"
                    @click="openFrequencyPhredDialog(item, 0)">
                    <strand-balance-pie-chart :data="strandBalanceFrequencies(item)"></strand-balance-pie-chart>
                </div>
                <div v-else class="text--disabled cursor-pointer" @click="openFrequencyPhredDialog(item, 0)">
                    NA
                </div>
            </template>
            <template v-slot:item.annotation="{ item }">
                <annotate-variant-btn :item="item" :readonly="readonly || item.is_masked"></annotate-variant-btn>
            </template>
            <template v-slot:item.report="{ item }">
                <v-btn icon v-if="item.pivot.report_status !== 'not_included' && !item.is_masked"
                       @click="updateReportStatus(item)"
                       :disabled="readonly">
                    <v-icon color="primary">{{
                            item.pivot.report_status === 'included' ? 'mdi-check' : 'mdi-star'
                        }}
                    </v-icon>
                </v-btn>
                <v-btn icon v-else disabled>
                    <v-icon color="primary">mdi-close</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.actions="{ item }">
                <variant-actions-btn :item="item" :ngs-analysis="ngsAnalysis" :type="type" :readonly="readonly"
                                     @publication-added="$emit('update')"></variant-actions-btn>
            </template>
        </v-data-table>
        <ngs-frequency-phred-dialog
            v-model="dialogFrequencyPhredOpen"
            :variant="selectedVariant"
            :selected-tab="selectedTab"
        ></ngs-frequency-phred-dialog>
        <ngs-cnv-annotation-dialog
            v-model="dialogCnvAnnotationOpen"
            :segments="selectedSegments"
            :gene="selectedGene"
        ></ngs-cnv-annotation-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue} from 'vue-property-decorator'
import {DataTableHeader} from "vuetify";
import FrequencyPieChart from "@/Components/domain-specific/ngs/charts/FrequencyPieChart.vue";
import DnaSequenceViewer from "@/Components/domain-specific/ngs/helpers/DnaSequenceViewer.vue";
import PhredBarChart from '@/Components/domain-specific/ngs/charts/PhredBarChart.vue';
import StrandBalancePieChart from '@/Components/domain-specific/ngs/charts/StrandBalancePieChart.vue';
import AmpliconBarChart from "@/Components/domain-specific/ngs/charts/AmpliconBarChart.vue";
import NgsFrequencyPhredDialog from '@/Components/domain-specific/ngs/dialogs/NgsFrequencyPhredDialog.vue';
import get from "lodash/get";
import AnnotateVariantBtn from "@/Components/domain-specific/ngs/dialogs/AnnotateVariantBtn.vue";
import route from "ziggy-js";
import {TYPE} from "vue-toastification";
import AddVariantPathogenicityBtn from "@/Components/domain-specific/ngs/dialogs/AddVariantPathogenicityBtn.vue";
import VariantActionsBtn from "@/Components/domain-specific/ngs/dialogs/VariantActionsBtn.vue";
import VariantDatabasesBtn from "@/Components/domain-specific/ngs/dialogs/VariantDatabasesBtn.vue";
import {Chromosome, NgsAnalysis, NgsPairAnalysis, Segment, Variant} from "@/models";
import {head} from 'lodash';
import NgsCnvAnnotationDialog from "@/Components/domain-specific/ngs/dialogs/NgsCnvAnnotationDialog.vue";

@Component({
    components: {
        NgsCnvAnnotationDialog,
        VariantDatabasesBtn,
        VariantActionsBtn,
        AddVariantPathogenicityBtn,
        AnnotateVariantBtn,
        NgsFrequencyPhredDialog,
        AmpliconBarChart,
        DnaSequenceViewer,
        FrequencyPieChart,
        StrandBalancePieChart,
        PhredBarChart
    }
})
export default class VariantsTable extends Vue {
    @VModel() filters!: {
        'show-silent-variants': boolean | null,
        'show-masked-variants': boolean | null,
        allelic_balance: number | null,
        chromosome: string | null,
        position: number | null
    }
    @Prop() readonly items!: Array<Variant>
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly annotatedSegments!: Array<Segment>
    @Prop() readonly loading!: boolean
    @Prop() readonly type!: 'simple' | 'pair'
    @Prop() readonly readonly!: boolean

    selectedTab: number = 0
    selectedVariant: Variant | null = null
    selectedSegments: Array<Segment> = []
    selectedGene: string | null = null
    dialogFrequencyPhredOpen: boolean = false
    dialogAmpliconCoverageOpen: boolean = false
    dialogCnvAnnotationOpen: boolean = false

    averagePosition(item: Variant): number {
        return Math.round(get(item, 'pivot.bam_readcount_alternate_0.avg_pos_as_fraction', 0) * 100)
    }

    frequencies(item: Variant): number[] {
        return [Math.round(get(item, 'pivot.allelic_frequency', 0) * 100), 100 - Math.round(get(item, 'pivot.allelic_frequency', 0) * 100)]
    }

    phred(item: Variant): number[][] {
        return [
            [
                (Number(get(item, 'pivot.bam_readcount_alternate_0.count', 0)) - Number(get(item, 'pivot.bam_readcount_alternate_20.count', 0))) / Number(get(item, 'pivot.bam_readcount_alternate_0.count', 100)) * 100,
                (Number(get(item, 'pivot.bam_readcount_reference_0.count', 0)) - Number(get(item, 'pivot.bam_readcount_reference_20.count', 0))) / Number(get(item, 'pivot.bam_readcount_reference_0.count', 100)) * 100
            ],
            [
                100 - ((Number(get(item, 'pivot.bam_readcount_alternate_0.count', 0)) - Number(get(item, 'pivot.bam_readcount_alternate_20.count', 0))) / Number(get(item, 'pivot.bam_readcount_alternate_0.count', 100)) * 100),
                100 - ((Number(get(item, 'pivot.bam_readcount_reference_0.count', 0)) - Number(get(item, 'pivot.bam_readcount_reference_20.count', 0))) / Number(get(item, 'pivot.bam_readcount_reference_0.count', 100)) * 100)
            ]
        ]
    }

    strandBalanceFrequencies(item: Variant): number[][] {
        return [
            [
                (Number(get(item, 'pivot.bam_readcount_alternate_0.num_plus_strand', 0)) / Number(get(item, 'pivot.bam_readcount_alternate_0.count', 100)) * 100),
                (100 - (Number(get(item, 'pivot.bam_readcount_alternate_0.num_plus_strand', 0)) / Number(get(item, 'pivot.bam_readcount_alternate_0.count', 100)) * 100))
            ],
            [
                (Number(get(item, 'pivot.bam_readcount_reference_0.num_plus_strand', 0)) / Number(get(item, 'pivot.bam_readcount_reference_0.count', 100)) * 100),
                (100 - (Number(get(item, 'pivot.bam_readcount_reference_0.num_plus_strand', 0)) / Number(get(item, 'pivot.bam_readcount_reference_0.count', 100)) * 100))
            ]
        ]
    }

    qualities: { [key: string]: { label: string; color: string; } } = {
        insufficient: {
            label: 'Insufficient',
            color: '#e53e3e'
        },
        weak: {
            label: 'Weak',
            color: '#ed8936'
        },
        good: {
            label: 'Good',
            color: '#ecc94b'
        },
        excellent: {
            label: 'Excellent',
            color: '#48bb78'
        },
    }

    headers: Array<DataTableHeader> = [
        {
            text: 'Position',
            value: 'position',
            width: '10%'
        },
        {
            text: 'HGVS.c',
            value: 'hgvs_c',
            width: '5%'
        },
        {
            text: 'HGVS.p',
            value: 'hgvs_p',
            width: '5%'
        },
        {
            text: 'Effet',
            value: 'effect',
            width: '10%'
        },
        {
            text: 'Transcrit',
            value: 'transcript',
            width: '17%'
        },
        {
            text: 'Bases de données',
            value: 'databases',
            width: '8%'
        },
        {
            text: 'Pathogénicité',
            value: 'pathogenicity',
            width: '8%'
        },
        {
            text: 'Position moyenne',
            value: 'average_position',
            width: '4%'
        },
        {
            text: 'Fréquence',
            value: 'frequency',
            width: '5%'
        },
        {
            text: 'Phred <20 (%)',
            value: 'phred',
            width: '5%'
        },
        {
            text: 'Strand balance',
            value: 'strand_balance',
            width: '5%'
        },
        {
            text: 'Annotations',
            value: 'annotation',
            width: '5%'
        },
        {
            text: 'Rapport',
            value: 'report',
            width: '5%'
        },
        {
            text: 'Actions',
            value: 'actions',
            width: '8%',
            align: 'end'
        }
    ]

    get chromosomes(): Array<Chromosome> {
        return (this.$page.props.chromosomes as Array<Chromosome>)
    }

    resetFilters() {
        this.filters.chromosome = null
        this.filters.position = null
        this.filters.allelic_balance = null
    }

    updateReportStatus(item: Variant) {
        this.$inertia.put(route('ngs_analysis_variants.report_status.update', {ngs_analysis_variant: (item as any).pivot.id}), {
            status: (item as any).pivot.report_status
        }, {
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                this.$toast(`Variant marqué comme ${(item as any).pivot.report_status === 'included' ? 'mis en évidence' : 'inclus'}`, {
                    type: TYPE.SUCCESS
                })
            }
        })
    }

    openAmpliconCoverageDialog(variant: Variant) {
        this.selectedVariant = variant
        this.dialogAmpliconCoverageOpen = true
    }

    openFrequencyPhredDialog(variant: Variant, tab: number) {
        this.selectedVariant = variant
        this.selectedTab = tab
        this.dialogFrequencyPhredOpen = true
    }

    openCnvAnnotationDialog(segments: Array<Segment>, gene: string) {
        this.selectedSegments = segments
        this.selectedGene = gene
        this.dialogCnvAnnotationOpen = true
    }

    head(array: Array<any>) {
        return head(array)
    }

    annotatedSegment(items: Array<Variant>): Array<Segment> {
        if (items === undefined || items.length === 0) {
            return []
        }
        const firstVariant = head(items)
        const gene = firstVariant.gene
        return this.annotatedSegments
            .filter((item: Segment) => gene.chromosome.id === item.chromosome_id)
            .filter((item: Segment) => (gene.start >= item.start_position && gene.start <= item.end_position) || (gene.end >= item.start_position && gene.end <= item.end_position))
    }

    truncate(str: string, n: number) {
        return (str.length > n) ? str.slice(0, n - 1) + '...' : str
    }

    get tumorTypeId(): number {
        if (this.type === 'pair') {
            return (this.ngsAnalysis as NgsPairAnalysis).ngs_tumoral_library.sample.tumor_type_id
        }

        return (this.ngsAnalysis as NgsAnalysis).sample.tumor_type_id
    }
}
</script>

<style scoped>
.row-chart {
    max-width: 80px;
    max-height: 80px;
}

.cursor-pointer {
    cursor: pointer;
}
</style>
