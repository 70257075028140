<template>
    <dialog-content
        v-model="isDialogOpen"
        title="Ajouter un variant génétique"
        :max-width="900"
    >
        <stepper
            v-model="stepper"
            :steps="steps"
        >
            <v-alert type="secondary" class="pa-5 text-body-2 border-0">
                <b>Informations sur l'ajout manuel de variants</b>
                <ul>
                    <li>La position et les séquences de référence et alternative doivent toujours être celles du brin
                        forward (même si le gène est sur le brin inverse; comme par exemple TP53). Afin de déterminer la
                        séquence et la position du variant, veuillez utiliser le read viewer.
                    </li>
                    <li>Les séquences de référence et alternative doivent être entrée en majuscules.</li>
                    <li>Dans le cas d’une délétion ou insertion simple, la position est celle de la base précédent le
                        variant. Par exemple pour la délétion de la séquence CAC en position 55259482 du chr7, il faut
                        renseigner « 7, 55259481, ACAC, A ». Ou pour une insertion TTT entre les positions 7:55259481 et
                        7:55259482, on renseignera « 7, 55259481, A, ATTT ».
                    </li>
                    <li>Dans tous les autres cas, il faut renseigner la position de la première base mutée.</li>
                    <li><b>ATTENTION: L'ajout peut prendre quelques minutes avant d'être effectif et fonctionnel, veuillez patienter avant de rafraîchir la page une fois le variant ajouté</b></li>
                </ul>
            </v-alert>
            <template v-slot:step-1>
                <div class="pa-5">
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                label="Chromosome"
                                v-model="form.chromosome_id"
                                :items="chromosomes"
                                :error-messages="form.errors.chromosome_id"
                                item-text="name"
                                item-value="id"
                                outlined
                                clearable
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Position"
                                v-model.number="form.position"
                                placeholder="Position génomique (ex: 123456)"
                                :error-messages="form.errors.position"
                                type="number"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Référence"
                                v-model="form.reference"
                                placeholder="REF (ex: AT)"
                                :error-messages="form.errors.reference"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Alternatif"
                                v-model="form.alternate"
                                placeholder="ALT (ex: A)"
                                :error-messages="form.errors.alternate"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
                <v-card-actions class="text-right justify-end">
                    <v-btn @click="cancel()" color="primary" text>
                        <v-icon small class="mr-1">mdi-close</v-icon>
                        Annuler
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="addVariant()"
                        color="primary"
                        depressed
                        :loading="form.processing"
                    >
                        <v-icon small class="mr-1">mdi-arrow-right</v-icon>
                        Suivant
                    </v-btn>
                </v-card-actions>
            </template>
            <template v-slot:step-2>
                <div class="pa-5">
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                label="Qualité"
                                v-model="analysisForm.mapped_quality"
                                :items="qualities"
                                :error-messages="analysisForm.errors.mapped_quality"
                                item-text="label"
                                item-value="value"
                                outlined
                                clearable
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Fréquence allélique"
                                v-model.number="analysisForm.allelic_frequency"
                                placeholder="Fréquence allélique (ex: 0.5)"
                                :error-messages="analysisForm.errors.allelic_frequency"
                                type="number"
                                min="0"
                                max="1"
                                step="0.1"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
                <v-card-actions class="text-right justify-end">
                    <v-btn @click="cancel()" color="primary" text>
                        <v-icon small class="mr-1">mdi-arrow-left</v-icon>
                        Précédent
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="addAnalysisVariant()"
                        color="primary"
                        depressed
                        :loading="analysisForm.processing"
                    >
                        <v-icon small class="mr-1">mdi-plus</v-icon>
                        Ajouter
                    </v-btn>
                </v-card-actions>
            </template>
        </stepper>
    </dialog-content>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import Btn from '@/Components/generic/Btn.vue'
import {TYPE} from 'vue-toastification'
import route from 'ziggy-js'
import get from 'lodash/get'
import {Chromosome, NgsAnalysis, NgsPairAnalysis, User} from "@/models";
import {AnalysisVariantForm, ReviewForm, VariantForm} from "@/forms";
import Stepper from "@/Components/generic/Stepper.vue";
import DialogContent from "@/Components/generic/DialogContent.vue";
import {Page} from "@inertiajs/inertia";

@Component({
    components: {
        DialogContent,
        Stepper,
        Btn,
        DialogConfirmation
    }
})
export default class NgsAddVariantDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly analysisType!: 'simple' | 'pair'

    @Watch('isDialogOpen', {immediate: true})
    onDialogOpenChange(value: boolean) {
        if (value) {
            this.form.reset()
            this.analysisForm.reset()
            this.stepper = 1
        }
    }

    loading: boolean = false

    stepper: number = 1
    steps: { [key: number]: { title: string; complete: boolean; hasErrors: boolean; } } = {
        1: {
            title: 'Information générales',
            complete: true,
            hasErrors: false
        },
        2: {
            title: 'Informations contextuelles',
            complete: false,
            hasErrors: false
        }
    }

    chromosomes: Array<Chromosome> = []

    qualities: Array<{ label: string; value: string }> = [
        {label: 'Faible', value: 'weak'},
        {label: 'Bonne', value: 'good'},
        {label: 'Excellente', value: 'excellent'},
    ]

    form: InertiaForm<VariantForm> = this.$inertia.form({
        chromosome_id: null,
        position: null,
        reference: null,
        alternate: null,
    })

    analysisForm: InertiaForm<AnalysisVariantForm> = this.$inertia.form({
        variant_id: null,
        mapped_quality: null,
        allelic_frequency: null,
    })

    cancel() {
        if (this.stepper === 1) {
            this.isDialogOpen = false
        } else {
            this.stepper--
        }
    }

    addVariant() {
        const routeName = this.analysisType === 'simple' ? 'ngs-analyses.variants.store' : 'ngs-pair-analyses.variants.store'

        this.form.post(route(routeName, this.ngsAnalysis.id), {
            preserveScroll: true,
            onSuccess: (page: Page) => {
                this.stepper = 2
                this.analysisForm.variant_id = (page.props.flash as any).data
            }
        })
    }

    addAnalysisVariant() {
        const routeName = this.analysisType === 'simple' ? 'ngs-analyses.variants.link' : 'ngs-pair-analyses.variants.link'

        this.analysisForm.post(route(routeName, [this.ngsAnalysis.id, this.analysisForm.variant_id]), {
            preserveScroll: true,
            onSuccess: () => {
                this.$toast.success('Le variant a bien été ajouté', {
                    timeout: 2000,
                    type: TYPE.SUCCESS
                })
                this.isDialogOpen = false
                this.$inertia.reload({
                    preserveScroll: true,
                    only: ['variants']
                })
            }
        })
    }

    fetchChromosomes() {
        this.loading = true
        this.$axios.get(route('chromosomes.index')).then(({data}) => {
            this.chromosomes = data
        }).finally(() => {
            this.loading = false
        })
    }

    created() {
        this.fetchChromosomes()
    }
}
</script>

<style lang="scss" scoped>
.border-0 {
    border: none !important;
}
</style>
