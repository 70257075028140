<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            item-key="id"
            disable-sort
            no-data-text="Aucune analyse"
            hide-default-footer
            disable-pagination
        >
            <template v-slot:item.sample="{ item }">
                {{
                    `${$defaultTo(item.library.sample.diamic, '')} ${$defaultTo(item.library.sample.genno_number, '')} (${$defaultTo(item.library.sample.laboratory_number, '')})`
                }}
                <v-chip
                    v-if="value.id !== item.library.ngs_run_id"
                    @click="$visit('ngs_runs.show', {ngs_run: item.library.ngs_run_id})"
                    class="ml-1 chip-run"
                    small
                >
                    Run N°{{ item.library.ngs_run_id }}
                </v-chip>
            </template>
            <template v-slot:item.ngs_analysis_status="{ item }">
                <ngs-analysis-status-progress
                    :status="item.ngs_analysis_status"
                ></ngs-analysis-status-progress>
            </template>
            <template v-slot:item.qc_status="{ item }">
                <v-icon
                    :color="qualityControl[item.has_passed_quality_control].color">
                    {{ qualityControl[item.has_passed_quality_control].icon }}
                </v-icon>
            </template>
            <template v-slot:item.report="{ item }">
                <v-tooltip v-if="item.ngs_analysis_status === 'reported'" top>
                    <template v-slot:activator="{ on }">
                        <div class="text-center">
                            <v-btn
                                :href="$route('ngs_analyses.download_report', { ngs_analysis: item.id, analysis_report: item.report.id })"
                                icon
                                v-on="on"
                            >
                                <v-icon color="primary">mdi-file-word-outline</v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <span>Télécharger rapport</span>
                </v-tooltip>
            </template>
            <template v-slot:item.comparison_status="{ item }">
                <div v-if="item.ngs_analysis_status !== 'loading_error'">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <div class="text-center">
                                <v-btn @click="openComparisonDialog(item)" text color="primary">
                                    {{ item.comparisons_count }}
                                </v-btn>
                            </div>
                        </template>
                        <span>{{ item.comparisons_exists ? 'Comparé' : 'Pas comparé' }}</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="text-center">
                    <v-btn
                        v-if="item.ngs_analysis_status !== 'error' || item.ngs_analysis_status !== 'loading_error'"
                        @click="$visit('ngs_analyses.show', {ngs_analysis: item.id})"
                        icon
                    >
                        <v-icon color="primary">mdi-arrow-right</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <ngs-analysis-comparisons-dialog
            v-if="selectedNgsAnalysis"
            v-model="dialogComparisonOpen"
            :reference="value"
            :item="selectedNgsAnalysis"
            type="simple"
        ></ngs-analysis-comparisons-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {DataTableHeader} from 'vuetify'
import NgsAnalysisStatusProgress from '@/Components/domain-specific/ngs/helpers/NgsAnalysisStatusProgress.vue'
import NgsAnalysisComparisonsDialog from '@/Components/domain-specific/ngs/dialogs/NgsAnalysisComparisonsDialog.vue'
import {NgsAnalysis, NgsRun} from "@/models";

@Component({
    components: {
        NgsAnalysisComparisonsDialog,
        NgsAnalysisStatusProgress,
    }
})
export default class NgsSimpleAnalysesTable extends Vue {
    @Prop() readonly value!: NgsRun
    @Prop() readonly items!: Array<NgsAnalysis>

    dialogComparisonOpen: boolean = false
    selectedNgsAnalysis: NgsAnalysis | null = null
    loading: boolean = false

    qualityControl: { [key: string]: { color: string; icon: string; text: string; } } = {
        true: {
            color: 'success',
            icon: 'mdi-check-circle',
            text: 'PASS'
        },
        false: {
            color: 'error',
            icon: 'mdi-close-circle',
            text: 'FAILED'
        },
        null: {
            color: 'grey',
            icon: 'mdi-help-circle',
            text: 'NA'
        }
    }

    headers: Array<DataTableHeader> = [
        {
            text: 'Échantillon (N° DIAMIC - N° demande GENNO - N° prélèvement)',
            value: 'sample',
            width: '30%',
            sortable: false
        },
        {
            text: 'Statut analyse',
            value: 'ngs_analysis_status',
            width: '30%',
            sortable: false
        },
        {
            text: 'Statut QC',
            value: 'qc_status',
            align: 'center',
            width: '10%',
            sortable: false
        },
        {
            text: 'Rapport',
            value: 'report',
            align: 'center',
            width: '10%',
            sortable: false
        },
        {
            text: 'Comparaisons',
            value: 'comparison_status',
            align: 'center',
            width: '10%',
            sortable: false
        },
        {
            text: 'Actions',
            value: 'actions',
            align: 'center',
            width: '10%',
            sortable: false
        }
    ]

    openComparisonDialog(analysis: NgsAnalysis) {
        this.dialogComparisonOpen = true
        this.selectedNgsAnalysis = analysis
    }
}
</script>

<style lang="scss">
.v-expansion-panel-content__wrap {
    padding: 0;
}

.chip-run:hover {
    cursor: pointer;
}
</style>
