<template>
    <v-btn :color="color" :text="$vuetify.breakpoint.lgAndUp"
           :icon="iconOnly ? true : $vuetify.breakpoint.mdAndDown"
           @click="onClick()" :loading="loading" :disabled="disabled" :block="block"
           @mouseleave="$emit('mouseleave')"
           :small="small">
        <v-icon :color="color">{{ icon }}</v-icon>
        <span v-if="iconOnly ? false : $vuetify.breakpoint.lgAndUp" class="ml-1 text-subtitle-2">
          <slot></slot>
        </span>
    </v-btn>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class Btn extends Vue {
    @Prop() readonly icon?: string
    @Prop({default: 'primary'}) readonly color!: string
    @Prop({default: false}) readonly iconOnly!: boolean
    @Prop({default: false}) readonly loading!: boolean
    @Prop({default: false}) readonly disabled!: boolean
    @Prop({default: false}) readonly block!: boolean
    @Prop({default: false}) readonly small!: boolean

    private onClick() {
        this.$emit('click')
    }
}
</script>

<style scoped lang="scss">
</style>
