<template>
    <v-row>
        <v-col cols="12">
            <p class="text-h6 font-weight-bold">Pathogénicités
                <v-chip small>{{ pathogenicities.length }}</v-chip>
                <v-btn icon class="ml-1" color="primary"
                       @click="isPathogenicitiesShown=!isPathogenicitiesShown">
                    <v-icon>{{
                            isPathogenicitiesShown ? 'mdi-chevron-right' : 'mdi-chevron-down'
                        }}
                    </v-icon>
                </v-btn>
            </p>
        </v-col>
        <v-col cols="12" v-if="isPathogenicitiesShown">
            <v-data-table
                :headers="headers"
                :items="pathogenicities"
                group-by="tumor_type.name"
                disable-pagination
                disable-sort
                hide-default-footer
            >
                <template v-slot:group.header="{ group, headers, isOpen, toggle, items }">
                    <td :colspan="headers.length" class="py-0 grey lighten-4">
                        <v-btn icon @click="toggle">
                            <v-icon>mdi-{{ isOpen ? 'chevron-right' : 'chevron-down' }}</v-icon>
                        </v-btn>
                        <strong>{{ group }}</strong>
                    </td>
                </template>
                <template v-slot:item.pathogenicity="{ item }">
                    {{ mappedPathogenicities[item.pathogenicity] }}
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {Variant, VariantPathogenicity} from "@/models";
import {DataTableHeader} from "vuetify";
import route from "ziggy-js";
import get from "lodash/get";

@Component
export default class VariantPathogenicitiesSection extends Vue {
    @Prop() readonly variant!: Variant

    isPathogenicitiesShown: boolean = false
    isLoading: boolean = false

    pathogenicities: Array<VariantPathogenicity> = []

    mappedPathogenicities: { [key: string]: string } = {
        pathogenic: 'Pathogénique',
        likely_pathogenic: 'Probablement pathogénique',
        uncertain: 'Incertain',
        probably_benign: 'Probablement bénin',
        benign: 'Bénin',
        NA: 'NA'
    }

    headers: Array<DataTableHeader> = [
        {
            text: '#',
            value: 'id',
            width: '10%'
        },
        {
            text: 'Tumeur',
            value: 'tumor_type.name',
            width: '30%'
        },
        {
            text: 'Pathogénicité',
            value: 'pathogenicity',
            width: '30%'
        },
        {
            text: 'Justification',
            value: 'evidence',
            width: '30%'
        },
    ]

    fetchPathogenicities() {
        this.isLoading = true

        this.$axios.get(route('variants.pathogenicities', {variant: this.variant.id}))
            .then(({data}) => {
                this.pathogenicities = data
            })
            .finally(() => {
                this.isLoading = false
            })
    }

    pathogenicity(item: any): string {
        return get(this, 'item.pathogenicity.pathogenicity', 'NA')
    }

    created() {
        this.fetchPathogenicities()
    }
}
</script>

<style scoped>

</style>
