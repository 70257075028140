<template>
  <v-autocomplete
    v-model="user"
    :items="users"
    :loading="isLoading"
    :search-input.sync="search"
    :autofocus="autofocus"
    color="primary"
    item-value="id"
    item-text="name"
    :label="label"
    :placeholder="placeholder"
    :outlined="outlined"
    :multiple="multiple"
    clearable
    :return-object="returnObject"
    :error-messages="errorMessages"
    hide-no-data
    :hide-details="hideDetails"
  >
    <template v-slot:item="{ item }">
      {{ `${item.name}` }}
    </template>
    <template v-slot:selection="{ item }">
      {{ `${item.name}` }}
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import route from 'ziggy-js'
import {User} from "@/models";

@Component
export default class SelectUser extends Vue {
  @VModel() user!: User | number | null
  @Prop() readonly users!: Array<User>
  @Prop({ default: 'Utilisateur' }) readonly placeholder!: string
  @Prop({ default: "Sélectionnez l'utilisateur" }) readonly label!: string | null
  @Prop({ default: false }) readonly outlined!: boolean
  @Prop({ default: false }) readonly multiple!: boolean
  @Prop({ default: false }) readonly readonly!: boolean
  @Prop({ default: false }) readonly returnObject!: boolean
  @Prop({ default: false }) readonly autofocus!: boolean
  @Prop({ default: false }) readonly hideDetails!: boolean
  @Prop({ default: null }) readonly errorMessages!: string | null;

  @Watch('search')
  onSearchInput (val: string) {
      if (!this.isLoading) {
          if (val !== '' && val) {
              setTimeout(() => {
                  this.fetchUsers({ search: val })
              }, 500)
          } else {
              setTimeout(() => {
                  this.fetchUsers()
              }, 500)
          }
      }
  }

  search: string | null = null

  isLoading: boolean = false

  dialogOpen: boolean = false

  fetchUsers (params?: any) {
      this.isLoading = true
      this.$inertia.get(
          route('users.index'),
          { params: params },
          {
              only: ['users'],
              preserveState: true,
              onFinish: () => {
                  this.isLoading = false
              }
          }
      )
  }
}
</script>

<style lang="scss" scoped>
</style>
