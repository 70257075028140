<template>
    <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        disable-sort
        disable-pagination
        disable-filtering
        no-data-text="Aucune couverture"
        hide-default-footer
    >
        <template v-slot:item.position="{ item }">
            chr{{ item.chromosome.name }}:{{ item.start_position }} - {{ item.end_position }}
        </template>
    </v-data-table>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import NgsAnalysisStatusProgress from '@/Components/domain-specific/ngs/helpers/NgsAnalysisStatusProgress.vue'
import {NgsAmplicon} from "@/models";

@Component({
    components: {
        NgsAnalysisStatusProgress
    }
})
export default class NgsRoiQualityTable extends Vue {
    @Prop() readonly items!: Array<NgsAmplicon>

    headers: Array<DataTableHeader> = [
        {
            text: 'Couverture',
            value: 'pivot.average_coverage',
        },
        {
            text: 'Gène',
            value: 'gene.name',
        },
        {
            text: 'Position',
            value: 'position',
        }
    ]
}
</script>

<style lang="scss">

</style>
