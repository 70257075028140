<template>
    <div>
        <slot name="activator">
        </slot>
        <v-dialog
            v-model="dialogOpen"
            :max-width="maxWidth"
            :persistent="persistent"
            transition="dialog-bottom-transition"
            open-delay="1500"
        >

            <card
                :title="title"
                :cancel-label="closeLabel"
                @cancel="close()"
            >

                <slot></slot>

                <template v-slot:actions>
                    <v-card-actions class="text-right justify-end">
                        <v-btn @click="close()" color="primary" text>
                            <v-icon small class="mr-1">mdi-close</v-icon>
                            {{ closeLabel }}
                        </v-btn>
                    </v-card-actions>
                </template>
            </card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, VModel, Emit } from 'vue-property-decorator'
import Card from '@/Components/generic/Card.vue'

@Component({
    components: { Card }
})
export default class DialogDisplay extends Vue {
    @VModel() dialogOpen!: boolean
    @Prop({ default: false }) persistent!: boolean
    @Prop({ default: 'Informations' }) title!: string
    @Prop({ default: 'Fermer' }) closeLabel!: string
    @Prop({ default: 450 }) maxWidth!: number | string
    @Prop({ default: 'primary' }) buttonClass!: string

    @Emit('close')
    close () {
        this.dialogOpen = false
    }
}
</script>

<style scoped lang="scss">

</style>
