<template>
    <div class="pa-5">
        <div class="mb-5 font-weight-bold">Commentaire</div>
        <element-row label="Commentaires" type="text">
            {{ ngsAnalysis.comment }}
        </element-row>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import {NgsAnalysis, NgsPairAnalysis} from "@/models";

@Component({
    components: {
        ElementRow
    }
})
export default class NgsCommentReviewsSection extends Vue {
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'
}
</script>

<style lang="scss" scoped>

</style>
