<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        title="Mettre à jour les gènes d'intérêt"
        :max-width="600"
        :loading="form.processing"
        @cancel="cancel()"
        @validate="save()"
    >
        <div class="pa-5">
            <v-row>
                <v-col cols="12">
                    <v-autocomplete
                        v-model="form.genes"
                        label="Gènes d'intérêt"
                        :items="genes"
                        item-value="id"
                        item-text="gencode_name"
                        chips
                        deletable-chips
                        outlined
                        multiple
                        small-chips
                        hide-selected
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </div>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import {TYPE} from 'vue-toastification'
import route from 'ziggy-js'
import {Gene} from "@/models";

@Component({
    components: { DialogConfirmation }
})
export default class NgsEditGeneInterestDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly ngsAnalysis!: any
    @Prop() readonly genes!: Array<Gene>
    @Prop() readonly type!: 'simple' | 'pair'

    @Watch('isDialogOpen')
    onDialogOpening(val: boolean) {
        if (val) {
            this.form.genes = this.ngsAnalysis.genes.map((gene: Gene) => gene.id)
            this.$inertia.reload({
                preserveState: true,
                preserveScroll: true,
                only: ['genes']
            })
        }
    }

    loading: boolean = false
    form: InertiaForm<{ genes: number[] }> = this.$inertia.form({
        genes: []
    })

    cancel () {
        this.form.reset()
    }

    save () {
        if (this.type === 'simple') {
            this.form.put(
                route('ngs_analyses.update-genes', {ngs_analysis: this.ngsAnalysis.id}),
                {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.isDialogOpen = false
                        this.$toast("Liste de gènes d'intérêt mis à jour", {
                            type: TYPE.SUCCESS
                        })
                    }
                }
            )
        }
        if (this.type === 'pair') {
            this.form.put(
                route('ngs_pair_analyses.update-genes', {ngs_pair_analysis: this.ngsAnalysis.id}),
                {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.isDialogOpen = false
                        this.$toast("Liste de gènes d'intérêt mis à jour", {
                            type: TYPE.SUCCESS
                        })
                    }
                }
            )
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
