<template>
    <layout>
        <v-container>
            <v-row>
                <v-col cols="12" md="3">
                    <p class="text-h5 font-weight-bold">
                        Configuration SAML
                    </p>
                    <p>
                        Ces paramètres permettent l'intégration du protocol SAML pour l'authentification.
                    </p>
                </v-col>
                <v-col cols="12" md="9">
                    <v-row dense>
                        <v-col cols="12" md="6">
                            <label class="font-weight-bold">Entity ID (IdP Issuer URL)</label>
                            <v-text-field outlined v-model="form.idp_entity_id" dense
                                          :error-messages="form.errors.idp_entity_id"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="6">
                            <label class="font-weight-bold">Login URL (IdP Sign On URL)</label>
                            <v-text-field outlined v-model="form.idp_login_url" dense
                                          :error-messages="form.errors.idp_login_url"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="6">
                            <label class="font-weight-bold">Logout URL (IdP Logout URL)</label>
                            <v-text-field outlined v-model="form.idp_logout_url" dense
                                          :error-messages="form.errors.idp_logout_url"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="6">
                            <label class="font-weight-bold">x509 Certificate (base64)</label>
                            <v-textarea outlined v-model="form.idp_x509_cert" dense
                                          :error-messages="form.errors.idp_x509_cert"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-divider class="mb-3"></v-divider>
                    <v-row dense>
                        <v-btn color="primary" @click="submit" depressed class="mb-5 mr-3"
                               :loading="form.processing">
                            Mettre à jour la configuration
                        </v-btn>
                        <v-btn @click="form.reset()" outlined depressed class="mb-5">
                            Annuler
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>

        </v-container>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Layout from '@/Layouts/Layout.vue'
import { InertiaForm } from '@inertiajs/inertia-vue'
import route from 'ziggy-js'
import { TYPE } from 'vue-toastification'
import {SamlForm} from "@/forms";
import {Tenant} from "@/models";

@Component({
    components: {
        Layout
    },
    metaInfo: {
        title: 'Éditer les paramètres SAML'
    }
})
export default class SamlConfig extends Vue {
    @Prop() readonly tenant!: Tenant

    form: InertiaForm<SamlForm> = this.$inertia.form({
        idp_entity_id: this.tenant.idp_entity_id,
        idp_login_url: this.tenant.idp_login_url,
        idp_logout_url: this.tenant.idp_logout_url,
        idp_x509_cert: this.tenant.idp_x509_cert
    })

    submit () {
        this.form.put(route('saml.update'), {
            preserveScroll: true,
            onSuccess: () => {
                this.$toast('La configuration SAML a été mise à jour.', {
                    type: TYPE.SUCCESS
                })
            }
        })
    }
}
</script>

<style lang="scss" scoped>

</style>
