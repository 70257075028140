<template>
    <caryotype-card
        :cnv="cnvAnalysis"
        :segments="graphSegments"
        :hide-title="true"
    ></caryotype-card>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import CaryotypeCard from '@/Components/domain-specific/cnv/charts/CaryotypeCard.vue'
import {CnvAnalysis, Segment} from "@/models";

@Component({
    components: { CaryotypeCard }
})
export default class ShowCaryotype extends Vue {
    @Prop() readonly cnvAnalysis!: CnvAnalysis
    @Prop() readonly graphSegments!: Array<Segment>
}
</script>

<style lang="scss" scoped>

</style>
