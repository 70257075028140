<template>
    <dialog-display-action
        v-model="isDialogOpen"
        :persistent="true"
        :action="true"
        :title="title"
        button-class="primary"
        :fullscreen="true"
    >
        <template v-slot:actions>
            <v-select
                v-if="list"
                v-model="selectedNgsAnalysis"
                :items="list"
                :return-object="true"
                item-text="pivot.alias"
                item-value="id"
                class="mr-2"
                hide-details
                outlined
                dense
                ></v-select>
        </template>
        <v-container class="px-5 py-0 overflow-y-auto" fluid>
            <v-row>
                <v-col cols="12">
                    <ngs-global-igv-viewer :dialog-open="isDialogOpen" :ngs-analysis="selectedNgsAnalysis" :type="selectedType" :position="position"></ngs-global-igv-viewer>
                </v-col>
            </v-row>
        </v-container>
    </dialog-display-action>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogDisplayAction from '@/Components/generic/DialogDisplayAction.vue'
import NgsIgvViewer from '@/Components/domain-specific/ngs/helpers/NgsIgvViewer.vue'
import Btn from '@/Components/generic/Btn.vue'
import {NgsAnalysis, NgsPairAnalysis} from "@/models";
import NgsGlobalIgvViewer from "@/Components/domain-specific/ngs/helpers/NgsGlobalIgvViewer.vue";

@Component({
    components: {
        NgsGlobalIgvViewer,
        NgsIgvViewer,
        DialogDisplayAction,
        Btn
    }
})
export default class NgsGlobalViewerDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly ngsAnalysis!: NgsAnalysis|NgsPairAnalysis|null
    @Prop() readonly type!: 'simple' | 'pair' | null
    @Prop() position?: { chromosome: string; start_position: string; end_position: string; }
    @Prop() readonly list?: Array<NgsAnalysis|NgsPairAnalysis>
    @Watch('isDialogOpen', {immediate: true})
    onDialogOpenChanged() {
        if (this.isDialogOpen) {
            this.selectedNgsAnalysis = this.ngsAnalysis
        }
    }


    selectedNgsAnalysis: NgsAnalysis|NgsPairAnalysis|null = null
    get selectedType(): 'simple' | 'pair' | null {
        if (!this.selectedNgsAnalysis) return null

        return this.selectedNgsAnalysis.hasOwnProperty('ngs_library_id') ? 'simple' : 'pair'
    }

    get title(): string {
        return `IGV viewer: Analysis ${this.selectedType === 'simple' ? 'NGS' : 'NGS PAIR'} ${this.ngsAnalysis?.id}`
    }
}
</script>

<style lang="scss" scoped>

</style>
