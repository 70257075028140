<template>
    <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :search="search"
        :footer-props="footerProps"
        :server-items-length="serverItemsLength"
        :loading="loading"
        :items-per-page="Number(syncedItemsPerPage)"
        :page="Number(syncedPage)"
        disable-sort
        no-data-text="Aucun résultat ne correspond à votre recherche"
        @update:page="updatePage"
        @update:items-per-page="updateItemsPerPage"
    >
        <template v-slot:item.date="{ item }">
            {{ formatDate(item.date) }}
        </template>
        <template v-slot:item.has_passed_quality_control="{ item }">
            <v-icon
                :color="qualityControl[item.has_passed_quality_control].color" class="my-auto mr-1">
                {{ qualityControl[item.has_passed_quality_control].icon }}
            </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
            <div class="text-center">
                <v-btn @click="$visit('cnv_analyses.show', item.id)" icon>
                    <v-icon color="primary">mdi-arrow-right</v-icon>
                </v-btn>

            </div>
        </template>
        <template v-slot:item.cnv_analysis_status="{ item }">
            <cnv-status-progress :status="item.cnv_analysis_status"></cnv-status-progress>
        </template>
        <template v-slot:item.segments_count="{ item }">
            {{ item.has_passed_quality_control ? item.segments_count : '-' }}
        </template>
    </v-data-table>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, PropSync, Vue} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {DataTableHeader} from 'vuetify'
import CnvStatusProgress from '@/Components/domain-specific/cnv/helpers/CnvStatusProgress.vue'
import {CnvAnalysis} from "@/models";

@Component({
    components: {
        CnvStatusProgress,
        DialogConfirmation
    }
})
export default class CnvAnalysisTable extends Vue {
    @Prop() items!: Array<CnvAnalysis>
    @Prop({ default: false }) readonly loading!: boolean
    @Prop() readonly serverItemsLength!: number
    @Prop() readonly search!: string
    @PropSync('page') syncedPage!: number
    @PropSync('itemsPerPage') syncedItemsPerPage!: number

    footerProps: { [key: string]: Array<number> } = {
        'items-per-page-options': [10, 25, 50, 100]
    }

    headers: Array<DataTableHeader> = [
        {
            text: '#',
            value: 'id',
            width: '5%'
        },
        {
            text: 'Date',
            value: 'date',
            width: '10%'
        },
        {
            text: 'Opérateur',
            value: 'operator.name',
            width: '15%'
        },
        {
            text: 'DIAMIC - N° prélèvement - N° demande GENNO',
            value: 'sample.full_diamic',
            width: '15%'
        },
        {
            text: 'Nom',
            value: 'name',
            width: '20%'
        },
        {
            text: 'Nb segments',
            value: 'segments_count',
            width: '5%'
        },
        {
            text: 'Statut QC',
            value: 'has_passed_quality_control',
            width: '10%',
            align: 'center'
        },
        {
            text: 'Statut',
            value: 'cnv_analysis_status',
            width: '15%'
        },
        {
            text: 'Actions',
            value: 'actions',
            width: '5%'
        }
    ]

    qualityControl: { [key: string]: { color: string; icon: string; text: string; }} = {
        true: {
            color: 'success',
            icon: 'mdi-check-circle',
            text: 'PASS'
        },
        false: {
            color: 'error',
            icon: 'mdi-close-circle',
            text: 'FAILED'
        },
        null: {
            color: 'grey',
            icon: 'mdi-help-circle',
            text: 'NA'
        }
    }

    updatePage (e: Event): void {
        this.$emit('pagination', {
            page: e,
            itemsPerPage: this.syncedItemsPerPage
        })
    }

    updateItemsPerPage (e: Event): void {
        this.$emit('pagination', {
            page: 1,
            itemsPerPage: e
        })
    }
}
</script>
