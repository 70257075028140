<template>
    <div>
        <panel title="Pipeline status" :expandable="true">
            <template>
                <v-row no-gutters>
                    <v-col cols="12" md="3">
                        <steps
                            category="PARSING"
                            :analysis-id="cnvAnalysis.id"
                            :items="filter(processingItems)"
                            type="cnv_analysis"
                            @reload="$emit('reload')"
                        ></steps>
                    </v-col>
                    <v-col cols="12" md="3">
                        <steps
                            category="ANNOTATION"
                            :analysis-id="cnvAnalysis.id"
                            :items="filter(annotatingItems)"
                            type="cnv_analysis"
                            @reload="$emit('reload')"
                        ></steps>
                    </v-col>
                    <v-col cols="12" md="3">
                        <steps
                            category="COMPUTATION"
                            :analysis-id="cnvAnalysis.id"
                            :items="filter(computingItems)"
                            type="cnv_analysis"
                            @reload="$emit('reload')"
                        ></steps>
                    </v-col>
                    <v-col cols="12" md="3">
                        <steps
                            category="EXTRA STEPS"
                            :analysis-id="cnvAnalysis.id"
                            :items="filter(extraStepsItems)"
                            type="cnv_analysis"
                            @reload="$emit('reload')"
                        ></steps>
                    </v-col>
                </v-row>
            </template>
        </panel>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Panel from '@/Components/generic/Panel.vue'
import Steps from '@/Components/generic/Steps.vue'
import {CnvAnalysis, CnvAnalysisStep} from "@/models";
import {AnalysisStep} from '@/laravel'

@Component({
    components: {
        Steps,
        Panel
    }
})
export default class CnvPipelineCard extends Vue {
    @Prop() readonly cnvAnalysis!: CnvAnalysis
    @Prop() readonly steps!: CnvAnalysisStep

    get processingItems (): Array<AnalysisStep> {
        return [
            {
                value: this.steps.qc_file_processed,
                title: 'QC File',
                label: 'Parsing of the QC file',
                route: 'cnv_analyses.process_qc_file',
                show: true
            },
            {
                value: this.steps.results_file_processed,
                title: 'Results File',
                label: 'Parsing of the results file',
                route: 'cnv_analyses.process_results_file',
                show: this.cnvAnalysis.has_passed_quality_control
            }
        ]
    }

    get annotatingItems (): Array<AnalysisStep> {
        return [
            {
                value: this.steps.annotate_loh_fragments,
                title: 'LOH Fragments Annotation',
                label: 'Annotation of the LOH fragments',
                route: 'cnv_analyses.annotate_loh_fragments',
                show: this.cnvAnalysis.has_passed_quality_control
            }
        ]
    }

    get computingItems (): Array<AnalysisStep> {
        return [
            {
                value: this.steps.oncoscan_r_completed,
                title: 'Arms computation',
                label: 'R script execution',
                route: 'cnv_analyses.compute_arms',
                show: this.cnvAnalysis.has_passed_quality_control
            },
            {
                value: this.steps.arms_associated,
                title: 'Arms association',
                label: 'Parsing of the script output',
                route: 'cnv_analyses.associate_arms',
                show: this.cnvAnalysis.has_passed_quality_control
            }
        ]
    }

    get extraStepsItems (): Array<AnalysisStep> {
        return [
            {
                value: this.steps.karyotype_rendered,
                title: 'Karyotype rendered',
                label: 'Saving the karyotype as an image',
                route: 'cnv_analyses.render_karyotype',
                show: this.cnvAnalysis.has_passed_quality_control
            }
        ]
    }

    filter (items: Array<AnalysisStep>): Array<AnalysisStep> {
        return items.filter((item) => item.show)
    }
}
</script>

<style lang="scss" scoped>

</style>
