<template>
    <v-row>
        <v-col cols="12">
            <p class="text-h6 font-weight-bold">Occurrences
                <v-chip small>{{ occurrences.length }}</v-chip>
                <v-btn icon class="ml-1" color="primary"
                       @click="isOccurrencesShown=!isOccurrencesShown">
                    <v-icon>{{
                            isOccurrencesShown ? 'mdi-chevron-right' : 'mdi-chevron-down'
                        }}
                    </v-icon>
                </v-btn>
            </p>
        </v-col>
        <v-col cols="12" v-if="isOccurrencesShown">
            <v-data-table
                :headers="headers"
                :items="occurrences"
                group-by="sample.tumor_type.name"
                disable-pagination
                disable-sort
                hide-default-footer
                ref="table"
            >
                <template v-slot:group.header="{ group, headers, isOpen, toggle, items }">
                    <td :colspan="headers.length" class="py-0 grey lighten-4">
                        <v-btn icon @click="toggle">
                            <v-icon>mdi-{{ isOpen ? 'chevron-right' : 'chevron-down' }}</v-icon>
                        </v-btn>
                        <strong>{{ group }}</strong>
                    </td>
                </template>
                <template v-slot:item.allelic_frequency="{ item }">
                    {{ Math.round($get(item, 'allelic_frequency', 0) * 100) }}%
                </template>
                <template v-slot:item.sample.full_diamic="{ item }">
                    {{ $get(item, 'analysis.sample.full_diamic') }}
                </template>
                <template v-slot:item.mapped_quality="{ item }">
                    <v-tooltip top color="rgba(0,0,0,0.9)">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                :color="qualities[$get(item, 'mapped_quality', 'excellent')].color"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-circle
                            </v-icon>
                        </template>
                        <span class="font-weight-bold">{{
                                qualities[$get(item, 'pivot.mapped_quality', 'excellent')].label
                            }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.report="{ item }">
                    <v-icon v-if="item.report_status !== 'not_included'" color="primary">
                        {{ item.report_status === 'included' ? 'mdi-check' : 'mdi-star' }}
                    </v-icon>
                    <v-icon v-else color="primary">mdi-close</v-icon>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn
                        v-if="item.analysis_type === 'ngs_pair'"
                        @click="$visit('ngs_pair_analyses.show', {ngs_pair_analysis: item.analysis.id})"
                        icon
                    >
                        <v-icon color="primary">mdi-arrow-right</v-icon>
                    </v-btn>
                    <v-btn
                        v-else
                        @click="$visit('ngs_analyses.show', {ngs_analysis: item.analysis.id})"
                        icon
                    >
                        <v-icon color="primary">mdi-arrow-right</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Ref, Vue} from 'vue-property-decorator'
import {Variant} from "@/models";
import {DataTableHeader} from "vuetify";
import route from "ziggy-js";

@Component
export default class VariantOccurrencesSection extends Vue {
    @Prop() readonly variant!: Variant
    @Ref('table') readonly table!: any

    isLoading: boolean = false
    isOccurrencesShown: boolean = false

    occurrences: Array<any> = []

    qualities: { [key: string]: { label: string; color: string; } } = {
        insufficient: {
            label: 'Insufficient',
            color: '#e53e3e'
        },
        weak: {
            label: 'Weak',
            color: '#ed8936'
        },
        good: {
            label: 'Good',
            color: '#ecc94b'
        },
        excellent: {
            label: 'Excellent',
            color: '#48bb78'
        },
    }

    headers: Array<DataTableHeader> = [
        {
            text: '#',
            value: 'analysis.id',
            width: '10%'
        },
        {
            text: 'Qualité',
            value: 'mapped_quality',
            width: '10%'
        },
        {
            text: 'DIAMIC',
            value: 'sample.full_diamic',
            width: '40%'
        },
        {
            text: 'Fréquence',
            value: 'allelic_frequency',
            width: '20%'
        },
        {
            text: 'Rapport',
            value: 'report',
            width: '15%'
        },
        {
            text: 'Actions',
            value: 'actions',
            width: '5%',
            align: 'end'
        },
    ]

    fetchOccurrences() {
        this.isLoading = true

        this.$axios.get(route('variants.occurrences', {variant: this.variant.id}))
            .then(({data}) => {
                this.occurrences = data
            })
            .finally(() => {
                this.isLoading = false
            })
    }

    created() {
        this.fetchOccurrences()
    }
}
</script>

<style scoped>

</style>
