<template>
    <dialog-confirmation
        v-model="dialog"
        title="Mettre à jour l'échantillon"
        width="600"
        :persistent="true"
        @validate="submit"
        @cancel="resetForm"
        :loading="sampleForm.processing"
    >
        <v-form ref="newSampleForm" class="ma-3">
            <v-alert
                text
                dense
                color="warning"
                border="left"
            >
                Les modifications que vous êtes sur le point de faire seront appliquées sur l'ensemble des analyses utilisant cet échantillon.
            </v-alert>
            <v-select
                v-model="sampleForm.tumor_type_id"
                :items="tumorTypes"
                item-value="id"
                item-text="name"
                label="Diagnostic"
                outlined
                color="primary"
                :error-messages="sampleForm.errors.tumor_type_id"
                @change="applyNonTumoralIfNeeded"
            ></v-select>
            <v-text-field
                v-model.number="sampleForm.tumor_cellularity_percentage"
                label="Pourcentage de la tumeur"
                type="number" prefix="%" outlined color="primary"
                :error-messages="sampleForm.errors.tumor_cellularity_percentage"
                :disabled="sampleForm.tumor_type_id === 15"
            ></v-text-field>
        </v-form>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import route from 'ziggy-js'
import {TYPE} from 'vue-toastification'
import {Sample, TumorType} from "@/models";
import {TumorForm} from "@/forms";

@Component({
    components: {
        DialogConfirmation
    }
})
export default class UpdateSampleDialog extends Vue {
    @VModel() dialog!: boolean
    @Prop() readonly sample!: Sample
    @Prop() readonly tumorTypes!: Array<TumorType>
    @Prop() readonly type!: 'simple' | 'pair'
    @Watch('dialog')
    onDialogOpening (val:boolean) {
        if (val) {
            this.sampleForm.tumor_type_id = this.sample.tumor_type_id
            this.sampleForm.tumor_cellularity_percentage = this.sample.tumor_cellularity_percentage
        }
    }

    samples: any[] = []

    sampleForm: InertiaForm<TumorForm> = this.$inertia.form({
        tumor_type_id: this.sample.tumor_type_id,
        tumor_cellularity_percentage: this.sample.tumor_cellularity_percentage
    })

    resetForm () {
        this.sampleForm.reset()
        this.sampleForm.clearErrors()
    }

    submit () {
        this.$nextTick(() => {
            this.sampleForm.put(
                route('samples.update-tumor', { sample: this.sample.id }),
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: () => {
                        this.$toast('Échantillon mis à jour', {
                            type: TYPE.SUCCESS
                        })
                        this.dialog = false
                        this.$emit('updated')
                        this.resetForm()
                    }
                }
            )
        })
    }

    applyNonTumoralIfNeeded() {
        if (this.sampleForm.tumor_type_id === 15) {
            this.sampleForm.tumor_cellularity_percentage = 0
        }
    }
}
</script>

<style scoped lang="scss">
</style>
