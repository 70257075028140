<template>
    <layout>
        <v-row v-if="ngsAnalysis.ngs_analysis_status === 'ignored'" class="px-3 mb-n9">
            <v-col cols="12">
                <v-alert type="error" class="mb-0">
                    <b>ANALYSE À IGNORER:</b> {{ ngsAnalysis.ignored_comment }}
                </v-alert>
            </v-col>
        </v-row>
        <v-row v-if="steps !== null">
            <v-col cols="12">
                <ngs-analysis-pipeline-card
                    :ngs-analysis="ngsAnalysis"
                    :steps.sync="steps"
                    @reload="reloadSteps"
                ></ngs-analysis-pipeline-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <ngs-analysis-summary-card
                    :ngs-analysis="ngsAnalysis"
                    :genes="genes"
                    :tumor-types="tumorTypes"
                    :related-cnv-analyses="relatedCnvAnalyses"
                    :related-ngs-analyses="relatedNgsAnalyses"
                    :related-ngs-pair-analyses="relatedNgsPairAnalyses"
                    @created="reloadAnalysis"
                    :users="users"
                    :missing-variants="missingVariants"
                    :can-delete-ngs-analysis="canDeleteNgsAnalysis"
                    :readonly="ngsAnalysis.ignored_at !== null || ngsAnalysis.is_archived === true"
                ></ngs-analysis-summary-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <ngs-analysis-variant-card
                    v-model="form"
                    :loading="loading"
                    :ngs-analysis="ngsAnalysis"
                    :variants="variants"
                    :silent-variants-count="silentVariantsCount"
                    :masked-variants-count="maskedVariantsCount"
                    :annotated-segments="annotatedSegments"
                    :readonly="ngsAnalysis.ignored_at !== null || ngsAnalysis.is_archived === true"
                    @select="$emit('select', $event)"
                    type="simple"
                    @update="$emit('update')"
                ></ngs-analysis-variant-card>
            </v-col>
        </v-row>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import Layout from "@/Layouts/Layout.vue";
import NgsAnalysisSummaryCard from "@/Components/domain-specific/ngs/cards/NgsAnalysisSummaryCard.vue";
import NgsAnalysisVariantCard from "@/Components/domain-specific/ngs/cards/NgsAnalysisVariantCard.vue";
import route from "ziggy-js";
import pickBy from "lodash/pickBy";
import NgsAnalysisPipelineCard from "@/Components/domain-specific/ngs/cards/NgsAnalysisPipelineCard.vue";
import {Gene, NgsAnalysis, NgsAnalysisStep, Segment, TumorType, User, Variant} from "@/models";

@Component({
    components: {
        NgsAnalysisPipelineCard,
        NgsAnalysisSummaryCard,
        NgsAnalysisVariantCard,
        Layout
    },
    metaInfo: {
        title: 'Analyse NGS'
    }
})
export default class ShowNgsAnalysis extends Vue {
    @Prop() readonly ngsAnalysis!: NgsAnalysis
    @Prop() readonly genes!: Array<Gene>
    @Prop() readonly variants!: Array<Variant>
    @Prop() readonly silentVariantsCount!: number
    @Prop() readonly maskedVariantsCount!: number
    @Prop() readonly steps!: NgsAnalysisStep | null
    @Prop() readonly users!: Array<User>
    @Prop() readonly tumorTypes!: Array<TumorType>
    @Prop() readonly missingVariants!: Array<Variant>
    @Prop() readonly annotatedSegments!: Array<Segment>
    @Prop() readonly canDeleteNgsAnalysis!: boolean
    @Prop() readonly relatedCnvAnalyses!: Array<{ id: number, created_at: string }>
    @Prop() readonly relatedNgsAnalyses!: Array<{ id: number, created_at: string, panel: { name: string } }>
    @Prop() readonly relatedNgsPairAnalyses!: Array<{ id: number, created_at: string, tumoral_panel: { name: string } }>
    @Prop() readonly filters!: {
        'show-silent-variants': boolean | null,
        'show-masked-variants': boolean | null,
        allelic_balance: number | null,
        chromosome: string | null,
        position: number | null
    }

    @Watch('form', {
        deep: true
    })
    onFormUpdated (): void {
        this.reloadData()
    }

    form: {
        'show-silent-variants': boolean | null,
        'show-masked-variants': boolean | null,
        allelic_balance: number | null,
        chromosome: string | null,
        position: number | null
    } = {
        'show-silent-variants': this.filters["show-silent-variants"],
        'show-masked-variants': this.filters["show-masked-variants"],
        allelic_balance: this.filters.allelic_balance,
        chromosome: this.filters.chromosome,
        position: this.filters.position
    }

    loading: boolean = false

    reloadData () {
        this.$inertia.get(
            route('ngs_analyses.show', { ngs_analysis: this.ngsAnalysis.id }),
            pickBy(this.form),
            {
                preserveState: true,
                preserveScroll: true,
                onStart: () => {
                    this.loading = true
                },
                onFinish: () => {
                    this.loading = false
                },
                only: ['variants']
            }
        )
    }

    reloadAnalysis () {
        this.$inertia.reload({ only: ['ngsAnalysis'] })
    }

    reloadSteps () {
        this.$inertia.reload({
            only: ['steps']
        })
    }
}
</script>

<style scoped>

</style>
