<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            :group-by="['gene.gencode_name']"
            :sort-by="['chromosome_id', 'position']"
            :loading="internalLoading || loading"
            @update:page="fakeLoading"
            disable-sort
            disable-filtering
            dense
        >
            <template v-slot:group.header="{ group, headers, isOpen, toggle, items }">
                <td :colspan="headers.length" class="py-0 grey lighten-4">
                    <v-btn icon @click="toggle">
                        <v-icon>mdi-{{ isOpen ? 'chevron-right' : 'chevron-down' }}</v-icon>
                    </v-btn>
                    <strong>{{ group }}</strong>
                    <span class="ml-1 grey--text">{{ `(${items.length} variants)` }}</span>
                </td>
            </template>
            <template v-slot:item="{ item, headers }">
                <tr v-if="!internalLoading" v-for="(ngsAnalysis, index) in analyses(item)">
                    <td
                        v-for="(header, i) in headers.filter((n) => singleHeaders.includes(n.value))"
                        :key="`item-${index}-header-${header.value}`"
                        v-if="singleHeaders.includes(header.value) && index === 0"
                        :class="header.align ? `text-${header.align}` : 'text-start'"
                        :rowspan="analyses(item).length"
                        :style="header.value === 'databases' ? 'border-right: thin solid rgba(0,0,0,.12);' : ''"
                    >
                        <div v-if="header.value === 'position'">
                            <span v-if="$get(item, 'vep_annotation.hgvsc', 'NA').includes('del') && !$get(item, 'vep_annotation.hgvsc', 'NA').includes('delins')">chr{{ item.chromosome.name }}:{{ (Number(item.position)+1).toLocaleString('de-CH') }}</span>
                            <span v-else>chr{{ item.chromosome.name }}:{{ Number(item.position).toLocaleString('de-CH') }}</span>
                            <v-icon small v-if="item.is_masked" color="primary" class="ml-1">mdi-eye-off-outline
                            </v-icon>
                            <v-icon small v-if="$get(item, 'vep_annotation.is_silent', false)" color="primary"
                                    class="ml-1">
                                mdi-circle-off-outline
                            </v-icon>
                        </div>
                        <div v-if="header.value === 'hgvs_c'" :class="$get(item, 'vep_annotation.hgvsc', 'NA') === 'NA' ? 'text--disabled' : ''">
                            {{ $get(item, 'vep_annotation.hgvsc', 'NA').after(':') }}
                        </div>
                        <div v-if="header.value === 'hgvs_p'" :class="$get(item, 'vep_annotation.hgvsp', 'NA') === 'NA' ? 'text--disabled' : ''">
                            {{ $get(item, 'vep_annotation.hgvsp', 'NA').after(':') }}
                        </div>
                        <div v-if="header.value === 'effect'" :class="$get(item, 'vep_annotation.variant_effect', 'NA') === 'NA' ? 'text--disabled' : ''">
                            {{ $get(item, 'vep_annotation.variant_effect') }}
                        </div>
                        <div v-if="header.value === 'transcript'">
                            {{ $get(item, 'transcript.transcript_id') }}
                        </div>
                        <div v-if="header.value === 'databases'">
                            <variant-databases-btn :item="item" :tumor-type-id="null"
                                                   :readonly="true"></variant-databases-btn>
                        </div>
                    </td>
                    <td
                        v-for="(header, i) in headers.filter((n) => !singleHeaders.includes(n.value))"
                        :key="`item-${index}-header-${i}`"
                        :class="header.align ? `text-${header.align}` : 'text-start'"
                        rowspan="1"
                    >
                        <div v-if="header.value === 'quality'">
                            <v-tooltip top color="rgba(0,0,0,0.9)">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        :color="qualities[$get(ngsAnalysis, 'pivot.mapped_quality', 'excellent')].color"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-circle-medium
                                    </v-icon>
                                </template>
                                <span class="font-weight-bold">{{
                                        qualities[$get(ngsAnalysis, 'pivot.mapped_quality', 'excellent')].label
                                    }}</span>
                            </v-tooltip>
                            <b>{{ aliases[ngsAnalysis.id] }}</b>
                        </div>
                        <div v-if="header.value === 'pathogenicity'">
                            <add-variant-pathogenicity-btn
                                class="my-6"
                                :item="item"
                                :ngs-analysis="ngsAnalysis"
                                :readonly="true"
                                :value="last(filterPathogenicities(item.pathogenicities, ngsAnalysis.sample.tumor_type_id))"
                            ></add-variant-pathogenicity-btn>
                        </div>
                        <div v-if="header.value === 'average_position'">
                            {{ averagePosition(ngsAnalysis) }}%
                        </div>
                        <div v-if="header.value === 'frequency'">
                            <frequency-pie-chart
                                :data="frequencies(ngsAnalysis)"
                                :variant-depth="$get(ngsAnalysis, 'pivot.variant_depth', 'NA')"
                                :total-depth="$get(ngsAnalysis, 'pivot.total_depth', 'NA')"
                            ></frequency-pie-chart>
                        </div>
                        <div v-if="header.value === 'phred'">
                            <div v-if="ngsAnalysis.pivot.variant_type !== 'Complex'">
                                <phred-bar-chart :data="phred(ngsAnalysis)"></phred-bar-chart>
                            </div>
                            <div v-else class="text--disabled py-2">
                                NA
                            </div>
                        </div>
                        <div v-if="header.value === 'strand_balance'">
                            <div v-if="ngsAnalysis.pivot.variant_type !== 'Complex'">
                                <strand-balance-pie-chart
                                    :data="strandBalanceFrequencies(ngsAnalysis)"></strand-balance-pie-chart>
                            </div>
                            <div v-else class="text--disabled py-2">
                                NA
                            </div>
                        </div>
                        <div v-if="header.value === 'annotation'">
                            <v-btn icon>
                                <v-icon
                                    v-if="$get(last(filterAnnotations(item.annotations, ngsAnalysis.pivot.id)), 'annotation') === 'valid'"
                                    :color="filterAnnotations(item.annotations, ngsAnalysis.pivot.id).length >= 2 ? 'success' : 'primary'">
                                    mdi-thumb-up
                                </v-icon>
                                <v-icon v-else color="primary">mdi-thumb-up-outline</v-icon>
                            </v-btn>
                            <v-btn icon>
                                <v-icon
                                    v-if="matchStatus($get(last(filterAnnotations(item.annotations, ngsAnalysis.pivot.id)), 'annotation'))"
                                    :color="filterAnnotations(item.annotations, ngsAnalysis.pivot.id).length >= 2 ? 'error' : 'primary'">
                                    mdi-thumb-down
                                </v-icon>
                                <v-icon v-else color="primary">mdi-thumb-down-outline</v-icon>
                            </v-btn>
                        </div>
                        <div v-if="header.value === 'report'">
                            <v-btn icon v-if="ngsAnalysis.pivot.report_status !== 'not_included'">
                                <v-icon color="primary">
                                    {{ ngsAnalysis.pivot.report_status === 'included' ? 'mdi-check' : 'mdi-star' }}
                                </v-icon>
                            </v-btn>
                            <v-btn icon v-else disabled>
                                <v-icon color="primary">mdi-close</v-icon>
                            </v-btn>
                        </div>
                        <div v-if="header.value === 'actions'">
                            <variant-actions-btn :item="item" :ngs-analysis="ngsAnalysis" :type="ngsAnalysis.pivot.analysis_type === 'ngs_simple' ? 'simple' : 'pair'"
                                                 :readonly="true" @open="$emit('open', $event)" :comparison="true"></variant-actions-btn>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue} from 'vue-property-decorator'
import {DataTableHeader} from "vuetify";
import FrequencyPieChart from "@/Components/domain-specific/ngs/charts/FrequencyPieChart.vue";
import PhredBarChart from '@/Components/domain-specific/ngs/charts/PhredBarChart.vue';
import StrandBalancePieChart from '@/Components/domain-specific/ngs/charts/StrandBalancePieChart.vue';
import AmpliconBarChart from "@/Components/domain-specific/ngs/charts/AmpliconBarChart.vue";
import get from "lodash/get";
import AnnotateVariantBtn from "@/Components/domain-specific/ngs/dialogs/AnnotateVariantBtn.vue";
import AddVariantPathogenicityBtn from "@/Components/domain-specific/ngs/dialogs/AddVariantPathogenicityBtn.vue";
import VariantActionsBtn from "@/Components/domain-specific/ngs/dialogs/VariantActionsBtn.vue";
import VariantDatabasesBtn from "@/Components/domain-specific/ngs/dialogs/VariantDatabasesBtn.vue";
import {last} from 'lodash';
import {Chromosome, NgsAnalysisVariantAnnotation, Variant, VariantPathogenicity} from "@/models";

@Component({
    components: {
        VariantDatabasesBtn,
        VariantActionsBtn,
        AddVariantPathogenicityBtn,
        AnnotateVariantBtn,
        AmpliconBarChart,
        FrequencyPieChart,
        StrandBalancePieChart,
        PhredBarChart
    }
})
export default class ComparisonVariantsTable extends Vue {
    @VModel() filters!: {
        'show-silent-variants': boolean | null,
        'show-masked-variants': boolean | null,
        allelic_balance: number | null,
        chromosome: string | null,
        position: number | null
    }
    @Prop() readonly items!: Array<Variant>
    @Prop() readonly aliases!: { [key: number]: string }
    @Prop() readonly loading!: boolean

    internalLoading: boolean = false

    fakeLoading() {
        this.internalLoading = true
        setTimeout(() => {
            this.internalLoading = false
        }, 500)
    }

    averagePosition(item: Variant): number {
        return Math.round(get(item, 'pivot.bam_readcount_alternate_0.avg_pos_as_fraction', 0) * 100)
    }

    frequencies(item: Variant): number[] {
        return [Math.round(get(item, 'pivot.allelic_frequency', 0) * 100), 100 - Math.round(get(item, 'pivot.allelic_frequency', 0) * 100)]
    }

    phred(item: Variant): number[][] {
        return [
            [
                (Number(get(item, 'pivot.bam_readcount_alternate_0.count', 0)) - Number(get(item, 'pivot.bam_readcount_alternate_20.count', 0))) / Number(get(item, 'pivot.bam_readcount_alternate_0.count', 100)) * 100,
                (Number(get(item, 'pivot.bam_readcount_reference_0.count', 0)) - Number(get(item, 'pivot.bam_readcount_reference_20.count', 0))) / Number(get(item, 'pivot.bam_readcount_reference_0.count', 100)) * 100
            ],
            [
                100 - ((Number(get(item, 'pivot.bam_readcount_alternate_0.count', 0)) - Number(get(item, 'pivot.bam_readcount_alternate_20.count', 0))) / Number(get(item, 'pivot.bam_readcount_alternate_0.count', 100)) * 100),
                100 - ((Number(get(item, 'pivot.bam_readcount_reference_0.count', 0)) - Number(get(item, 'pivot.bam_readcount_reference_20.count', 0))) / Number(get(item, 'pivot.bam_readcount_reference_0.count', 100)) * 100)
            ]
        ]
    }

    strandBalanceFrequencies(item: Variant): number[][] {
        return [
            [
                (Number(get(item, 'pivot.bam_readcount_alternate_0.num_plus_strand', 0)) / Number(get(item, 'pivot.bam_readcount_alternate_0.count', 100)) * 100),
                (100 - (Number(get(item, 'pivot.bam_readcount_alternate_0.num_plus_strand', 0)) / Number(get(item, 'pivot.bam_readcount_alternate_0.count', 100)) * 100))
            ],
            [
                (Number(get(item, 'pivot.bam_readcount_reference_0.num_plus_strand', 0)) / Number(get(item, 'pivot.bam_readcount_reference_0.count', 100)) * 100),
                (100 - (Number(get(item, 'pivot.bam_readcount_reference_0.num_plus_strand', 0)) / Number(get(item, 'pivot.bam_readcount_reference_0.count', 100)) * 100))
            ]
        ]
    }

    qualities: { [key: string]: { label: string; color: string; } } = {
        insufficient: {
            label: 'Insufficient',
            color: '#e53e3e'
        },
        weak: {
            label: 'Weak',
            color: '#ed8936'
        },
        good: {
            label: 'Good',
            color: '#ecc94b'
        },
        excellent: {
            label: 'Excellent',
            color: '#48bb78'
        },
    }

    headers: Array<DataTableHeader> = [
        {
            text: 'Position',
            value: 'position',
            width: '5%'
        },
        {
            text: 'HGVS.c',
            value: 'hgvs_c',
            width: '5%'
        },
        {
            text: 'HGVS.p',
            value: 'hgvs_p',
            width: '5%'
        },
        {
            text: 'Effet',
            value: 'effect',
            width: '10%'
        },
        {
            text: 'Transcrit',
            value: 'transcript',
            width: '10%'
        },
        {
            text: 'Bases de données',
            value: 'databases',
            width: '10%'
        },
        {
            text: 'Qualité',
            value: 'quality',
            width: '5%'
        },
        {
            text: 'Pathogénicité',
            value: 'pathogenicity',
            width: '10%'
        },
        {
            text: 'Position moyenne',
            value: 'average_position',
            width: '5%'
        },
        {
            text: 'Fréquence',
            value: 'frequency',
            width: '5%'
        },
        {
            text: 'Phred <20 (%)',
            value: 'phred',
            width: '5%'
        },
        {
            text: 'Strand balance',
            value: 'strand_balance',
            width: '5%'
        },
        {
            text: 'Annotations',
            value: 'annotation',
            width: '5%'
        },
        {
            text: 'Rapport',
            value: 'report',
            width: '5%'
        },
        {
            text: 'Actions',
            value: 'actions',
            width: '10%',
            align: 'end'
        }
    ]

    singleHeaders: string[] = [
        'position',
        'hgvs_c',
        'hgvs_p',
        'effect',
        'transcript',
        'databases',
    ]

    get chromosomes(): Array<Chromosome> {
        return (this.$page.props.chromosomes as Array<Chromosome>)
    }

    filterPathogenicities(pathogenicities: Array<VariantPathogenicity>, tumorTypeId: number) {
        return pathogenicities.filter((pathogenicity: any) => pathogenicity.tumor_type_id === tumorTypeId)
    }

    filterAnnotations(annotations: Array<NgsAnalysisVariantAnnotation>, ngsAnalysisVariantId: number) {
        return annotations.filter((annotation: any) => annotation.ngs_analysis_variant_id === ngsAnalysisVariantId)
    }

    last(list: Array<any>) {
        return last(list)
    }

    matchStatus(value: string) {
        return value === 'technical_artifact' || value === 'constitutional_variant' || value === 'weak_quality'
    }

    analyses(item: Variant) {
        return [...item.ngs_analyses, ...item.ngs_pair_analyses]
    }


}
</script>

<style scoped lang="scss">
tbody {
    tr:hover {
        background-color: transparent !important;
    }
}
</style>
