<template>
    <div>
        <panel title="Liste de segments" :expandable="true">
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
            </template>
            <template>
                <v-row class="ma-3" align-content="center">
                    <v-col cols="12">
                        <v-row justify="space-around" align-content="space-around">
                            <v-col cols="6">
                                <v-text-field
                                    label="Rechercher un gène"
                                    v-model="filters.gene"
                                    outlined
                                    color="primary"
                                    hide-details
                                    dense
                                    clearable
                                    @input="selectTabAndReloadSegments"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-checkbox
                                    label="Afficher uniquement les segments avec gènes annotés"
                                    v-model="filters.only_annotated"
                                    outlined
                                    color="primary"
                                    hide-details
                                    dense

                                >
                                </v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-tabs :value="tabs[filters.filter]" class="font-size">
                    <v-tab @click="filters.filter = null">
                        Segments &lt; 10Mb
                        <v-chip class="ml-2" color="secondary" small>{{ smallSegmentsCount }}</v-chip>
                    </v-tab>
                    <v-tab @click="filters.filter = 'big'">
                        Segments &gt;= 10Mb
                        <v-chip class="ml-2" color="secondary" small>{{ bigSegmentsCount }}</v-chip>
                    </v-tab>
                    <v-tab @click="filters.filter = 'arm'">
                        Altérations de bras entiers
                        <v-chip class="ml-2" color="secondary" small>{{ armsCount }}</v-chip>
                    </v-tab>
                    <v-tab v-if="filters.gene !== null && filters.gene !== ''" @click="filters.filter = 'search'">
                        Recherche: {{ filters.gene }}
                        <v-btn icon class="ml-1" :color="filters.filter === 'search' ? 'primary' : null"
                               @click="closeSearchTab">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-tab>
                </v-tabs>

                <v-divider></v-divider>

                <div>
                    <v-row class="mx-5 mt-2" justify="space-around" v-if="filters.filter === 'arm'">
                        <v-col cols="12" class="my-3">
                            <element-row
                                v-for="(groupByArms, alterationId) in arms"
                                :key="alterationId"
                                :label="armAlterationIdentifier(alterationId)"
                                :dense="true"
                                left-col-size="1"
                            >
                                <span
                                    v-for="(arm,index) in groupByArms"
                                    :key="`${arm.name}-${index}`"
                                >
                                    {{ arm.name }}
                                    <v-btn
                                        :id="`${arm.name}-${index}`"
                                        icon
                                        color="info"
                                        @click="annotateArm(arm, alterationId)"
                                        :disabled="readonly"
                                    >
                                        <v-rating
                                            :value="annotationState(arm)"
                                            background-color="info lighten-1"
                                            color="info"
                                            length="1"
                                            half-increments
                                            readonly
                                        ></v-rating>
                                    </v-btn>
                                </span>
                            </element-row>
                        </v-col>
                    </v-row>
                    <v-divider v-if="filters.filter === 'arm'"></v-divider>
                    <segment-table
                        v-if="filters.filter !== 'arm' && filters.filter !== 'search' && filters.only_annotated !== true"
                        v-model="filters"
                        :loading="loading"
                        :items="segments.data"
                        :server-items-length="segments.total"
                        :page.sync="segments.current_page"
                        :items-per-page.sync="segments.per_page"
                        @pagination="retrievePagination"
                        :readonly="readonly"
                    ></segment-table>
                    <segment-table
                        v-if="filters.filter === 'search' || filters.only_annotated === true"
                        v-model="filters"
                        :loading="loading"
                        :items="filteredSegments.data"
                        :server-items-length="filteredSegments.total"
                        :page.sync="filteredSegments.current_page"
                        :items-per-page.sync="filteredSegments.per_page"
                        @pagination="retrievePagination"
                        :readonly="readonly"
                        :search="filters.filter === 'search'"
                    ></segment-table>
                </div>
            </template>
        </panel>
        <cnv-annotate-element-dialog
            v-model="annotationDialogOpen"
            type="arm"
            :cnv-analysis-id="cnvAnalysisId"
            :item="selectedArm"
            :alteration-id="parseInt(selectedAlterationId)"
            @close="resetSelectedItem()"
        ></cnv-annotate-element-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import Panel from '@/Components/generic/Panel.vue'
import SegmentTable from '@/Components/domain-specific/segment/SegmentTable.vue'
import ElementRow from '@/Components/generic/ElementRow.vue'
import CnvAnnotateElementDialog from '@/Components/domain-specific/cnv/dialogs/CnvAnnotateElementDialog.vue'
import {Pagination} from "@/laravel";
import {Arm, CnvAlteration, Segment} from "@/models";

@Component({
    components: {
        CnvAnnotateElementDialog,
        ElementRow,
        SegmentTable,
        Panel
    }
})
export default class SegmentsPanel extends Vue {
    @Prop() readonly segments!: Pagination<Segment>
    @Prop() readonly filteredSegments!: Pagination<Segment>
    @Prop() readonly smallSegmentsCount!: number
    @Prop() readonly bigSegmentsCount!: number
    @Prop() readonly armsCount!: number
    @Prop() readonly loading!: boolean
    @Prop({ default: false }) readonly readonly?: boolean
    @Prop() readonly arms!: { [key: number]: Array<Arm> }
    @Prop() readonly cnvAnalysisId!: number
    @VModel() filters!: {
        filter: string | null,
        chromosome_id: any,
        start_position: string | null,
        end_position: string | null,
        min_size: string | null,
        max_size: string | null,
        alteration_id: any,
        min_copy: string | null,
        max_copy: string | null,
        page: string | null,
        gene: string | null,
        gene_type: string | null,
        only_annotated: string | null,
        'items-per-page': string | null
    }

    @Watch('filtersOpen')
    onDialogOpening (val: boolean) {
        if (val) {
            this.$inertia.reload({
                only: ['geneTypes']
            })
        }
    }

    @Watch('filters.chromosome_id')
    onChromosomeIdChanged () {
        this.filters.page = '1'
    }

    @Watch('filters.start_position')
    onStartPositionChanged () {
        this.filters.page = '1'
    }

    @Watch('filters.end_position')
    onEndPositionChanged () {
        this.filters.page = '1'
    }

    @Watch('filters.min_size')
    onMinSizeChanged () {
        this.filters.page = '1'
    }

    @Watch('filters.max_size')
    onMaxSizeChanged () {
        this.filters.page = '1'
    }

    @Watch('filters.alteration_id')
    onAlterationIdChanged () {
        this.filters.page = '1'
    }

    @Watch('filters.min_copy')
    onMinCopyChanged () {
        this.filters.page = '1'
    }

    @Watch('filters.max_copy')
    onMaxCopyChanged () {
        this.filters.page = '1'
    }

    @Watch('filters.gene_type')
    onGeneTypeChanged () {
        this.filters.page = '1'
    }

    @Watch('filters.only_annotated')
    onOnlyAnnotatedChanged () {
        this.filters.page = '1'
    }

    @Watch('filters.filter')
    onTabChange (val: null | 'big' | 'arm') {
        this.filters.page = '1'
    }

    @Watch('filters.gene')
    onResetGeneFilter (gene: null | string) {
        if (gene === null || gene === '') {
            this.filters.filter = null
        }
    }

    filtersOpen: boolean = false
    annotationDialogOpen: boolean = false
    selectedArm: Arm | null = null
    selectedAlterationId: number | null = null
    tabs: any = {
        null: 0,
        big: 1,
        arm: 2,
        search: 3
    }

    armAlterationText: { [key: string]: string } = {
        LOSS: 'Perte',
        LOH: 'LOH',
        GAIN: 'Gain',
        AMP: 'Amplification'
    }

    get geneTypes (): Array<string> {
        return (this.$page.props.geneTypes as string[])
    }

    get alterations (): Array<CnvAlteration> {
        return (this.$page.props.alterations as Array<CnvAlteration>) ?? []
    }

    get armAlterations (): Array<CnvAlteration> {
        return (this.$page.props.arm_alterations as Array<CnvAlteration>) ?? []
    }

    armAlterationIdentifier (id: number | string): string | null {
        if (typeof id === 'string') {
            id = parseInt(id)
        }
        if (this.armAlterations) {
            const alteration = this.armAlterations.find(alteration => alteration.id === id)
            return alteration ? this.armAlterationText[alteration.name] : null
        }
        return null
    }

    retrievePagination ({
        page,
        itemsPerPage
    }: { page: number, itemsPerPage: number }): void {
        this.filters.page = page.toString()
        this.filters['items-per-page'] = itemsPerPage.toString()
    }

    annotateArm (item: Arm, alterationId: number) {
        if (!this.readonly) {
            this.selectedArm = item
            this.selectedAlterationId = alterationId
            this.annotationDialogOpen = true
        }
    }

    resetSelectedItem () {
        this.selectedArm = null
        this.selectedAlterationId = null
    }

    annotationState (arm: Arm) {
        if ((arm as any).is_partially_annotated) {
            return 0.5
        }
        if ((arm as any).is_annotated) {
            return 1
        }
        return 0
    }

    selectTabAndReloadSegments () {
        this.filters.filter = 'search'
        this.$emit('reload-filtered-segments')
    }

    closeSearchTab () {
        this.filters.gene = null
        this.filters.filter = null
    }

    created () {
        if (this.filters.filter === 'search' && this.filters.gene === null || this.filters.gene === '') {
            this.filters.filter = null
        }
    }
}
</script>

<style scoped lang="scss">
</style>
