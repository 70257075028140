<template>
    <guest-layout :fill="true">
        <v-row align="center"
               justify="center">
            <v-col class="text-center">
                <p class="icon-big">⚠️</p>
                <p class="text-h5 font-weight-bold">Votre compte est en attente d'activation</p>
                <p>Un administrateur a été notifié et votre compte devrait être activé sous peu.</p>
                <a class="font-weight-bold" @click="logout()">Se déconnecter</a>
            </v-col>
        </v-row>
    </guest-layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Vue } from 'vue-property-decorator'
import GuestLayout from '@/Layouts/GuestLayout.vue'
import route from 'ziggy-js'

@Component({
    components: { GuestLayout },
    metaInfo: {
        title: 'Compte inactif'
    }
})
export default class InactiveAccount extends Vue {
    logout () {
        this.$axios.post(route('logout')).then(() => {
            location.href = route('login')
        })
    }
}
</script>

<style lang="scss" scoped>

</style>
