<template>
  <v-row align-content="space-between">
    <v-col cols="12" md="4" lg="4" v-for="(link,index) in links" :key="index">
      <menu-btn :to="link.to" :disabled="link.disabled">{{ link.label }}</menu-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'
import MenuBtn from './MenuBtn.vue'
import route from 'ziggy-js'
@Component({
    components: { MenuBtn }
})
export default class SubMenu extends Vue {
  links: { label: string; to: string; disabled: boolean }[] = [
      {
          label: 'Créer un run NGS',
          to: route('ngs_runs.create'),
          disabled: false
      },
      {
          label: 'Créer une analyse CNV',
          to: route('cnv_analyses.create'),
          disabled: false
      },
      {
          label: "Gérer les groupes d'échantillons",
          to: route('samples_groups.index'),
          disabled: false
      }
  ]
}
</script>

<style scoped lang="scss">
</style>
