<template>
    <v-app>
        <guest-navbar></guest-navbar>

        <v-main class="app">
            <v-container :class="fill ? 'fill-height' : null">
                <slot/>
            </v-container>
        </v-main>

        <custom-footer></custom-footer>
    </v-app>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CustomFooter from '@/Components/generic/CustomFooter.vue'
import GuestNavbar from '@/Components/generic/GuestNavbar.vue'

@Component({
    components: {
        GuestNavbar,
        CustomFooter
    }
})
export default class GuestLayout extends Vue {
    @Prop({ default: false }) readonly fill?: boolean;
}
</script>

<style scoped lang="scss">
.app {
    background-color: #F5F5F5;
}
</style>
