<template>
    <div>
        <v-btn @click="readonly ? openAnnotationDialog() : annotateAsValid()" icon>
            <v-icon v-if="$get(item, 'annotation.annotation') === 'valid'" color="#3EA8A2">
                {{ $get(item, 'annotations_count') >= 2 ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
            </v-icon>
            <v-icon v-else color="#BDBDBD">mdi-thumb-up-outline</v-icon>
        </v-btn>
        <v-btn @click="openAnnotationDialog" icon>
            <v-icon v-if="$get(item, 'annotation.annotation', 'valid') !== 'valid'" color="error">
                {{ $get(item, 'annotations_count') >= 2 ? 'mdi-thumb-down' : 'mdi-thumb-down-outline' }}
            </v-icon>
            <v-icon v-else color="#BDBDBD">mdi-thumb-down-outline</v-icon>
        </v-btn>
        <ngs-edit-annotation-dialog
            v-if="isDialogOpen"
            v-model="isDialogOpen"
            :variant="item"
            :readonly="readonly"
            @update="$emit('update')"
        ></ngs-edit-annotation-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import route from "ziggy-js";
import {TYPE} from "vue-toastification";
import NgsEditAnnotationDialog from "@/Components/domain-specific/ngs/dialogs/NgsEditAnnotationDialog.vue";
import {Variant} from "@/models";

@Component({
    components: {NgsEditAnnotationDialog}
})
export default class AnnotateVariantBtn extends Vue {
    @Prop() readonly item!: Variant
    @Prop({default: false}) readonly readonly?: boolean
    isDialogOpen: boolean = false

    annotateAsValid() {
        this.$inertia.put(
            route('ngs_analysis_variants.annotations.store', {ngs_analysis_variant: (this.item as any).pivot.id}),
            {
                annotation: 'valid'
            },
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: () => {
                    this.$toast('Variant annoté comme valide', {
                        type: TYPE.SUCCESS
                    })
                    this.$emit('update')
                },
                onError: () => {
                    this.$toast('Vous avez déjà annoté ce variant', {
                        type: TYPE.ERROR
                    })
                }
            }
        )
    }

    openAnnotationDialog() {
        this.isDialogOpen = true
    }
}
</script>

<style scoped>
.disable-events {
    pointer-events: none
}
</style>
