<template>
    <div class="pa-5" v-if="cnvAnalysis.quality_control_content">
        <div class="mb-5 font-weight-bold">OncoscanR</div>
        <element-row label="Ploïdie">
            {{ Math.round($get(cnvAnalysis, 'quality_control_content.tu_scan_ploidy', 0)) }}
        </element-row>
        <element-row v-for="(value,key) in $get(cnvAnalysis, 'oncoscan_r_content.scores', {})" :label="key" :key="key">
            {{value}}
        </element-row>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import {CnvAnalysis} from "@/models";

@Component({
    components: {
        ElementRow
    }
})
export default class CnvOncoscanInfoSection extends Vue {
    @Prop() readonly cnvAnalysis!: CnvAnalysis
}
</script>

<style lang="scss" scoped>

</style>
