<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :loading="loading || isLoading"
            no-data-text="Aucun annotation"
            disable-sort
        >
            <template v-slot:item.status="{ item }">
                <v-rating
                    :value="annotationState(item)"
                    background-color="info lighten-1"
                    color="info"
                    length="1"
                    half-increments
                    readonly
                ></v-rating>
            </template>
            <template v-slot:item.source_type="{ item }">
                {{ annotationType[item.source_type] }}
            </template>
            <template v-slot:item.alteration.name="{ item }">
                {{ item.alteration.level === 'arm' ? armAlterationText[item.alteration.name] : item.alteration.name }}
            </template>
            <template v-slot:item.info="{ item }">
                {{ position(item) }}
            </template>
            <template v-slot:item.reporter.name="{ item }">
                {{ $get(item, 'reporter.name', null) }}
            </template>
            <template v-slot:item.validator.name="{ item }">
                {{ $get(item, 'validator.name', null) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn icon small class="mr-1" color="primary" @click="editAnnotation(item)" :disabled="disabled">
                    <v-icon small>
                        mdi-pencil
                    </v-icon>
                </v-btn>
                <v-btn v-if="item.validator === null" icon small color="success" @click="validateAnnotation(item)" :disabled="disabled">
                    <v-icon small>
                        mdi-check
                    </v-icon>
                </v-btn>
                <v-btn icon small color="error" @click="selectAnnotationAndDelete(item)" :disabled="disabled">
                    <v-icon small>
                        mdi-delete
                    </v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <cnv-annotation-delete-dialog
            v-model="isDeleteDialogOpen"
            @delete="deleteAnnotation(selectedAnnotation)"
        ></cnv-annotation-delete-dialog>
        <cnv-annotate-element-dialog
            v-if="selectedAnnotation"
            v-model="isAnnotationDialogOpen"
            :item="selectedAnnotation.source"
            :cnvAnalysisId="selectedAnnotation.cnv_analysis_id"
            :alterationId="selectedAnnotation.alteration_id"
            :type="selectedAnnotation.source_type"
            @updated="$emit('update')"
            @deleted="$emit('update')"
        ></cnv-annotate-element-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {DataTableHeader} from 'vuetify'
import CnvStatusProgress from '@/Components/domain-specific/cnv/helpers/CnvStatusProgress.vue'
import ElementRow from '@/Components/generic/ElementRow.vue'
import route from 'ziggy-js'
import {TYPE} from 'vue-toastification'
import {InertiaForm} from '@inertiajs/inertia-vue'
import CnvAnnotationDeleteDialog from '@/Components/domain-specific/cnv/dialogs/CnvAnnotationDeleteDialog.vue'
import CnvAnnotateElementDialog from '@/Components/domain-specific/cnv/dialogs/CnvAnnotateElementDialog.vue'
import {Arm, CnvAnnotation, Gene, Segment, User} from "@/models";
import {CnvAnnotationForm} from "@/forms";

@Component({
    components: {
        CnvAnnotateElementDialog,
        CnvAnnotationDeleteDialog,
        ElementRow,
        CnvStatusProgress,
        DialogConfirmation
    }
})
export default class CnvAnnotationsTable extends Vue {
    @Prop() readonly items!: Array<CnvAnnotation>
    @Prop({ default: false }) readonly loading!: boolean
    @Prop() readonly search!: string
    @Prop({ default: false }) readonly disabled!: boolean

    isLoading: boolean = false

    form: InertiaForm<CnvAnnotationForm> = this.$inertia.form({
        cnv_analysis_id: null,
        publication_id: null,
        reported_by: null,
        validated_by: null,
        evidence: null,
        alteration_id: null
    })

    selectedAnnotation: null | CnvAnnotation = null

    annotationType: { [key: string]: string } = {
        gene: 'Gène',
        segment: 'Segment',
        arm: 'Bras'
    }

    isDeleteDialogOpen: boolean = false
    isAnnotationDialogOpen: boolean = false

    armAlterationText: { [key: string]: string } = {
        LOSS: 'Perte',
        LOH: 'LOH',
        GAIN: 'Gain',
        AMP: 'Amplification'
    }

    headers: Array<DataTableHeader> = [
        {
            text: 'Statut',
            value: 'status',
            width: '5%',
            sortable: false
        },
        {
            text: 'Type',
            value: 'source_type',
            width: '10%'
        },
        {
            text: 'Info',
            value: 'source.name',
            width: '15%',
            sortable: false
        },
        {
            text: 'Altération',
            value: 'alteration.name',
            width: '10%'
        },
        {
            text: 'Annotateur',
            value: 'reporter.name',
            width: '15%'
        },
        {
            text: 'Validateur',
            value: 'validator.name',
            width: '15%'
        },
        {
            text: 'Commentaire',
            value: 'evidence',
            width: '15%',
            sortable: false
        },
        {
            text: 'Actions',
            value: 'actions',
            width: '15%',
            sortable: false
        }
    ]

    annotationState (annotation: CnvAnnotation) {
        if (annotation.validator) {
            return 1
        }
        return 0.5
    }

    position (annotation: CnvAnnotation): string | null {
        if (annotation.source_type === 'segment') {
            return (annotation.source as Segment).full_location
        }
        if (annotation.source_type === 'gene') {
            return (annotation.source as Gene).gencode_name + ' (' + (annotation.source as Gene).full_location + ')'
        }
        if (annotation.source_type === 'arm') {
            return (annotation.source as Arm).name
        }
        return null
    }

    validateAnnotation (annotation: CnvAnnotation) {
        this.form.cnv_analysis_id = annotation.cnv_analysis_id
        this.form.reported_by = annotation.reported_by
        this.form.validated_by = (this.$page.props.user as User).id
        this.form.evidence = annotation.evidence
        this.form.alteration_id = annotation.alteration_id

        if (this.form.reported_by === this.form.validated_by) {
            this.$toast('Vous ne pouvez pas valider vos propres annotations', {
                timeout: 2000,
                type: TYPE.ERROR
            })
        } else {
            this.form.put(
                route('annotations.update', { cnv_annotation: annotation.id }),
                {
                    preserveScroll: true,
                    preserveState: true,
                    onStart: () => {
                        this.isLoading = true
                    },
                    onFinish: () => {
                        this.isLoading = false
                    },
                    onSuccess: () => {
                        this.form.reset()
                        this.$toast('Annotation validée', {
                            type: TYPE.SUCCESS
                        })
                        this.$emit('update')
                    }
                }
            )
        }
    }

    selectAnnotationAndDelete (annotation: CnvAnnotation) {
        this.selectedAnnotation = annotation
        this.isDeleteDialogOpen = true
    }

    editAnnotation (annotation: CnvAnnotation) {
        this.selectedAnnotation = annotation
        this.isAnnotationDialogOpen = true
    }

    deleteAnnotation (annotation: CnvAnnotation) {
        this.$inertia.delete(route('annotations.delete', { cnv_annotation: annotation.id }), {
            preserveState: true,
            preserveScroll: true,
            onStart: () => {
                this.isLoading = true
            },
            onFinish: () => {
                this.isLoading = false
            },
            onSuccess: () => {
                this.isDeleteDialogOpen = false
                this.$emit('update')
                this.$toast('Annotation supprimée', {
                    type: TYPE.ERROR
                })
            }
        })
    }
}
</script>
