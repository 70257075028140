<template>
    <v-row dense>
        <v-col>
            <v-progress-linear
                v-if="status"
                :value="statusList[status].percentage"
                :height="dense ? '10' : '25'"
                rounded
                :color="`${statusList[status].color} darken-4`"
            >
                <span v-if="!dense" class="white--text font-weight-bold">{{ statusList[status].text }}</span>
            </v-progress-linear>
            <div v-if="dense" class="text-center black--text">{{ statusList[status].text }}</div>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class NgsRunStatusProgress extends Vue {
    @Prop() readonly status!: string
    @Prop() readonly dense?: boolean

    statusList: { [key: string]: { text: string; percentage: number; color: string; }} = {
        incomplete: {
            text: 'En chargement',
            percentage: 0,
            color: 'secondary'
        },
        planned: {
            text: 'Planifié',
            percentage: 25,
            color: 'secondary'
        },
        sequencing: {
            text: 'Séquençage',
            percentage: 50,
            color: 'secondary'
        },
        in_progress: {
            text: 'En cours',
            percentage: 75,
            color: 'secondary'
        },
        finished: {
            text: 'Terminé',
            percentage: 100,
            color: 'secondary'
        },
        failed: {
            text: 'Échec QC',
            percentage: 0,
            color: 'error'
        },
        error: {
            text: 'Erreur',
            percentage: 0,
            color: 'error'
        }
    }
}
</script>

<style scoped lang="scss">
</style>
