<template>
    <div class="pa-5">
        <div class="mb-5 font-weight-bold">Fichiers</div>
        <element-row label="Fichier de résultats">
            <v-btn color="primary" icon :href="$route('cnv_analyses.results_file', {cnv_analysis: cnvAnalysis.id})">
                <v-icon>mdi-download</v-icon>
            </v-btn>
        </element-row>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import {CnvAnalysis} from "@/models";

@Component({
    components: {
        ElementRow
    }
})
export default class CnvResultsFileSection extends Vue {
    @Prop() readonly cnvAnalysis!: CnvAnalysis
}
</script>

<style lang="scss" scoped>

</style>
