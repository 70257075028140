<template>
    <dialog-display-action
        v-model="isDialogOpen"
        :persistent="true"
        :action="true"
        :title="title"
        button-class="primary"
        :fullscreen="true"
    >
        <v-container class="px-5 py-0 overflow-y-auto" fluid>
            <v-row>
                <v-col cols="12">
                    <ngs-igv-viewer :dialog-open="isDialogOpen" v-model="position" :ngs-analysis="ngsAnalysis" :type="type"></ngs-igv-viewer>
                </v-col>
            </v-row>
        </v-container>
    </dialog-display-action>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogDisplayAction from '@/Components/generic/DialogDisplayAction.vue'
import NgsIgvViewer from '@/Components/domain-specific/ngs/helpers/NgsIgvViewer.vue'
import Btn from '@/Components/generic/Btn.vue'
import {NgsAnalysis, NgsPairAnalysis, Variant} from "@/models";
import get from "lodash/get";

@Component({
    components: {
        NgsIgvViewer,
        DialogDisplayAction,
        Btn
    }
})
export default class NgsAlignmentViewerDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly variant!: Variant
    @Prop() readonly ngsAnalysis!: NgsAnalysis|NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'

    @Watch('isDialogOpen', {immediate: true})
    onDialogOpening(val: boolean) {
        if (val) {
            const increment = get(this, 'variant.vep_annotation.hgvsc', 'NA').includes('del') && !get(this, 'variant.vep_annotation.hgvsc', 'NA').includes('delins') ? 1 : 0

            this.position.chromosome = this.variant.chromosome.name
            this.position.start_position = Number(this.variant.position) - 20 + increment
            this.position.end_position = Number(this.variant.position) + 20 + increment
        }
    }

    position: { [key: string]: string | null | number } = {
        chromosome: null,
        start_position: null,
        end_position: null
    }

    get title(): string {
        return `IGV viewer: ${get(this, 'variant.gene.name')} - ${get(this, 'variant.vep_annotation.hgvsc', '').after(':')} - ${get(this, 'variant.vep_annotation.hgvsp', '').after(':')}`
    }
}
</script>

<style lang="scss" scoped>

</style>
