<template>
    <v-list rounded dense>
        <v-subheader>{{ category }}</v-subheader>
        <v-list-item
            class="bordered"
            v-for="(item,i) in items"
            :key="i"
            dense
        >
            <v-list-item-icon class="my-auto">
                <v-icon v-if="item.value === true" color="success" x-large>mdi-check-circle-outline
                </v-icon>
                <v-icon v-if="item.value === false" color="error" x-large>mdi-close-circle-outline
                </v-icon>
                <v-icon v-if="item.value === null" color="info" x-large>mdi-pause-circle-outline
                </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.label }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action v-if="!disabled">
                <v-btn icon large class="bordered" @click="restart(item.route)">
                    <v-icon color="grey lighten-1">mdi-reload</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-list>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import route from 'ziggy-js'
import {AnalysisStep} from "@/laravel";

@Component
export default class Steps extends Vue {
    @Prop() analysisId!: number
    @Prop() category!: string
    @Prop() items!: AnalysisStep[]
    @Prop() type!: 'ngs_analysis' | 'cnv_analysis'
    @Prop({ default: false }) disabled!: boolean

    restart (routeName: string) {
        this.$inertia.put(route(routeName, { [this.type] : this.analysisId }), {}, {
            onSuccess: () => {
                this.$emit('reload')
            }
        })
    }
}
</script>

<style lang="scss" scoped>
.bordered {
    border: #BDBDBD solid 1px;
}
</style>
