<template>
    <panel title="Liste des variants" :expandable="true">
        <template v-slot:actions>
            <v-checkbox
                v-model="filters['show-common-variants']"
                dark
                class="ma-0 mr-3"
                hide-details
            >
                <template v-slot:label>
                    {{ `En commun (${commonVariantsCount} variants)` }}
                </template>
            </v-checkbox>
            <v-checkbox
                v-model="filters['show-masked-variants']"
                dark
                class="ma-0 mr-3"
                hide-details
            >
                <template v-slot:label>
                    {{ `Masqué par défaut (${maskedVariantsCount} variants)` }}
                </template>
            </v-checkbox>
            <v-checkbox
                v-model="filters['show-silent-variants']"
                dark
                class="ma-0"
                hide-details
            >
                <template v-slot:label>
                    {{ `Silencieux (${silentVariantsCount} variants)` }}
                </template>
            </v-checkbox>
        </template>
        <template>
            <comparison-variants-table
                v-model="filters"
                :loading="loading"
                :items="variants"
                :aliases="aliases"
                @select="$emit('select', $event)"
                @open="$emit('open', $event)"
            ></comparison-variants-table>
        </template>
    </panel>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue} from 'vue-property-decorator'
import Panel from '@/Components/generic/Panel.vue'
import ComparisonVariantsTable from "@/Components/domain-specific/ngs/tables/ComparisonVariantsTable.vue";
import {Variant} from "@/models";

@Component({
    components: {
        ComparisonVariantsTable,
        Panel,
    }
})
export default class NgsComparisonVariantCard extends Vue {
    @VModel() filters!: {
        'show-silent-variants': boolean | null,
        'show-masked-variants': boolean | null,
        allelic_balance: number | null,
        chromosome: string | null,
        position: number | null
    }
    @Prop() readonly variants!: Array<Variant>
    @Prop() readonly commonVariantsCount!: number
    @Prop() readonly silentVariantsCount!: number
    @Prop() readonly maskedVariantsCount!: number
    @Prop() readonly aliases!: { [key: number]: string }
    @Prop() readonly loading!: boolean
}
</script>

<style lang="css" scoped>
:deep(.v-expansion-panel-content__wrap) {
    padding: 0;
}
</style>
