<template>
    <dialog-confirmation
        v-model="dialog"
        title="Créer un nouvel échantillon"
        width="600"
        :persistent="true"
        @validate="submit"
        @cancel="resetForm"
        :loading="sampleForm.processing"
    >
        <v-form ref="newSampleForm" class="ma-3">
            <v-combobox
                ref="diamic"
                v-model="sampleForm.diamic"
                label="N° DIAMIC *"
                outlined
                :items="filteredSamples"
                item-text="diamic"
                item-value="diamic"
                :error-messages="sampleForm.errors.diamic"
                autofocus
                :return-object="false"
                :loading="isLoading"
            ></v-combobox>
            <v-text-field
                v-model="sampleForm.diamic_suffix"
                label="Bloc"
                outlined
                color="primary"
                :error-messages="sampleForm.errors.diamic_suffix"
            ></v-text-field>
            <v-text-field
                v-model="sampleForm.laboratory_number"
                label="N° prélèvement *"
                outlined
                color="primary"
                :error-messages="sampleForm.errors.laboratory_number"
            ></v-text-field>
            <v-text-field
                v-model="sampleForm.nip"
                label="NIP"
                outlined
                color="primary"
                :error-messages="sampleForm.errors.nip"
            ></v-text-field>
            <v-text-field
                v-model="sampleForm.genno_number"
                label="N° demande GENNO *"
                outlined
                color="primary"
                :error-messages="sampleForm.errors.genno_number"
            ></v-text-field>
            <v-select
                v-model="sampleForm.tumor_type_id"
                :items="tumorTypes"
                item-value="id"
                item-text="name"
                label="Diagnostic"
                outlined
                color="primary"
                :error-messages="sampleForm.errors.tumor_type_id"
            ></v-select>
            <v-text-field
                v-model.number="sampleForm.tumor_cellularity_percentage"
                label="Pourcentage de la tumeur"
                type="number" prefix="%" outlined color="primary"
                :error-messages="sampleForm.errors.tumor_cellularity_percentage"
            ></v-text-field>
        </v-form>
        <p class="text-center text-caption font-weight-normal error--text">* champ requis</p>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Ref, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import route from 'ziggy-js'
import {TYPE} from 'vue-toastification'
import {Sample, TumorType} from "@/models";
import {SampleForm} from "@/forms";
import {Page} from "@inertiajs/inertia";

@Component({
    components: {
        DialogConfirmation
    }
})
export default class CreateSampleDialog extends Vue {
    @VModel() dialog!: boolean
    @Prop() readonly tumorTypes!: Array<TumorType>
    @Prop() readonly input!: string
    @Ref('diamic') readonly diamic!: HTMLInputElement

    @Watch('dialog')
    onDialogOpening (val: boolean) {
        if (val) {
            this.resetForm()
            this.sampleForm.diamic = this.input
            this.isLoading = true
            this.$axios.get(route('samples.index')).then(({ data }) => {
                this.samples = data
            }).finally(() => {
                this.isLoading = false
            })
        }
    }

    samples: any[] = []

    sampleForm: InertiaForm<SampleForm> = this.$inertia.form({
        diamic: '',
        diamic_suffix: '',
        laboratory_number: '',
        nip: '',
        genno_number: '',
        tumor_type_id: 7,
        patient_id: null,
        tumor_cellularity_percentage: null
    })

    isLoading: boolean = false

    resetForm () {
        this.sampleForm.reset()
        this.sampleForm.clearErrors()
    }

    submit () {
        this.diamic.blur()
        this.$nextTick(() => {
            this.sampleForm.post(
                route('samples.store'),
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: (page: Page) => {
                        this.$toast('Nouvel échantillon créé', {
                            type: TYPE.SUCCESS
                        })
                        this.dialog = false
                        this.$emit('created', (page.props.flash as any).data)
                        this.resetForm()
                    }
                }
            )
        })
    }

    get filteredSamples(): Array<Sample> {
        return this.samples.filter((sample: Sample) => sample.diamic !== null)
    }
}
</script>

<style scoped lang="scss">
</style>
