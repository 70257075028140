<template>
    <v-dialog
        v-model="isDialogOpen"
        v-if="ngsAnalysis"
        transition="dialog-bottom-transition"
        open-delay="1500"
        width="1400"
        persistent
    >

        <card :loading="loading">
            <template v-slot:header>
                Couverture des ROIs pour l'analyse OB {{ identifier }}
                <v-spacer></v-spacer>
                <v-btn @click="close()" color="primary" text>
                    <v-icon small class="mr-1">mdi-close</v-icon>
                    Fermer
                </v-btn>
            </template>
            <v-container fluid>


                <v-tabs v-model="tabs">
                    <v-tab>Vue globale ({{ Object.keys(datasets).length }} gènes)</v-tab>
                    <v-tab>ROIs par qualité</v-tab>
                    <v-tab v-if="selectedGene">Zoom sur {{ selectedGene }}</v-tab>
                </v-tabs>

                <v-divider></v-divider>

                <v-tabs-items v-model="tabs">
                    <v-tab-item>
                        <coverage-bar-chart :ngs-analysis="ngsAnalysis" :is-dialog-open="isDialogOpen"
                                            :width="ngsAnalysis.genes.length * 20" :datasets="datasets"
                                            @select="openGene($event)"></coverage-bar-chart>
                    </v-tab-item>
                    <v-tab-item>
                        <div class="pa-5">
                            <v-row>
                                <v-col>
                                    <v-expansion-panels v-model="panels" focusable multiple accordion>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="very-weak-coverage">
                                                <span class="font-weight-bold white--text">{{ amplicons[0].length }} ROIs - Couverture très faible (< 30)</span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <ngs-roi-quality-table
                                                    :items="amplicons[0]"
                                                ></ngs-roi-quality-table>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="weak-coverage">
                                                <span class="font-weight-bold white--text">{{ amplicons[1].length }} ROIs - Couverture faible (< 60)</span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <ngs-roi-quality-table
                                                    :items="amplicons[1]"
                                                ></ngs-roi-quality-table>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="medium-coverage">
                                                <span class="font-weight-bold white--text">{{ amplicons[2].length }} ROIs - Couverture moyenne (< 100)</span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <ngs-roi-quality-table
                                                    :items="amplicons[2]"
                                                ></ngs-roi-quality-table>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="good-coverage">
                                                <span class="font-weight-bold white--text">{{ amplicons[3].length }} ROIs - Bonne couverture (>= 100)</span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <ngs-roi-quality-table
                                                    :items="amplicons[3]"
                                                ></ngs-roi-quality-table>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-row>
                        </div>
                    </v-tab-item>
                    <v-tab-item v-if="selectedGene">
                        <ngs-zoom-gene-table :items="geneAmplicons"></ngs-zoom-gene-table>
                    </v-tab-item>
                </v-tabs-items>
            </v-container>
            <v-card-actions class="text-right justify-end">
                <v-spacer></v-spacer>
                <v-btn @click="close()" color="primary" text>
                    <v-icon small class="mr-1">mdi-close</v-icon>
                    Fermer
                </v-btn>
            </v-card-actions>
        </card>
    </v-dialog>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import NgsRoiQualityTable from '@/Components/domain-specific/ngs/tables/NgsRoiQualityTable.vue'
import Btn from '@/Components/generic/Btn.vue'
import CoverageBarChart from "@/Components/domain-specific/ngs/charts/CoverageBarChart.vue";
import route from "ziggy-js";
import NgsZoomGeneTable from "@/Components/domain-specific/ngs/tables/NgsZoomGeneTable.vue";
import Card from "@/Components/generic/Card.vue";
import {NgsAnalysis, NgsPairAnalysis} from "@/models";

@Component({
    components: {
        Card,
        NgsZoomGeneTable,
        CoverageBarChart,
        NgsRoiQualityTable,
        DialogConfirmation,
        Btn
    }
})
export default class NgsAmpliconCoverageDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly ngsAnalysis!: NgsAnalysis|NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'

    @Watch('isDialogOpen')
    onDialogOpening(val: boolean) {
        if (val) {
            this.loading = true

            if (this.datasets.length === 0) {
                if (this.type === 'simple') {
                    this.$axios.get(route('ngs_analyses.coverage', {ngs_analysis: this.ngsAnalysis.id}))
                        .then(({data}) => {
                            this.datasets = data
                        })
                }
                if (this.type === 'pair') {
                    this.$axios.get(route('ngs_pair_analyses.coverage', {ngs_pair_analysis: this.ngsAnalysis.id}))
                        .then(({data}) => {
                            this.datasets = data
                        })
                }
            }

            if (this.amplicons.length === 4) {
                if (this.type === 'simple') {
                    this.$axios.get(route('ngs_analyses.amplicons', {ngs_analysis: this.ngsAnalysis.id}))
                        .then(({data}) => {
                            this.amplicons[0] = data.filter((amplicon: any) => amplicon.pivot.average_coverage < 30)
                            this.amplicons[1] = data.filter((amplicon: any) => amplicon.pivot.average_coverage >= 30 && amplicon.pivot.average_coverage < 60)
                            this.amplicons[2] = data.filter((amplicon: any) => amplicon.pivot.average_coverage >= 60 && amplicon.pivot.average_coverage < 100)
                            this.amplicons[3] = data.filter((amplicon: any) => amplicon.pivot.average_coverage >= 100)
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
                if (this.type === 'pair') {
                    this.$axios.get(route('ngs_pair_analyses.amplicons', {ngs_pair_analysis: this.ngsAnalysis.id}))
                        .then(({data}) => {
                            this.amplicons[0] = data.filter((amplicon: any) => amplicon.pivot.average_coverage < 30)
                            this.amplicons[1] = data.filter((amplicon: any) => amplicon.pivot.average_coverage >= 30 && amplicon.pivot.average_coverage < 60)
                            this.amplicons[2] = data.filter((amplicon: any) => amplicon.pivot.average_coverage >= 60 && amplicon.pivot.average_coverage < 100)
                            this.amplicons[3] = data.filter((amplicon: any) => amplicon.pivot.average_coverage >= 100)
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            }
        }
    }

    loading: boolean = true
    tabs: number = 0
    panels: number[] = []
    datasets: any[] = []
    amplicons: any[] = [[], [], [], []]
    selectedGene: string | null = null
    geneAmplicons: any[] = []

    items: string[] = [
        'Vue globale',
        'Amplicons par qualité',
    ]

    openGene(event: string) {
        this.selectedGene = event
        if (this.type === 'simple') {
            this.$axios.get(route('ngs_analyses.amplicons-of-gene', {
                ngs_analysis: this.ngsAnalysis.id,
                gene_name: event
            }))
                .then(({data}) => {
                    this.geneAmplicons = data
                })
                .finally(() => {
                    this.tabs = 2
                })
        }
        if (this.type === 'pair') {
            this.$axios.get(route('ngs_pair_analyses.amplicons-of-gene', {
                ngs_pair_analysis: this.ngsAnalysis.id,
                gene_name: event
            }))
                .then(({data}) => {
                    this.geneAmplicons = data
                })
                .finally(() => {
                    this.tabs = 2
                })
        }
    }

    close() {
        this.isDialogOpen = false
        this.selectedGene = null
    }

    get identifier(): string {
        if (this.type === 'pair') {
            return `${this.ngsAnalysis.id}-${(this.ngsAnalysis as NgsPairAnalysis).tumoral_barcode.name}-${(this.ngsAnalysis as NgsPairAnalysis).ngs_tumoral_library.sample.diamic}`
        }
        return `${this.ngsAnalysis.id}-${(this.ngsAnalysis as NgsAnalysis).barcode.name}-${(this.ngsAnalysis as NgsAnalysis).sample.diamic}`
    }

}
</script>

<style lang="scss" scoped>
.very-weak-coverage {
    background-color: #e53e3e;
}

.weak-coverage {
    background-color: #ed8936;
}

.medium-coverage {
    background-color: #ecc94b;
}

.good-coverage {
    background-color: #48bb78;
}
</style>
