<template>
    <div class="pa-5">
        <v-row dense class="ma-0 cursor-pointer" v-for="(amplicons, gene) in datasets" :key="gene" no-gutters @click="$emit('select', gene)">
            <v-col cols="1" class="text-center font-weight-bold">
                {{ gene }}
            </v-col>
            <v-col cols="11">
                <div class="gene-coverage" :style="mappedStyle(amplicons)">
                    <v-tooltip top v-for="(amplicon, index) in mappedAmplicons(amplicons)" :key="index" color="rgb(0,0,0,0.9)">
                        <template v-slot:activator="{ on, attrs }">
                            <div :class="mappedClass(amplicon.coverage)" v-bind="attrs" v-on="on">
                                {{ Math.ceil(amplicon.coverage) }}</div>
                        </template>
                        <span>{{ amplicon.coverage }}</span>
                    </v-tooltip>

                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {NgsAnalysis, NgsPairAnalysis} from "@/models";

@Component
export default class AmpliconBarChart extends Vue {
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly width!: number
    @Prop() readonly isDialogOpen!: boolean
    @Prop() readonly datasets!: any[]

    mappedClass(coverage: number): string {
        if (coverage < 30) {
            return 'very-low'
        }

        if (coverage >= 30 && coverage < 60) {
            return 'low'
        }

        if (coverage >= 60 && coverage < 100) {
            return 'average'
        }

        if (coverage >= 100) {
            return 'good'
        }

        return 'unknown'
    }

    mappedAmplicons(amplicons: any[]): { coverage: number; length: number }[] {
        return amplicons.map((amplicon) => ({
            coverage: amplicon.pivot.average_coverage,
            length: Number(amplicon.end_position) - Number(amplicon.start_position)
        }))
    }

    mappedStyle(amplicons: any[]): string {
        const mappedAmplicons = amplicons.map((amplicon) => `${(Number(amplicon.end_position) - Number(amplicon.start_position))}fr`)
        return `grid-template-columns: ${mappedAmplicons.join(' ')}`
    }
}
</script>

<style scoped lang="css">
.gene-coverage {
    border: 2px solid white;
    border-radius: 10px;
    overflow: hidden;
    display: grid;
}

.gene-coverage div {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 10px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    border-radius: 10px;
}

.good {
    background: #48bb78;
    color: #48bb78;
}

.average {
    background: #ecc94b;
    color: #ecc94b;
}

.low {
    background: #ed8936;
    color: #ed8936;
}

.very-low {
    background: #e53e3e;
    color: #e53e3e;
}

.cursor-pointer {
    cursor: pointer;
}
</style>
