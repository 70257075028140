<template>
    <div class="chart-container cursor-pointer transparent-chart" style="position: relative; width:3rem"
         @click.exact="showNumbers=!showNumbers">
        <div>
            <Pie
                v-if="!showNumbers"
                :chart-options="chartOptions"
                :chart-data="chartData"
                :width="width"
                :height="height"
            ></Pie>
            <small v-if="showNumbers">
                var: {{ Math.round(data[0][0]) }}%<br>
                ref: {{ Math.round(data[1][0]) }}%
            </small>
        </div>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Ref, Vue} from 'vue-property-decorator'
import {Pie} from 'vue-chartjs/legacy'
import {ArcElement, CategoryScale, Chart as ChartJS, ChartData, ChartOptions, Legend, Title, Tooltip} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

@Component({
    components: {
        Pie
    }
})
export default class StrandBalancePieChart extends Vue {
    @Prop() data!: number[][]
    @Prop({default: 10}) readonly width?: number
    @Prop({default: 10}) readonly height?: number

    showNumbers: boolean = false

    chartData: ChartData = {
        datasets: [
            {
                backgroundColor: ['#93cfa9', '#CFD8DC'],
                data: this.data[0],
                borderWidth: [1, 1],
                borderAlign: ['inner', 'inner'],
                label: this.data[0].toString()
            },
            {
                backgroundColor: ['#93cfa9', '#CFD8DC'],
                data: this.data[1],
                borderWidth: [1, 1],
                borderAlign: ['inner', 'inner'],
                label: this.data[1].toString()
            }
        ]

    }

    chartOptions: ChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            },
        },
        hover: {
            mode: null
        },
        animation: false,
    }
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}
</style>
