<template>
    <div>
        <panel title="Liste des appariement tumeur/non-tumeur" :expandable="true">
            <template v-slot:actions>
                <btn
                    @click="dialogOpen = true"
                    color="white"
                    icon="mdi-plus"
                >
                     Ajouter une paire
                </btn>
            </template>
            <template>
                <ngs-library-pairs-table
                    :value="value"
                    :items="items"
                ></ngs-library-pairs-table>
            </template>
        </panel>
        <ngs-create-library-pair-dialog
            v-model="dialogOpen"
            :item="value"
        ></ngs-create-library-pair-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Panel from '@/Components/generic/Panel.vue'
import NgsLibraryPairsTable from '@/Components/domain-specific/ngs/tables/NgsLibraryPairsTable.vue'
import Btn from '@/Components/generic/Btn.vue'
import NgsCreateLibraryPairDialog from '@/Components/domain-specific/ngs/dialogs/NgsCreateSamplePairDialog.vue'
import {NgsLibraryPair, NgsRun} from "@/models";

@Component({
    components: {
        Panel,
        Btn,
        NgsCreateLibraryPairDialog,
        NgsLibraryPairsTable
    }
})
export default class NgsRunLibraryPairsCard extends Vue {
    @Prop() readonly value!: NgsRun
    @Prop() readonly items!: Array<NgsLibraryPair>

    dialogOpen: boolean = false
}
</script>

<style scoped lang="scss">
</style>
