<template>
    <v-row>
        <v-col cols="12">
            <p class="text-h6 font-weight-bold">
                Publications
                <v-chip small>{{ variant.publications.length }}</v-chip>
                <v-btn icon class="ml-1" color="primary"
                       @click="isPublicationsShown=!isPublicationsShown">
                    <v-icon>
                        {{ isPublicationsShown ? 'mdi-chevron-right' : 'mdi-chevron-down' }}
                    </v-icon>
                </v-btn>
            </p>
            <div v-if="isPublicationsShown">
                <v-row
                    v-for="annotation in variant.publications"
                    :key="`pubmed-${annotation.id}`"
                    dense
                >
                    <v-col cols="12">
                        <element-row>
                            <template #label>
                                <v-btn
                                    link
                                    color="primary"
                                    text
                                    small
                                    block
                                    class="ml-1"
                                    :href="`https://pubmed.ncbi.nlm.nih.gov/${$get(annotation, 'publication.content.uid')}`"
                                    target="_blank">
                                    {{ $get(annotation, 'publication.content.uid') }}
                                    <v-icon class="ml-1" small>mdi-open-in-new</v-icon>
                                </v-btn>
                                <v-btn
                                    color="error"
                                    small
                                    block
                                    depressed
                                    outlined
                                    @click="removePublication(annotation.id)"
                                >
                                    Supprimer
                                </v-btn>
                            </template>
                            <p class="font-weight-medium">
                                {{ $get(annotation, 'publication.content.title') }}
                            </p>
                            <p class="text--disabled">Commentaire:
                                {{ $get(annotation, 'evidence') }}
                            </p>
                            <p class="text-small text-caption text-right">Ajouté par
                                {{ $get(annotation, 'user.name') }}</p>
                        </element-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn
                            outlined
                            class="my-2"
                            @click="publicationDialogOpen = true"
                            block
                            color="primary"
                        >
                            Ajouter une publication
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <add-publication-dialog
                    v-model="publicationDialogOpen"
                    :tumor-type-id="tumorTypeId"
                    :item-id="$get(variant, 'id', null)"
                    type="variant"
                ></add-publication-dialog>
            </div>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {Variant} from "@/models";
import ElementRow from "@/Components/generic/ElementRow.vue";
import route from "ziggy-js";
import {TYPE} from "vue-toastification";
import AddPublicationDialog from "@/Components/domain-specific/cnv/dialogs/AddPublicationDialog.vue";

@Component({
    components: {AddPublicationDialog, ElementRow}
})
export default class VariantPublicationsSection extends Vue {
    @Prop() readonly variant!: Variant
    @Prop() readonly tumorTypeId!: null | number

    isPublicationsShown: boolean = false
    publicationDialogOpen: boolean = false

    removePublication(publicationId: number) {
        this.$inertia.delete(
            route('ngs_publications.destroy', {
                ngs_publication: publicationId
            }),
            {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.$emit('publication-added')
                    this.$toast('Publication supprimée', {
                        type: TYPE.ERROR
                    })
                }
            }
        )
    }
}
</script>

<style scoped>

</style>
