<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            item-key="id"
            disable-sort
            no-data-text="Aucun appariement tumeur/non-tumeur"
            hide-default-footer
            disable-pagination
        >
            <template v-slot:item.tumoral_library="{ item }">
                <span :class="value.id !== item.tumoral_library.ngs_run_id ? 'grey--text' : ''">
                    {{ `${$defaultTo(item.tumoral_library.sample.diamic, '')} ${$defaultTo(item.tumoral_library.sample.laboratory_number, '')}` }}
                </span>
                <v-chip
                    @click="$visit('ngs_runs.show', {ngs_run: item.tumoral_library.ngs_run_id})"
                    v-if="value.id !== item.tumoral_library.ngs_run_id"
                    class="ml-1 chip-run"
                    small
                >
                    {{ formatDate(item.tumoral_library.run.date) }}
                </v-chip>
            </template>
            <template v-slot:item.non_tumoral_library="{ item }">
                <span :class="value.id !== item.non_tumoral_library.ngs_run_id ? 'grey--text' : ''">
                    {{ `${$defaultTo(item.non_tumoral_library.sample.diamic, '')} ${$defaultTo(item.non_tumoral_library.sample.laboratory_number, '')}` }}
                </span>
                    <v-chip
                        @click="$visit('ngs_runs.show', {ngs_run: item.non_tumoral_library.ngs_run_id})"
                        v-if="value.id !== item.non_tumoral_library.ngs_run_id"
                        class="ml-1 chip-run"
                        small
                    >
                        {{ formatDate(item.non_tumoral_library.run.date) }}
                    </v-chip>
            </template>
            <template v-slot:item.status="{ item }">
                <ngs-sample-pair-status-progress :status="item.status"></ngs-sample-pair-status-progress>
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="text-center">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-if="item.status !== 'analysed'"
                                @click="unpairLibraries(item)"
                                v-on="on"
                                icon
                            >
                                <v-icon color="primary">mdi-link-variant-off</v-icon>
                            </v-btn>
                        </template>
                        <span>Désapparier</span>
                    </v-tooltip>
                </div>
            </template>
        </v-data-table>
        <ngs-delete-library-pair-dialog
            v-model="isDialogOpen"
            :item="selectedLibraryPair"
        ></ngs-delete-library-pair-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {DataTableHeader} from 'vuetify'
import NgsSamplePairStatusProgress from '@/Components/domain-specific/ngs/helpers/NgsSamplePairStatusProgress.vue'
import NgsDeleteLibraryPairDialog from '@/Components/domain-specific/ngs/dialogs/NgsDeleteLibraryPairDialog.vue'
import {NgsLibraryPair, NgsRun} from "@/models";

@Component({
    components: {
        NgsDeleteLibraryPairDialog,
        NgsSamplePairStatusProgress,
    }
})
export default class NgsLibraryPairsTable extends Vue {
    @Prop() readonly value!: NgsRun
    @Prop() readonly items!: Array<NgsLibraryPair>

    isDialogOpen: boolean = false
    selectedLibraryPair: NgsLibraryPair | null = null

    headers: Array<DataTableHeader> = [
        {
            text: 'Lien',
            value: 'id',
            width: '10%',
            sortable: false
        },
        {
            text: 'Tumeur',
            value: 'tumoral_library',
            width: '30%',
            sortable: false
        },
        {
            text: 'Non-tumeur',
            value: 'non_tumoral_library',
            width: '30%',
            sortable: false
        },
        {
            text: 'Statut',
            value: 'status',
            width: '20%',
            sortable: false
        },
        {
            text: 'Actions',
            value: 'actions',
            align: 'center',
            width: '10%',
            sortable: false
        }
    ]

    unpairLibraries (item: NgsLibraryPair) {
        this.isDialogOpen = true
        this.selectedLibraryPair = item
    }
}
</script>

<style lang="scss" scoped>
.chip-run:hover {
    cursor: pointer;
}
</style>
