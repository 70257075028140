<template>
    <dialog-confirmation
        v-model="dialogOpen"
        :hide-validate-btn="true"
        cancel-label="Fermer"
        title="Détails du variant"
        max-width="1200"
        width="1200"
    >
        <variant-details
            :variant="item"
            :tumor-type-id="tumorTypeId"
        ></variant-details>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {Variant} from "@/models";
import VariantDetails from "@/Components/domain-specific/ngs/sections/VariantDetails.vue";

@Component({
    components: {
        VariantDetails,
        DialogConfirmation
    }
})
export default class NgsVariantDetailsDialog extends Vue {
    @VModel() dialogOpen!: boolean
    @Prop() readonly item!: Variant
    @Prop() readonly tumorTypeId!: null | number
    @Prop() readonly readonly!: boolean
}
</script>

<style lang="scss" scoped>

</style>
