<template>
    <div>
        <div class="pa-5" v-if="type === 'simple'">
            <div class="mb-5 font-weight-bold">Couverture</div>
            <element-row label="Nombre total de reads">
                {{ $get(ngsAnalysis, 'quality_control_content.n_reads_dedup', 'NA') }}
            </element-row>
            <element-row label="Profondeur à 85%">
                {{ $get(ngsAnalysis, 'quality_control_content.depth_85p', 'NA') }} reads
            </element-row>
            <element-row label="Pourcentage on-target">
                {{ roundTo($get(ngsAnalysis, 'quality_control_content.ontarget_rate', 0) * 100, 1) }}%
            </element-row>
            <element-row label="Couverture du panel à 30X">
                {{ roundTo($get(ngsAnalysis, 'quality_control_content.width_30X', 0) * 100, 1) }}%
            </element-row>
            <element-row label="Couverture du panel à 100X">
                {{ roundTo($get(ngsAnalysis, 'quality_control_content.width_100X', 0) * 100, 1) }}%
            </element-row>
            <element-row label="Couverture des hotspots">
                {{ roundTo($get(ngsAnalysis, 'quality_control_content.hotspots_100X', 0) * 100, 1) }}%
            </element-row>
<!--            <element-row label="Couverture">
                <btn
                    @click="dialogOpen = true"
                    color="primary"
                    icon="mdi-magnify"
                    :icon-only="true"
                ></btn>
            </element-row>-->
        </div>
        <div class="pa-5" v-if="type === 'pair'">
            <div class="mb-5 font-weight-bold">Couverture</div>
            <v-row dense>
                <v-col cols="12" md="6">
                    <div class="mb-3 font-weight-medium">Tumoral</div>
                    <element-row label="Nombre total de reads" :right-col-size="6" :left-col-size="6">
                        {{ $get(ngsAnalysis, 'tumoral_quality_control_content.n_reads_dedup', 'NA') }}
                    </element-row>
                    <element-row label="Profondeur à 85%" :right-col-size="6" :left-col-size="6">
                        {{ $get(ngsAnalysis, 'tumoral_quality_control_content.depth_85p', 'NA') }} reads
                    </element-row>
                    <element-row label="Pourcentage on-target" :right-col-size="6" :left-col-size="6">
                        {{ roundTo($get(ngsAnalysis, 'tumoral_quality_control_content.ontarget_rate', 0) * 100, 1) }}%
                    </element-row>
                    <element-row label="Couverture du panel à 30X" :right-col-size="6" :left-col-size="6">
                        {{ roundTo($get(ngsAnalysis, 'tumoral_quality_control_content.width_30X', 0) * 100, 1) }}%
                    </element-row>
                    <element-row label="Couverture du panel à 100X" :right-col-size="6" :left-col-size="6">
                        {{ roundTo($get(ngsAnalysis, 'tumoral_quality_control_content.width_100X', 0) * 100, 1) }}%
                    </element-row>
                    <element-row label="Couverture des hotspots" :right-col-size="6" :left-col-size="6">
                        {{ roundTo($get(ngsAnalysis, 'tumoral_quality_control_content.hotspots_100X', 0) * 100, 1) }}%
                    </element-row>
<!--                    <element-row label="Couverture" :right-col-size="6" :left-col-size="6">
                        <btn
                            @click="dialogOpen = true"
                            color="primary"
                            icon="mdi-magnify"
                            :icon-only="true"
                        ></btn>
                    </element-row>-->
                </v-col>
                <v-col cols="12" md="6">
                    <div class="mb-3 font-weight-medium">Non-tumoral</div>
                    <element-row label="Nombre total de reads" :right-col-size="6" :left-col-size="6">
                        {{ $get(ngsAnalysis, 'non_tumoral_quality_control_content.n_reads_dedup', 'NA') }}
                    </element-row>
                    <element-row label="Profondeur à 85%" :right-col-size="6" :left-col-size="6">
                        {{ $get(ngsAnalysis, 'non_tumoral_quality_control_content.depth_85p', 'NA') }} reads
                    </element-row>
                    <element-row label="Pourcentage on-target" :right-col-size="6" :left-col-size="6">
                        {{ roundTo($get(ngsAnalysis, 'non_tumoral_quality_control_content.ontarget_rate', 0) * 100, 1) }}%
                    </element-row>
                    <element-row label="Couverture du panel à 30X" :right-col-size="6" :left-col-size="6">
                        {{ roundTo($get(ngsAnalysis, 'non_tumoral_quality_control_content.width_30X', 0) * 100, 1) }}%
                    </element-row>
                </v-col>
            </v-row>
        </div>
        <ngs-amplicon-coverage-dialog
            v-model="dialogOpen"
            :ngs-analysis="ngsAnalysis"
            :type="type"
        ></ngs-amplicon-coverage-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import Btn from '@/Components/generic/Btn.vue'
import NgsAmpliconCoverageDialog from '@/Components/domain-specific/ngs/dialogs/NgsAmpliconCoverageDialog.vue'
import {NgsAnalysis, NgsPairAnalysis} from "@/models";

@Component({
    components: {
        NgsAmpliconCoverageDialog,
        ElementRow,
        Btn
    }
})
export default class NgsMetricsSection extends Vue {
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'

    dialogOpen: boolean = false
}
</script>

<style lang="scss" scoped>

</style>
