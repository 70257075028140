<template>
    <div>
        <div v-if="type === 'simple'" id="igv-div"></div>

        <div v-if="type === 'pair'">
            <v-tabs v-model="tabs">
                <v-tab>Tumoral</v-tab>
                <v-tab>Non-tumoral</v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <div id="igv-div"></div>
        </div>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
// @ts-ignore
import igv from 'igv/dist/igv.js'
import route from 'ziggy-js'
import {NgsAnalysis, NgsPairAnalysis} from "@/models";

@Component
export default class NgsIgvViewer extends Vue {
    @VModel() position!: { chromosome: string; start_position: string; end_position: string; }
    @Prop() readonly dialogOpen!: boolean
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'

    @Watch('tabs', {
        immediate: true
    })
    onTabChanging() {
        this.closeIgv()
        this.openIgv()
    }

    @Watch('dialogOpen', {
        immediate: true
    })
    onDialogChanging(dialogOpen: boolean) {
        if (!dialogOpen) {
            this.closeIgv()
        } else {
            this.tabs = 0
            this.openIgv()
        }
    }

    tabs: number = 0
    igvDiv: any = null
    options: any = null
    optionsTumoral: any = null
    optionsNonTumoral: any = null
    tracks: any[] = []
    tracksTumoral: any[] = []
    tracksNonTumoral: any[] = []

    mounted() {
        this.igvDiv = document.getElementById('igv-div')
        this.openIgv()
    }

    openIgv() {
        if (this.type === 'simple') {
            this.tracks = [
                {
                    name: 'VCF',
                    type: 'variant',
                    format: 'vcf',
                    url: route('ngs_analyses.stream.vcf_gz_file', {ngs_analysis: this.ngsAnalysis.id}),
                    indexURL: route('ngs_analyses.stream.vcf_gz_tbi_file', {ngs_analysis: this.ngsAnalysis.id}),
                    order: 2,
                    displayMode: 'COLLAPSED'
                },
                {
                    name: 'BAM',
                    type: 'alignment',
                    format: 'bam',
                    sourceType: 'file',
                    url: route('ngs_analyses.stream.bam_file', {ngs_analysis: this.ngsAnalysis.id}, true),
                    indexURL: route('ngs_analyses.stream.bai_file', {ngs_analysis: this.ngsAnalysis.id}, true),
                    coverage: '#EEEEEE',
                    coverageColor: '#BDBDBD',
                    deletionColor: '#D0A9F5',
                    insertionColor: '#FFB6C1',
                    colorBy: 'strand',
                    negStrandColor: 'rgba(0, 0, 255, 0.10)',
                    posStrandColor: 'rgba(255, 0, 0, 0.10)',
                    showMismatches: true,
                    showSoftClips: false,
                    viewAsPairs: true,
                    sort: {
                        chr: 'chr' + this.position.chromosome,
                        position: this.position.start_position,
                        option: 'BASE',
                        direction: 'ASC'
                    },
                    roi: [
                        {
                            url: route('ngs_analyses.stream.panel_file', {ngs_analysis: this.ngsAnalysis.id}),
                            indexed: false,
                            color: 'rgba(0, 150, 50, 0.1)'
                        }
                    ],
                    order: 3,
                    height: 500,
                }
            ]

            this.options = {
                genome: 'hg19',
                locus: 'chr' + this.position.chromosome + ':' + this.position.start_position + '-' + this.position.end_position,
                showNavigation: true,
                showRuler: true,
                showCenterGuide: true,
                reference: {
                    id: 'hg19',
                    name: 'Human (GRCh37/hg19)',
                    fastaURL: route('static.fasta', {}, true),
                    indexURL: route('static.fasta.fai', {}, true),
                    cytobandURL: route('static.cytoband', {}, true),
                    aliasURL: route('static.tab'),
                    tracks: [
                        {
                            name: 'Refseq Genes',
                            format: 'refgene',
                            url: route('static.refseq', {}, true),
                            indexURL: route('static.refseq.tbi', {}, true),
                            visibilityWindow: -1,
                            removable: false,
                            order: 1,
                            infoURL: 'https://www.ncbi.nlm.nih.gov/gene/?term=$$'
                        }
                    ],
                    chromosomeOrder: 'chr1, chr2, chr3, chr4, chr5, chr6, chr7, chr8, chr9, chr10, chr11, chr12, chr13, chr14, chr15, chr16, chr17, chr18, chr19, chr20, chr21, chr22, chrX, chrY'
                },
                tracks: this.tracks,
            }

            igv.createBrowser(this.igvDiv, this.options)
                .then(function (browser: any) {
                    igv.browser = browser
                    igv.browser.zoomOut();
                })
        }

        if (this.type === 'pair') {
            if (this.tabs === 0) {
                this.tracksTumoral = [
                    {
                        name: 'VCF',
                        type: 'variant',
                        format: 'vcf',
                        url: route('ngs_pair_analyses.stream.vcf_gz_file', {ngs_pair_analysis: this.ngsAnalysis.id}),
                        indexURL: route('ngs_pair_analyses.stream.vcf_gz_tbi_file', {ngs_pair_analysis: this.ngsAnalysis.id}),
                        order: 2,
                        displayMode: 'COLLAPSED'
                    },
                    {
                        name: 'Tumoral BAM',
                        type: 'alignment',
                        format: 'bam',
                        sourceType: 'file',
                        url: route('ngs_pair_analyses.stream.tumoral_bam_file', {ngs_pair_analysis: this.ngsAnalysis.id}, true),
                        indexURL: route('ngs_pair_analyses.stream.tumoral_bai_file', {ngs_pair_analysis: this.ngsAnalysis.id}, true),
                        coverage: '#EEEEEE',
                        coverageColor: '#BDBDBD',
                        deletionColor: '#D0A9F5',
                        insertionColor: '#FFB6C1',
                        colorBy: 'strand',
                        negStrandColor: 'rgba(0, 0, 255, 0.10)',
                        posStrandColor: 'rgba(255, 0, 0, 0.10)',
                        showMismatches: true,
                        showSoftClips: false,
                        viewAsPairs: true,
                        sort: {
                            chr: 'chr' + this.position.chromosome,
                            position: this.position.start_position,
                            option: 'BASE',
                            direction: 'ASC'
                        },
                        roi: [
                            {
                                url: route('ngs_pair_analyses.stream.panel_file', {ngs_pair_analysis: this.ngsAnalysis.id}),
                                indexed: false,
                                color: 'rgba(0, 150, 50, 0.1)'
                            }
                        ],
                        order: 3,
                        height: 450,
                    }
                ]

                this.optionsTumoral = {
                    genome: 'hg19',
                    locus: 'chr' + this.position.chromosome + ':' + this.position.start_position + '-' + this.position.end_position,
                    showNavigation: true,
                    showRuler: true,
                    showCenterGuide: true,
                    reference: {
                        id: 'hg19',
                        name: 'Human (GRCh37/hg19)',
                        fastaURL: route('static.fasta', {}, true),
                        indexURL: route('static.fasta.fai', {}, true),
                        cytobandURL: route('static.cytoband', {}, true),
                        aliasURL: route('static.tab'),
                        tracks: [
                            {
                                name: 'Refseq Genes',
                                format: 'refgene',
                                url: route('static.refseq', {}, true),
                                indexURL: route('static.refseq.tbi', {}, true),
                                visibilityWindow: -1,
                                removable: false,
                                order: 1,
                                infoURL: 'https://www.ncbi.nlm.nih.gov/gene/?term=$$'
                            }
                        ],
                        chromosomeOrder: 'chr1, chr2, chr3, chr4, chr5, chr6, chr7, chr8, chr9, chr10, chr11, chr12, chr13, chr14, chr15, chr16, chr17, chr18, chr19, chr20, chr21, chr22, chrX, chrY'
                    },
                    tracks: this.tracksTumoral
                }

                igv.createBrowser(this.igvDiv, this.optionsTumoral)
                    .then(function (browser: any) {
                        igv.browser = browser
                        igv.browser.zoomOut();
                    })
            }

            if (this.tabs === 1) {
                this.tracksNonTumoral = [
                    {
                        name: 'Non-tumoral BAM',
                        type: 'alignment',
                        format: 'bam',
                        sourceType: 'file',
                        url: route('ngs_pair_analyses.stream.non_tumoral_bam_file', {ngs_pair_analysis: this.ngsAnalysis.id}, true),
                        indexURL: route('ngs_pair_analyses.stream.non_tumoral_bai_file', {ngs_pair_analysis: this.ngsAnalysis.id}, true),
                        coverage: '#EEEEEE',
                        coverageColor: '#BDBDBD',
                        deletionColor: '#D0A9F5',
                        insertionColor: '#FFB6C1',
                        colorBy: 'strand',
                        negStrandColor: 'rgba(0, 0, 255, 0.10)',
                        posStrandColor: 'rgba(255, 0, 0, 0.10)',
                        showMismatches: true,
                        showSoftClips: false,
                        viewAsPairs: true,
                        sort: {
                            chr: 'chr' + this.position.chromosome,
                            position: this.position.start_position,
                            option: 'BASE',
                            direction: 'ASC'
                        },
                        roi: [
                            {
                                url: route('ngs_pair_analyses.stream.panel_file', {ngs_pair_analysis: this.ngsAnalysis.id}),
                                indexed: false,
                                color: 'rgba(0, 150, 50, 0.1)'
                            }
                        ],
                        order: 4,
                        height: 560,
                    }
                ]

                this.optionsNonTumoral = {
                    genome: 'hg19',
                    locus: 'chr' + this.position.chromosome + ':' + this.position.start_position + '-' + this.position.end_position,
                    showNavigation: true,
                    showRuler: true,
                    showCenterGuide: true,
                    reference: {
                        id: 'hg19',
                        name: 'Human (GRCh37/hg19)',
                        fastaURL: route('static.fasta', {}, true),
                        indexURL: route('static.fasta.fai', {}, true),
                        cytobandURL: route('static.cytoband', {}, true),
                        aliasURL: route('static.tab'),
                        tracks: [
                            {
                                name: 'Refseq Genes',
                                format: 'refgene',
                                url: route('static.refseq', {}, true),
                                indexURL: route('static.refseq.tbi', {}, true),
                                visibilityWindow: -1,
                                removable: false,
                                order: 1,
                                infoURL: 'https://www.ncbi.nlm.nih.gov/gene/?term=$$'
                            }
                        ],
                        chromosomeOrder: 'chr1, chr2, chr3, chr4, chr5, chr6, chr7, chr8, chr9, chr10, chr11, chr12, chr13, chr14, chr15, chr16, chr17, chr18, chr19, chr20, chr21, chr22, chrX, chrY'
                    },
                    tracks: this.tracksNonTumoral
                }

                igv.createBrowser(this.igvDiv, this.optionsNonTumoral)
                    .then(function (browser: any) {
                        igv.browser = browser
                        igv.browser.zoomOut();
                    })
            }
        }
    }

    closeIgv() {
        igv.removeAllBrowsers()
    }
}
</script>

<style lang="scss" scoped>

</style>
