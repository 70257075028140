<template>
    <guest-layout :fill="true">
        <v-row justify="center" dense>
            <v-col cols="12" lg="4" md="6" sm="12">
                <h1 class="text-h4 mb-5">OncoBench</h1>
                <p class="font-weight-bold text-h6">Next Generation Sequencing annotation pipeline for
                    pathology</p>
                <p class="text--disabled">OncoBench is a single application for the entire annotation of NGS-based
                    bioinformatics analyses lifecycle. From computing and annotating to reviewing and reporting.</p>
                <p class="text--disabled">This is a self-managed instance of OncoBench.</p>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
                <v-tabs
                    fixed-tabs
                >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab>
                        HUG
                    </v-tab>

                    <v-tab dusk="standard-tab">
                        Standard
                    </v-tab>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p class="font-weight-bold">
                                    Connectez-vous avec
                                </p>
                                <v-btn outlined block :href="$route('saml2.login')" link>
                                    <img height="48" src="@/assets/hug.png" alt="hug">
                                </v-btn>
                                <v-btn v-if="environment === 'development'" class="mt-3" outlined block @click="$visit('dev-login')" link>
                                    <v-icon class="mr-1">mdi-code-tags</v-icon>Developer Account
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-text-field
                                    dusk="email"
                                    label="E-mail"
                                    v-model="form.email"
                                    :error-messages="form.errors.email"
                                    type="email"
                                    autofocus
                                    outlined
                                    dense
                                    @keyup.enter="submit"
                                ></v-text-field>
                                <v-text-field
                                    dusk="password"
                                    label="Mot de passe"
                                    v-model="form.password"
                                    type="password"
                                    outlined
                                    dense
                                    @keyup.enter="submit"
                                ></v-text-field>
                                <v-row>
                                    <v-checkbox
                                        class="ml-3"
                                        dense
                                        v-model="form.remember_me"
                                    >
                                        <template v-slot:label>
                                            <small class="font-weight-bold">Se souvenir de moi</small>
                                        </template>
                                    </v-checkbox>
                                    <v-spacer></v-spacer>
                                    <a class="font-weight-bold primary--text mr-3 mt-2 text-decoration-none"
                                       @click="$visit('password.request')">Forgot password?</a>
                                </v-row>
                                <v-btn dusk="submit-button" type="submit" color="primary" block depressed @click="submit"
                                       :loading="form.processing">
                                    Se Connecter
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-col>
        </v-row>
    </guest-layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import GuestLayout from '@/Layouts/GuestLayout.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import route from 'ziggy-js'
import {LoginForm} from "@/forms";

@Component({
    components: { GuestLayout },
    metaInfo: {
        title: "S'authentifier"
    }
})
export default class Login extends Vue {
    @Prop() readonly is_local!: boolean

    form: InertiaForm<LoginForm> = this.$inertia.form({
        email: '',
        password: '',
        remember_me: false
    })

    submit () {
        this.form.post(route('login'), {
            preserveScroll: true,
            onFinish: () => {
                this.form.reset()
            }
        })
    }

    get environment () {
        return process.env.NODE_ENV
    }
}
</script>

<style lang="scss" scoped>

</style>
