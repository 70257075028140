<template>
    <dialog-confirmation
        v-model="dialogOpen"
        cancel-label="Fermer"
        title="Annotation du variant"
        max-width="800"
        width="800"
        @cancel="resetForm"
        @validate="submit"
    >
        <div class="pa-5">
            <v-row dense>
                <v-col cols="6">
                    <v-radio-group v-model="form.annotation">
                        <v-radio
                            v-for="annotation in annotations"
                            :key="annotation.value"
                            :label="annotation.text"
                            :value="annotation.value"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="6">
                    <v-textarea
                        label="Justification"
                        v-model="form.evidence"
                        :disabled="form.annotation === 'valid'"
                        :error-messages="form.errors.evidence"
                        rows="3"
                        dense
                        outlined
                        auto-grow
                    ></v-textarea>
                </v-col>
            </v-row>
        </div>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {NgsAnalysisVariantAnnotation} from "@/models";
import VariantDetails from "@/Components/domain-specific/ngs/sections/VariantDetails.vue";
import {InertiaForm} from "@inertiajs/inertia-vue";
import {NgsAnalysisVariantAnnotationForm} from "@/forms";
import route from "ziggy-js";
import {TYPE} from "vue-toastification";

@Component({
    components: {
        VariantDetails,
        DialogConfirmation
    }
})
export default class NgsEditVariantAnnotationDialog extends Vue {
    @VModel() dialogOpen!: boolean
    @Prop() readonly item!: NgsAnalysisVariantAnnotation

    @Watch('form.annotation')
    onAnnotationChanged(value: string) {
        if (value === 'valid') {
            this.form.evidence = null
        }
    }

    form: InertiaForm<NgsAnalysisVariantAnnotationForm> = this.$inertia.form({
        annotation: this.item.annotation,
        evidence: this.item.evidence,
    })

    annotations: any[] = [
        {
            value: 'valid',
            text: 'Valide',
            icon: 'mdi-check'
        },
        {
            value: 'technical_artifact',
            text: 'Artefact technique',
            icon: 'mdi-wrench'
        },
        {
            value: 'constitutional_variant',
            text: 'Variant constitutionnel',
            icon: 'mdi-account-group'
        },
        {
            value: 'weak_quality',
            text: 'Qualité faible',
            icon: 'mdi-thumb-down'
        }
    ]

    annotationsText: { [key: string]: string } = {
        technical_artifact: 'artefact technique',
        constitutional_variant: 'variant constitutionnel',
        weak_quality: 'qualité faible',
    }

    resetForm() {
        this.form.reset()
        this.dialogOpen = false
    }

    submit() {
        this.form.put(route('ngs_analysis_variant_annotations.update', {ngs_analysis_variant_annotation: this.item.id}), {
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                this.$emit('reloaded')
                this.$toast('Annotation modifiée', {
                    type: TYPE.SUCCESS
                })
                this.dialogOpen = false
            }
        })
    }
}
</script>

<style lang="scss" scoped>

</style>
