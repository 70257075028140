<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            item-key="id"
            no-data-text="Aucun historique"
            :loading="loading"
            hide-default-footer
            disable-sort
            disable-pagination
        >
            <template v-slot:item.value="{ item }">
                {{
                    item['annotation'] !== undefined ? annotations[item.annotation] : pathogenicities[item.pathogenicity]
                }}
            </template>
            <template v-slot:item.date="{ item }">
                {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn icon small color="primary" @click="editAnnotation(item)"
                       :disabled="readonly || item.reported_by !== currentUser.id">
                    <v-icon small>
                        mdi-pencil
                    </v-icon>
                </v-btn>
                <v-btn icon small color="error" @click="deleteAnnotation(item)" :disabled="readonly || items.length > 1">
                    <v-icon small>
                        mdi-delete
                    </v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <ngs-edit-variant-annotation-dialog
            v-if="isEditVariantAnnotationDialogOpen"
            v-model="isEditVariantAnnotationDialogOpen"
            :item="selectedAnnotation"
            @reloaded="reloadAnnotations"
        ></ngs-edit-variant-annotation-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {DataTableHeader} from 'vuetify'
import route from "ziggy-js";
import {TYPE} from "vue-toastification";
import {NgsAnalysisVariantAnnotation, User, VariantPathogenicity} from "@/models";
import NgsEditVariantAnnotationDialog
    from "@/Components/domain-specific/ngs/dialogs/NgsEditVariantAnnotationDialog.vue";

@Component({
    components: {NgsEditVariantAnnotationDialog}
})
export default class HistoryTable extends Vue {
    @Prop() readonly loading!: boolean
    @Prop() readonly items!: Array<NgsAnalysisVariantAnnotation | VariantPathogenicity>
    @Prop() readonly type!: 'annotations' | 'pathogenicities'
    @Prop({ default: false }) readonly readonly?: boolean

    isEditVariantAnnotationDialogOpen: boolean = false

    selectedAnnotation: null | NgsAnalysisVariantAnnotation = null

    annotations: {
        technical_artifact: string
        constitutional_variant: string
        weak_quality: string
        valid: string
    } = {
        technical_artifact: 'Artefact technique',
        constitutional_variant: 'Variant constitutionnel',
        weak_quality: 'Qualité faible',
        valid: 'Valide'
    }

    pathogenicities: {
        pathogenic: string
        likely_pathogenic: string
        uncertain: string
        probably_benign: string
        benign: string
        NA: string
    } = {
        pathogenic: 'Pathogénique',
        likely_pathogenic: 'Probablement pathogénique',
        uncertain: 'Incertain',
        probably_benign: 'Probablement bénin',
        benign: 'Bénin',
        NA: 'NA'
    }

    annotationsHeaders: Array<DataTableHeader> = [
        {
            text: 'Opérateur',
            value: 'reporter.name',
        },
        {
            text: 'Valeur',
            value: 'value',
        },
        {
            text: 'Justification',
            value: 'evidence',
        },
        {
            text: 'Date',
            value: 'date',
        },
        {
            text: 'Actions',
            value: 'actions'
        }
    ]

    pathogenicitiesHeaders: Array<DataTableHeader> = [
        {
            text: 'Opérateur',
            value: 'reporter.name',
        },
        {
            text: 'Valeur',
            value: 'value',
        },
        {
            text: 'Justification',
            value: 'evidence',
        },
        {
            text: 'Date',
            value: 'date',
        }
    ]

    deleteAnnotation(item: NgsAnalysisVariantAnnotation) {
        this.$inertia.delete(route('ngs_analysis_variant_annotations.destroy', {ngs_analysis_variant_annotation: item.id}), {
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                this.$emit('reloaded')
                this.$toast('Annotation supprimée', {
                    type: TYPE.ERROR
                })
            }
        })
    }

    reloadAnnotations() {
        this.$emit('reloaded')
    }

    editAnnotation(item: NgsAnalysisVariantAnnotation) {
        this.selectedAnnotation = item
        this.isEditVariantAnnotationDialogOpen = true
    }

    get headers(): Array<DataTableHeader> {
        return this.type === 'annotations' ? this.annotationsHeaders : this.pathogenicitiesHeaders
    }

    get currentUser(): User {
        return this.$page.props.user as User
    }
}
</script>

<style lang="scss">

</style>
