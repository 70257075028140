<template>
    <panel title="Liste des analyses" :expandable="true">
        <template>
            <v-container fluid>
                <v-row v-for="(analyses, key, index) in ngsAnalyses" :key="`ngs-analyses-${index}`" dense>
                    <v-col cols="12" class="ma-3">
                        <span class="font-weight-bold">{{ key }}</span>
                    </v-col>
                    <v-col cols="12">
                        <ngs-simple-analyses-table
                            :value="value"
                            :items="analyses"
                        ></ngs-simple-analyses-table>
                    </v-col>
                </v-row>
                <v-row v-for="(analyses, key, index) in ngsPairAnalyses" :key="`ngs-pair-analyses-${index}`" dense>
                    <v-col cols="12" class="ma-3">
                        <span class="font-weight-bold">{{ key }}</span>
                    </v-col>
                    <v-col cols="12">
                        <ngs-pair-analyses-table
                            :value="value"
                            :items="analyses"
                        ></ngs-pair-analyses-table>
                    </v-col>
                </v-row>
                <div v-if="(ngsAnalyses.length + ngsPairAnalyses.length) === 0" class="text-center text--disabled text-body-2 my-3">Aucune analyse ou analyse de paire</div>
            </v-container>
        </template>
    </panel>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Panel from '@/Components/generic/Panel.vue'
import NgsSimpleAnalysesTable from "@/Components/domain-specific/ngs/tables/NgsSimpleAnalysesTable.vue";
import NgsPairAnalysesTable from "@/Components/domain-specific/ngs/tables/NgsPairAnalysesTable.vue";
import {NgsAnalysis, NgsPairAnalysis, NgsRun} from "@/models";

@Component({
    components: {
        NgsPairAnalysesTable,
        NgsSimpleAnalysesTable,
        Panel
    }
})
export default class NgsRunAnalysesCard extends Vue {
    @Prop() readonly value!: NgsRun
    @Prop() readonly ngsAnalyses!: Array<{ [key: string]: Array<NgsAnalysis>}>
    @Prop() readonly ngsPairAnalyses!: Array<{ [key: string]: Array<NgsPairAnalysis>}>
    panel: number[] = []
}
</script>

<style scoped lang="scss">
</style>
