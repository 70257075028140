import Vue from 'vue'
import Vuetify, { UserVuetifyPreset } from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import fr from 'vuetify/src/locale/fr'
import en from 'vuetify/src/locale/en'

Vue.use(Vuetify)

const opts: Partial<UserVuetifyPreset> = {
    lang: {
        locales: { en, fr },
        current: 'fr'
    },
    theme: {
        themes: {
            light: {
                primary: '#65c6c1',
                secondary: '#93cfa9',
                info: '#34c2f2',
                error: '#eb5c2e'
            }
        }
    }
}

Vuetify.config.silent = true

export default new Vuetify(opts)
