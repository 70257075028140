<template>
    <div class="pa-5">
        <div class="mb-5 font-weight-bold">
            <v-btn icon @click="areVariantsShown = !areVariantsShown">
                <v-icon>{{ areVariantsShown ? 'mdi-chevron-right' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
            Variants non affichés
            <v-chip>{{ missingVariants.length }}</v-chip>
        </div>
        <v-alert type="warning" prominent text border="left" v-if="areVariantsShown">
            <div class="text-subtitle-2" v-for="variant in missingVariants" :key="variant.id">
                chr{{ $get(variant, 'chromosome.name') }}:{{ $get(variant, 'position') }} {{ $get(variant, 'reference') }}>{{ $get(variant, 'alternate') }} ({{ $get(variant, 'gene.name')}})
            </div>
        </v-alert>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import {NgsAnalysis, NgsPairAnalysis, Variant} from "@/models";

@Component({
    components: {
        ElementRow
    }
})
export default class NgsMissingVariantsSection extends Vue {
    @Prop() readonly missingVariants!: Array<Variant>

    areVariantsShown: boolean = false
}
</script>

<style lang="scss" scoped>

</style>
