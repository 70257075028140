<template>
    <layout>
        <v-row dense>
            <v-col cols="12">
                <panel :title="`Comparaison d'analyses: ${ngsComparison.name}`" :expandable="true">
                    <template v-slot:actions>
                        <btn
                            @click="openDeleteDialog()"
                            color="white"
                            icon="mdi-delete"
                            :small="true"
                            class="mr-1"
                        >
                            Supprimer la comparaison
                        </btn>
                    </template>
                    <v-row dense class="ma-0">
                        <v-col cols="12" md="6" class="px-0">
                            <v-data-table
                                :headers="headers"
                                :items="analyses"
                                item-key="id"
                                disable-sort
                                no-data-text="Aucune analyse"
                                hide-default-footer
                                disable-pagination
                            >
                                <template v-slot:item.pivot.alias="{ item }">
                                    <b>{{ item.pivot.alias }}</b>
                                </template>
                                <template v-slot:item.sample="{ item }">
                                    <div v-if="item.pivot.analysis_type === 'ngs_simple'">
                                        {{
                                            `${$defaultTo(item.library.sample.diamic, '')} ${$defaultTo(item.library.sample.genno_number, '')}`
                                        }}
                                        <v-chip
                                            @click="$visit('ngs_runs.show', {ngs_run: item.library.ngs_run_id})"
                                            class="ml-1 chip-run"
                                            small
                                            color="info"
                                        >
                                            Run N°{{ item.library.ngs_run_id }}
                                        </v-chip>
                                    </div>
                                    <div v-if="item.pivot.analysis_type === 'ngs_pair'">
                                        <span class="font-weight-bold mr-1">(T)</span>
                                        {{
                                            `${$defaultTo(item.ngs_tumoral_library.sample.diamic, '')} ${$defaultTo(item.ngs_tumoral_library.sample.genno_number, '')}`
                                        }}
                                        <v-chip
                                            @click="$visit('ngs_runs.show', {ngs_run: item.ngs_tumoral_library.ngs_run_id})"
                                            class="ma-1 chip-run"
                                            small
                                            color="info"
                                        >
                                            Run N°{{ item.ngs_tumoral_library.ngs_run_id }}
                                        </v-chip>
                                        <br>
                                        <span class="font-weight-bold mr-1">(NT)</span>
                                        {{
                                            `${$defaultTo(item.ngs_non_tumoral_library.sample.diamic, '')} ${$defaultTo(item.ngs_non_tumoral_library.sample.genno_number, '')}`
                                        }}
                                        <v-chip
                                            @click="$visit('ngs_runs.show', {ngs_run: item.ngs_non_tumoral_library.ngs_run_id})"
                                            class="ma-1 chip-run"
                                            small
                                            color="info"
                                        >
                                            Run N°{{ item.ngs_non_tumoral_library.ngs_run_id }}
                                        </v-chip>
                                    </div>
                                </template>
                                <template v-slot:item.ngs_analysis_status="{ item }">
                                    <ngs-analysis-status-progress
                                        :status="item.ngs_analysis_status"
                                    ></ngs-analysis-status-progress>
                                </template>
                                <template v-slot:item.report="{ item }">
                                    <v-tooltip v-if="item.ngs_analysis_status === 'reported'" top>
                                        <template v-slot:activator="{ on }">
                                            <div class="text-center">
                                                <v-btn
                                                    :href="$route('ngs_analyses.download_report', { ngs_analysis: item.id, analysis_report: item.report.id })"
                                                    icon
                                                    v-on="on"
                                                >
                                                    <v-icon color="primary">mdi-file-word-outline</v-icon>
                                                </v-btn>
                                            </div>
                                        </template>
                                        <span>Télécharger rapport</span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:item.alias="{ item }">

                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <div class="text-center">
                                        <div v-if="item.pivot.analysis_type === 'ngs_simple'">
                                            <v-btn
                                                v-if="item.ngs_analysis_status !== 'error' || item.ngs_analysis_status !== 'loading_error'"
                                                @click="$visit('ngs_analyses.show', {ngs_analysis: item.id})"
                                                icon
                                            >
                                                <v-icon color="primary">mdi-arrow-right</v-icon>
                                            </v-btn>
                                        </div>
                                        <div v-if="item.pivot.analysis_type === 'ngs_pair'">
                                            <v-btn
                                                v-if="item.ngs_analysis_status !== 'error' || item.ngs_analysis_status !== 'loading_error'"
                                                @click="$visit('ngs_pair_analyses.show', {ngs_pair_analysis: item.id})"
                                                icon
                                            >
                                                <v-icon color="primary">mdi-arrow-right</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-col>
                        <v-divider
                            v-show="$vuetify.breakpoint.mdAndUp"
                            vertical
                        ></v-divider>
                        <v-col cols="12" md="6" class="pa-5">
                            <element-row label="Liste des rapports disponibles:">
                                <p v-for="(report, index) in ngsComparison.reports" :key="index" class="mb-0">
                                    <span :class="report.invalided_at ? 'text--disabled' : null">{{
                                            report.name
                                        }} {{
                                            report.invalided_at !== null ? ` (${formatDate(report.invalided_at)})` : null
                                        }}</span>
                                    <v-btn color="primary" icon
                                           :href="$route('ngs_comparisons.download_report', {ngs_comparison: ngsComparison.id, analysis_report: report.id})">
                                        <v-icon>mdi-download</v-icon>
                                    </v-btn>
                                    <v-tooltip bottom v-if="report.invalided_at === null">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="success"
                                                v-bind="attrs"
                                                v-on="on"
                                            >mdi-check-circle
                                            </v-icon>
                                        </template>
                                        <span>Rapport valide</span>
                                    </v-tooltip>
                                </p>
                            </element-row>
                        </v-col>
                    </v-row>
                </panel>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <ngs-comparison-variant-card
                    v-model="form"
                    :loading="loading"
                    :variants="variants"
                    :aliases="aliases"
                    :common-variants-count="commonVariantsCount"
                    :silent-variants-count="silentVariantsCount"
                    :masked-variants-count="maskedVariantsCount"
                    @select="$emit('select', $event)"
                    @open="openViewer($event)"
                ></ngs-comparison-variant-card>
            </v-col>
        </v-row>
        <dialog-confirmation
            v-model="isDeleteDialogOpen"
            title="Supprimer la comparaison"
            :width="600"
            :max-width="600"
            @validate="deleteComparison()"
            :loading="loadingDeletion"
            button-class="error"
        >
            <div class="pa-5">
                <v-row>
                    <v-col cols="12">
                        <p>Êtes-vous sûr(e) de vouloir supprimer cette comparaison? Vous n'aurez plus accès à cette
                            comparaison</p>
                    </v-col>
                </v-row>
            </div>
        </dialog-confirmation>
        <ngs-global-viewer-dialog
            v-model="isViewerDialogOpen"
            :ngs-analysis="selectedNgsAnalysis"
            :type="selectNgsAnalysisType"
            :position="selectedPosition"
            :list="analyses"
        >
        </ngs-global-viewer-dialog>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import Layout from "@/Layouts/Layout.vue";
import route from "ziggy-js";
import pickBy from "lodash/pickBy";
import NgsComparisonVariantCard from "@/Components/domain-specific/ngs/cards/NgsComparisonVariantCard.vue";
import Panel from "@/Components/generic/Panel.vue";
import {DataTableHeader} from "vuetify";
import NgsAnalysisStatusProgress from "@/Components/domain-specific/ngs/helpers/NgsAnalysisStatusProgress.vue";
import ElementRow from "@/Components/generic/ElementRow.vue";
import Btn from "@/Components/generic/Btn.vue";
import DialogConfirmation from "@/Components/generic/DialogConfirmation.vue";
import {TYPE} from "vue-toastification";
import {NgsAnalysis, NgsComparison, NgsPairAnalysis, Variant} from "@/models";
import NgsAlignmentViewerDialog from "@/Components/domain-specific/ngs/dialogs/NgsAlignmentViewerDialog.vue";
import NgsGlobalViewerDialog from "@/Components/domain-specific/ngs/dialogs/NgsGlobalViewerDialog.vue";

@Component({
    components: {
        NgsGlobalViewerDialog,
        NgsAlignmentViewerDialog,
        DialogConfirmation,
        Btn,
        ElementRow,
        NgsAnalysisStatusProgress,
        Panel,
        NgsComparisonVariantCard,
        Layout
    },
    metaInfo: {
        title: "Comparaison d'analyses"
    }
})
export default class ShowNgsComparison extends Vue {
    @Prop() readonly variants!: Array<Variant>
    @Prop() readonly ngsComparison!: NgsComparison
    @Prop() readonly commonVariantsCount!: number
    @Prop() readonly silentVariantsCount!: number
    @Prop() readonly maskedVariantsCount!: number
    @Prop() readonly filters!: { 'show-common-variants': boolean; 'show-silent-variants': boolean; 'show-masked-variants': boolean; }

    @Watch('form', {
        deep: true
    })
    onFormUpdated(): void {
        this.reloadData()
    }

    form: { 'show-common-variants': boolean; 'show-silent-variants': boolean; 'show-masked-variants': boolean; } = {
        'show-common-variants': this.filters['show-common-variants'],
        'show-silent-variants': this.filters["show-silent-variants"],
        'show-masked-variants': this.filters["show-masked-variants"],
    }

    loading: boolean = false
    loadingDeletion: boolean = false

    isDeleteDialogOpen: boolean = false

    isViewerDialogOpen: boolean = false

    selectedNgsAnalysis: NgsAnalysis | NgsPairAnalysis | null = null
    selectNgsAnalysisType: 'simple' | 'pair' | null = null
    selectedPosition: { [key: string]: string | null | number } | null = null

    headers: Array<DataTableHeader> = [
        {
            text: 'Alias',
            value: 'pivot.alias',
            align: 'center',
            width: '10%',
            sortable: false
        },
        {
            text: 'Échantillon (DIAMIC - N° demande GENNO)',
            value: 'sample',
            width: '40%',
            sortable: false
        },
        {
            text: 'Statut analyse',
            value: 'ngs_analysis_status',
            width: '40%',
            sortable: false
        },
        {
            text: 'Actions',
            value: 'actions',
            align: 'center',
            width: '10%',
            sortable: false
        }
    ]

    openViewer(e: { [key: string]: any }) {
        this.selectedNgsAnalysis = e['analysis'] as NgsAnalysis | NgsPairAnalysis
        this.selectNgsAnalysisType = e['analysis'] as 'simple' | 'pair'
        this.selectedPosition = e['position']
        this.isViewerDialogOpen = true
    }

    reloadData() {
        this.$inertia.get(
            route('ngs_comparisons.show', {ngs_comparison: this.ngsComparison.id}),
            pickBy(this.form),
            {
                preserveState: true,
                preserveScroll: true,
                onStart: () => {
                    this.loading = true
                },
                onFinish: () => {
                    this.loading = false
                },
                only: [
                    'variants',
                    'silentVariantsCount',
                    'maskedVariantsCount',
                ]
            }
        )
    }

    openDeleteDialog() {
        this.isDeleteDialogOpen = true
    }

    deleteComparison() {
        this.$inertia.delete(route('ngs_comparisons.delete', {ngs_comparison: this.ngsComparison.id}), {
            onStart: () => {
                this.loadingDeletion = true
            },
            onSuccess: () => {
                this.$toast('Comparaison supprimée', {
                    type: TYPE.ERROR
                })
            }
        })
    }

    get aliases(): { [key: number]: string } {
        let aliases: { [key: number]: string } = {}

        this.analyses.forEach((ngsAnalysis: NgsAnalysis | NgsPairAnalysis) => {
            aliases[ngsAnalysis.id] = (ngsAnalysis as any).pivot.alias
        })

        return aliases
    }

    get analyses(): Array<NgsAnalysis | NgsPairAnalysis> {
        return [...this.ngsComparison.analyses, ...this.ngsComparison.pair_analyses]
    }
}
</script>

<style scoped>

</style>
