import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import {createInertiaApp, plugin} from '@inertiajs/inertia-vue'
import {InertiaProgress} from '@inertiajs/progress'
import vuetify from './Plugins/vuetify'
import Toast, {POSITION, TYPE} from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import '../css/app.css'
import {Inertia, VisitOptions} from '@inertiajs/inertia'
import axios from 'axios'
import get from 'lodash/get'
import route, {RouteParams} from 'ziggy-js'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers'
import VueMeta from 'vue-meta'
import {defaultTo} from "lodash";

Vue.use(plugin)
InertiaProgress.init()

const options = {
    timeout: 1000,
    position: POSITION.BOTTOM_RIGHT
}

Vue.use(Toast, options)
Vue.use(VueMeta)

Inertia.on('error', () => {
    Vue.$toast("Oops... Quelque chose s'est mal passé!", {
        timeout: 2000,
        type: TYPE.ERROR,
        position: POSITION.BOTTOM_CENTER
    })
})

const CancelToken = axios.CancelToken
const source = CancelToken.source()

Vue.prototype.$axios = axios.create({
    timeout: 30000,
    cancelToken: source.token
})

Vue.prototype.$cancelToken = CancelToken

Vue.mixin({
    methods: {
        $get: get,
        $defaultTo: defaultTo,
        $route: route,
        formatDate (date, withTime = false) {
            if (withTime) {
                return new Date(date).toLocaleDateString('fr', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                })
            }
            return new Date(date).toLocaleDateString('fr', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
        },
        $visit (routeName: string, params?: RouteParams, options?: (VisitOptions & { data?: object })): void {
            this.$inertia.visit(route(routeName, params), options)
        },
        formatNumber (number: string): string {
            return String(number).replace(/(.)(?=(\d{3})+$)/g, '$1\'')
        },
        roundTo(number: number, to: number): string {
            if (to === undefined) {
                to = 1
            }

            return (Math.round(number * (10 ** to)) / 10 ** to).toFixed(to)
        }
    }
})

declare global {
    interface String {
        ucFirst(): string;
        after(char: string): string;
    }
}

String.prototype.ucFirst = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

String.prototype.after = function(char: string) {
    return this.substring(this.indexOf(char) + char.length);
}


createInertiaApp({
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.globEager('./Pages/**/*.vue')),
    setup ({ el, app, props, plugin }) {
        Vue.use(plugin)

        new Vue({
            vuetify,
            render: h => h(app, props)
        }).$mount(el)
    }
})

