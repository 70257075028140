<template>
    <layout>
        <v-container>
            <v-row>
                <v-col>
                    <h1>Groupe d'échantillons</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card flat color="grey lighten-2" :loading="loading">
                        <v-card-text>
                            <v-row dense>
                                <v-text-field
                                    v-model="form.search"
                                    outlined
                                    dense
                                    hide-details
                                    background-color="white"
                                    placeholder="Rechercher..."
                                    prepend-inner-icon="mdi-magnify"
                                >
                                </v-text-field>
                                <v-btn class="ml-3" height="40" color="white" depressed @click="queryAnReset"
                                       :loading="loading">
                                    Rechercher
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-btn class="my-3" color="primary" large block :disabled="selectedRows.length === 0" depressed
                           @click="link">
                        Regrouper les échantillons
                    </v-btn>
                    <v-data-table
                        :headers="headers"
                        :items="results.data"
                        item-key="id"
                        :footer-props="footerProps"
                        :server-items-length="results.total"
                        :loading="loading"
                        :items-per-page="Number(results.per_page)"
                        :page="Number(results.current_page)"
                        v-model="selectedRows"
                        selectable-key="id"
                        show-select
                        disable-sort
                        disable-filtering
                        no-data-text="Aucun résultat ne correspond à votre recherche"
                        @update:page="updatePage"
                        @update:items-per-page="updateItemsPerPage"
                    >
                        <template v-slot:item.tumor_type="{ item }">
                            <div v-for="(tumor, index) in tumorTypes(item)"
                                 :key="`group-${item.id}-tumor-type-${index}`">
                                <v-chip label color="primary" small class="ma-1">
                                    {{ tumor }}
                                </v-chip>
                            </div>
                        </template>
                        <template v-slot:item.samples="{ item }">
                            <ul>
                                <li class="ma-1" v-for="(sample, index) in samples(item)"
                                    :key="`group-${item.id}-sample-${index}`">
                                    {{ sample }}
                                </li>
                            </ul>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            {{ formatDate(item.updated_at) }}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <div class="text-start">
                                <v-btn v-if="item.samples.length > 1" @click="openDialog(item)" icon>
                                    <v-icon color="error">mdi-link-variant-off</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </v-data-table>
                    <dialog-confirmation
                        v-if="selectedItem"
                        v-model="isDeleteDialogOpen"
                        @validate="unlink(selectedItem)"
                        @cancel="selectedItem = null"
                        button-class="error"
                    >
                        <v-container>
                            <v-row>
                                <v-col>
                                    <p>Êtes-vous sûr de vouloir dégrouper les échantillons suivants:</p>
                                    <ul>
                                        <li v-for="sample in selectedItem.samples">
                                            {{ sample.full_diamic }}
                                        </li>
                                    </ul>
                                </v-col>
                            </v-row>
                        </v-container>
                    </dialog-confirmation>
                </v-col>
            </v-row>
        </v-container>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {Sample, SamplesGroup, Variant} from "@/models";
import Layout from "@/Layouts/Layout.vue";
import Panel from "@/Components/generic/Panel.vue";
import {Pagination} from "@/laravel";
import route from "ziggy-js";
import pickBy from "lodash/pickBy";
import throttle from "lodash/throttle";
import {DataTableHeader} from 'vuetify';
import uniq from "lodash/uniq";
import {InertiaForm} from "@inertiajs/inertia-vue";
import {SamplesForm} from "@/forms";
import {TYPE} from "vue-toastification";
import DialogConfirmation from "@/Components/generic/DialogConfirmation.vue";

@Component({
    components: {DialogConfirmation, Panel, Layout}
})
export default class ShowSamplesGroups extends Vue {
    @Prop() readonly results!: Pagination<SamplesGroup>
    @Prop() readonly search!: null | string
    @Prop() readonly itemsPerPage!: null | string
    @Prop() readonly page!: null | string

    loading: boolean = false

    form: { search: null | string; 'items-per-page': null | string; page: null | string; } = {
        search: this.search,
        'items-per-page': this.itemsPerPage,
        page: this.page,
    }

    isDeleteDialogOpen: boolean = false

    selectedItem: null | SamplesGroup = null

    selectedRows: Array<SamplesGroup> = []

    samplesForm: InertiaForm<SamplesForm> = this.$inertia.form({
        samples: []
    })

    headers: Array<DataTableHeader> = [
        {
            value: 'id',
            text: '#',
        },
        {
            value: 'tumor_type',
            text: 'Diagnostic',
        },
        {
            value: 'samples',
            text: 'Échantillons',
        },
        {
            value: 'created_at',
            text: "Date de création du groupe"
        },
        {
            value: 'actions',
            text: 'Actions',
        }
    ]

    footerProps: object = {
        'items-per-page-options': [10, 25, 50, 100]
    }

    updatePage(e: string): void {
        this.form.page = e
        this.query()
    }

    updateItemsPerPage(e: string): void {
        this.form["items-per-page"] = e
        this.query()
    }

    query() {
        this.$inertia.get(
            route('samples_groups.index'),
            pickBy(this.form),
            {
                preserveState: true,
                preserveScroll: true,
                onStart: () => {
                    this.loading = true
                },
                onFinish: () => {
                    this.loading = false
                }
            }
        )
    }

    queryAnReset() {
        this.form.page = '1'
        this.query()
    }

    tumorTypes(item: SamplesGroup) {
        return uniq(item.samples.map((item) => item.tumor_type.name))
    }

    samples(item: SamplesGroup) {
        return item.samples.map((item) => item.full_diamic)
    }

    openDialog(item: SamplesGroup) {
        this.selectedItem = item

        this.isDeleteDialogOpen = true
    }

    link() {
        this.selectedRows.forEach((item: SamplesGroup) => {
            this.samplesForm.samples.push(item.samples.map<number>((item: Sample) => item.id))
        })

        this.samplesForm.samples = this.samplesForm.samples.flat()

        this.samplesForm.post(route('samples_groups.store'), {
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                this.isDeleteDialogOpen = false
                this.$toast('Les échantillons ont été correctement groupés', {
                    type: TYPE.SUCCESS
                })
                this.selectedRows = []
                this.samplesForm.samples = []
            }
        })
    }

    unlink(item: SamplesGroup) {
        this.$inertia.delete(route('samples_groups.destroy', {samples_group: item.id}), {
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                this.isDeleteDialogOpen = false
                this.$toast('Les échantillons ont été correctement dégroupés', {
                    type: TYPE.ERROR
                })
            }
        })
    }

    created() {
        this.query = throttle(this.query, 3000)
    }
}
</script>

<style scoped>

</style>
