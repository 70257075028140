<template>
    <div class="pa-5">
        <div class="mb-5 font-weight-bold">Échantillon</div>
        <element-row label="N° DIAMIC">{{ cnvAnalysis.sample.diamic }}
        </element-row>
        <element-row label="Bloc" v-if="cnvAnalysis.sample.diamic_suffix">
            {{ cnvAnalysis.sample.diamic_suffix }}
        </element-row>
        <element-row label="N° prélèvement">
            {{ cnvAnalysis.sample.laboratory_number }}
        </element-row>
        <element-row label="N° demande GENNO">
            {{ cnvAnalysis.sample.genno_number }}
        </element-row>
        <element-row label="Sexe">
            <v-icon color="black" class="mr-3">
                mdi-gender-{{ sex[0] }}
            </v-icon>
            {{ sex[1] }}
        </element-row>
        <element-row label="Diagnostic">
            {{ $get(cnvAnalysis, 'sample.tumor_type.name', null) }}
        </element-row>
        <element-row label="Pourcentage de la tumeur">
            {{
                cnvAnalysis.sample.tumor_cellularity_percentage ? cnvAnalysis.sample.tumor_cellularity_percentage : 'NA'
            }}% (Histologie) /
            {{ $get(cnvAnalysis, 'quality_control_content.tu_scan_%ac', null) }}% (ChAS)
        </element-row>
        <element-row label="Autres analyses CNV">
            <a :href="$route('cnv_analyses.show', analysis.id)" v-for="analysis in filteredRelatedCnvAnalyses" :key="analysis.id" target="_blank">
                {{ `#${analysis.id}, ${formatDate(analysis.created_at)}` }}<br/>
            </a>
            <span v-if="filteredRelatedCnvAnalyses.length <= 0">Aucunes</span>
        </element-row>
        <element-row label="Autres analyses NGS">
            <a :href="$route('ngs_analyses.show', analysis.id)" v-for="analysis in relatedNgsAnalyses" :key="analysis.id" target="_blank">
                {{ `#${analysis.id}, ${formatDate(analysis.created_at)}, ${analysis.panel.name}` }}<br/>
            </a>
            <a :href="$route('ngs_pair_analyses.show', analysis.id)" v-for="analysis in relatedNgsPairAnalyses" :key="analysis.id" target="_blank">
                {{ `#${analysis.id}, ${formatDate(analysis.created_at)}, ${analysis.tumoral_panel.name}` }}<br/>
            </a>
            <span v-if="relatedNgsAnalyses.length <= 0 && relatedNgsPairAnalyses.length <= 0">Aucunes</span>
        </element-row>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import {CnvAnalysis, TumorType} from "@/models";
import get from "lodash/get";
import {lowerCase} from "lodash";

@Component({
    components: {
        ElementRow
    }
})
export default class CnvSampleInfoSection extends Vue {
    @Prop() readonly cnvAnalysis!: CnvAnalysis
    @Prop() readonly tumorTypes!: Array<TumorType>
    @Prop() readonly relatedCnvAnalyses!: Array<{ id: number, created_at: string }>
    @Prop() readonly relatedNgsAnalyses!: Array<{ id: number, created_at: string, panel: { name: string } }>
    @Prop() readonly relatedNgsPairAnalyses!: Array<{ id: number, created_at: string, tumoral_panel: { name: string } }>

    get chas (): string {
        if (this.cnvAnalysis.quality_control_content['tu_scan_%ac'] === 'NA') {
            return 'Non-déterminé'
        }
        if (this.cnvAnalysis.quality_control_content['tu_scan_%ac'] === '' || this.cnvAnalysis.quality_control_content['tu_scan_%ac'] === null) {
            return 'Homogène'
        }
        return this.cnvAnalysis.quality_control_content['tu_scan_%ac']
    }

    get sex(): [string, string] {
        const sex = get(this, 'cnvAnalysis.quality_control_content.sex', 'unknown')
        const options: {[key: string]: string} = {
            male: 'Homme',
            female: 'Femme',
            unknown: 'Inconnu',
        }
        return [lowerCase(sex), options[lowerCase(sex)]]
    }

    get filteredRelatedCnvAnalyses(): Array<{ id: number, created_at: string }> {
        return this.relatedCnvAnalyses.filter((analysis: { id: number, created_at: string }) => analysis.id !== this.cnvAnalysis.id)
    }
}
</script>

<style lang="scss" scoped>

</style>
