<template>
    <div class="chart-container cursor-pointer transparent-chart" style="position: relative; width: 2.5rem" @click.exact="showNumbers=!showNumbers">
            <Bar
                :chart-options="chartOptions"
                :chart-data="chartData"
                :width="width"
                :height="height"
            ></Bar>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Ref, Vue} from 'vue-property-decorator'
import {Bar} from 'vue-chartjs/legacy'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartData, ChartOptions} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

@Component({
    components: {
        Bar
    }
})
export default class PhredBarChart extends Vue {
    @Prop() readonly data!: number[][]
    @Prop({default: 10}) readonly width?: number
    @Prop({default: 10}) readonly height?: number

    showNumbers: boolean = false

    chartData: ChartData = {
        labels: ['alt', 'ref'],
        datasets: [
            {
                data: this.data[0],
                backgroundColor: ['#eb5c2e', '#eb5c2e'],
            },
            {
                data: this.data[1],
                backgroundColor: ['#CFD8DC', '#CFD8DC'],
            },
        ]
    }

    chartOptions: ChartOptions = {
        responsive: true,
        layout: {
            padding: {
                bottom: -8
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                displayColors: false,
                titleMarginBottom: 0,
                callbacks: {
                    label: (context: any) => {
                        return context.getters
                    }
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    display: false
                },
                grid: {
                    display: false,
                },
                display: false,
                stacked: true,
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false
                },
                ticks: {
                    display: false,
                    padding: 0,
                },
                stacked: true
            }
        },
        hover: {
            mode: null
        },
        animation: false
    }
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}
</style>
