<template>
    <div>
        <div class="pa-5" v-if="type === 'simple'">
            <div class="mb-5 font-weight-bold">Contrôle qualité</div>
            <element-row label="Fichier de QC">
                <v-btn color="primary" icon :href="$route('ngs_analyses.qc_file', {ngs_analysis: ngsAnalysis.id})">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
            </element-row>
            <element-row label="Passe les critères de QC"
            >
                <v-icon
                    :color="qualityControl[ngsAnalysis.has_passed_quality_control].color" class="my-auto mr-1">
                    {{ qualityControl[ngsAnalysis.has_passed_quality_control].icon }}
                </v-icon>
                <span class="my-auto text--disabled">{{
                        qualityControl[ngsAnalysis.has_passed_quality_control].text
                    }}</span>
            </element-row>
            <element-row label="Fréquence allélique">
                {{ $get(ngsAnalysis, 'allelic_frequency_limit', 'NA') }}%
            </element-row>
        </div>
        <div class="pa-5" v-if="type === 'pair'">
            <div class="mb-5 font-weight-bold">Contrôle qualité</div>
            <element-row label="Fichier tumoral de QC">
                <v-btn color="primary" icon :href="$route('ngs_pair_analyses.tumoral_qc_file', {ngs_pair_analysis: ngsAnalysis.id})">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
            </element-row>
            <element-row label="Fichier non-tumoral de QC">
                <v-btn color="primary" icon :href="$route('ngs_pair_analyses.non_tumoral_qc_file', {ngs_pair_analysis: ngsAnalysis.id})">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
            </element-row>
            <element-row label="Passe les critères de QC"
            >
                <v-icon
                    :color="qualityControl[ngsAnalysis.has_passed_quality_control].color" class="my-auto mr-1">
                    {{ qualityControl[ngsAnalysis.has_passed_quality_control].icon }}
                </v-icon>
                <span class="my-auto text--disabled">{{
                        qualityControl[ngsAnalysis.has_passed_quality_control].text
                    }}</span>
            </element-row>
            <element-row label="Fréquence allélique">
                {{ $get(ngsAnalysis, 'allelic_frequency_limit', 'NA') }}%
            </element-row>
        </div>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import {NgsAnalysis, NgsPairAnalysis} from "@/models";

@Component({
    components: {
        ElementRow
    }
})
export default class NgsQualityControlSection extends Vue {
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'

    qualityControl: any = {
        true: {
            color: 'success',
            icon: 'mdi-check-circle',
            text: 'PASS'
        },
        false: {
            color: 'error',
            icon: 'mdi-close-circle',
            text: 'FAILED'
        },
        null: {
            color: 'grey',
            icon: 'mdi-help-circle',
            text: 'NA'
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
