<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        :title="ngsAnalysis.ignored_at !== null ? 'Restaurer l\'analyse' : 'Ignorer l\'analyse'"
        :width="600"
        @cancel="cancel()"
        @validate="save()"
        :loading="form.processing"
    >
        <div class="pa-5" v-if="ngsAnalysis.ignored_at === null">
            <v-row>
                <v-col cols="12">
                    <v-textarea
                        v-model="form.ignored_comment"
                        label="Justification"
                        placeholder="Entrez votre justification"
                        :error-messages="form.errors.ignored_comment"
                        outlined
                    ></v-textarea>
                </v-col>
            </v-row>
        </div>
        <div class="pa-5" v-if="ngsAnalysis.ignored_at !== null">
            <v-row>
                <v-col cols="12">
                    <p>En restaurant l'analyse vous pourrez de nouveau interagir avec.</p>
                </v-col>
            </v-row>
        </div>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import {TYPE} from 'vue-toastification'
import route from 'ziggy-js'
import {NgsAnalysis, NgsPairAnalysis} from "@/models";
import {IgnoredAnalysisForm} from "@/forms";

@Component({
    components: { DialogConfirmation }
})
export default class CnvIgnoreDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'

    loading: boolean = false
    form: InertiaForm<IgnoredAnalysisForm> = this.$inertia.form({
        id: this.ngsAnalysis.id,
        ignored_comment: this.ngsAnalysis.ignored_comment
    })

    cancel () {
        this.form.reset()
    }

    save () {
        if (this.type === 'simple') {
            this.form.put(
                route('ngs_analyses.ignore', {ngs_analysis: this.ngsAnalysis.id}),
                {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.isDialogOpen = false
                        this.$toast(`Analyse ${this.ngsAnalysis.ignored_at === null ? 'restaurée' : 'ignorée'}`, {
                            type: TYPE.SUCCESS
                        })
                    }
                }
            )
        }

        if (this.type === 'pair') {
            this.form.put(
                route('ngs_pair_analyses.ignore', {ngs_pair_analysis: this.ngsAnalysis.id}),
                {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.isDialogOpen = false
                        this.$toast(`Analyse ${this.ngsAnalysis.ignored_at === null ? 'restaurée' : 'ignorée'}`, {
                            type: TYPE.SUCCESS
                        })
                    }
                }
            )
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
