<template>
    <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        no-data-text="Aucune couverture"
        disable-filtering
        disable-pagination
        hide-default-footer
    >
        <template v-slot:item.pivot.average_coverage="{ item }">
            <td :class="`${mappedClass(item.pivot.average_coverage)} white--text font-weight-bold`">{{ item.pivot.average_coverage }}</td>
        </template>
        <template v-slot:item.position="{ item }">
            chr{{ item.chromosome.name }}:{{ item.start_position }} - {{ item.end_position }}
        </template>
    </v-data-table>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import {NgsAmplicon} from "@/models";

@Component
export default class NgsZoomGeneTable extends Vue {
    @Prop() readonly items!: Array<NgsAmplicon>

    headers: Array<DataTableHeader> = [
        {
            text: 'Couverture',
            value: 'pivot.average_coverage',
            width: '33%',
            sortable: true
        },
        {
            text: "Nom du gène",
            value: 'gene.name',
            width: '33%',
            sortable: false
        },
        {
            text: 'Position',
            value: 'position',
            width: '33%',
            sortable: false
        }
    ]

    mappedClass(coverage: number): string {
        if (coverage < 30) {
            return 'very-low'
        }

        if (coverage >= 30 && coverage < 60) {
            return 'low'
        }

        if (coverage >= 60 && coverage < 100) {
            return 'average'
        }

        if (coverage >= 100) {
            return 'good'
        }

        return 'unknown'
    }
}
</script>

<style lang="scss" scoped>
.good {
    background: #48bb78;
}

.average {
    background: #ecc94b;
}

.low {
    background: #ed8936;
}

.very-low {
    background: #e53e3e;
}
</style>
