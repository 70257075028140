<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        title="Pathogénicité du variant"
        :width="800"
        :max-width="800"
        @validate="save()"
        @cancel="cancel()"
        :loading="loading"
        :validate-disabled="tabs === 1"
        button-class="primary"
        :hide-validate-btn="readonly"
        :cancel-label="readonly ? 'Fermer' : 'Annuler'"
    >
        <v-tabs v-model="tabs">
            <v-tab v-if="!readonly">Pathogénicité</v-tab>
            <v-tab>Historique</v-tab>
        </v-tabs>

        <v-divider></v-divider>

        <v-tabs-items v-model="tabs">
            <v-tab-item v-if="!readonly">
                <div class="pa-5">
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                label="Pathogénicité"
                                v-model="form.pathogenicity"
                                :items="pathogenicities"
                                item-text="text"
                                item-value="value"
                                :error-messages="form.errors.pathogenicity"
                                outlined
                            ></v-autocomplete>
                            <v-textarea
                                label="Justification"
                                v-model="form.evidence"
                                :error-messages="form.errors.evidence"
                                class="mt-1"
                                rows="3"
                                dense
                                outlined
                                auto-grow
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-5">
                    <history-table
                        type="pathogenicities"
                        :items="variantPathogenicities"
                        :loading="historicLoading"
                        @reloaded="$emit('reloaded')"
                    ></history-table>
                </div>
            </v-tab-item>
        </v-tabs-items>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import Panel from '@/Components/generic/Panel.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import route from 'ziggy-js'
import HistoryTable from "@/Components/domain-specific/ngs/tables/HistoryTable.vue";
import {TYPE} from 'vue-toastification'
import {NgsAnalysis, NgsPairAnalysis, Variant} from "@/models";
import {VariantPathogenicityForm} from "@/forms";
import { get } from 'lodash'

@Component({
    components: {
        DialogConfirmation,
        Panel,
        HistoryTable
    }
})
export default class NgsPathogenicityReviewDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly variant!: Variant
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'
    @Prop({ default: false }) readonly readonly?: boolean

    @Watch('isDialogOpen', {immediate: true})
    onDialogOpen (isOpen: boolean) {
        if (isOpen) {
            this.form.reset()
            this.form.clearErrors()
            this.historicLoading = true
            if (this.type === 'simple') {
                this.$axios.get(route('ngs_analyses.variant.pathogenicities', {
                    ngs_analysis: this.ngsAnalysis.id,
                    variant: this.variant.id
                }))
                    .then(({data}) => {
                        this.variantPathogenicities = data
                    })
                    .finally(() => {
                        this.historicLoading = false
                    })
            }
            if (this.type === 'pair') {
                this.$axios.get(route('ngs_pair_analyses.variant.pathogenicities', {
                    ngs_pair_analysis: this.ngsAnalysis.id,
                    variant: this.variant.id
                }))
                    .then(({data}) => {
                        this.variantPathogenicities = data
                    })
                    .finally(() => {
                        this.historicLoading = false
                    })
            }
        }
    }

    tabs: number = 0
    loading: boolean = false
    historicLoading: boolean = false
    variantPathogenicities: any[] = []
    form: InertiaForm<VariantPathogenicityForm> = this.$inertia.form({
        pathogenicity: get(this, 'variant.pathogenicity.pathogenicity', null),
        evidence: get(this, 'variant.pathogenicity.evidence', null),
    })

    pathogenicities: any[] = [
        {
            value: 'pathogenic',
            text: 'Pathogénique',
        },
        {
            value: 'likely_pathogenic',
            text: 'Probablement pathogénique',
        },
        {
            value: 'uncertain',
            text: 'Incertain',
        },
        {
            value: 'probably_benign',
            text: 'Probablement bénin',
        },
        {
            value: 'benign',
            text: 'Bénin',
        }
    ]

    cancel () {
        this.form.reset()
        this.tabs = 0
    }

    save () {
        if (this.type === 'simple') {
            this.form.post(
                route('ngs_analyses.variant.pathogenicity.store', {
                    ngs_analysis: this.ngsAnalysis.id,
                    variant: this.variant.id
                }),
                {
                    preserveScroll: true,
                    preserveState: true,
                    onStart: () => {
                        this.loading = true
                    },
                    onFinish: () => {
                        this.loading = false
                    },
                    onSuccess: () => {
                        this.isDialogOpen = false
                        this.form.reset()
                        this.$emit('reloaded')
                    }
                }
            )
        }
        if (this.type === 'pair') {
            this.form.post(
                route('ngs_pair_analyses.variant.pathogenicity.store', {
                    ngs_pair_analysis: this.ngsAnalysis.id,
                    variant: this.variant.id
                }),
                {
                    preserveScroll: true,
                    preserveState: true,
                    onStart: () => {
                        this.loading = true
                    },
                    onFinish: () => {
                        this.loading = false
                    },
                    onSuccess: () => {
                        this.isDialogOpen = false
                        this.form.reset()
                        this.$emit('reloaded')
                    }
                }
            )
        }
    }
}
</script>
