<template>
    <div>
        <div class="pa-5">
            <div class="mb-5 font-weight-bold">Lectures et rapports</div>
            <element-row label="Lectures">
                <div v-if="cnvAnalysis.review">
                    <p>1ère lecture par: <span
                        class="font-weight-bold my-0">{{ cnvAnalysis.review.reviewer.name }}</span>
                        <span class="grey--text">
                            ({{ formatDate(cnvAnalysis.review.created_at) }})
                        </span>
                    </p>
                    <p v-if="$get(cnvAnalysis, 'review.review_comment', false)" class="grey--text">
                        Commentaire: {{ cnvAnalysis.review.review_comment }}
                    </p>
                    <cnv-review-request-list
                        v-if="cnvAnalysis.review.reviewer !== null && cnvAnalysis.review.validator === null && cnvAnalysis.review.review_requests.length > 0"
                        :review-requests="cnvAnalysis.review.review_requests"
                    ></cnv-review-request-list>
                    <p v-if="cnvAnalysis.review.hasOwnProperty('validator_id') && cnvAnalysis.review.validator_id !== null">
                        2ème lecture par: <span
                        class="font-weight-bold my-0">{{ cnvAnalysis.review.validator.name }}</span>
                        <span class="grey--text">
                            ({{ formatDate(cnvAnalysis.review.updated_at) }})
                        </span>
                    </p>
                    <p v-if="$get(cnvAnalysis, 'review.validation_comment', false)"
                       class="grey--text">
                        Commentaire: {{ cnvAnalysis.review.validation_comment }}
                    </p>
                </div>
            </element-row>
        </div>
        <div class="pa-5">
            <element-row label="Rapports">
                <span v-if="$page.props.errors.report" class="error--text">{{ Array.isArray($page.props.errors.report) ? $page.props.errors.report.toString() : $page.props.errors.report }}</span>
                <p v-for="(report, index) in cnvAnalysis.reports" :key="index" class="mb-0">
                    <span :class="report.invalided_at ? 'text--disabled' : null">{{ report.name }} {{ report.invalided_at !== null ? ` (${formatDate(report.invalided_at)})` : null}}</span>
                    <v-btn color="primary" icon
                           :href="$route('cnv_analyses.download_report', {cnv_analysis: cnvAnalysis.id, analysis_report: report.id})">
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <v-tooltip bottom v-if="report.invalided_at === null">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="success"
                                v-bind="attrs"
                                v-on="on"
                            >mdi-check-circle
                            </v-icon>
                        </template>
                        <span>Rapport valide</span>
                    </v-tooltip>
                </p>
            </element-row>
        </div>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import CnvReviewRequestList from '@/Components/domain-specific/cnv/helpers/CnvReviewRequestList.vue'
import {CnvAnalysis} from "@/models";

@Component({
    components: {
        ElementRow,
        CnvReviewRequestList
    }
})
export default class CnvReportsSection extends Vue {
    @Prop() readonly cnvAnalysis!: CnvAnalysis
}
</script>

<style lang="scss" scoped>

</style>
