<template>
    <layout>
        <v-container fluid>
            <stepper
                v-model="stepper"
                :steps="steps"
            >
                <template v-slot:step-1>
                    <date-picker
                        label="Date du run"
                        v-model="ngsRunForm.date"
                        outlined
                        :error-messages="ngsRunForm.errors.date"
                        :readonly="true"
                    ></date-picker>
                    <v-file-input
                        v-model="ngsRunForm.libraries_file"
                        color="primary"
                        label="Fichier d'échantillon GENNO"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                        counter
                        :error-messages="ngsRunForm.errors.libraries_file"
                        accept=".csv"
                    >
                        <template v-slot:selection="{ text }">
                            <v-chip
                                color="primary"
                                dark
                                label
                                small
                            >
                                {{ text }}
                            </v-chip>
                        </template>
                    </v-file-input>
                    <v-btn
                        color="primary"
                        x-large
                        dark
                        depressed
                        block
                        :loading="ngsRunForm.processing"
                        @click="create()"
                    >Créer
                    </v-btn>
                </template>
                <template v-slot:step-2>
                    <v-row class="mt-2">
                        <v-col>
                            <element-row label="Opérateur">
                                {{ operator }}
                            </element-row>
                        </v-col>
                        <v-col>
                            <element-row label="Date">
                                {{ formatDate(ngsRunForm.date) }}
                            </element-row>
                        </v-col>
                        <v-col>
                            <element-row label="Fichier GENNO">
                                {{ filename }}
                            </element-row>
                        </v-col>
                    </v-row>
                    <v-divider class="my-3"></v-divider>
                    <h4 class="my-5">Fichier GENNO</h4>

                    <v-data-table
                        :headers="headers"
                        :items="sampleForm.samples"
                        item-key="id"
                        hide-default-footer
                        disable-sort
                        :loading="loading"
                        no-data-text="Aucun échantillon trouvé dans le fichier"
                        disable-pagination
                    >
                        <template v-slot:item.tumor_type_id="{ item }">
                            <v-autocomplete
                                v-model="item.tumor_type_id"
                                label="Diagnostic"
                                :items="tumorTypes"
                                item-text="shortname"
                                :error-messages="sampleForm.errors[retrieveKey(item.id, 'tumor_type_id')]"
                                item-value="id"
                                color="primary"
                                hide-details
                                outlined
                                dense
                            ></v-autocomplete>
                        </template>
                        <template v-slot:item.tumor_cellularity_percentage="{ item }">
                            <!-- tumor_type_id: 15 = Non tumoral -->
                            <v-combobox
                                v-model="item.tumor_cellularity_percentage"
                                @input.native="item.tumor_cellularity_percentage = $event.srcElement.value"
                                :disabled="sampleForm.samples[retrieveKeyOnly(item.id)].tumor_type_id === 15"
                                label="Pourcentage"
                                :items="['Non-renseigné']"
                                :error-messages="sampleForm.errors[retrieveKey(item.id, 'tumor_cellularity_percentage')]"
                                :suffix="item.tumor_cellularity !== 'Non-renseigné' ? '%' : null"
                                color="primary"
                                hide-details
                                outlined
                                dense
                            ></v-combobox>
                        </template>
                        <template v-slot:item.conc_lib="{ item }">
                            <span>{{ item.conc_lib.toFixed(2) }}</span>
                        </template>
                        <template v-slot:item.qubit_precap="{ item }">
                            <span>{{ item.qubit_precap.toFixed(2) }}</span>
                        </template>
                    </v-data-table>
                    <v-btn
                        color="primary"
                        @click="submit()"
                        :loading="sampleForm.processing"
                        x-large
                        dark
                        depressed
                        block
                        class="mt-3"
                    >Valider le run
                    </v-btn>
                    <v-btn
                        color="error"
                        @click="deleteRun()"
                        x-large
                        dark
                        depressed
                        block
                        class="mt-3"
                    >Supprimer le run
                    </v-btn>
                </template>
            </stepper>
        </v-container>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator'
import Layout from '@/Layouts/Layout.vue'
import Stepper from '@/Components/generic/Stepper.vue'
import DatePicker from '@/Components/generic/DatePicker.vue'
import {DataTableHeader} from 'vuetify'
import {InertiaForm} from '@inertiajs/inertia-vue'
import ElementRow from '@/Components/generic/ElementRow.vue'
import route from 'ziggy-js'
import {TYPE} from 'vue-toastification'
import get from 'lodash/get'
import {NgsRun, Sample, TumorType} from "@/models";
import {NgsRunForm, NgsRunLibrariesForm} from "@/forms";
import {Page} from "@inertiajs/inertia";

@Component({
    components: {
        Layout,
        Stepper,
        DatePicker,
        ElementRow
    },
    metaInfo: {
        title: 'Ajouter un nouveau run NGS'
    }
})
export default class CreateNgsRun extends Vue {
    @Prop() readonly ngsRun?: NgsRun
    @Prop() readonly step!: string
    @Prop() readonly tumorTypes!: Array<TumorType>
    @Prop() readonly samples!: Array<Sample>

    @Ref('trigger') readonly trigger!: HTMLElement

    @Watch('ngsRunForm', {
        deep: true,
        immediate: true
    })
    onNgsRunUpdated (ngsRun: NgsRun) {
        if (ngsRun.id) {
            this.toggleCompletion(1, true)
        } else {
            this.toggleCompletion(1, false)
        }
    }

    @Watch('sampleForm.samples', {
        deep: true,
        immediate: true
    })
    onTumorTypeEqualsNonTumoral (samples: Array<Sample> | undefined) {
        if (samples !== undefined) {
            for (let i = 0; i < samples.length; i++) {
                if (this.sampleForm.samples[i].tumor_type_id === 15) { // tumor_type_id: 15 = Non tumoral
                    this.sampleForm.samples[i].tumor_cellularity_percentage = 0
                }
            }
        }
    }

    @Watch('sampleForm.samples', {
        deep: true,
        immediate: true
    })
    onTumorCellularityPercentageEqualsNonRenseigne (samples: Array<Sample>) {
        if (samples !== undefined) {
            for (let i = 0; i < samples.length; i++) {
                if (this.sampleForm.samples[i].tumor_cellularity_percentage === null) {
                    (this.sampleForm.samples[i].tumor_cellularity_percentage as any) = 'Non-renseigné'
                }
            }
        }
    }

    operator: null | string = (this.$page.props.operator as null|string)
    filename: null | string = (this.$page.props.filename as null|string)
    loading: boolean = false
    stepper: number = Number(this.step)
    steps: { [key: number]: { title: string; complete: boolean; hasErrors: boolean; }} = {
        1: {
            title: 'Nouveau run NGS',
            complete: true,
            hasErrors: false
        },
        2: {
            title: "Tableau d'échantillons",
            complete: false,
            hasErrors: false
        }
    }

    headers: Array<DataTableHeader> = [
        {
            text: 'NIP',
            value: 'nip',
            width: '6%',
            sortable: false
        },
        {
            text: 'DIAMIC',
            value: 'diamic',
            width: '7%',
            sortable: false
        },
        {
            text: 'ID GENNO',
            value: 'genno_number',
            width: '5%',
            sortable: false
        },
        {
            text: 'Laboratory number',
            value: 'laboratory_number',
            width: '10%',
            sortable: false
        },
        {
            text: 'Barcode',
            value: 'barcode',
            width: '6%',
            sortable: false
        },
        {
            text: 'Qubit Precap',
            value: 'qubit_precap',
            width: '5%',
            sortable: false
        },
        {
            text: 'Conc_Lib',
            value: 'conc_lib',
            width: '5%',
            sortable: false
        },
        {
            text: 'Panel',
            value: 'panel',
            width: '12%',
            sortable: false
        },
        {
            text: 'Diagnostic',
            value: 'tumor_type_id',
            width: '12%',
            sortable: false
        },
        {
            text: 'Cellularité tumorale',
            value: 'tumor_cellularity_percentage',
            width: '12%',
            sortable: false
        },
        {
            text: 'Commentaire',
            value: 'comment',
            width: '20%',
            sortable: false
        }
    ]

    ngsRunForm: InertiaForm<NgsRunForm> = this.$inertia.form({
        date: new Date().toISOString().substr(0, 10),
        libraries_file: null
    })

    sampleForm: InertiaForm<NgsRunLibrariesForm> = this.$inertia.form({
        id: get(this, 'ngsRun.id', null),
        samples: this.samples
    })

    get user () {
        return (this.$page.props.user as any)
    }

    toggleCompletion (step: number, value: boolean) {
        this.steps[step].complete = value
    }

    retrieveKey (id: number, field: string): string {
        return `samples.${this.sampleForm.samples.findIndex((element) => element.id === id)}.${field}`
    }

    retrieveKeyOnly (id: number): number {
        return this.sampleForm.samples.findIndex((element) => element.id === id)
    }

    create () {
        this.ngsRunForm.post(
            route('ngs_runs.store'),
            {
                onSuccess: (page: Page) => {
                    this.sampleForm.id = (page.props.flash as any).data.id
                    this.sampleForm.samples = (page.props.flash as any).data.libraries
                    this.operator = (page.props.flash as any).data.operator
                    this.filename = (page.props.flash as any).data.filename
                    this.stepper = 2
                    this.toggleCompletion(1, true)
                    this.$toast('Run NGS créé', {
                        type: TYPE.SUCCESS
                    })
                },
                onError: () => {
                    this.toggleCompletion(1, true)
                }
            }
        )
    }

    submit () {
        this.$nextTick(() => {
            this.sampleForm.patch(
                route('ngs_runs.libraries.update', { ngs_run: this.sampleForm.id }),
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: () => {
                        this.$inertia.visit(
                            route('ngs_runs.show', { ngs_run: this.sampleForm.id })
                        )
                    },
                    onError: () => {
                        this.toggleCompletion(2, false)
                    }
                }
            )
        })
    }

    deleteRun () {
        this.$nextTick(() => {
            this.$inertia.delete(route('ngs_runs.destroy', {ngs_run: this.sampleForm.id}), {
                onSuccess: () => {
                    this.$toast('Run NGS correctement supprimé', {
                        type: TYPE.ERROR
                    })
                }
            })
        })
    }
}
</script>

<style scoped lang="scss">
.hidden {
    display: none;
}
</style>
