<template>
    <v-row dense>
        <v-col>
            <v-progress-linear
                v-if="status"
                :value="statusList[status].percentage"
                :height="dense ? '10' : '25'"
                rounded
                :color="`${statusList[status].color} darken-4`"
            >
                <span v-if="!dense" class="white--text font-weight-bold">{{ statusList[status].text }}</span>
            </v-progress-linear>
            <div v-if="dense" class="text-center black--text">{{ statusList[status].text }}</div>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NgsAnalysisStatusProgress extends Vue {
    @Prop() readonly status!: string
    @Prop() readonly dense?: boolean

    statusList: { [key: string]: { text: string; percentage: number; color: string; }} = {
        loading: {
            text: 'En chargement',
            percentage: 25,
            color: 'secondary darken-4'
        },
        analysed: {
            text: 'À annoter',
            percentage: 50,
            color: 'secondary darken-4'
        },
        first_review: {
            text: '1ère lecture faite',
            percentage: 75,
            color: 'secondary darken-4'
        },
        reported: {
            text: 'Rapport créé',
            percentage: 100,
            color: 'secondary darken-4'
        },
        ignored: {
            text: 'À ignorer',
            percentage: 100,
            color: 'secondary darken-4'
        },
        error: {
            text: 'Erreur',
            percentage: 100,
            color: 'error darken-1'
        }
    }
}
</script>

<style scoped lang="scss">
</style>
