<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            item-key="uniqueKey(item)"
            :footer-props="footerProps"
            :server-items-length="serverItemsLength"
            :loading="loading"
            :items-per-page="Number(syncedItemsPerPage)"
            :page="Number(syncedPage)"
            :expanded.sync="expandedItems"
            disable-sort
            show-expand
            :item-class="positionRowClass"
            no-data-text="Aucun résultat ne correspond à votre recherche"
            @update:page="updatePage"
            @update:items-per-page="updateItemsPerPage"
        >
            <template v-slot:body.prepend>
                <tr v-if="$vuetify.breakpoint.mdAndUp">
                    <td></td>
                    <td>
                        <v-row no-gutters>
                            <v-col cols="4">
                                <v-select
                                    v-model="filters.chromosome_id"
                                    label="Chromosome"
                                    :items="chromosomes"
                                    item-value="id"
                                    item-text="name"
                                    class="mr-1"
                                    outlined
                                    dense
                                    clearable
                                    hide-details
                                >
                                    <template v-slot:append-outer>
                                        :
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    v-model="filters.start_position"
                                    min="0"
                                    label="Début"
                                    class="mx-1"
                                    outlined
                                    dense
                                    hide-details
                                    type="number"
                                    step="100"
                                >
                                    <template v-slot:append-outer>
                                        -
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    v-model="filters.end_position"
                                    min="0"
                                    label="Fin"
                                    class="ml-1"
                                    outlined
                                    dense
                                    hide-details
                                    type="number"
                                    step="100"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </td>
                    <td>
                        <v-row no-gutters>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="filters.min_size"
                                    min="0"
                                    label="Min"
                                    type="number"
                                    step="100"
                                    class="mr-1"
                                    outlined
                                    dense
                                    hide-details
                                >
                                    <template v-slot:append-outer>
                                        -
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="filters.max_size"
                                    min="0"
                                    label="Max"
                                    type="number"
                                    step="100"
                                    class="ml-1"
                                    outlined
                                    dense
                                    hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </td>
                    <td>
                        <v-select
                            :items="alterations"
                            v-model="filters.alteration_id"
                            label="Contexte"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            clearable
                            hide-details
                        ></v-select>
                    </td>
                    <td>
                        <v-row no-gutters>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="filters.min_copy"
                                    min="0"
                                    max="10"
                                    label="Min"
                                    type="number"
                                    class="mr-1"
                                    outlined
                                    dense
                                    hide-details
                                >
                                    <template v-slot:append-outer>
                                        -
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model.number="filters.max_copy"
                                    min="0"
                                    max="10"
                                    label="Max"
                                    type="number"
                                    class="ml-1"
                                    outlined
                                    dense
                                    hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </td>
                    <td></td>
                    <td>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="resetFilters()" v-on="on" icon>
                                    <v-icon>mdi-filter-remove-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Réinitialiser les filtres</span>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
            <template v-slot:item.position="{ item }">
                {{ item.full_location }}
                <v-chip label small color="secondary" class="mr-1">{{ item.cytoband }}</v-chip>
                <v-tooltip top v-if="armAlteration(item, 'start')['label'] !== 'Unknown'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip v-bind="attrs"
                                v-on="on"
                                label
                                small
                                class="font-weight-bold"
                                :color="armAlteration(item, 'start')['color']"
                                dark
                        >{{ item.arm_start.name }}
                        </v-chip>
                    </template>
                    <span>{{ armAlteration(item, 'start')['label'] }}</span>
                </v-tooltip>
                <v-tooltip top
                           v-if="item.arm_start_id !== item.arm_end_id && armAlteration(item, 'end')['label'] !== 'Unknown'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip v-bind="attrs"
                                v-on="on"
                                label
                                small
                                class="font-weight-bold"
                                :color="armAlteration(item, 'end')['color']"
                                dark
                        >{{ item.arm_end.name }}
                        </v-chip>
                    </template>
                    <span>{{ armAlteration(item, 'end')['label'] }}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.length="{ item }">
                {{ formatNumber(item.size) }} kb
            </template>
            <template v-slot:item.context="{ item }">
                {{ alterationIdentifier(item.context.alteration_id) }}
            </template>
            <template v-slot:item.copy_number="{ item }">
                {{ item.context.copy_number }}
            </template>
            <template v-slot:item.genes_number="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                    <span v-on="on">
                        <span :class="item.other_genes_count > 0 ? 'font-weight-bold' : ''">
                            {{ item.annotated_genes_count }}
                        </span>
                        /
                        <span :class="item.other_genes_count > 0 ? 'font-weight-bold' : ''">
                            {{ item.database_genes_count }}
                        </span>
                        /
                        <span>
                            {{ item.other_genes_count }}
                        </span>
                    </span>
                    </template>
                    <span>Annotés / Base de données / Autres</span>
                </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn
                    @click="annotateSegment(item)"
                    icon
                    color="info"
                    :disabled="readonly"
                >
                    <v-rating
                        :value="annotationState(item)"
                        background-color="info lighten-1"
                        color="info"
                        length="1"
                        half-increments
                        readonly
                    ></v-rating>
                </v-btn>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <segment-expanded-row
                    class="black-border"
                    :item="item"
                    :expanded="item.database_genes_count > 0 || item.annotated_genes_count > 0"
                    :gene="filters.gene"
                    :headers-length="$vuetify.breakpoint.mdAndUp ? headers.length : 1"
                    :filter="filters.gene !== null && filters.gene !== ''"
                    :readonly="readonly"
                    :search="search"
                ></segment-expanded-row>
            </template>
        </v-data-table>
        <cnv-annotate-element-dialog
            v-model="annotationDialogOpen"
            type="segment"
            :cnv-analysis-id="cnvAnalysisId"
            :item="selectedSegment"
            :alteration-id="selectedAlterationId"
            @close="resetSelectedItem()"
        ></cnv-annotate-element-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, PropSync, VModel, Vue, Watch} from 'vue-property-decorator'
import mapValues from 'lodash/mapValues'
import SegmentExpandedRow from '@/Components/domain-specific/segment/SegmentExpandedRow.vue'
import CnvAnnotateElementDialog from '@/Components/domain-specific/cnv/dialogs/CnvAnnotateElementDialog.vue'
import throttle from 'lodash/throttle'
import isEmpty from 'lodash/isEmpty'
import {Chromosome, CnvAlteration, CnvAnalysis, Segment, User} from "@/models";
import {DataTableHeader} from "vuetify";

@Component({
    components: {
        CnvAnnotateElementDialog,
        SegmentExpandedRow
    }
})
export default class SegmentTable extends Vue {
    @VModel() filters!: {
        filter: string | null,
        chromosome_id: any,
        start_position: string | null,
        end_position: string | null,
        min_size: string | null,
        max_size: string | null,
        alteration_id: any,
        min_copy: string | null,
        max_copy: string | null,
        page: string | null,
        gene: string | null,
        gene_type: string | null,
        only_annotated: string | null,
        'items-per-page': string | null
    }
    @Prop() readonly items!: Array<Segment>
    @Prop({default: false}) readonly loading!: boolean
    @Prop() readonly serverItemsLength!: number
    @PropSync('page') syncedPage!: number
    @PropSync('itemsPerPage') syncedItemsPerPage!: number
    @PropSync('expanded') syncedExpanded!: boolean
    @Prop({default: false}) readonly readonly?: boolean
    @Prop({default: false}) readonly search?: boolean

    @Watch('loading')
    onExpansion(loading: boolean, previousState: boolean) {
        if (loading === false && previousState === true) {
            if (this.filters.gene !== '' && this.filters.gene) {
                this.expandedItems = this.items
            } else {
                this.expandedItems = this.items.filter(item => item.database_genes_count > 0 || item.annotated_genes_count > 0)
            }
            this.expandedSubRows = true
        } else {
            this.expandedItems = []
            this.expandedSubRows = false
        }
    }

    annotationDialogOpen: boolean = false
    expandedSubRows: boolean = false
    selectedSegment: Segment | null = null
    selectedAlterationId: number | null = null
    expandedItems: Array<Segment> = []
    footerProps: { [key: string]: Array<number> | boolean } = {
        'items-per-page-options': [30, 50, 100],
        'show-current-page': true,
        'show-first-last-page': true
    }

    headers: Array<DataTableHeader> = [
        {text: 'Position', width: '40%', value: 'position'},
        {text: 'Longueur', width: '15%', value: 'length'},
        {text: 'Contexte', width: '15%', value: 'context'},
        {text: 'Nombre de copies', width: '15%', value: 'copy_number'},
        {text: 'Nombre de gènes', width: '10%', value: 'genes_number'},
        {text: 'Actions', width: '5%', value: 'actions'}
    ];

    get chromosomes(): Array<Chromosome> {
        return (this.$page.props.chromosomes as Array<Chromosome>)
    }

    get alterations(): Array<CnvAlteration> {
        return [
            {
                "id": 2,
                "name": "Perte homozygote",
                "class": "loss",
                "level": "segment"
            },
            {
                "id": 4,
                "name": "Perte hétérozygote",
                "class": "loss",
                "level": "segment"
            },
            {
                "id": 5,
                "name": "LOH",
                "class": "loss",
                "level": "segment"
            },
            {
                "id": 3,
                "name": "Gain",
                "class": "gain",
                "level": "segment"
            },
            {
                "id": 1,
                "name": "Amplification",
                "class": "gain",
                "level": "segment"
            },
        ]
    }

    get cnvAnalysisId(): number {
        return (this.$page.props.cnvAnalysis as CnvAnalysis).id
    }

    uniqueKey(segment: Segment): string
    {
        return `${segment.id}-${(segment as any).context.alteration_id}`
    }

    alterationIdentifier(id: number): string | null {
        if (this.alterations) {
            const alteration = this.alterations.find(alteration => alteration.id === id)
            return alteration ? alteration.name : null
        }
        return null
    }

    resetFilters() {
        this.filters = mapValues(this.filters, () => null)
    }

    updatePage(e: Event): void {
        this.$emit('pagination', {
            page: e,
            itemsPerPage: this.syncedItemsPerPage
        })
        this.expandedItems = []
        this.$cancelToken.source().cancel()
    }

    updateItemsPerPage(e: Event): void {
        this.$emit('pagination', {
            page: 1,
            itemsPerPage: e
        })
        this.expandedItems = []
        this.$cancelToken.source().cancel()
    }

    annotateSegment(item: Segment) {
        if (!this.readonly) {
            this.selectedSegment = item
            this.selectedAlterationId = (item as any).context.alteration_id
            this.annotationDialogOpen = true
        }
    }

    resetSelectedItem() {
        this.selectedSegment = null
        this.selectedAlterationId = null
    }

    expandRows() {
        this.expandedItems = this.items.filter(item => item.annotated_genes_count > 0 || item.database_genes_count > 0)
    }

    annotationState(segment: Segment) {
        if ((segment as any).is_partially_annotated) {
            return 0.5
        }
        if ((segment as any).is_annotated) {
            return 1
        }
        return 0
    }

    isEmpty(value?: any) {
        return isEmpty(value)
    }

    armAlteration(item: Segment, prefix: 'start' | 'end') {
        if ((item as any)[`is_on_amplified_${prefix}_arm`]) {
            return {
                label: 'Amplification',
                color: 'indigo'
            }
        }
        if ((item as any)[`is_on_gained_${prefix}_arm`]) {
            return {
                label: 'Gain',
                color: 'blue'
            }
        }
        if ((item as any)[`is_on_loh_${prefix}_arm`]) {
            return {
                label: 'LOH',
                color: 'purple'
            }
        }
        if ((item as any)[`is_on_loss_${prefix}_arm`]) {
            return {
                label: 'Perte',
                color: 'red'
            }
        }
        return {
            label: 'Unknown',
            color: 'grey'
        }
    }

    positionRowClass() {
        return 'position-row'
    }

    created() {
        this.expandRows = throttle(this.expandRows, 150)
        this.expandRows()
    }
}
</script>

<style lang="scss">
.position-row {
    background-color: #E0E0E0;
}

.position-row:hover {
    background-color: #E0E0E0 !important;
}

.shares_left {
    background-image: linear-gradient(to right, #9c27b0, white) !important;
}

.shares_right {
    background-image: linear-gradient(to left, #9c27b0, white) !important;
}

:deep(.v-data-table__expand-icon) {
    transform: rotate(0deg);
}

:deep(.v-data-table__expand-icon--active) {
    transform: rotate(-90deg);
}
</style>
