<template>
    <dialog-display
        v-model="isDialogOpen"
        :title="`Altérations CNV pour le gène ${gene}`"
        :max-width="800"
        button-class="primary"
    >
        <v-data-table
            :headers="headers"
            :items="segments"
            disable-pagination
            disable-sort
            disable-filtering
            hide-default-footer
        >
            <template v-slot:item.actions="{ item }">
                <v-btn class="ml-3" color="primary" icon
                       @click="$visit('cnv_analyses.show', {cnv_analysis: item.context.cnv_analysis_id, _query: { gene: gene, filter: 'search', page: '1' } })"
                       target="_blank">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </dialog-display>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue} from 'vue-property-decorator'
import DialogDisplay from '@/Components/generic/DialogDisplay.vue'
import {Segment} from "@/models";
import {DataTableHeader} from "vuetify";

@Component({
    components: {
        DialogDisplay
    }
})
export default class NgsCnvAnnotationDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly segments!: Array<Segment>
    @Prop() readonly gene!: string

    headers: Array<DataTableHeader> = [
        {
            text: 'Altération',
            value: 'context.alteration.name'
        },
        {
            text: 'Position',
            value: 'full_location'
        },
        {
          text: 'Niveau',
          value: 'context.alteration.level'
        },
        {
            text: 'Actions',
            value: 'actions'
        }
    ]
}
</script>

<style lang="scss" scoped>

</style>
