<template>
    <div>
        <div class="pa-5">
            <div class="mb-5 font-weight-bold">Lectures et rapports</div>
            <element-row label="Lectures">
                <div v-if="ngsAnalysis.review">
                    <p>1ère lecture par: <span
                        class="font-weight-bold my-0">{{ ngsAnalysis.review.reviewer.name }}</span>
                        <span class="grey--text">
                                            ({{ formatDate(ngsAnalysis.review.created_at) }})
                                        </span>
                    </p>
                    <p v-if="$get(ngsAnalysis, 'review.review_comment', false)" class="grey--text">
                        Commentaire: {{ ngsAnalysis.review.review_comment }}
                    </p>
                    <ngs-review-request-list
                        v-if="ngsAnalysis.review.reviewer !== null && ngsAnalysis.review.validator === null && ngsAnalysis.review.review_requests.length > 0"
                        :review-requests="ngsAnalysis.review.review_requests"
                    ></ngs-review-request-list>
                    <p v-if="ngsAnalysis.review.hasOwnProperty('validator_id') && ngsAnalysis.review.validator_id !== null">
                        2ème lecture par: <span
                        class="font-weight-bold my-0">{{ ngsAnalysis.review.validator.name }}</span>
                        <span class="grey--text">
                                            ({{ formatDate(ngsAnalysis.review.updated_at) }})
                                        </span>
                    </p>
                    <p v-if="$get(ngsAnalysis, 'review.validation_comment', false)"
                       class="grey--text">
                        Commentaire: {{ ngsAnalysis.review.validation_comment }}
                    </p>
                </div>
            </element-row>
        </div>
        <div class="pa-5">
            <element-row label="Rapports">
                <span v-if="$page.props.errors.report" class="error--text">{{ Array.isArray($page.props.errors.report) ? $page.props.errors.report.toString() : $page.props.errors.report }}</span>
                <p v-for="(report, index) in ngsAnalysis.reports" :key="index" class="mb-0">
                    <span :class="report.invalided_at ? 'text--disabled' : null">{{ report.name }} {{ report.invalided_at !== null ? ` (${formatDate(report.invalided_at)})` : null}}</span>
                    <v-btn
                        color="primary"
                        icon
                        :href="type === 'simple' ? $route('ngs_analyses.download_report', {ngs_analysis: ngsAnalysis.id, analysis_report: report.id}) : $route('ngs_pair_analyses.download_report', {ngs_pair_analysis: ngsAnalysis.id, analysis_report: report.id})"
                    >
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <v-tooltip bottom v-if="report.invalided_at === null">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="success"
                                v-bind="attrs"
                                v-on="on"
                            >mdi-check-circle
                            </v-icon>
                        </template>
                        <span>Rapport valide</span>
                    </v-tooltip>
                </p>
            </element-row>
        </div>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import {NgsAnalysis, NgsPairAnalysis} from "@/models";
import NgsReviewRequestList from "@/Components/domain-specific/ngs/helpers/NgsReviewRequestList.vue";

@Component({
    components: {
        NgsReviewRequestList,
        ElementRow
    }
})
export default class NgsReportsSection extends Vue {
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'
}
</script>

<style lang="scss" scoped>

</style>
