<template>
    <v-data-table
        v-model="selectedLibraries"
        :headers="headers"
        :items="filteredItems"
        item-key="id"
        :loading="loading"
        :footer-props="footerProps"
        :server-items-length="serverItemsLength"
        :page="syncedPage"
        :items-per-page="syncedItemsPerPage"
        @update:page="updatePage"
        @update:items-per-page="updateItemsPerPage"
        selectable-key="selectable"
        no-data-text="Aucun échantillon"
        dense
        show-select
        single-select
        disable-sort
    >
        <template v-slot:item.run.date="{ item }">
            {{ formatDate(item.run.date) }}<br/>
        </template>
    </v-data-table>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, PropSync, VModel, Vue} from 'vue-property-decorator'
import {DataTableHeader} from 'vuetify'
import {NgsLibrary} from "@/models";

@Component
export default class NgsSelectableLibraryPairsTable extends Vue {
    @VModel() selectedLibraries!: Array<NgsLibrary>
    @PropSync('page') syncedPage!: number
    @PropSync('itemsPerPage') syncedItemsPerPage!: number
    @Prop() items!: Array<NgsLibrary>
    @Prop({ default: false }) readonly loading!: boolean
    @Prop() readonly serverItemsLength!: number
    @Prop() readonly ignoreId!: number

    footerProps: { [key: string]: Array<number> } = {
        'items-per-page-options': [10, 25, 50, 100]
    }

    headers: Array<DataTableHeader> = [
        {
            text: 'Date',
            value: 'run.date',
            width: '20%',
        },
        {
            text: 'DIAMIC',
            value: 'sample.diamic',
            width: '20%',
        },
        {
            text: 'Laboratoire',
            value: 'sample.laboratory_number',
            width: '25%',
        },
        {
            text: 'Barcode',
            value: 'barcode.name',
            width: '10%',
        },
        {
            text: 'Diagnostic',
            value: 'sample.tumor_type.shortname',
            width: '25%',
        }
    ]

    get filteredItems(): NgsLibrary[] {
        return this.items.map((item: NgsLibrary) => {
            (item as any).selectable = item.id !== this.ignoreId
            return item
        })
    }

    updatePage (e: Event): void {
        this.$emit('pagination', {
            page: e,
            itemsPerPage: this.syncedItemsPerPage
        })
    }

    updateItemsPerPage (e: Event): void {
        this.$emit('pagination', {
            page: 1,
            itemsPerPage: e
        })
    }
}
</script>

<style scoped lang="scss">
</style>
