<template>
    <layout :fill="true">
        <v-row align="center"
               justify="center">
            <v-col class="text-center">
                <p class="icon-big">🔒</p>
                <p class="text-h5 font-weight-bold">L'analyse NGS #{{ ngsAnalysis.id }} a été temporairement bloquée par un administrateur.</p>
            </v-col>
        </v-row>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Layout from '@/Layouts/Layout.vue'
import {NgsAnalysis} from "@/models";

@Component({
    components: {
        Layout
    },
    metaInfo: {
        title: 'Analyse bloquée'
    }
})
export default class LockedNgsAnalysis extends Vue {
    @Prop() readonly ngsAnalysis!: NgsAnalysis
}
</script>

<style scoped>
.icon-big {
    font-size: 5rem;
}
</style>
