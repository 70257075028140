<template>
    <div>
        <div class="px-5 pt-5 font-weight-bold">Informations générales</div>
        <div class="pa-5" v-if="type === 'simple'">
            <element-row v-if="ngsAnalysis.is_archived === true" label="État">
                <v-chip label>
                    <v-icon class="mr-2">mdi-archive-outline</v-icon>
                    Analyse archivée
                </v-chip>
            </element-row>
            <element-row label="Date du run">
                {{ formatDate(ngsAnalysis.run.date) }} ({{ $get(ngsAnalysis, 'run.operator.name') }})
            </element-row>
            <element-row label="Statut">
                <ngs-analysis-status-progress :status="ngsAnalysis.ngs_analysis_status"></ngs-analysis-status-progress>
            </element-row>
            <element-row label="Dernière modification">
                {{ formatDate(ngsAnalysis.updated_at, true) }}
            </element-row>
            <element-row label="Opérateur">
                {{ ngsAnalysis.operator.name }}
            </element-row>
        </div>
        <div class="pa-5" v-if="type === 'pair'">
            <element-row v-if="ngsAnalysis.is_archived === true" label="État">
                <v-chip label>
                    <v-icon class="mr-2">mdi-archive-outline</v-icon>
                    Analyse archivée
                </v-chip>
            </element-row>
            <element-row label="Date du run">
                {{ formatDate(ngsAnalysis.tumoral_run.date) }} ({{ $get(ngsAnalysis, 'tumoral_run.operator.name') }})
            </element-row>
            <element-row label="Statut">
                <ngs-analysis-status-progress :status="ngsAnalysis.ngs_analysis_status"></ngs-analysis-status-progress>
            </element-row>
            <element-row label="Dernière modification">
                {{ formatDate(ngsAnalysis.updated_at, true) }}
            </element-row>
            <element-row label="Opérateur">
                {{ ngsAnalysis.operator.name }}
            </element-row>
        </div>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import NgsAnalysisStatusProgress from "@/Components/domain-specific/ngs/helpers/NgsAnalysisStatusProgress.vue";
import {NgsAnalysis, NgsPairAnalysis} from "@/models";

@Component({
    components: {
        NgsAnalysisStatusProgress,
        ElementRow
    }
})
export default class NgsGeneralInfoSection extends Vue {
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'
}
</script>

<style lang="scss" scoped>
</style>
