<template>
    <layout>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <panel title="Informations générales">
                        <template v-slot:actions>
                            <btn
                                @click="openDialogEditSample()"
                                color="white"
                                icon="mdi-pencil"
                                :small="true"
                                class="mr-1"
                            >
                                Éditer
                            </btn>
                        </template>
                        <div class="pa-5">
                            <v-row dense>
                                <v-col cols="12">
                                    <p class="text-h6 font-weight-bold">{{ sample.full_diamic }}</p>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <element-row label="N° DIAMIC">{{ sample.diamic }}
                                    </element-row>
                                    <element-row label="Bloc" v-if="sample.diamic_suffix">
                                        {{ sample.diamic_suffix }}
                                    </element-row>
                                    <element-row label="N° prélèvement">
                                        {{ sample.laboratory_number }}
                                    </element-row>
                                    <element-row label="N° demande GENNO">
                                        {{ sample.genno_number }}
                                    </element-row>
                                    <element-row label="NIP">
                                        {{ sample.nip }}
                                    </element-row>
                                    <element-row label="Diagnostic">
                                        {{ $get(sample, 'tumor_type.name') }}
                                    </element-row>
                                    <element-row label="Pourcentage de la tumeur">
                                        {{
                                            sample.tumor_cellularity_percentage ? sample.tumor_cellularity_percentage : 'NA'
                                        }}% (Histologie)
                                    </element-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <p class="text-h6 font-weight-bold">Analyses CNV
                                        <v-chip small>{{ cnvAnalyses.length }}</v-chip>
                                        <v-btn icon class="ml-1" color="primary"
                                               @click="isCnvAnalysesShown=!isCnvAnalysesShown">
                                            <v-icon>{{
                                                    isCnvAnalysesShown ? 'mdi-chevron-right' : 'mdi-chevron-down'
                                                }}
                                            </v-icon>
                                        </v-btn>
                                    </p>
                                </v-col>
                                <v-col cols="12" v-if="isCnvAnalysesShown">
                                    <v-data-table
                                        :headers="cnvAnalysesHeaders"
                                        :items="cnvAnalyses"
                                        disable-pagination
                                        disable-sort
                                        hide-default-footer
                                    >
                                        <template v-slot:item.date="{ item }">
                                            {{ formatDate(item.date) }}
                                        </template>
                                        <template v-slot:item.has_passed_quality_control="{ item }">
                                            <v-icon
                                                :color="qualityControl[item.has_passed_quality_control].color" class="my-auto mr-1">
                                                {{ qualityControl[item.has_passed_quality_control].icon }}
                                            </v-icon>
                                        </template>
                                        <template v-slot:item.actions="{ item }">
                                            <div class="text-center">
                                                <v-btn @click="$visit('cnv_analyses.show', item.id)" icon>
                                                    <v-icon color="primary">mdi-arrow-right</v-icon>
                                                </v-btn>

                                            </div>
                                        </template>
                                        <template v-slot:item.cnv_analysis_status="{ item }">
                                            <cnv-status-progress :status="item.cnv_analysis_status"></cnv-status-progress>
                                        </template>
                                        <template v-slot:item.segments_count="{ item }">
                                            {{ item.has_passed_quality_control ? item.segments_count : '-' }}
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <p class="text-h6 font-weight-bold">Analyses NGS
                                        <v-chip small>{{ ngsAnalyses.length }}</v-chip>
                                        <v-btn icon class="ml-1" color="primary"
                                               @click="isNgsAnalysesShown=!isNgsAnalysesShown">
                                            <v-icon>{{
                                                    isNgsAnalysesShown ? 'mdi-chevron-right' : 'mdi-chevron-down'
                                                }}
                                            </v-icon>
                                        </v-btn>
                                    </p>
                                </v-col>
                                <v-col cols="12" v-if="isNgsAnalysesShown">
                                    <v-data-table
                                        :headers="ngsAnalysesHeaders"
                                        :items="ngsAnalyses"
                                        disable-pagination
                                        disable-sort
                                        hide-default-footer
                                    >
                                        <template v-slot:item.date="{ item }">
                                            {{ formatDate(item.created_at) }}
                                        </template>
                                        <template v-slot:item.external_id="{ item }">
                                            <span class="text--disabled">NA</span>
                                        </template>
                                        <template v-slot:item.panels="{ item }">
                                            {{ item.panel.name }}
                                        </template>
                                        <template v-slot:item.libraries_count="{ item }">
                                            <span class="text--disabled">NA</span>
                                        </template>
                                        <template v-slot:item.qc_status="{ item }">
                                            <span class="text--disabled">NA</span>
                                        </template>
                                        <template v-slot:item.ngs_run_status="{ item }">
                                            <ngs-analysis-status-progress :status="item.ngs_analysis_status"></ngs-analysis-status-progress>
                                        </template>
                                        <template v-slot:item.actions="{ item }">
                                            <div class="text-center">
                                                <v-btn @click="$visit('ngs_analyses.show', {ngs_analysis: item.id})" icon>
                                                    <v-icon color="primary">mdi-arrow-right</v-icon>
                                                </v-btn>
                                            </div>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <p class="text-h6 font-weight-bold">Analyses NGS T/NT
                                        <v-chip small>{{ ngsPairAnalyses.length }}</v-chip>
                                        <v-btn icon class="ml-1" color="primary"
                                               @click="isNgsPairAnalysesShown=!isNgsPairAnalysesShown">
                                            <v-icon>{{
                                                    isNgsPairAnalysesShown ? 'mdi-chevron-right' : 'mdi-chevron-down'
                                                }}
                                            </v-icon>
                                        </v-btn>
                                    </p>
                                </v-col>
                                <v-col cols="12" v-if="isNgsPairAnalysesShown">
                                    <v-data-table
                                        :headers="ngsPairAnalysesHeaders"
                                        :items="ngsPairAnalyses"
                                        disable-pagination
                                        disable-sort
                                        hide-default-footer
                                    >
                                        <template v-slot:item.date="{ item }">
                                            {{ formatDate(item.created_at) }}
                                        </template>
                                        <template v-slot:item.external_id="{ item }">
                                            <span class="text--disabled">NA</span>
                                        </template>
                                        <template v-slot:item.panels="{ item }">
                                            {{ item.tumoral_panel.name }}
                                        </template>
                                        <template v-slot:item.libraries_count="{ item }">
                                            <span class="text--disabled">NA</span>
                                        </template>
                                        <template v-slot:item.qc_status="{ item }">
                                            <span class="text--disabled">NA</span>
                                        </template>
                                        <template v-slot:item.ngs_run_status="{ item }">
                                            <ngs-analysis-status-progress :status="item.ngs_analysis_status"></ngs-analysis-status-progress>
                                        </template>
                                        <template v-slot:item.actions="{ item }">
                                            <div class="text-center">
                                                <v-btn @click="$visit('ngs_pair_analyses.show', {ngs_pair_analysis: item.id})" icon>
                                                    <v-icon color="primary">mdi-arrow-right</v-icon>
                                                </v-btn>
                                            </div>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </div>
                    </panel>
                </v-col>
            </v-row>
        </v-container>
        <edit-sample-dialog v-model="isEditSampleDialogOpen" :sample="sample" :tumor-types="tumorTypes"></edit-sample-dialog>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Layout from "@/Layouts/Layout.vue";
import Panel from "@/Components/generic/Panel.vue";
import ElementRow from "@/Components/generic/ElementRow.vue";
import {DataTableHeader} from "vuetify";
import CnvStatusProgress from "@/Components/domain-specific/cnv/helpers/CnvStatusProgress.vue";
import NgsAnalysisStatusProgress from "@/Components/domain-specific/ngs/helpers/NgsAnalysisStatusProgress.vue";
import Btn from "@/Components/generic/Btn.vue";
import EditSampleDialog from "@/Components/domain-specific/sample/dialogs/EditSampleDialog.vue";
import {CnvAnalysis, NgsAnalysis, NgsPairAnalysis, Sample, TumorType} from "@/models";

@Component({
    components: {EditSampleDialog, Btn, NgsAnalysisStatusProgress, CnvStatusProgress, ElementRow, Panel, Layout},
    metaInfo: {
        title: 'Échantillon'
    }
})
export default class ShowSample extends Vue {
    @Prop() readonly sample!: Sample
    @Prop() readonly cnvAnalyses!: Array<CnvAnalysis>
    @Prop() readonly ngsAnalyses!: Array<NgsAnalysis>
    @Prop() readonly ngsPairAnalyses!: Array<NgsPairAnalysis>
    @Prop() readonly tumorTypes!: Array<TumorType>

    isCnvAnalysesShown: boolean = false
    isNgsAnalysesShown: boolean = false
    isNgsPairAnalysesShown: boolean = false
    isEditSampleDialogOpen: boolean = false

    qualityControl: { [key: string]: { color: string; icon: string; text: string; } } = {
        true: {
            color: 'success',
            icon: 'mdi-check-circle',
            text: 'PASS'
        },
        false: {
            color: 'error',
            icon: 'mdi-close-circle',
            text: 'FAILED'
        },
        null: {
            color: 'grey',
            icon: 'mdi-help-circle',
            text: 'NA'
        }
    }

    cnvAnalysesHeaders: Array<DataTableHeader> = [
        {
            text: '#',
            value: 'id',
            width: '10%'
        },
        {
            text: 'Date',
            value: 'date',
            width: '10%'
        },
        {
            text: 'Opérateur',
            value: 'operator.name',
            width: '15%'
        },
        {
            text: 'Nom',
            value: 'name',
            width: '20%'
        },
        {
            text: 'Nb segments',
            value: 'segments_count',
            width: '10%'
        },
        {
            text: 'Statut QC',
            value: 'has_passed_quality_control',
            width: '10%',
            align: 'center'
        },
        {
            text: 'Statut',
            value: 'cnv_analysis_status',
            width: '15%'
        },
        {
            text: 'Actions',
            value: 'actions',
            width: '10%'
        }
    ]

    ngsAnalysesHeaders: Array<DataTableHeader> = [
        {
            text: '#',
            value: 'id',
            width: '5%'
        },
        {
            text: 'Date',
            value: 'date',
            width: '5%'
        },
        {
            text: 'Opérateur',
            value: 'operator.name',
            width: '15%'
        },
        {
            text: 'ID externe',
            value: 'external_id',
            width: '10%'
        },
        {
            text: 'Panel',
            value: 'panels',
            width: '20%'
        },
        {
            text: 'Nb échantillons',
            value: 'libraries_count',
            width: '10%'
        },
        {
            text: 'Statut QC',
            value: 'qc_status',
            width: '10%'
        },
        {
            text: 'Statut',
            value: 'ngs_run_status',
            width: '15%'
        },
        {
            text: 'Actions',
            value: 'actions',
            width: '5%'
        }
    ]

    ngsPairAnalysesHeaders: Array<DataTableHeader> = [
        {
            text: '#',
            value: 'id',
            width: '5%'
        },
        {
            text: 'Date',
            value: 'date',
            width: '5%'
        },
        {
            text: 'Opérateur',
            value: 'operator.name',
            width: '15%'
        },
        {
            text: 'ID externe',
            value: 'external_id',
            width: '10%'
        },
        {
            text: 'Panel',
            value: 'panels',
            width: '20%'
        },
        {
            text: 'Nb échantillons',
            value: 'libraries_count',
            width: '10%'
        },
        {
            text: 'Statut QC',
            value: 'qc_status',
            width: '10%'
        },
        {
            text: 'Statut',
            value: 'ngs_run_status',
            width: '15%'
        },
        {
            text: 'Actions',
            value: 'actions',
            width: '5%'
        }
    ]

    openDialogEditSample() {
        this.isEditSampleDialogOpen = true
    }
}
</script>

<style scoped>

</style>
