<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        :title="`Masquer le variant: ${variant.vep_annotation.hgvsg}`"
        :max-width="600"
        @cancel="cancel()"
        @validate="save()"
        :loading="form.processing"
    >
        <div class="pa-5">
            <v-row>
                <v-alert
                    text
                    dense
                    color="warning"
                    border="left"
                >
                    Attention, vous êtes sur le point de masquer ce variant globalement pour toutes les analyses. Pour restaurer un variant, veuillez envoyer un mail à oncobench-support@sib.swiss.
                </v-alert>
                <v-col cols="12">
                    <v-autocomplete
                        label="Déclaré par *"
                        v-model="form.reported_by"
                        :items="users"
                        item-text="name"
                        item-value="id"
                        :error-messages="form.errors.reported_by"
                        outlined
                    ></v-autocomplete>
                    <v-textarea
                        label="Justification *"
                        v-model="form.evidence"
                        rows="3"
                        :error-messages="form.errors.evidence"
                        outlined
                    ></v-textarea>
                    <v-autocomplete
                        label="Confirmé par *"
                        v-model="form.validated_by"
                        :items="filteredUsers"
                        item-text="name"
                        item-value="id"
                        :error-messages="form.errors.validated_by"
                        outlined
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </div>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import {TYPE} from 'vue-toastification'
import route from 'ziggy-js'
import {User, Variant} from "@/models";
import {MaskVariantForm} from "@/forms";

@Component({
    components: { DialogConfirmation }
})
export default class NgsMaskVariantDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly variant!: Variant

    @Watch('isDialogOpen', {
        immediate: true
    })
    onDialogOpening (val: boolean) {
        if (val) {
            this.fetchUsers()
            this.form.reset()
        }
    }

    loading: boolean = false
    form: InertiaForm<MaskVariantForm> = this.$inertia.form({
        reported_by: (this.$page.props.user as User).id,
        validated_by: null,
        evidence: null,
    })
    users: Array<User> = []

    cancel () {
        this.form.reset()
    }

    save () {
        this.form.put(
            route('variants.mask', { variant: this.variant.id }),
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: () => {
                    this.isDialogOpen = false
                    this.$toast('Le variant est désormais masqué dans toutes les analyses', {
                        type: TYPE.ERROR
                    })
                }
            }
        )
    }

    fetchUsers() {
        this.loading = true
        this.$axios.get(route('users.index')).then(({data}) => {
            this.users = data
        }).finally(() => {
            this.loading = false
        })
    }

    get filteredUsers (): Array<User> {
        return this.users.filter(user => user.id !== this.form.reported_by)
    }
}
</script>

<style lang="scss" scoped>

</style>
