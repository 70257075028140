<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        title="Créer une paire tumorale/non-tumorale"
        :max-width="1600"
        @validate="save()"
        @cancel="cancel()"
    >
        <div class="pa-5">
            <v-row>
                <v-col cols="6">
                    <ngs-libraries-filter-and-table
                        v-model="selectedTumoralLibraries"
                        :is-dialog-open="isDialogOpen"
                        :item="item"
                        title="Tissu tumoral"
                        type="tumoral"
                    ></ngs-libraries-filter-and-table>
                    <v-alert text color="error" v-if="form.errors.tumoral_library_id">{{ form.errors.tumoral_library_id }}</v-alert>
                </v-col>
                <v-col cols="6">
                    <ngs-libraries-filter-and-table
                        v-model="selectedNonTumoralLibraries"
                        :is-dialog-open="isDialogOpen"
                        :item="item"
                        title="Tissu non-tumoral"
                        type="non-tumoral"
                    ></ngs-libraries-filter-and-table>
                    <v-alert text color="error" v-if="form.errors.non_tumoral_library_id">{{ form.errors.non_tumoral_library_id }}</v-alert>
                </v-col>
            </v-row>
        </div>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import {TYPE} from 'vue-toastification'
import route from 'ziggy-js'
import get from "lodash/get";
import NgsLibrariesFilterAndTable from "@/Components/domain-specific/ngs/sections/NgsLibrariesFilterAndTable.vue";
import {PairForm} from "@/forms";
import {NgsRun} from "@/models";

@Component({
    components: {
        NgsLibrariesFilterAndTable,
        DialogConfirmation
    }
})
export default class NgsCreateLibraryPairDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly item!: NgsRun
    @Watch('isDialogOpen')
    onDialogOpening(val: boolean) {
        if (val) {
            this.form.reset()
            this.form.clearErrors()
            this.form.ngs_run_id = this.item.id
            this.selectedTumoralLibraries = []
            this.selectedNonTumoralLibraries = []
        }
    }

    selectedTumoralLibraries: any[] = []
    selectedNonTumoralLibraries: any[] = []

    form: InertiaForm<PairForm> = this.$inertia.form({
        ngs_run_id: null,
        tumoral_library_id: null,
        non_tumoral_library_id: null
    })

    save () {
        this.form.tumoral_library_id = get(this, 'selectedTumoralLibraries[0].id')
        this.form.non_tumoral_library_id = get(this, 'selectedNonTumoralLibraries[0].id')

        this.form.post(route('ngs_library_pairs.store'), {
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                this.$toast('La paire a été crée avec succès', {
                    type: TYPE.SUCCESS
                })
                this.isDialogOpen = false
                this.form.reset()
            }
        })
    }

    cancel () {
        this.form.reset()
    }
}
</script>

<style lang="scss" scoped>
</style>
