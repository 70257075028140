<template>
    <v-row dense>
        <v-col>
            <v-progress-linear
                v-if="status"
                :value="statusList[status].percentage"
                :height="dense ? '10' : '25'"
                rounded
                :color="`${statusList[status].color} darken-4`"
            >
                <span v-if="!dense" class="white--text font-weight-bold">{{ statusList[status].text }}</span>
            </v-progress-linear>
            <div v-if="dense" class="text-center black--text">{{ statusList[status].text }}</div>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CnvStatusProgress extends Vue {
    @Prop() readonly status!: string
    @Prop() readonly dense?: boolean

    statusList: { [key:string]: { text: string; percentage: number; color: string; } } =
        {
            incomplete: {
                text: 'En chargement',
                percentage: 0,
                color: 'secondary'
            },
            imported: {
                text: 'En chargement',
                percentage: 20,
                color: 'secondary'
            },
            analysed: {
                text: 'A annoter',
                percentage: 40,
                color: 'secondary'
            },
            first_review: {
                text: '1ère lecture',
                percentage: 60,
                color: 'secondary'
            },
            reviewed: {
                text: 'Lecture',
                percentage: 80,
                color: 'secondary'
            },
            reported: {
                text: 'Rapportée',
                percentage: 100,
                color: 'secondary'
            },
            error: {
                text: 'Erreur',
                percentage: 0,
                color: 'error'
            },
            ignored: {
                text: 'A ignorer',
                percentage: 0,
                color: 'warning'
            }
        }
}
</script>

<style scoped lang="scss">
</style>
