<template>
    <panel title="Liste des variants" :expandable="true">
        <template v-slot:actions>
            <v-checkbox
                v-model="filters['show-masked-variants']"
                dark
                class="ma-0 mr-3"
                hide-details
            >
                <template v-slot:label>
                    {{ `Masqué par défaut (${maskedVariantsCount} variants)` }}
                </template>
            </v-checkbox>
            <v-checkbox
                v-model="filters['show-silent-variants']"
                dark
                class="ma-0"
                hide-details
            >
                <template v-slot:label>
                    {{ `Silencieux (${silentVariantsCount} variants)` }}
                </template>
            </v-checkbox>
        </template>
        <template>
            <v-alert
                v-if="variants.length + maskedVariantsCount + silentVariantsCount === 0"
                text
                prominent
                type="warning"
                class="ma-0"
            >
                <v-row align="center">
                    <v-col class="grow font-weight-bold text-center">
                        Aucun variant détecté dans le fichier VCF
                    </v-col>
                </v-row>
            </v-alert>
            <v-container v-if="variants.length + maskedVariantsCount + silentVariantsCount > 0" fluid>
                <v-row align="center" class="grey lighten-4">
                    <v-col cols="12">
                        <div class="font-weight-bold">
                            Gènes d'intérêt
                            <v-chip color="primary" text-color="white" class="font-weight-bold ml-2" small>
                                {{ genesOfInterestCount }}
                            </v-chip>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="mb-3">
                    <v-col cols="12" class="pa-0">
                        <variants-table
                            v-model="filters"
                            :loading="loading"
                            :items="variantsOfGenesOfInterest"
                            :ngs-analysis="ngsAnalysis"
                            :annotated-segments="annotatedSegments"
                            @select="$emit('select', $event)"
                            :type="type"
                            :readonly="readonly"
                            @update="$emit('update')"
                        ></variants-table>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row align="center" class="grey lighten-4">
                    <v-col cols="12">
                        <div class="font-weight-bold">
                            Autres gènes
                            <v-chip color="primary" text-color="white" class="font-weight-bold ml-2" small>
                                {{ otherGenesCount }}
                            </v-chip>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="pa-0">
                        <variants-table
                            v-model="filters"
                            :loading="loading"
                            :items="variantsOfOtherGenes"
                            :ngs-analysis="ngsAnalysis"
                            :annotated-segments="annotatedSegments"
                            @select="$emit('select', $event)"
                            :type="type"
                            :readonly="readonly"
                            @update="$emit('update')"
                        ></variants-table>
                    </v-col>
                </v-row>
            </v-container>
        </template>

    </panel>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue} from 'vue-property-decorator'
import Panel from '@/Components/generic/Panel.vue'
import VariantsTable from "@/Components/domain-specific/ngs/tables/VariantsTable.vue";
import uniq from "lodash/uniq";
import {Gene, NgsAnalysis, NgsPairAnalysis, Segment, Variant} from "@/models";
import Btn from "@/Components/generic/Btn.vue";
import NgsGlobalViewerDialog from "@/Components/domain-specific/ngs/dialogs/NgsGlobalViewerDialog.vue";

@Component({
    components: {
        Btn,
        VariantsTable,
        Panel,
    }
})
export default class NgsAnalysisVariantCard extends Vue {
    @VModel() filters!: {
        'show-silent-variants': boolean | null,
        'show-masked-variants': boolean | null,
        allelic_balance: number | null,
        chromosome: string | null,
        position: number | null
    }
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly variants!: Array<Variant>
    @Prop() readonly silentVariantsCount!: number
    @Prop() readonly maskedVariantsCount!: number
    @Prop() readonly annotatedSegments!: Array<Segment>
    @Prop() readonly loading!: boolean
    @Prop() readonly type!: 'simple' | 'pair'
    @Prop({default: false}) readonly readonly?: boolean

    get geneIds(): number[] {
        return this.ngsAnalysis.genes.map((gene: Gene) => gene.id)
    }

    get genesOfInterestCount(): number {
        return uniq(this.variantsOfGenesOfInterest.map((item: Variant) => item.gene.id)).length
    }

    get variantsOfGenesOfInterest(): Array<Variant> {
        return this.variants.filter((item: Variant) => this.geneIds.includes(item.gene.id))
    }

    get otherGenesCount(): number {
        return uniq(this.variantsOfOtherGenes.map((item: Variant) => item.gene.id)).length
    }

    get variantsOfOtherGenes(): Array<Variant> {
        return this.variants.filter((item: Variant) => !this.geneIds.includes(item.gene.id))
    }
}
</script>

<style lang="css" scoped>
:deep(.v-expansion-panel-content__wrap) {
    padding: 0;
}
</style>
