<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        title="Supprimer l'analyse"
        :max-width="600"
        @validate="save()"
        :loading="loading"
        button-class="error"
    >
        <div class="pa-5">
            <v-row>
                <v-col cols="12">
                    <p>Êtes-vous sûr(e) de vouloir supprimer cette analyse? Vous n'aurez plus accès à cette analyse</p>
                </v-col>
            </v-row>
        </div>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {TYPE} from 'vue-toastification'
import route from 'ziggy-js'
import {CnvAnalysis} from "@/models";

@Component({
    components: { DialogConfirmation }
})
export default class CnvDeleteDialogDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly cnvAnalysis!: CnvAnalysis

    loading: boolean = false

    save () {
        this.$inertia.delete(
            route('cnv_analyses.destroy', { cnv_analysis: this.cnvAnalysis.id }),
            {
                preserveScroll: true,
                preserveState: true,
                onStart: () => {
                    this.loading = true
                },
                onFinish: () => {
                    this.loading = false
                },
                onSuccess: () => {
                    this.isDialogOpen = false
                    this.$toast('Analyse supprimée', {
                        type: TYPE.ERROR
                    })
                }
            }
        )
    }
}
</script>

<style lang="scss" scoped>

</style>
