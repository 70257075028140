<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            :sort-by="['chromosome_id', 'position']"
            :loading="loading"
            :search="search"
            disable-sort
        >
            <template v-slot:item.full_location="{ item }">
                <v-tooltip top color="rgba(0,0,0,0.9)">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            :color="qualities[$get(item, 'pivot.mapped_quality', 'excellent')].color"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-circle-medium
                        </v-icon>
                    </template>
                    <span class="font-weight-bold">{{
                            qualities[$get(item, 'pivot.mapped_quality', 'excellent')].label
                        }}</span>
                </v-tooltip>
                {{ item.full_location }}
                <v-icon small v-if="item.is_masked" color="primary" class="ml-1">mdi-eye-off-outline</v-icon>
                <v-icon small v-if="$get(item, 'vep_annotation.is_silent', false)" color="primary" class="ml-1">
                    mdi-circle-off-outline
                </v-icon>
            </template>
            <template v-slot:item.vep_annotation.hgvsc="{ item }">
                <span :class="$get(item, 'vep_annotation.hgvsc', 'NA') === 'NA' ? 'text--disabled' : ''">
                    {{ $get(item, 'vep_annotation.hgvsc', 'NA').after(':') }}
                </span>
            </template>
            <template v-slot:item.vep_annotation.hgvsp="{ item }">
                <span :class="$get(item, 'vep_annotation.hgvsp', 'NA') === 'NA' ? 'text--disabled' : ''">
                    {{ $get(item, 'vep_annotation.hgvsp', 'NA').after(':') }}
                </span>
            </template>

            <template v-slot:item.pathogenicity="{ item }">
                <add-variant-pathogenicity-btn :item="item" :ngs-analysis="ngsAnalysis" :small="true" @reloaded="$emit('update')" :readonly="readonly || $get(item, 'annotation.annotation', 'valid') !== 'valid'"
                                               :value="$get(item, 'pathogenicity', 'NA')" :type="type"></add-variant-pathogenicity-btn>
            </template>
            <template v-slot:item.annotation="{ item }">
                <annotate-variant-btn :item="item" @update="$emit('update')" :readonly="readonly"></annotate-variant-btn>
            </template>
            <template v-slot:item.report="{ item }">
                <v-btn icon v-if="item.pivot.report_status !== 'not_included'" @click="updateReportStatus(item)" :disabled="readonly">
                    <v-icon color="primary">{{
                            item.pivot.report_status === 'included' ? 'mdi-check' : 'mdi-star'
                        }}
                    </v-icon>
                </v-btn>
                <v-btn icon v-else disabled>
                    <v-icon color="primary">mdi-close</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.actions="{ item }">
                <variant-actions-btn :item="item" :ngs-analysis="ngsAnalysis" :type="type" :readonly="readonly"></variant-actions-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {DataTableHeader} from "vuetify";
import AnnotateVariantBtn from "@/Components/domain-specific/ngs/dialogs/AnnotateVariantBtn.vue";
import route from "ziggy-js";
import {TYPE} from "vue-toastification";
import AddVariantPathogenicityBtn from "@/Components/domain-specific/ngs/dialogs/AddVariantPathogenicityBtn.vue";
import VariantActionsBtn from "@/Components/domain-specific/ngs/dialogs/VariantActionsBtn.vue";
import {NgsAnalysis, NgsPairAnalysis, Variant} from "@/models";

@Component({
    components: {
        VariantActionsBtn,
        AddVariantPathogenicityBtn,
        AnnotateVariantBtn,
    }
})
export default class SummaryVariantsTable extends Vue {
    @Prop() readonly items!: Array<Variant>
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop({ default: false }) readonly loading!: boolean
    @Prop({ default: false }) readonly readonly!: boolean
    @Prop() readonly type!: 'simple' | 'pair'
    @Prop() readonly search!: string

    qualities: { [key: string]: { label: string; color: string; }} = {
        insufficient: {
            label: 'Insufficient',
            color: '#e53e3e'
        },
        weak: {
            label: 'Weak',
            color: '#ed8936'
        },
        good: {
            label: 'Good',
            color: '#ecc94b'
        },
        excellent: {
            label: 'Excellent',
            color: '#48bb78'
        },
    }

    headers: Array<DataTableHeader> = [
        {
            text: 'Position',
            value: 'full_location',
        },
        {
            text: 'Gène',
            value: 'gene.name',
        },
        {
            text: 'HGVS.c',
            value: 'vep_annotation.hgvsc',
        },
        {
            text: 'HGVS.p',
            value: 'vep_annotation.hgvsp',
        },
        {
            text: 'Pathogénicité',
            value: 'pathogenicity',
        },
        {
            text: 'Annotations',
            value: 'annotation',
        },
        {
            text: 'Rapport',
            value: 'report',
        },
        {
            text: 'Actions',
            value: 'actions',
            align: 'end'
        }
    ]

    updateReportStatus(item: any) {
        this.$inertia.put(route('ngs_analysis_variants.report_status.update', {ngs_analysis_variant: item.pivot.id}), {
            status: item.pivot.report_status
        }, {
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                this.$toast(`Variant marqué comme ${item.pivot.report_status === 'included' ? 'mis en évidence' : 'inclus'}`, {
                    type: TYPE.SUCCESS
                })
                this.$emit('update')
            }
        })
    }

    get tumorTypeId(): number {
        if (this.type === 'pair') {
            return (this.ngsAnalysis as NgsPairAnalysis).ngs_tumoral_library.sample.tumor_type_id
        }

        return (this.ngsAnalysis as NgsAnalysis).sample.tumor_type_id
    }
}
</script>

<style scoped>
.row-chart {
    max-width: 80px;
    max-height: 80px;
}

.row-chart:hover {
    cursor: pointer;
}
</style>
