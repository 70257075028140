<template>
    <v-dialog
        v-model="isDialogOpen"
        transition="dialog-bottom-transition"
        open-delay="1500"
        width="1400"
        persistent
    >

        <card>
            <template v-slot:header>
                {{ `Liste des variants annotés ${partialAnnotationsCount}/${annotationsCount}` }}
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-checkbox
                    v-model="partiallyAnnotatedOnly"
                    label="En attente de validation"
                    class="mr-3"
                    dense
                    hide-details
                ></v-checkbox>
                <v-text-field
                    v-model="search"
                    placeholder="Rechercher..."
                    hide-details
                    dense
                    outlined
                    clearable
                ></v-text-field>
            </template>
            <v-container fluid>
                <summary-variants-table
                    :items="filteredVariants"
                    :loading="loading"
                    :search="search"
                    :type="type"
                    :ngs-analysis="ngsAnalysis"
                    :readonly="readonly"
                    @update="reloadAnnotations"
                ></summary-variants-table>
            </v-container>
            <v-card-actions class="text-right justify-end">
                <v-spacer></v-spacer>
                <v-btn @click="close()" color="primary" text>
                    <v-icon small class="mr-1">mdi-close</v-icon>
                    Fermer
                </v-btn>
            </v-card-actions>
        </card>
    </v-dialog>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogContent from '@/Components/generic/DialogContent.vue'
import route from 'ziggy-js'
import Card from '@/Components/generic/Card.vue'
import SummaryVariantsTable from "@/Components/domain-specific/ngs/tables/SummaryVariantsTable.vue";
import {NgsAnalysis, NgsPairAnalysis, Variant} from "@/models";

@Component({
    components: {
        SummaryVariantsTable,
        Card,
        DialogContent
    }
})
export default class NgsVariantsDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop({default: false}) readonly disabled!: boolean
    @Prop() readonly type!: 'simple' | 'pair'
    @Prop({default: false}) readonly readonly?: boolean

    @Watch('isDialogOpen', {immediate: true})
    onDialogChanged(val: boolean) {
        if (val) {
            this.load()
        } else {
            this.annotatedVariants = []
        }
    }

    annotatedVariants: Array<Variant> = []

    loading: boolean = false

    search: string | null = null

    partiallyAnnotatedOnly: boolean = false

    load() {
        this.loading = true
        if (this.type === 'simple') {
            this.$axios.get(route('ngs_analyses.annotated_variants', {ngs_analysis: this.ngsAnalysis.id}))
                .then(({data}) => {
                    this.annotatedVariants = data
                })
                .finally(() => {
                    this.loading = false
                })
        }
        if (this.type === 'pair') {
            this.$axios.get(route('ngs_pair_analyses.annotated_variants', {ngs_pair_analysis: this.ngsAnalysis.id}))
                .then(({data}) => {
                    this.annotatedVariants = data
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }

    reloadAnnotations() {
        this.load()

        this.$emit('updated')
    }

    get partialAnnotationsCount(): number {
        return this.annotatedVariants.filter((item: any) => item.annotations_count > 1).length
    }

    get annotationsCount(): number {
        return this.annotatedVariants.length
    }

    get filteredVariants(): Array<Variant> {
        return this.partiallyAnnotatedOnly ? this.annotatedVariants.filter((item: any) => item.annotations_count === 1) : this.annotatedVariants
    }

    close() {
        this.isDialogOpen = false
    }
}
</script>

<style lang="scss" scoped>

</style>
