<template>
    <layout>
        <v-container fluid>
            <stepper
                v-model="stepper"
                :steps="steps"
                hide-buttons
            >
                <template v-slot:step-1>
                    <select-sample
                        v-model="cnvAnalysisForm.sample_id"
                        :search.sync="filters.search_sample"
                        :samples="samples"
                        :error-messages="cnvAnalysisForm.errors.sample_id"
                        outlined
                    ></select-sample>
                    <v-btn
                        color="primary"
                        x-large
                        dark
                        depressed
                        block
                        :loading="cnvAnalysisForm.processing"
                        @click="create()"
                    >Créer une nouvelle analyse CNV
                    </v-btn>
                </template>
                <template v-slot:step-2>
                    <v-text-field
                        v-model="cnvAnalysisQualityControlForm.name"
                        label="Nom de l'analyse *"
                        outlined
                        :error-messages="cnvAnalysisQualityControlForm.errors.name"
                        autofocus
                        clearable
                    ></v-text-field>
                    <date-picker
                        label="Date de l'analyse *"
                        v-model="cnvAnalysisQualityControlForm.date"
                        outlined
                        :error-messages="cnvAnalysisQualityControlForm.errors.date"
                        :readonly="true"
                    ></date-picker>
                    <p class="text--disabled">DIAMIC: <b>{{ diamic }}</b></p>
                    <v-switch
                        v-model="cnvAnalysisQualityControlForm.has_passed_quality_control"
                        class="mx-3"
                        inset
                        :error-messages="cnvAnalysisQualityControlForm.errors.has_passed_quality_control"
                    >
                        <template v-slot:label>
                            Contrôle qualité passé: <span class="font-weight-bold ml-1">{{ cnvAnalysisQualityControlForm.has_passed_quality_control ? 'oui' : 'non' }}</span>
                        </template>
                    </v-switch>
                    <v-file-input
                        v-model="cnvAnalysisQualityControlForm.quality_control_file"
                        color="primary"
                        label="Fichier de contrôle qualité"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                        counter
                        :error-messages="cnvAnalysisQualityControlForm.errors.quality_control_file"
                        accept=".txt"
                    >
                        <template v-slot:selection="{ text }">
                            <v-chip
                                color="primary"
                                dark
                                label
                                small
                            >
                                {{ text }}
                            </v-chip>
                        </template>
                    </v-file-input>
                    <transition name="fade">
                        <div v-show="cnvAnalysisQualityControlForm.has_passed_quality_control === true">
                            <v-file-input
                                v-model="cnvAnalysisQualityControlForm.results_file"
                                color="primary"
                                label="Fichier de résultats"
                                prepend-icon="mdi-paperclip"
                                outlined
                                :show-size="1000"
                                counter
                                :error-messages="cnvAnalysisQualityControlForm.errors.results_file"
                                accept=".txt"
                            >
                                <template v-slot:selection="{ text }">
                                    <v-chip
                                        color="primary"
                                        dark
                                        label
                                        small
                                    >
                                        {{ text }}
                                    </v-chip>
                                </template>
                            </v-file-input>
                        </div>
                    </transition>
                    <transition name="fade">
                        <div v-show="cnvAnalysisQualityControlForm.has_passed_quality_control === false">
                            <select-user
                                class="mt-3"
                                v-model="cnvAnalysisQualityControlForm.reviewer_id"
                                :users="filteredUsers"
                                outlined
                                :error-messages="cnvAnalysisQualityControlForm.errors.reviewer_id"
                                label="Sélectionnez le réviseur"
                            ></select-user>
                            <v-textarea
                                v-model="cnvAnalysisQualityControlForm.quality_control_comment"
                                outlined
                                label="Commentaire"
                                :error-messages="cnvAnalysisQualityControlForm.errors.quality_control_comment"
                            ></v-textarea>
                        </div>
                    </transition>
                    <v-btn
                        class="mb-1"
                        color="primary"
                        x-large
                        dark
                        depressed
                        block
                        :loading="cnvAnalysisQualityControlForm.processing"
                        @click="submit()"
                    >Valider
                    </v-btn>
                    <v-btn
                        color="error"
                        x-large
                        dark
                        depressed
                        block
                        @click="remove()"
                    >Supprimer
                    </v-btn>
                </template>
            </stepper>
        </v-container>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import Stepper from '@/Components/generic/Stepper.vue'
import DatePicker from '@/Components/generic/DatePicker.vue'
import SelectSample from '@/Components/domain-specific/sample/SelectSample.vue'
import SelectUser from '@/Components/domain-specific/user/SelectUser.vue'
import Layout from '@/Layouts/Layout.vue'
import {TYPE} from 'vue-toastification'
import route from 'ziggy-js'
import get from 'lodash/get'
import {CnvAnalysis, Sample, User} from '@/models'
import {InertiaForm} from "@inertiajs/inertia-vue";
import {CnvAnalysisFilesForm, CnvAnalysisForm} from "@/forms";
import {Page} from "@inertiajs/inertia";

@Component({
    components: {
        Layout,
        SelectUser,
        DatePicker,
        SelectSample,
        Stepper
    },
    metaInfo: {
        title: 'Ajouter une nouvelle analyse CNV'
    }
})
export default class CreateCnvAnalysis extends Vue {
    @Prop() readonly filters!: { search_sample: string | null }
    @Prop() readonly step!: string
    @Prop() readonly users!: Array<User>
    @Prop() readonly samples!: Array<Sample>
    @Prop() readonly cnvAnalysis?: Array<CnvAnalysis>

    @Watch('stepper')
    onStepCompletion (value: number) {
        this.$inertia.visit(route('cnv_analyses.create', {
            _query: {
                step: value
            }
        }), {
            preserveScroll: true,
            preserveState: true
        })
    }

    diamic: string = get(this, 'cnvAnalysis.sample.full_diamic', '')

    stepper: number = Number(this.step)

    steps: { [key: number]: { title: string; complete: boolean; hasErrors: boolean; }} = {
        1: {
            title: "Sélection de l'échantillon",
            complete: false,
            hasErrors: false
        },
        2: {
            title: 'Statut du contrôle qualité',
            complete: false,
            hasErrors: false
        }
    }

    cnvAnalysisForm: InertiaForm<CnvAnalysisForm> = this.$inertia.form({
        sample_id: null
    })

    cnvAnalysisQualityControlForm: InertiaForm<CnvAnalysisFilesForm> = this.$inertia.form({
        id: get(this, 'cnvAnalysis.id', null),
        name: get(this, 'cnvAnalysis.name', null),
        date: get(this, 'cnvAnalysis.date', null),
        has_passed_quality_control: true,
        quality_control_file: null,
        results_file: null,
        reviewer_id: (this.$page.props.user as any).id,
        quality_control_comment: null
    })

    toggleCompletion (step: number, value: boolean) {
        this.steps[step].complete = value
    }

    create () {
        this.cnvAnalysisForm.post(
            route('cnv_analyses.store'),
            {
                onSuccess: (page: Page) => {
                    this.cnvAnalysisQualityControlForm.id = (page.props.flash as any).data.id
                    this.cnvAnalysisQualityControlForm.name = (page.props.flash as any).data.name
                    this.cnvAnalysisQualityControlForm.date = (page.props.flash as any).data.date
                    this.diamic = (page.props.flash as any).data.sample.full_diamic
                    this.stepper = 2
                    this.toggleCompletion(1, true)
                    this.$toast(`Analyse CNV ${(page.props.flash as any).data.name} créée`, {
                        type: TYPE.SUCCESS
                    })
                },
                onError: () => {
                    this.toggleCompletion(1, false)
                }
            }
        )
    }

    submit () {
        this.cnvAnalysisQualityControlForm.post(
            route('cnv_analyses.quality_control', this.cnvAnalysisQualityControlForm.id),
            {
                onSuccess: () => {
                    this.$inertia.visit(
                        route('cnv_analyses.show', this.cnvAnalysisQualityControlForm.id)
                    )
                }
            }
        )
    }

    remove () {
        this.$inertia.delete(
            route('cnv_analyses.destroy', this.cnvAnalysisQualityControlForm.id), {
                onSuccess: () => {
                    this.$toast(`Analyse CNV ${this.cnvAnalysisQualityControlForm.name} a été supprimée avec succès`, {
                        type: TYPE.SUCCESS
                    })
                }
            }
        )
    }

    get filteredUsers () {
        return this.users.filter(user => user.id !== (this.$page.props.user as User).id)
    }
}
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>
