<template>
    <div class="text-end">
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-btn @click="openMaskVariantDialog" v-on="on" icon>
                    <v-icon color="primary">mdi-cancel</v-icon>
                </v-btn>
            </template>
            <span>Masquer le variant</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-btn @click="openDetailsDialog" v-on="on" icon>
                    <div class="occurrence">
                        <v-icon color="primary">mdi-magnify</v-icon>
                        <span class="occurrence-text mt-n1">{{ item.occurrences === 0 ? 'NEW' : item.occurrences }}</span>
                    </div>
                </v-btn>
            </template>
            <span>Page détaillée du variant</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-btn @click="openAlignmentDialog" v-on="on" icon>
                    <v-icon color="primary">mdi-view-sequential</v-icon>
                </v-btn>
            </template>
            <span>IGV viewer</span>
        </v-tooltip>
        <v-tooltip top v-if="!readonly">
            <template v-slot:activator="{ on }">
                <v-btn @click="openCommentDialog" v-on="on" icon>
                    <v-icon color="primary">
                        {{ item.pivot.comment === null ? 'mdi-comment-text-outline' : 'mdi-comment-text' }}
                    </v-icon>
                </v-btn>
            </template>
            <span>Éditer commentaire</span>
        </v-tooltip>
        <ngs-variant-details-dialog
            v-if="isDialogDetailsOpen"
            v-model="isDialogDetailsOpen"
            :item="item"
            :tumor-type-id="$get(ngsAnalysis, 'sample.tumor_type_id')"
            :readonly="readonly"
            @publication-added="$emit('publication-added')"
        ></ngs-variant-details-dialog>
        <ngs-edit-variant-comment-dialog
            v-if="isDialogCommentOpen && !readonly"
            v-model="isDialogCommentOpen"
            :variant="item"
        ></ngs-edit-variant-comment-dialog>
        <ngs-alignment-viewer-dialog
            v-if="isDialogAlignmentOpen"
            v-model="isDialogAlignmentOpen"
            :variant="item"
            :ngs-analysis="ngsAnalysis"
            :type="type"
        ></ngs-alignment-viewer-dialog>
        <ngs-mask-variant-dialog
            v-if="isDialogMaskVariantOpen"
            v-model="isDialogMaskVariantOpen"
            :variant="item"
        ></ngs-mask-variant-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import NgsEditVariantCommentDialog from "@/Components/domain-specific/ngs/dialogs/NgsEditVariantCommentDialog.vue";
import NgsAlignmentViewerDialog from "@/Components/domain-specific/ngs/dialogs/NgsAlignmentViewerDialog.vue";
import NgsVariantDetailsDialog from "@/Components/domain-specific/ngs/dialogs/NgsVariantDetailsDialog.vue";
import {NgsAnalysis, NgsPairAnalysis, Variant} from "@/models";
import NgsMaskVariantDialog from "@/Components/domain-specific/ngs/dialogs/NgsMaskVariantDialog.vue";
import get from "lodash/get";

@Component({
    components: {NgsMaskVariantDialog, NgsVariantDetailsDialog, NgsAlignmentViewerDialog, NgsEditVariantCommentDialog}
})
export default class VariantActionsBtn extends Vue {
    @Prop() readonly item!: Variant
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop({default: false}) readonly readonly?: boolean
    @Prop({default: false}) readonly comparison?: boolean
    @Prop() readonly type!: 'simple' | 'pair'

    isDialogDetailsOpen: boolean = false
    isDialogCommentOpen: boolean = false
    isDialogAlignmentOpen: boolean = false
    isDialogMaskVariantOpen: boolean = false

    openCommentDialog() {
        this.isDialogCommentOpen = true
    }

    openAlignmentDialog() {
        if(this.comparison) {
            const increment = get(this, 'item.vep_annotation.hgvsc', 'NA').includes('del') && !get(this, 'item.vep_annotation.hgvsc', 'NA').includes('delins') ? 1 : 0

            let position: { [key: string]: string | null | number } = {
                chromosome: this.item.chromosome.name,
                start_position: Number(this.item.position) - 20 + increment,
                end_position: Number(this.item.position) + 20 + increment
            }

            this.$emit('open', {position: position, analysis: this.ngsAnalysis, type: this.type})
        } else {
            this.isDialogAlignmentOpen = true
        }
    }

    openDetailsDialog() {
        this.isDialogDetailsOpen = true
    }

    openMaskVariantDialog() {
        this.isDialogMaskVariantOpen = true
    }
}
</script>

<style scoped>
.occurrence {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.occurrence-text {
    font-size: 10px;
    color: #65c6c1;
    font-weight: bold;
}
</style>
