<template>
    <layout>
        <v-container>
            <v-row>
                <v-col cols="12" md="3">
                    <p class="text-h5 font-weight-bold">
                        Paramètres principaux
                    </p>
                    <p>
                        Ces informations apparaîtront sur votre profil Certaines options ne sont pas disponibles pour
                        les comptes LDAP.
                    </p>
                </v-col>
                <v-col cols="12" md="9">
                    <v-row dense>
                        <v-col cols="9" md="6" lg="3">
                            <label class="font-weight-bold">Email</label>
                            <v-text-field outlined v-model="form.email" dense
                                          :error-messages="form.errors.email" :disabled="!user.is_local"
                                          :hint="!user.is_local ? 'Votre email a été automatiquement défini sur la base de votre compte HUG.' : null"
                                          :persistent-hint="!user.is_local"></v-text-field>
                        </v-col>
                        <v-col cols="3" md="2" lg="1">
                            <label class="font-weight-bold">User ID</label>
                            <v-text-field outlined v-model="user.id" dense disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="6" lg="3">
                            <label class="font-weight-bold">Prénom</label>
                            <v-text-field outlined v-model="form.first_name" dense
                                          :error-messages="form.errors.first_name" :disabled="!user.is_local"
                                          :hint="!user.is_local ? 'Votre prénom a été automatiquement défini sur la base de votre compte HUG.' : null"
                                          :persistent-hint="!user.is_local"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="6" lg="3">
                            <label class="font-weight-bold">Nom de famille</label>
                            <v-text-field outlined v-model="form.last_name" dense
                                          :error-messages="form.errors.last_name"
                                          :disabled="!user.is_local"
                                          :hint="!user.is_local ? 'Votre nom de famille a été automatiquement défini sur la base de votre compte HUG.' : null"
                                          :persistent-hint="!user.is_local"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider class="mb-3"></v-divider>
                    <v-row dense>
                        <v-btn color="primary" @click="submit" depressed class="mb-5 mr-3"
                               :loading="form.processing">
                            Mettre à jour le profil
                        </v-btn>
                        <v-btn @click="form.reset()" outlined depressed class="mb-5">
                            Annuler
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>

        </v-container>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Layout from '@/Layouts/Layout.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import DatePicker from '@/Components/generic/DatePicker.vue'
import route from 'ziggy-js'
import {TYPE} from 'vue-toastification'
import {User} from "@/models";
import {ProfileForm} from "@/forms";

@Component({
    components: {
        DatePicker,
        Layout
    },
    metaInfo: {
        title: 'Éditer profil'
    }
})
export default class Profile extends Vue {
    @Prop() readonly user!: User

    form: InertiaForm<ProfileForm> = this.$inertia.form({
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email
    })

    submit () {
        this.form.put(route('profile.update'), {
            preserveScroll: true,
            onSuccess: () => {
                this.$toast('Votre profil a été mis à jour.', {
                    type: TYPE.SUCCESS
                })
            }
        })
    }
}
</script>

<style lang="scss" scoped>

</style>
