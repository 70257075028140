<template>
    <guest-layout :fill="true">
        <v-row justify="center">
            <v-col cols="10" sm="8" md="4" lg="3">
                <v-text-field
                    v-model="form.email"
                    label="E-mail"
                    :error-messages="form.errors.email"
                    autofocus
                    outlined
                    dense
                ></v-text-field>
                <v-text-field
                    label="Nouveau mot de passe"
                    v-model="form.password"
                    type="password"
                    outlined
                    dense
                ></v-text-field>
                <v-text-field
                    label="Confirmer nouveau mot de passe"
                    v-model="form.password_confirmation"
                    type="password"
                    outlined
                    dense
                ></v-text-field>
                <v-btn
                    color="primary"
                    @click="submit"
                    :loading="form.processing"
                    block
                    depressed
                >
                    Réinitialiser le mot de passe
                </v-btn>
            </v-col>
        </v-row>
    </guest-layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import GuestLayout from '@/Layouts/GuestLayout.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import route from 'ziggy-js'
import {ResetPasswordForm} from "@/forms";

@Component({
    components: { GuestLayout },
    metaInfo: {
        title: 'Réinitialiser le mot de passe'
    }
})
export default class ResetPassword extends Vue {
    @Prop() readonly email!: string
    @Prop() readonly token!: string

    form: InertiaForm<ResetPasswordForm> = this.$inertia.form({
        email: this.email,
        token: this.token,
        password: '',
        password_confirmation: ''
    })

    submit () {
        this.form.post(route('password.update'), {
            preserveScroll: true,
            onFinish: () => {
                this.form.reset('password', 'password_confirmation')
            }
        })
    }
}
</script>

<style lang="scss" scoped>

</style>
