<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        title="Mettre à jour le contrôle qualité"
        :max-width="600"
        @cancel="cancel()"
        @validate="save()"
        :loading="form.processing"
    >
        <div class="pa-5">
            <v-row>
                <v-col cols="12">
                    <v-switch
                        v-model="form.has_passed_quality_control"
                        class="mx-3"
                        inset
                        :error-messages="form.errors.has_passed_quality_control"
                    >
                        <template v-slot:label>
                            Contrôle qualité passé: <span class="font-weight-bold ml-1">{{ form.has_passed_quality_control ? 'oui' : 'non' }}</span>
                        </template>
                    </v-switch>
                </v-col>
                <v-col cols="12">
                    <transition name="fade">
                        <div v-show="form.has_passed_quality_control === false">
                            <select-user
                                class="mt-3"
                                v-model="form.reviewer_id"
                                :users="filteredUsers"
                                outlined
                                :error-messages="form.errors.reviewer_id"
                                label="Sélectionnez le réviseur"
                            ></select-user>
                            <v-textarea
                                v-model="form.quality_control_comment"
                                outlined
                                label="Commentaire"
                                :error-messages="form.errors.quality_control_comment"
                            ></v-textarea>
                        </div>
                    </transition>
                </v-col>
            </v-row>
        </div>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import {TYPE} from 'vue-toastification'
import route from 'ziggy-js'
import SelectUser from "@/Components/domain-specific/user/SelectUser.vue";
import {NgsAnalysis, NgsPairAnalysis, User} from "@/models";

@Component({
    components: {SelectUser, DialogConfirmation }
})
export default class NgsEditQcDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly users!: Array<User>
    @Prop() readonly type!: 'simple' | 'pair'

    loading: boolean = false
    form: InertiaForm<{ has_passed_quality_control: boolean; reviewer_id: null | number; quality_control_comment: null | string; }> = this.$inertia.form({
        has_passed_quality_control: this.ngsAnalysis.has_passed_quality_control,
        reviewer_id: null,
        quality_control_comment: ''
    })

    cancel () {
        this.form.reset()
    }

    save () {
        if (this.type === 'simple') {
            this.form.put(
                route('ngs_analyses.qc', {ngs_analysis: this.ngsAnalysis.id}),
                {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.isDialogOpen = false
                        this.$toast('Contrôle qualité mis à jour', {
                            type: TYPE.SUCCESS
                        })
                    }
                }
            )
        }

        if (this.type === 'pair') {
            this.form.put(
                route('ngs_pair_analyses.qc', {ngs_pair_analysis: this.ngsAnalysis.id}),
                {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.isDialogOpen = false
                        this.$toast('Contrôle qualité mis à jour', {
                            type: TYPE.SUCCESS
                        })
                    }
                }
            )
        }
    }

    get filteredUsers () {
        return this.users.filter(user => user.id !== (this.$page.props.user as User).id)
    }
}
</script>

<style lang="scss" scoped>

</style>
