<template>
    <div>
        <dialog-confirmation
            v-model="isDialogOpen"
            :title="`${annotation ? 'Consulter' : 'Ajouter'} une annotation`"
            :width="600"
            :max-width="600"
            @cancel="cancel()"
            @validate="annotation !== null && annotation.validator === null ? updateAnnotation() : save()"
            :loading="form.processing || loading"
            :validate-disabled="annotation !== null && annotation.validator !== null"
        >
            <div class="pa-5" v-if="loading">
                <v-skeleton-loader type="article"></v-skeleton-loader>
            </div>
            <div class="pa-5" v-if="!loading && !annotation">
                <v-row>
                    <v-col cols="12">
                        <element-row label="Type">{{ elementType[type] }}</element-row>
                        <element-row label="Altération">{{ alterationIdentifier(form.alteration_id) }}</element-row>
                        <element-row label="Info">{{ position }}</element-row>
                    </v-col>
                </v-row>
                <v-divider class="my-3"></v-divider>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            label="Déclaré par *"
                            v-model="form.reported_by"
                            :items="users"
                            item-text="name"
                            item-value="id"
                            :error-messages="form.errors.reported_by"
                            outlined
                        ></v-autocomplete>
                        <v-textarea
                            label="Justification *"
                            v-model="form.evidence"
                            rows="3"
                            :error-messages="form.errors.evidence"
                            outlined
                        ></v-textarea>
                        <v-autocomplete
                            label="Confirmé par *"
                            v-model="form.validated_by"
                            :items="filteredUsers"
                            item-text="name"
                            item-value="id"
                            :error-messages="form.errors.validated_by"
                            outlined
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </div>
            <div class="pa-5" v-if="!loading && annotation">
                <v-row>
                    <v-col cols="12">
                        <element-row label="Type">{{ elementType[annotation.source_type] }}</element-row>
                        <element-row label="Altération">{{
                                alterationIdentifier(annotation.alteration_id)
                            }}
                        </element-row>
                        <element-row label="Info">{{ position }}</element-row>
                    </v-col>
                </v-row>
                <v-divider class="my-3"></v-divider>
                <v-row>
                    <v-col cols="12">
                        <element-row label="Reporté par">{{ annotation.reporter.name }} <span
                            class="grey--text">({{ formatDate(annotation.created_at) }})</span></element-row>
                        <element-row v-if="annotation.validator" label="Validé par">{{ annotation.validator.name }}
                            <span
                                class="grey--text">({{ formatDate(annotation.updated_at) }})</span></element-row>
                        <element-row label="Justification">{{ annotation.evidence }}</element-row>
                    </v-col>
                </v-row>
                <v-divider class="my-3" v-if="annotation.validator === null"></v-divider>
                <v-row v-if="annotation.validator === null">
                    <v-col cols="12">
                        <v-autocomplete
                            label="Confirmé par"
                            v-model="form.validated_by"
                            :items="filteredUsers"
                            item-text="name"
                            item-value="id"
                            :error-messages="form.errors.validated_by"
                            outlined
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-divider class="my-3"></v-divider>
                <v-btn
                    class="my-2"
                    @click="deleteAnnotation()"
                    block
                    outlined
                    color="error"
                    :loading="loadingDeletion"
                    @mouseleave="confirmDeletion = false"
                >
                    <v-icon class="mr-1" color="error">mdi-delete</v-icon>
                    {{ deleteLabel }}
                </v-btn>
            </div>
            <template v-slot:buttons>
                <v-btn
                    outlined
                    class="my-2"
                    @click="publicationDialogOpen = true"
                    color="primary"
                >
                    Ajouter une publication
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
        </dialog-confirmation>
        <add-publication-dialog
            v-model="publicationDialogOpen"
            :alteration-id="alterationId"
            :item-id="$get(item, 'id', null)"
            type="gene"
            @publication-added="reloadAnnotations"
        ></add-publication-dialog>

    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import isEmpty from 'lodash/isEmpty'
import ElementRow from '@/Components/generic/ElementRow.vue'
import Btn from '@/Components/generic/Btn.vue'
import PublicationsListSection from '@/Components/domain-specific/cnv/sections/PublicationsListSection.vue'
import {TYPE} from 'vue-toastification'
import AddPublicationDialog from '@/Components/domain-specific/cnv/dialogs/AddPublicationDialog.vue'
import route from 'ziggy-js'
import {Arm, CnvAlteration, CnvAnnotation, Gene, Segment, User} from "@/models";
import {CnvAnnotationForm} from "@/forms";

@Component({
    components: {
        AddPublicationDialog,
        PublicationsListSection,
        Btn,
        ElementRow,
        DialogConfirmation
    }
})
export default class CnvEditCommentDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly item!: Arm | Gene | Segment | null
    @Prop() readonly cnvAnalysisId!: number
    @Prop({default: null}) readonly alterationId?: number | null
    @Prop() readonly type!: string

    @Watch('isDialogOpen', {
        immediate: true
    })
    onDialogOpening(val: boolean) {
        if (val) {
            this.form.reset()
            this.fetchAnnotation()
            this.fetchUsers()
        } else {
            this.annotation = null
        }
    }

    confirmDeletion: boolean = false
    publicationDialogOpen: boolean = false
    annotation: CnvAnnotation | null = null
    loading: boolean = false
    loadingDeletion: boolean = false
    form: InertiaForm<CnvAnnotationForm> = this.$inertia.form({
        cnv_analysis_id: null,
        publication_id: null,
        reported_by: (this.$page.props.user as User).id,
        validated_by: null,
        evidence: null,
        alteration_id: null
    })
    users: Array<User> = []
    elementType: { [key: string]: string } = {
        arm: 'Bras',
        gene: 'Gène',
        segment: 'Segment'
    }

    cancel() {
        this.form.reset()
        this.$emit('close')
    }

    save() {
        this.form.post(
            route(`${this.type}s.annotate`, [this.item.id]),
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: () => {
                    this.form.reset()
                    this.isDialogOpen = false
                    this.$emit('annotated')
                    this.fetchAnnotation()
                }
            }
        )
    }

    reloadAnnotations() {
        this.isDialogOpen = false
        this.$emit('publication-added')
    }

    fetchUsers() {
        this.loading = true
        this.$axios.get(route('users.index')).then(({data}) => {
            this.users = data
        }).finally(() => {
            this.loading = false
        })
    }

    fetchAnnotation() {
        this.loading = true
        const params: { [key: string]: any } = {
            cnv_analysis: this.cnvAnalysisId,
            [this.type]: this.item.id,
            _query: {
                annotation_alteration_id: this.alterationId
            }
        }
        this.$axios.get(
            route(`cnv_analyses.${this.type}s.annotations`, params)
        ).then(({data}) => {
            this.annotation = isEmpty(data) ? null : data
            if (this.annotation) {
                this.form.cnv_analysis_id = this.annotation.cnv_analysis_id
                this.form.reported_by = this.annotation.reported_by
                this.form.validated_by = this.annotation.validated_by ? this.annotation.validated_by : (this.$page.props.user as User).id !== this.annotation.reported_by ? (this.$page.props.user as User).id : null
                this.form.evidence = this.annotation.evidence
                this.form.alteration_id = this.annotation.alteration_id
            } else {
                this.form.cnv_analysis_id = this.cnvAnalysisId
                this.form.alteration_id = this.alterationId
            }
        }).finally(() => {
            this.loading = false
        })
    }

    updateAnnotation() {
        this.form.put(
            route('annotations.update', {cnv_annotation: this.annotation.id}),
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: () => {
                    this.form.reset()
                    this.isDialogOpen = false
                    this.$emit('updated')
                }
            }
        )
    }

    deleteAnnotation() {
        if (this.confirmDeletion === false) {
            this.confirmDeletion = true
        } else {
            this.$inertia.delete(route('annotations.delete', {cnv_annotation: this.annotation.id}), {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.annotation = null
                    this.confirmDeletion = false
                    this.isDialogOpen = false
                    this.$emit('deleted')
                    this.$toast('Annotation supprimée', {
                        type: TYPE.ERROR
                    })
                },
                onStart: () => {
                    this.loadingDeletion = true
                },
                onFinish: () => {
                    this.loadingDeletion = false
                }
            })
        }
    }

    alterationIdentifier(id: number): string | null {
        if (this.alterations) {
            const alteration = this.alterations.find(alteration => alteration.id === id)
            return alteration ? alteration.name : null
        }
        return null
    }

    get alterations(): CnvAlteration[] {
        return (this.$page.props.alterations as CnvAlteration[]) ?? []
    }

    get filteredUsers(): Array<User> {
        return this.users.filter(user => user.id !== this.form.reported_by)
    }

    get position(): string | null {
        if (this.annotation) {
            if (this.annotation.source_type === 'segment') {
                return (this.annotation.source as Segment).full_location
            }
            if (this.annotation.source_type === 'gene') {
                return (this.annotation.source as Gene).gencode_name + ' (' + (this.item as Gene).full_location + ')'
            }
            if (this.annotation.source_type === 'arm') {
                return (this.annotation.source as Arm).name
            }
            return null
        }
        if (this.item) {
            if (this.type === 'segment') {
                return (this.item as Segment).full_location
            }
            if (this.type === 'gene') {
                return (this.item as Gene).gencode_name + ' (' + (this.item as Gene).full_location + ')'
            }
            if (this.type === 'arm') {
                return (this.item as Arm).name
            }
            return null
        }
        return null
    }

    get deleteLabel(): string {
        if (this.confirmDeletion) {
            return 'Êtes-vous sûr(e) de vouloir supprimer cette annotation?'
        }
        return 'Supprimer cette annotation'
    }
}
</script>

<style lang="scss" scoped>

</style>
