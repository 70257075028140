<template>
    <dialog-display
        v-model="isDialogOpen"
        :title="`Informations sur la couverture: ${$get(variant, 'pivot.total_depth', 0)} reads`"
        :max-width="800"
        button-class="primary"
    >

        <v-tabs v-model="tab">
            <v-tab>Coverage</v-tab>
            <v-tab>PHRED</v-tab>
        </v-tabs>

        <v-divider></v-divider>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <div class="pa-5">
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                :headers="coverageHeaders"
                                :items="coverageValues"
                                disable-sort
                                no-data-text="Aucun coverage"
                                hide-default-footer
                            >
                                <template v-slot:item.forward="{ item, index }">
                                    <div v-if="item.forward === 'NA' || index === 0 && (variant.pivot.bam_readcount_alternate_0 === null || $get(variant, 'pivot.bam_readcount_alternate_0.avg_pos_as_fraction', 0) === 0)" class="text--disabled">
                                        NA
                                    </div>
                                    <div v-else>
                                        {{ item.forward }}
                                    </div>
                                </template>
                                <template v-slot:item.reverse="{ item, index }">
                                    <div v-if="item.reverse === 'NA' || index === 0 && (variant.pivot.bam_readcount_alternate_0 === null || $get(variant, 'pivot.bam_readcount_alternate_0.avg_pos_as_fraction', 0) === 0)" class="text--disabled">
                                        NA
                                    </div>
                                    <div v-else>
                                        {{ item.reverse }}
                                    </div>
                                </template>
                                <template v-slot:item.total="{ item, index }">
                                    <div v-if="item.total === 'NA' || index === 0 && (variant.pivot.bam_readcount_alternate_0 === null || $get(variant, 'pivot.bam_readcount_alternate_0.avg_pos_as_fraction', 0) === 0)" class="text--disabled">
                                        NA
                                    </div>
                                    <div v-else>
                                        {{ item.total }}
                                    </div>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-5">
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                :headers="phredHeaders"
                                :items="phredValues"
                                disable-sort
                                no-data-text="Aucun phred"
                                hide-default-footer
                            >
                                <template v-slot:item.phred_smaller_than_20="{ item, index }">
                                    <div v-if="item.phred_smaller_than_20 === 'NA' || index === 0 && (variant.pivot.bam_readcount_alternate_0 === null || $get(variant, 'pivot.bam_readcount_alternate_0.avg_pos_as_fraction', 0) === 0)" class="text--disabled">
                                        NA
                                    </div>
                                    <div v-else>
                                        {{ item.phred_smaller_than_20 }}
                                    </div>
                                </template>
                                <template v-slot:item.phred_equals_or_greater_than_20="{ item, index }">
                                    <div v-if="item.phred_equals_or_greater_than_20 === 'NA' || index === 0 && (variant.pivot.bam_readcount_alternate_0 === null || $get(variant, 'pivot.bam_readcount_alternate_0.avg_pos_as_fraction', 0) === 0)" class="text--disabled">
                                        NA
                                    </div>
                                    <div v-else>
                                        {{ item.phred_equals_or_greater_than_20 }}
                                    </div>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </div>
            </v-tab-item>
        </v-tabs-items>
    </dialog-display>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, PropSync, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogDisplay from '@/Components/generic/DialogDisplay.vue'
import {DataTableHeader} from 'vuetify'
import get from "lodash/get";

@Component({
    components: {
        DialogDisplay
    }
})
export default class NgsFrequencyPhredDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly variant!: any
    @Prop() readonly selectedTab!: number

    @Watch('isDialogOpen')
    onDialogOpening(val: boolean) {
        if (val) {
            this.tab = this.selectedTab
        }
    }

    tab: number = null

    coverageHeaders: Array<DataTableHeader> = [
        {
            text: '',
            value: 'label',
            width: '33%'
        },
        {
            text: 'Forward',
            value: 'forward',
            width: '25%'
        },
        {
            text: 'Reverse',
            value: 'reverse',
            width: '25%'
        },
        {
            text: 'Total',
            value: 'total',
            width: '25%'
        }
    ]

    phredHeaders: Array<DataTableHeader> = [
        {
            text: '',
            value: 'label',
            width: '33%'
        },
        {
            text: 'Phred < 20',
            value: 'phred_smaller_than_20',
            width: '33%'
        },
        {
            text: 'Phred >= 20',
            value: 'phred_equals_or_greater_than_20',
            width: '33%'
        }
    ]

    get phredValues(): Array<{ label: string; phred_smaller_than_20: number | string; phred_equals_or_greater_than_20: number | string; }> {
        if (get(this, 'variant.pivot.bam_readcount_alternate_0') === null) {
            return [
                {
                    label: 'Variant',
                    phred_smaller_than_20: 'NA',
                    phred_equals_or_greater_than_20: 'NA'
                },
                {
                    label: 'Reference',
                    phred_smaller_than_20: 'NA',
                    phred_equals_or_greater_than_20: 'NA'
                }
            ]
        }

        return [
            {
                label: 'Variant',
                phred_smaller_than_20: Number(get(this, 'variant.pivot.bam_readcount_alternate_0.count', 0)) - Number(get(this, 'variant.pivot.bam_readcount_alternate_20.count', 0)),
                phred_equals_or_greater_than_20: Number(get(this, 'variant.pivot.bam_readcount_alternate_20.count', 0))
            },
            {
                label: 'Reference',
                phred_smaller_than_20: Number(get(this, 'variant.pivot.bam_readcount_reference_0.count', 0)) - Number(get(this, 'variant.pivot.bam_readcount_reference_20.count', 0)),
                phred_equals_or_greater_than_20: Number(get(this, 'variant.pivot.bam_readcount_reference_20.count', 0))
            }
        ]
    }

    get coverageValues(): Array<{ label: string; forward: number | string; reverse: number | string; total: number | string; }> {
        if (get(this, 'variant.pivot.bam_readcount_alternate_0') === null) {
            return [
                {
                    label: 'Variant',
                    forward: 'NA',
                    reverse: 'NA',
                    total: 'NA',
                },
                {
                    label: 'Reference',
                    forward: 'NA',
                    reverse: 'NA',
                    total: 'NA',
                }
            ]
        }

        return [
            {
                label: 'Variant',
                forward: Number(get(this, 'variant.pivot.bam_readcount_alternate_0.num_plus_strand', 0)),
                reverse: Number(get(this, 'variant.pivot.bam_readcount_alternate_0.num_minus_strand', 0)),
                total: Number(get(this, 'variant.pivot.bam_readcount_alternate_0.count', 0)),
            },
            {
                label: 'Reference',
                forward: Number(get(this, 'variant.pivot.bam_readcount_reference_0.num_plus_strand', 0)),
                reverse: Number(get(this, 'variant.pivot.bam_readcount_reference_0.num_minus_strand', 0)),
                total: Number(get(this, 'variant.pivot.bam_readcount_reference_0.count', 0)),
            }
        ]
    }
}
</script>

<style lang="scss" scoped>
.vertical-header {
    font-weight: bold;
    font-size: 12px;
    color: #00000099;
}
</style>
