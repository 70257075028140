<template>
    <div>
        <v-data-table
            :headers="runsHeaders"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="footerProps"
            :server-items-length="serverItemsLength"
            :loading="loading"
            :items-per-page="Number(syncedItemsPerPage)"
            :page="Number(syncedPage)"
            disable-sort
            show-expand
            :expanded.sync="expandedItems"
            no-data-text="Aucun résultat ne correspond à votre recherche"
            @update:page="updatePage"
            @update:items-per-page="updateItemsPerPage"
        >
            <template v-slot:item.date="{ item }">
                {{ formatDate(item.date) }}
            </template>
            <template v-slot:item.panels="{ item }">
                {{ panels(item.panels) }}
            </template>
            <template v-slot:item.external_id="{ item }">
                {{ $defaultTo(item.external_id, 'Non-renseigné') }}
            </template>
            <template v-slot:item.ngs_run_status="{ item }">
                <ngs-run-status-progress :status="item.ngs_run_status"></ngs-run-status-progress>
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="text-center">
                    <v-btn @click="$visit('ngs_runs.show', item.id)" icon>
                        <v-icon color="primary">mdi-arrow-right</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:expanded-item="{ item, headers }">
                <td :colspan="headers.length" class="pa-0">
                    <v-data-table
                        class="grey lighten-4"
                        hide-default-footer
                        disable-pagination
                        disable-filtering
                        disable-sort
                        :items="mergedAnalyses(item)"
                        :headers="analysesHeaders"
                    >
                        <template v-slot:item.date="{ item }">
                            {{ formatDate(item.created_at) }}
                        </template>
                        <template v-slot:item.operator="{ item }">
                            <span class="text--disabled">-</span>
                        </template>
                        <template v-slot:item.external_id="{ item }">
                            <span class="text--disabled">-</span>
                        </template>
                        <template v-slot:item.panels="{ item }">
                            <span v-if="item.type === 'simple'">{{ $get(item, 'panel.name') }}</span>
                            <span v-if="item.type === 'pair'">{{ $get(item, 'tumoral_panel.name') }}</span>
                        </template>
                        <template v-slot:item.libraries_count="{ item }">
                            <span class="text--disabled">-</span>
                        </template>
                        <template v-slot:item.qc_status="{ item }">
                            <v-icon
                                :color="qualityControl[item.has_passed_quality_control].color" class="my-auto mr-1">
                                {{ qualityControl[item.has_passed_quality_control].icon }}
                            </v-icon>
                        </template>
                        <template v-slot:item.ngs_run_status="{ item }">
                            <ngs-analysis-status-progress
                                :status="item.ngs_analysis_status"></ngs-analysis-status-progress>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <div class="text-center">
                                <v-btn v-if="item.type === 'simple'" @click="$visit('ngs_analyses.show', {ngs_analysis: item.id})" icon>
                                    <v-icon color="primary">mdi-arrow-right</v-icon>
                                </v-btn>
                                <v-btn v-if="item.type === 'pair'" @click="$visit('ngs_pair_analyses.show', {ngs_pair_analysis: item.id})" icon>
                                    <v-icon color="primary">mdi-arrow-right</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </v-data-table>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, PropSync, Vue} from 'vue-property-decorator'
import {DataTableHeader} from 'vuetify'
import NgsRunStatusProgress from '@/Components/domain-specific/ngs/helpers/NgsRunStatusProgress.vue'
import NgsAnalysisStatusProgress from '@/Components/domain-specific/ngs/helpers/NgsAnalysisStatusProgress.vue'
import Btn from '@/Components/generic/Btn.vue'
import {NgsAnalysis, NgsPairAnalysis, NgsPanel, NgsRun} from "@/models";

@Component({
    components: {
        NgsRunStatusProgress,
        NgsAnalysisStatusProgress,
        Btn
    }
})
export default class NgsRunTable extends Vue {
    @Prop() readonly items!: Array<NgsRun>
    @Prop({default: false}) readonly loading!: boolean
    @Prop() readonly serverItemsLength!: number
    @Prop() readonly search!: string
    @PropSync('page') syncedPage!: number
    @PropSync('itemsPerPage') syncedItemsPerPage!: number

    expandedItems: number[] = []
    isDialogOpen: boolean = false

    footerProps: object = {
        'items-per-page-options': [10, 25, 50, 100]
    }

    runsHeaders: Array<DataTableHeader> = [
        {
            text: '#',
            value: 'id',
            width: '5%'
        },
        {
            text: 'Date',
            value: 'date',
            width: '5%'
        },
        {
            text: 'Opérateur',
            value: 'operator.name',
            width: '15%'
        },
        {
            text: 'ID externe',
            value: 'external_id',
            width: '10%'
        },
        {
            text: 'Panel',
            value: 'panels',
            width: '20%'
        },
        {
            text: 'Nb échantillons',
            value: 'libraries_count',
            width: '10%'
        },
        {
            text: 'Statut',
            value: 'ngs_run_status',
            width: '15%'
        },
        {
            text: 'Actions',
            value: 'actions',
            width: '5%'
        }
    ]

    analysesHeaders: Array<DataTableHeader> = [
        {
            text: '#',
            value: 'id',
            width: '5%'
        },
        {
            text: 'Date',
            value: 'date',
            width: '5%'
        },
        {
            text: 'Opérateur',
            value: 'operator',
            width: '15%'
        },
        {
            text: 'ID externe',
            value: 'external_id',
            width: '10%'
        },
        {
            text: 'Panel',
            value: 'panels',
            width: '20%'
        },
        {
            text: 'Nb échantillons',
            value: 'libraries_count',
            width: '10%'
        },
        {
            text: 'Statut QC',
            value: 'qc_status',
            width: '10%'
        },
        {
            text: 'Statut',
            value: 'ngs_run_status',
            width: '15%'
        },
        {
            text: 'Actions',
            value: 'actions',
            width: '5%'
        }
    ]

    qualityControl: { [key: string]: { color: string; icon: string; text: string; }} = {
        true: {
            color: 'success',
            icon: 'mdi-check-circle',
            text: 'PASS'
        },
        false: {
            color: 'error',
            icon: 'mdi-close-circle',
            text: 'FAILED'
        },
        null: {
            color: 'grey',
            icon: 'mdi-help-circle',
            text: 'NA'
        }
    }

    updatePage(e: Event): void {
        this.$emit('pagination', {
            page: e,
            itemsPerPage: this.syncedItemsPerPage
        })
    }

    updateItemsPerPage(e: Event): void {
        this.$emit('pagination', {
            page: 1,
            itemsPerPage: e
        })
    }

    mergedAnalyses(item: NgsRun) {
        return [...item.analyses.map((item: NgsAnalysis) => { item['type'] = 'simple'; return item;}), ...item.pair_analyses.map((item: NgsPairAnalysis) => { item['type'] = 'pair'; return item;})]
    }

    panels(items: Array<NgsPanel>) {
        return items.map((item: NgsPanel) => item.name).join(', ')
    }
}
</script>

<style lang="scss" scoped>
.header {
    background-color: lightgrey;
}
</style>
