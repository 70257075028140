<template>
    <v-row dense>
        <v-col cols="12">
            <p class="text-h6 font-weight-bold">
                chr{{ $get(variant, 'chromosome.name') }}:{{ $get(variant, 'position') }}
                {{ $get(variant, 'reference') }}>{{ $get(variant, 'alternate') }} <span class="text--disabled">#{{ $get(variant, 'id')}}</span>
            </p>
        </v-col>
        <v-col cols="12" md="6">
            <element-row label="Chromosome">
                {{ $get(variant, 'chromosome.name') }}
            </element-row>
            <element-row label="Gène">
                {{ $get(variant, 'gene.name') }}
            </element-row>
            <element-row label="Transcrit">
                {{ $get(variant, 'transcript.transcript_id') }}
            </element-row>
            <element-row label="Position">
                {{ $get(variant, 'position') }}
            </element-row>
            <element-row label="HGVS.c">
                <span v-if="$get(variant, 'vep_annotation.hgvsc', 'NA') !== 'NA'">
                    {{ $get(variant, 'vep_annotation.hgvsc', '').after(':') }}
                </span>
                <span v-else class="text--disabled">
                    NA
                </span>
            </element-row>
            <element-row label="HGVS.p">
                <span v-if="$get(variant, 'vep_annotation.hgvsp', 'NA') !== 'NA'">
                    {{ $get(variant, 'vep_annotation.hgvsp', '').after(':') }}
                </span>
                <span v-else class="text--disabled">
                    NA
                </span>
            </element-row>
        </v-col>
        <v-col cols="12" md="6">
            <element-row label="Conséquence fonctionnelle">
                <span v-if="$get(variant, 'vep_annotation.variant_effect', 'NA') !== 'NA'">
                    {{ $get(variant, 'vep_annotation.variant_effect', 'NA') }}
                </span>
                <span v-else class="text--disabled">
                    NA
                </span>
            </element-row>
            <element-row label="Fréquence de population (gnomAD)">
                <span v-if="gnomAD(variant) !== 'NA'">
                    {{ gnomAD(variant) }}
                </span>
                <span v-else class="text--disabled">
                    NA
                </span>
            </element-row>
            <element-row label="Prédiction SIFT">
                <span v-if="$get(variant, 'vep_annotation.sift_prediction', 'NA') !== 'NA'">
                    {{ $get(variant, 'vep_annotation.sift_prediction', 'NA') }}
                </span>
                <span v-else class="text--disabled">
                    NA
                </span>
            </element-row>
            <element-row label="Prédiction Polyphen">
                <span v-if="$get(variant, 'vep_annotation.polyphen_prediction', 'NA') !== 'NA'">
                    {{ $get(variant, 'vep_annotation.polyphen_prediction', 'NA') }}
                </span>
                <span v-else class="text--disabled">
                    NA
                </span>
            </element-row>
            <element-row label="Prédiction FATHMM"><span v-if="$get(variant, 'vep_annotation.fathmm-mkl_coding_pred', 'NA') !== 'NA'">
                    {{ $get(variant, 'vep_annotation.fathmm-mkl_coding_pred', 'NA') }}
                </span>
                <span v-else class="text--disabled">
                    NA
                </span>
            </element-row>
            <element-row
                v-if="variant.gene.name === 'TP53' && variant.iarc_annotations.length > 0"
                label="IARC"
            >
                <!--{{ firstOf(variant.iarc_annotations).transactivation_class }}-->
                <a href="https://tp53.isb-cgc.org/search_gene_by_var" target="_blank">https://tp53.isb-cgc.org/search_gene_by_var</a>
            </element-row>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {Variant} from "@/models";
import ElementRow from "@/Components/generic/ElementRow.vue";
import get from "lodash/get";
import {first} from "lodash";
@Component({
    components: {ElementRow}
})
export default class VariantGeneralInfoSection extends Vue {
    @Prop() readonly variant!: Variant

    gnomAD(item: Variant) {
        if (get(item, 'vep_annotation.gnomad_exomes_af', 'NA') !== 'NA') {
            return `${Number(get(item, 'vep_annotation.gnomad_exomes_af') * 100)}%`
        }
        return 'NA'
    }
    firstOf(list: any[]) {
        return first(list)
    }
}
</script>

<style scoped>

</style>
