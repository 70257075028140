<template>
    <layout :fill="true">
        <v-row align="center"
               justify="center">
            <v-col class="text-center">
                <p class="icon-big">⚠️</p>
                <p class="text-h5 font-weight-bold">Une erreur s'est produite dans l'analyse CNV #{{ cnvAnalysis.id }}</p>
            </v-col>
        </v-row>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Layout from '@/Layouts/Layout.vue'
import {CnvAnalysis} from "@/models";

@Component({
    components: {
        Layout
    },
    metaInfo: {
        title: 'Erreur'
    }
})
export default class ErrorCnvAnalysis extends Vue {
    @Prop() readonly cnvAnalysis!: CnvAnalysis
}
</script>

<style scoped>
.icon-big {
    font-size: 5rem;
}
</style>
