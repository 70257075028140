<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <div class="chart-container cursor-pointer" style="position: relative; width:2.5rem;"
                 @click.exact="showNumbers=!showNumbers" v-bind="attrs" v-on="on">
                <Pie
                    v-if="!showNumbers"
                    :chart-options="chartOptions"
                    :chart-data="chartData"
                    :width="width"
                    :height="height"
                ></Pie>
                <div v-if="showNumbers" class="text-center text-caption">
                    {{ variantDepth || 'NA' }}
                    <hr>
                    {{ totalDepth || 'NA' }}
                </div>
            </div>
        </template>
        <span>{{ Number(data[0]).toFixed(0) }}%</span>
    </v-tooltip>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Ref, Vue} from 'vue-property-decorator'
import {Pie} from 'vue-chartjs/legacy'
import {ArcElement, CategoryScale, Chart as ChartJS, ChartData, ChartOptions, Legend, Title, Tooltip} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

@Component({
    components: {
        Pie
    }
})
export default class FrequencyPieChart extends Vue {
    @Prop() readonly data!: number[]
    @Prop() readonly variantDepth!: number
    @Prop() readonly totalDepth!: number
    @Prop({default: 10}) readonly width?: number
    @Prop({default: 10}) readonly height?: number

    showNumbers: boolean = false

    chartData: ChartData = {
        datasets: [
            {
                data: this.data,
                backgroundColor: ['#93cfa9', '#CFD8DC'],
                borderWidth: 0
            }
        ]
    }

    chartOptions: ChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            },
        },
        hover: {
            mode: null
        },
        animation: false,
    }
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}
</style>
