<template>
    <div>
        <slot name="activator">
        </slot>
        <v-dialog
            v-model="dialogOpen"
            :width="width"
            :max-width="maxWidth"
            :persistent="persistent"
            transition="dialog-bottom-transition"
            open-delay="1500"
        >

            <card
                :title="title"
                :cancel-label="cancelLabel"
                :validate-label="validateLabel"
                :loading="loading"
                @cancel="cancel()"
                @validate="validate()"
            >

                <slot></slot>

                <template v-slot:actions>
                    <v-card-actions class="text-right justify-end">
                        <v-btn @click="cancel()" color="primary" text>
                            <v-icon small class="mr-1">mdi-close</v-icon>
                            {{ cancelLabel }}
                        </v-btn>
                        <v-spacer v-if="!hideValidateBtn"></v-spacer>
                        <slot name="buttons"></slot>
                        <v-btn
                            v-if="!hideValidateBtn"
                            @click="validate()"
                            :disabled="validateDisabled"
                            :color="buttonClass"
                            depressed
                            :loading="loading"
                        >
                            <v-icon small class="mr-1">mdi-check</v-icon>
                            {{ validateLabel }}
                        </v-btn>
                    </v-card-actions>
                </template>
            </card>
        </v-dialog>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, VModel, Emit } from 'vue-property-decorator'
import Card from '@/Components/generic/Card.vue'

@Component({
    components: { Card }
})
export default class DialogConfirmation extends Vue {
    @VModel() dialogOpen!: boolean
    @Prop({ default: true }) persistent!: boolean
    @Prop({ default: 'Demande de confirmation' }) title!: string
    @Prop({ default: 'Annuler' }) cancelLabel!: string
    @Prop({ default: 'Valider' }) validateLabel!: string
    @Prop() validateDisabled?: boolean
    @Prop({ default: null }) width!: number | string | null
    @Prop({ default: 600 }) maxWidth!: number | string
    @Prop({ default: false }) loading!: boolean
    @Prop({ default: false }) hideValidateBtn!: boolean
    @Prop({ default: 'primary' }) buttonClass!: string
    @Prop({ default: true }) closeOnValidation!: boolean
    @Emit('cancel')
    cancel () {
        this.dialogOpen = false
    }

    @Emit('validate')
    validate () {
        this.dialogOpen = true
    }
}
</script>

<style scoped lang="scss">

</style>
