<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        title="Comparaisons d'analyses"
        @validate="save()"
        :max-width="1200"
        :loading="form.processing"
        :validate-disabled="stepper !== 2"
    >
        <ngs-analysis-comparisons-table
            v-if="!addNewComparison"
            :value="item"
            :is-dialog-open="isDialogOpen"
            :type="type"
            @add="addNewComparison = true"
        ></ngs-analysis-comparisons-table>
        <stepper
            v-if="addNewComparison"
            v-model="stepper"
            :steps="steps"
            hide-buttons
        >
            <template v-slot:step-1>
                <ngs-analyses-filter-and-table
                    v-model="selectedAnalyses"
                    :reference-analysis="item"
                    :ngs-run="reference"
                    :is-dialog-open="isDialogOpen"
                    title="Seconde analyse"
                    :type="type"
                ></ngs-analyses-filter-and-table>
            </template>
            <template v-slot:step-2>
                <div class="pa-5">
                    <v-row dense>
                        <v-col cols="12">
                            <v-text-field
                                v-model="form.name"
                                label="Nom de la comparaison"
                                :error-messages="form.errors.name"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="form.first_analysis_alias"
                                label="Alias de la 1ère analyse"
                                :error-messages="form.errors.first_analysis_alias"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="form.second_analysis_alias"
                                label="Alias de la 2ème analyse"
                                :error-messages="form.errors.second_analysis_alias"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </stepper>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {InertiaForm} from '@inertiajs/inertia-vue'
import route from 'ziggy-js'
import NgsAnalysisComparisonsTable from '@/Components/domain-specific/ngs/tables/NgsAnalysisComparisonsTable.vue'
import Stepper from "@/Components/generic/Stepper.vue";
import {TYPE} from "vue-toastification";
import {head} from "lodash";
import NgsAnalysesFilterAndTable from "@/Components/domain-specific/ngs/sections/NgsAnalysesFilterAndTable.vue";
import {ComparisonForm} from "@/forms";
import {NgsAnalysis, NgsPairAnalysis, NgsRun} from "@/models";

@Component({
    components: {
        NgsAnalysesFilterAndTable,
        NgsAnalysisComparisonsTable,
        Stepper,
        DialogConfirmation,
    }
})
export default class NgsAnalysisComparisonsDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly reference!: NgsRun
    @Prop() readonly item!: NgsAnalysis|NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'

    @Watch('isDialogOpen')
    onDialogOpening(val: boolean) {
        if (val) {
            this.addNewComparison = false
            this.stepper = 1
            this.selectedAnalyses = []
            this.form.reset()
            this.form.first_analysis_id = this.item.id
        }
    }

    @Watch('selectedAnalyses')
    onSelection(val: any[]) {
        if(val.length > 0) {
            this.form.second_analysis_id = head(val).id
            this.stepper++
        }
    }

    form: InertiaForm<ComparisonForm> = this.$inertia.form({
        name: '',
        first_analysis_alias: '',
        first_analysis_id: this.item.id,
        second_analysis_alias: '',
        second_analysis_id: null,
        type: this.type,
    })

    addNewComparison: boolean = false
    stepper: number = 1
    steps: { [key: number]: { title: string; complete: boolean; hasErrors: boolean; }} = {
        1: {
            title: "Sélection de la seconde analyse",
            complete: false,
            hasErrors: false
        },
        2: {
            title: 'Renseignements des informations',
            complete: false,
            hasErrors: false
        }
    }

    selectedAnalyses : any[] = []

    save() {
        this.form.post(route('ngs_analysis_comparisons.store'), {
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                this.addNewComparison = false
                this.$toast("La comparaison d'analyses a été crée avec succès.", {
                    type: TYPE.SUCCESS
                })
                this.addNewComparison = false
                this.stepper = 1
                this.selectedAnalyses = []
                this.form.reset()
                this.form.first_analysis_id = this.item.id
            }
        })
    }
}
</script>

<style lang="scss" scoped>
</style>
