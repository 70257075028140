<template>
    <layout>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <panel title="Informations générales">
                        <div class="pa-5">
                            <variant-general-info-section :variant="variant"></variant-general-info-section>
                            <v-divider class="my-3"></v-divider>
                            <variant-database-section :variant="variant"></variant-database-section>
                            <v-divider class="my-3"></v-divider>
                            <variant-gene-section :variant="variant"></variant-gene-section>
                            <v-divider class="my-3"></v-divider>
                            <variant-occurrences-section :variant="variant"></variant-occurrences-section>
                            <v-divider class="my-3"></v-divider>
                            <variant-pathogenicities-section :variant="variant"></variant-pathogenicities-section>
                            <v-divider class="my-3"></v-divider>
                            <variant-publications-section
                                :variant="variant"
                            ></variant-publications-section>
                        </div>
                    </panel>
                </v-col>
            </v-row>
        </v-container>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Layout from "@/Layouts/Layout.vue";
import Panel from "@/Components/generic/Panel.vue";
import {Variant} from "@/models";
import VariantGeneralInfoSection from "@/Components/domain-specific/ngs/sections/VariantGeneralInfoSection.vue";
import VariantDatabaseSection from "@/Components/domain-specific/ngs/sections/VariantDatabaseSection.vue";
import VariantGeneSection from "@/Components/domain-specific/ngs/sections/VariantGeneSection.vue";
import VariantOccurrencesSection from "@/Components/domain-specific/ngs/sections/VariantOccurrencesSection.vue";
import VariantPathogenicitiesSection from "@/Components/domain-specific/ngs/sections/VariantPathogenicitiesSection.vue";
import VariantPublicationsSection from "@/Components/domain-specific/ngs/sections/VariantPublicationsSection.vue";

@Component({
    components: {
        VariantPublicationsSection,
        VariantPathogenicitiesSection,
        VariantOccurrencesSection,
        VariantGeneSection,
        VariantDatabaseSection,
        VariantGeneralInfoSection,
        Panel,
        Layout
    },
    metaInfo: {
        title: 'Variant'
    }
})
export default class ShowVariant extends Vue {
    @Prop() readonly variant!: Variant
}
</script>

<style scoped>

</style>
