<template>
    <div>
        <div class="text-center">
            <v-btn :outlined="item.pathogenicities_count < 2" block @click="openAnnotationDialog" depressed :color="btnColor" class="text-caption" :small="small">
                {{ prefix }}{{ pathogenicities[pathogenicity] }}
                <v-icon v-if="item.pathogenicities_count > 0" class="ml-1" small>{{ item.pathogenicities_count > 1 ? 'mdi-check-all' : 'mdi-check' }}</v-icon>
            </v-btn>
        </div>
        <ngs-pathogenicity-review-dialog
            v-if="isDialogOpen"
            v-model="isDialogOpen"
            :variant="item"
            :ngs-analysis="ngsAnalysis"
            :type="type"
            :readonly="readonly"
            @reloaded="$emit('reloaded')"
        ></ngs-pathogenicity-review-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import NgsPathogenicityReviewDialog from "@/Components/domain-specific/ngs/dialogs/NgsPathogenicityReviewDialog.vue";
import get from "lodash/get";
import {NgsAnalysis, NgsPairAnalysis, Variant, VariantPathogenicity} from "@/models";

@Component({
    components: {NgsPathogenicityReviewDialog}
})
export default class AddVariantPathogenicityBtn extends Vue {
    @Prop() readonly item!: Variant
    @Prop() readonly value!: VariantPathogenicity
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly prefix?: string
    @Prop({ default: false }) readonly small?: boolean
    @Prop({ default: false }) readonly readonly?: boolean
    @Prop() readonly type!: 'simple' | 'pair'


    isDialogOpen: boolean = false
    pathogenicities: { [key: string]: string; } = {
        pathogenic: 'Pathogénique',
        likely_pathogenic: 'Probablement pathogénique',
        uncertain: 'Incertain',
        probably_benign: 'Probablement bénin',
        benign: 'Bénin',
        NA: 'NA'
    }

    openAnnotationDialog() {
        this.isDialogOpen = true
    }

    get pathogenicity(): string {
        return get(this, 'value.pathogenicity', 'NA')
    }

    get btnColor(): string {
        const pathogenicity = get(this, 'value.pathogenicity', 'NA')

        if (pathogenicity === 'NA') {
            return 'disabled'
        }

        if (this.readonly) {
            return 'disabled'
        }

        return 'primary'
    }
}
</script>

<style scoped>
</style>
