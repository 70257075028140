<template>
    <div>
        <v-menu bottom v-model="menuOpen" offset-y>
            <template v-slot:activator>
                <navbar-btn icon="mdi-account" @click="menuOpen = !menuOpen">
                    {{ username }}
                </navbar-btn>
            </template>
            <v-list>
                <v-list-item v-for="(menuItem, index) in menuItems" :key="index" link @click="goTo(menuItem)"
                             v-show="!menuItem.hidden">
                    <v-list-item-title>{{ menuItem.label }}</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="logout">
                    <v-list-item-title>Se déconnecter</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <about-dialog
            v-model="aboutDialogOpen"
        ></about-dialog>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import NavbarBtn from './NavbarBtn.vue'
import route from 'ziggy-js'
import AboutDialog from "@/Components/generic/AboutDialog.vue";

@Component({
    components: {AboutDialog, NavbarBtn}
})
export default class UserMenu extends Vue {
    @Prop() readonly username!: string

    menuOpen: boolean = false

    aboutDialogOpen: boolean = false

    menuItems: Array<{ label: string; to: string | boolean; type: 'inertia' | 'route' | 'link' | 'dialog'; hidden: boolean; }> = [
        {
            label: 'Profil',
            to: 'profile',
            type: 'inertia',
            hidden: false
        },
        {
            label: 'Documentation',
            to: 'larecipe.index',
            type: 'route',
            hidden: false
        },
        {
            label: "Tokens d'accès personnel",
            to: 'access_tokens.index',
            type: 'inertia',
            hidden: false
        },
        {
            label: 'Support',
            to: 'mailto:oncobench-support@sib.swiss',
            type: 'link',
            hidden: false
        },
        {
            label: 'Utilisateurs',
            to: 'hug.users',
            type: 'inertia',
            hidden: !(this.$page.props.canAccessAdminPanels as boolean)
        },
        {
            label: 'Horizon',
            to: 'horizon.index',
            type: 'route',
            hidden: !(this.$page.props.canAccessAdminPanels as boolean)
        },
        {
            label: 'Cluster',
            to: 'laravel-cluster.index',
            type: 'route',
            hidden: !(this.$page.props.canAccessAdminPanels as boolean)
        },
        {
            label: 'SAML',
            to: 'saml.edit',
            type: 'inertia',
            hidden: !(this.$page.props.canEditSamlConfig as boolean)
        },
        {
            label: 'A propos',
            to: this.aboutDialogOpen,
            type: 'dialog',
            hidden: false
        }
    ]

    goTo(link: { label: string; to: string; type: 'inertia' | 'route' | 'link'; hidden: boolean; }) {
        if (typeof link.to === 'string') {
            if (link.type === 'inertia') {
                this.$inertia.visit(route(link.to))
            } else if (link.type === 'route') {
                window.open(
                    this.$route(link.to),
                    '_blank'
                )
            } else {
                window.open(
                    link.to,
                    '_blank'
                )
            }
        }

        if (typeof link.to === 'boolean') {
            this.aboutDialogOpen = true
        }
    }

    logout() {
        this.$axios.post(route('logout')).then(() => {
            location.href = route('login')
        })
    }
}
</script>

<style scoped lang="scss">
</style>
