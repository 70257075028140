<template>
    <card>
        <template v-slot:header>
            <span class="headline font-weight-bold">Runs NGS</span>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
<!--            <v-text-field
                color="primary"
                class="mr-3"
                v-model="form['ngs-search']"
                placeholder="Rechercher"
                outlined
                dense
                hide-details
                append-icon="mdi-magnify"
            ></v-text-field>-->
            <v-btn
                color="primary"
                outlined
                @click="filtersOpen = !filtersOpen"
            >
                Filtres
                <v-icon>{{ iconStatus }}</v-icon>
            </v-btn>
        </template>
        <v-row
            v-if="filtersOpen"
            class="grey lighten-4 ma-3"
            align-content="center"
        >
            <v-col cols="12">
                <v-row
                    justify="space-around"
                    align-content="space-around"
                >
                    <v-col cols="12" lg="3">
                        <date-picker
                            v-model="form['ngs-from']"
                            label="Date de début"
                            hide-details
                            dense
                            outlined
                        ></date-picker>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <date-picker
                            v-model="form['ngs-to']"
                            label="Date de fin"
                            hide-details
                            dense
                            outlined
                        ></date-picker>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-select
                            v-model="form['ngs-run-status']"
                            :items="RunStatusList"
                            label="Statut du run"
                            clearable
                            outlined
                            color="primary"
                            hide-details
                            dense></v-select>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-select
                            v-model="form['ngs-analysis-status']"
                            :items="AnalysisStatusList"
                            label="Statut de l'analyse"
                            clearable
                            outlined
                            color="primary"
                            hide-details
                            dense></v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <ngs-run-table
                    :loading="loading"
                    :items="ngsRuns.data"
                    :server-items-length="ngsRuns.total"
                    :page="ngsRuns.current_page"
                    :items-per-page="ngsRuns.per_page"
                    @pagination="retrievePagination"
                ></ngs-run-table>
            </v-col>
        </v-row>
    </card>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import NgsRunTable from '@/Components/domain-specific/ngs/tables/NgsRunTable.vue'
import DatePicker from '@/Components/generic/DatePicker.vue'
import Card from '@/Components/generic/Card.vue'
import pickBy from 'lodash/pickBy'
import route from 'ziggy-js'
import {Pagination} from "@/laravel";
import {NgsRun} from "@/models";

@Component({
    components: {
        NgsRunTable,
        DatePicker,
        Card
    }
})
export default class NgsDashboardCard extends Vue {
    @Prop() readonly ngsRuns!: Pagination<NgsRun>
    @Prop() readonly filters!: {
        'ngs-from': string | null,
        'ngs-to': string | null,
        'ngs-run-status': string | null,
        'ngs-analysis-status': string | null,
        'ngs-search': string | null,
        'ngs-page': string | null,
        'ngs-items-per-page': string | null
    }

    @Watch('form', {
        deep: true
    })
    onFormUpdated (): void {
        this.$inertia.get(
            route('dashboard'),
            pickBy(this.form),
            { preserveState: true }
        )
    }

    search: string = null
    loading: boolean = false
    filtersOpen: boolean = false
    form: {
        'ngs-from': string | null,
        'ngs-to': string | null,
        'ngs-run-status': string | null,
        'ngs-analysis-status': string | null,
        'ngs-search': string | null,
        'ngs-page': string | null,
        'ngs-items-per-page': string | null
    } = {
        'ngs-from': null,
        'ngs-to': null,
        'ngs-run-status': null,
        'ngs-analysis-status': null,
        'ngs-search': null,
        'ngs-page': null,
        'ngs-items-per-page': null,
    }

    RunStatusList: Array<{ value: string; text: string}> = [
        {
            value: 'planified',
            text: 'Planifié'
        },
        {
            value: 'sequencing',
            text: 'Séquençage'
        },
        {
            value: 'in_progress',
            text: 'En cours'
        },
        {
            value: 'finished',
            text: 'Terminé'
        }
    ]

    AnalysisStatusList: Array<{ value: string; text: string}> = [
        {
            value: 'loading',
            text: 'En chargement'
        },
        {
            value: 'analysed',
            text: 'À annoter'
        },
        {
            value: 'first_review',
            text: '1ère lecture'
        },
        {
            value: 'reported',
            text: 'Rapportée'
        },
        {
            value: 'ignored',
            text: 'A ignorer'
        },
        {
            value: 'error',
            text: 'Erreur'
        }
    ]

    get iconStatus (): string {
        return this.filtersOpen ? 'mdi-chevron-right' : 'mdi-chevron-down'
    }

    retrievePagination ({ page, itemsPerPage }:{ page: number, itemsPerPage: number }): void {
        this.form['ngs-page'] = page.toString()
        this.form['ngs-items-per-page'] = itemsPerPage.toString()
    }
}
</script>

<style scoped lang="scss">
</style>
