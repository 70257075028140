<template>
    <layout>
        <v-row v-if="cnvAnalysis.cnv_analysis_status === 'ignored'" class="px-3 mb-n9">
            <v-col cols="12">
                <v-alert type="error" class="mb-0">
                    <b>ANALYSE À IGNORER:</b> {{ cnvAnalysis.ignored_comment }}
                </v-alert>
            </v-col>
        </v-row>
        <v-row v-if="steps !== null">
            <v-col cols="12">
                <cnv-pipeline-card
                    :cnv-analysis="cnvAnalysis"
                    :steps.sync="steps"
                    @reload="reloadSteps"
                ></cnv-pipeline-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <cnv-summary-card
                    :cnv-analysis="cnvAnalysis"
                    :readonly="cnvAnalysis.ignored_at !== null || cnvAnalysis.is_archived === true"
                    :can-delete-cnv-analysis="canDeleteCnvAnalysis"
                    :tumor-types="tumorTypes"
                    :related-cnv-analyses="relatedCnvAnalyses"
                    :related-ngs-analyses="relatedNgsAnalyses"
                    :related-ngs-pair-analyses="relatedNgsPairAnalyses"
                    @updated="reloadData"
                    @created="reloadAnalysis"
                ></cnv-summary-card>
            </v-col>
        </v-row>

        <v-row v-if="cnvAnalysis.has_passed_quality_control">
            <v-col cols="12">
                <caryotype-card
                    :cnv="cnvAnalysis"
                    :segments="graphSegments"
                ></caryotype-card>
            </v-col>
        </v-row>
        <v-row v-if="cnvAnalysis.has_passed_quality_control">
            <v-col cols="12">
                <segments-panel
                    :cnv-analysis-id="cnvAnalysis.id"
                    :segments="segments"
                    :filtered-segments="filteredSegments"
                    :small-segments-count="smallSegmentsCount"
                    :big-segments-count="bigSegmentsCount"
                    :arms-count="armsCount"
                    :arms="arms"
                    v-model="form"
                    :loading="loading"
                    :readonly="cnvAnalysis.ignored_at !== null || cnvAnalysis.is_archived === true"
                ></segments-panel>
            </v-col>
        </v-row>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import Layout from '@/Layouts/Layout.vue'
import CaryotypeCard from '@/Components/domain-specific/cnv/charts/CaryotypeCard.vue'
import pickBy from 'lodash/pickBy'
import SegmentsPanel from '@/Components/domain-specific/segment/SegmentsPanel.vue'
import CnvSummaryCard from '@/Components/domain-specific/cnv/cards/CnvSummaryCard.vue'
import throttle from 'lodash/throttle'
import route from 'ziggy-js'
import CnvPipelineCard from '@/Components/domain-specific/cnv/cards/CnvPipelineCard.vue'
import {Arm, CnvAnalysis, CnvAnalysisStep, Segment, TumorType} from "@/models";
import {Pagination} from '@/laravel'

@Component({
    components: {
        CnvPipelineCard,
        CnvSummaryCard,
        SegmentsPanel,
        CaryotypeCard,
        Layout
    },
    metaInfo: {
        title: 'Analyse CNV'
    }
})
export default class ShowCnvAnalysis extends Vue {
    @Prop() readonly cnvAnalysis!: CnvAnalysis
    @Prop() readonly smallSegmentsCount!: number
    @Prop() readonly bigSegmentsCount!: number
    @Prop() readonly armsCount!: number
    @Prop() readonly segments!: Pagination<Segment>
    @Prop() readonly filteredSegments!: Pagination<Segment>
    @Prop() readonly arms!: { [key: number]: Array<Arm> }
    @Prop() readonly graphSegments!: Array<Segment>
    @Prop() readonly steps!: CnvAnalysisStep | null
    @Prop() readonly canDeleteCnvAnalysis!: boolean
    @Prop() readonly tumorTypes!: Array<TumorType>
    @Prop() readonly relatedCnvAnalyses!: Array<{ id: number, created_at: string }>
    @Prop() readonly relatedNgsAnalyses!: Array<{ id: number, created_at: string, panel: { name: string } }>
    @Prop() readonly relatedNgsPairAnalyses!: Array<{ id: number, created_at: string, tumoral_panel: { name: string } }>
    @Prop() readonly filters!: {
        filter: string | null,
        chromosome_id: number,
        start_position: string | null,
        end_position: string | null,
        min_size: string | null,
        max_size: string | null,
        alteration_id: number,
        min_copy: string | null,
        max_copy: string | null,
        page: string | null,
        gene: string | null,
        gene_type: string | null,
        only_annotated: string | null,
        'items-per-page': string | null
    }

    @Watch('form', {
        deep: true
    })
    onFormUpdated (): void {
        this.reloadData()
    }

    loading: boolean = false
    form: {
        filter: string | null,
        chromosome_id: number,
        start_position: string | null,
        end_position: string | null,
        min_size: string | null,
        max_size: string | null,
        alteration_id: number,
        min_copy: string | null,
        max_copy: string | null,
        page: string | null,
        gene: string | null,
        gene_type: string | null,
        only_annotated: string | null,
        'items-per-page': string | null
    } = {
        filter: this.filters.filter,
        chromosome_id: parseInt(this.filters.chromosome_id),
        start_position: this.filters.start_position,
        end_position: this.filters.end_position,
        min_size: this.filters.min_size,
        max_size: this.filters.max_size,
        alteration_id: parseInt(this.filters.alteration_id),
        min_copy: this.filters.min_copy,
        max_copy: this.filters.max_copy,
        page: this.filters.page,
        gene_type: this.filters.gene_type,
        gene: this.filters.gene,
        only_annotated: this.filters.only_annotated,
        'items-per-page': this.filters['items-per-page']
    }

    reloadData () {
        this.$inertia.get(
            route('cnv_analyses.show', { cnv_analysis: this.cnvAnalysis.id }),
            pickBy(this.form),
            {
                preserveState: true,
                preserveScroll: true,
                onStart: () => {
                    this.loading = true
                },
                onFinish: () => {
                    this.loading = false
                },
                only: ['segments', 'filteredSegments']
            }
        )
    }

    reloadAnalysis () {
        this.$inertia.reload({ only: ['cnvAnalysis'] })
    }

    reloadSteps () {
        this.$inertia.reload({
            only: ['steps']
        })
    }

    created () {
        this.reloadData = throttle(this.reloadData, 300)
    }
}
</script>

<style scoped lang="scss">
</style>
