<template>
    <v-btn @click="onClick()" color="info" block depressed :disabled="disabled">
        <slot></slot>
    </v-btn>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class MenuBtn extends Vue {
    @Prop() readonly to!: string;
    @Prop() readonly disabled!: boolean;

    onClick () {
        this.$inertia.visit(this.to)
        this.$emit('click')
    }
}
</script>

<style scoped lang="scss">
</style>
