<template>
    <div>
        <div class="pa-5" v-if="type === 'simple'">
            <div class="mb-5 font-weight-bold">Librairie</div>
            <element-row label="Run">
                <v-btn depressed color="primary" small @click="$visit('ngs_runs.show', {ngs_run: ngsAnalysis.run.id})">Aller au run</v-btn>
            </element-row>
            <element-row label="Barcode">{{ $get(ngsAnalysis, 'library.barcode.name') }}</element-row>
            <element-row label="Qubit">{{ $get(ngsAnalysis, 'library.qubit').toFixed(2) }}</element-row>
            <element-row label="Qubit Precap">{{ $get(ngsAnalysis, 'library.qubit_precap').toFixed(2) }}</element-row>
            <element-row label="Conc_Lib">{{ $get(ngsAnalysis, 'library.conc_lib').toFixed(2) }}</element-row>
            <element-row label="Commentaire">{{ $get(ngsAnalysis, 'library.comment') }}</element-row>
        </div>
        <div class="pa-5" v-if="type === 'pair'">
            <div class="mb-5 font-weight-bold">Librairie</div>
            <v-row dense>
                <v-col cols="12" md="6">
                    <div class="mb-3 font-weight-medium">Tumoral</div>
                    <element-row label="Run" :right-col-size="6" :left-col-size="6">
                        <v-btn color="primary" depressed small @click="$visit('ngs_runs.show', {ngs_run: ngsAnalysis.tumoral_run.id})">
                            Aller au run
                        </v-btn>
                    </element-row>
                    <element-row label="Barcode" :right-col-size="6" :left-col-size="6">{{ $get(ngsAnalysis, 'ngs_tumoral_library.barcode.name') }}</element-row>
                    <element-row label="Qubit" :right-col-size="6" :left-col-size="6">{{ $get(ngsAnalysis, 'ngs_tumoral_library.qubit').toFixed(2) }}</element-row>
                    <element-row label="Qubit Precap" :right-col-size="6" :left-col-size="6">
                        {{ $get(ngsAnalysis, 'ngs_tumoral_library.qubit_precap').toFixed(2) }}
                    </element-row>
                    <element-row label="Conc_Lib" :right-col-size="6" :left-col-size="6">{{ $get(ngsAnalysis, 'ngs_tumoral_library.conc_lib').toFixed(2) }}</element-row>
                    <element-row label="Commentaire" :right-col-size="6" :left-col-size="6">{{ $get(ngsAnalysis, 'ngs_tumoral_library.comment') }}</element-row>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="mb-3 font-weight-medium">Non-tumoral</div>
                    <element-row label="Run" :right-col-size="6" :left-col-size="6">
                        <v-btn color="primary" depressed small @click="$visit('ngs_runs.show', {ngs_run: ngsAnalysis.non_tumoral_run.id})">
                            Aller au run
                        </v-btn>
                    </element-row>
                    <element-row label="Barcode" :right-col-size="6" :left-col-size="6">{{ $get(ngsAnalysis, 'ngs_non_tumoral_library.barcode.name') }}</element-row>
                    <element-row label="Qubit" :right-col-size="6" :left-col-size="6">{{ $get(ngsAnalysis, 'ngs_non_tumoral_library.qubit').toFixed(2) }}</element-row>
                    <element-row label="Qubit Precap" :right-col-size="6" :left-col-size="6">
                        {{ $get(ngsAnalysis, 'ngs_non_tumoral_library.qubit_precap').toFixed(2) }}
                    </element-row>
                    <element-row label="Conc_Lib" :right-col-size="6" :left-col-size="6">{{ $get(ngsAnalysis, 'ngs_non_tumoral_library.conc_lib').toFixed(2) }}</element-row>
                    <element-row label="Commentaire" :right-col-size="6" :left-col-size="6">{{ $get(ngsAnalysis, 'ngs_non_tumoral_library.comment') }}</element-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import {Gene, NgsAnalysis, NgsPairAnalysis} from "@/models";

@Component({
    components: {
        ElementRow
    }
})
export default class NgsLibrarySection extends Vue {
    @Prop() readonly ngsAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'
}
</script>

<style lang="scss" scoped>

</style>
