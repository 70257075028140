<template>
    <div class="text-center cursor-pointer text-caption">
        <div v-for="(base, index) in sequence" style="display: inline-block;"
             :class="`dna-base ${base.toLowerCase()}-base ${index === start ? 'left-limit' : ''} ${index === sequence.length - 7 ? 'right-limit' : ''}`">
            {{ base }}
        </div>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class DnaSequenceViewer extends Vue {
    @Prop() readonly data!: string
    @Prop() readonly strand!: number
    @Prop() readonly hgvsc!: string

    matchingBases: { [key: string]: string } = {
        A:'T',
        T:'A',
        G:'C',
        C:'G',
    }

    get sequence(): string[] {
        let sequence = this.data.split('').slice(4, this.data.length - 4)

        if (this.strand === -1) {
            return sequence.map((base: string) => this.matchingBases[base]).reverse()
        }

        return sequence
    }

    get start(): number {
        if (this.hgvsc.includes('del') && !this.hgvsc.includes('delins')) {
            return 7
        }
        return 6
    }
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.dna-base {
    font-size: 11px;
}

.dna-base:hover {
    font-size: 150%;
}

.left-limit {
    border-left: solid red 2px;
}

.right-limit {
    border-right: solid red 2px;
}

.t-base {
    color: #009688;
}

.a-base {
    color: #93cfa9;
}

.c-base {
    color: #34c2f2;
}

.g-base {
    color: #FF9800;
}
</style>
