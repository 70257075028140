<template>
    <card>
        <template v-slot:header>
            <span class="headline font-weight-bold">Analyses CNV</span>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
<!--            <v-text-field
                color="primary"
                class="mr-3"
                v-model="form['cnv-search']"
                placeholder="Rechercher"
                outlined
                dense
                hide-details
                append-icon="mdi-magnify"
            ></v-text-field>-->
            <v-btn
                color="primary"
                outlined
                @click="filtersOpen = !filtersOpen"
            >
                Filtres
                <v-icon>{{ iconStatus }}</v-icon>
            </v-btn>
        </template>
        <v-row
            v-if="filtersOpen"
            class="grey lighten-4 ma-3"
            align-content="center"
        >
            <v-col cols="12">
                <v-row
                    justify="space-around"
                    align-content="space-around"
                >
                    <v-col cols="12" lg="4">
                        <date-picker
                            v-model="form['cnv-from']"
                            label="Date de début"
                            hide-details
                            dense
                            outlined
                        ></date-picker>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <date-picker
                            v-model="form['cnv-to']"
                            label="Date de fin"
                            hide-details
                            dense
                            outlined
                        ></date-picker>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-select
                            v-model="form['cnv-status']"
                            :items="statusList"
                            label="Statut de l'analyse"
                            clearable
                            outlined
                            color="primary"
                            hide-details
                            dense></v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <cnv-analysis-table
                    :loading="loading"
                    :items="cnvAnalyses.data"
                    :server-items-length="cnvAnalyses.total"
                    :page="cnvAnalyses.current_page"
                    :items-per-page="cnvAnalyses.per_page"
                    @pagination="retrievePagination"
                ></cnv-analysis-table>
            </v-col>
        </v-row>
    </card>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import CnvAnalysisTable from '@/Components/domain-specific/cnv/tables/CnvAnalysisTable.vue'
import DatePicker from '@/Components/generic/DatePicker.vue'
import Card from '@/Components/generic/Card.vue'
import pickBy from 'lodash/pickBy'
import mapValues from 'lodash/mapValues'
import route from 'ziggy-js'
import {Pagination} from "@/laravel";
import {CnvAnalysis} from "@/models";

@Component({
    components: {
        CnvAnalysisTable,
        DatePicker,
        Card
    }
})
export default class CnvDashboardCard extends Vue {
    @Prop() readonly cnvAnalyses!: Pagination<CnvAnalysis>
    @Prop() readonly filters!: {
        'cnv-from': string | null,
        'cnv-to': string | null,
        'cnv-status': string | null,
        'cnv-search': string | null,
        'cnv-page': string | null,
        'cnv-items-per-page': string | null
    }

    @Watch('form', {
        deep: true
    })
    onFormUpdated (): void {
        this.$inertia.get(
            route('dashboard'),
            pickBy(this.form),
            { preserveState: true }
        )
    }

    @Watch('cnvAnalyses', {
        deep: true
    })
    onCnvAnalysesUpdated (cnvAnalyses: Pagination<CnvAnalysis>, oldCnvAnalyses: Pagination<CnvAnalysis>) {
        if (cnvAnalyses.total !== oldCnvAnalyses.total) {
            this.form['cnv-page'] = '1'
        }
    }

    search: string = null
    loading: boolean = false
    filtersOpen: boolean = false
    form: {
        'cnv-from': string | null,
        'cnv-to': string | null,
        'cnv-status': string | null,
        'cnv-search': string | null,
        'cnv-page': string | null,
        'cnv-items-per-page': string | null
    } = {
        'cnv-from': this.filters['cnv-from'],
        'cnv-to': this.filters['cnv-to'],
        'cnv-status': this.filters['cnv-status'],
        'cnv-search': this.filters['cnv-search'],
        'cnv-page': this.filters['cnv-page'],
        'cnv-items-per-page': this.filters['cnv-items-per-page']
    }

    statusList: Array<{ value: string; text: string; }> = [
        {
            value: 'incomplete',
            text: 'En chargement (1/2)'
        },
        {
            value: 'imported',
            text: 'En chargement (2/2)'
        },
        {
            value: 'analysed',
            text: 'A annoter'
        },
        {
            value: 'reviewed',
            text: 'Lecture'
        },
        {
            value: 'reported',
            text: 'Rapportée'
        },
        {
            value: 'first_review',
            text: '1ère lecture'
        },
        {
            value: 'ignored',
            text: 'A ignorer'
        },
        {
            value: 'error',
            text: 'Erreur'
        }
    ]

    get iconStatus (): string {
        return this.filtersOpen ? 'mdi-chevron-right' : 'mdi-chevron-down'
    }

    reset (): void {
        this.form = mapValues(this.form, () => null)
    }

    retrievePagination ({ page, itemsPerPage }:{ page: number, itemsPerPage: number }): void {
        this.form['cnv-page'] = page.toString()
        this.form['cnv-items-per-page'] = itemsPerPage.toString()
    }

    created () {
        this.reset()
    }
}
</script>

<style scoped lang="scss">
</style>
