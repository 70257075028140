<template>
    <layout>
        <v-row dense>
            <v-col cols="12">
                <ngs-run-summary-card
                    :value="ngsRun"
                ></ngs-run-summary-card>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" lg="6">
                <ngs-run-libraries-card
                    :items="ngsLibraries"
                    :tumor-types="tumorTypes"
                ></ngs-run-libraries-card>
            </v-col>
            <v-col cols="12" lg="6">
                <ngs-run-library-pairs-card
                    :value="ngsRun"
                    :items="ngsLibraryPairs"
                ></ngs-run-library-pairs-card>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <ngs-run-analyses-card
                    :value="ngsRun"
                    :ngs-analyses="ngsAnalyses"
                    :ngs-pair-analyses="ngsPairAnalyses"
                ></ngs-run-analyses-card>
            </v-col>
        </v-row>
    </layout>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import Layout from '@/Layouts/Layout.vue'
import NgsRunSummaryCard from '@/Components/domain-specific/ngs/cards/NgsRunSummaryCard.vue'
import NgsRunLibrariesCard from '@/Components/domain-specific/ngs/cards/NgsRunLibrariesCard.vue'
import NgsRunLibraryPairsCard from "@/Components/domain-specific/ngs/cards/NgsRunLibraryPairsCard.vue";
import NgsRunAnalysesCard from "@/Components/domain-specific/ngs/cards/NgsRunAnalysesCard.vue";
import {NgsAnalysis, NgsLibrary, NgsLibraryPair, NgsPairAnalysis, NgsRun, TumorType} from "@/models";

@Component({
    components: {
        NgsRunAnalysesCard,
        NgsRunLibraryPairsCard,
        NgsRunLibrariesCard,
        Layout,
        NgsRunSummaryCard,
    },
    metaInfo: {
        title: 'Run NGS'
    }
})
export default class ShowNgsRun extends Vue {
    @Prop() readonly ngsRun!: NgsRun
    @Prop() readonly ngsLibraries!: Array<NgsLibrary>
    @Prop() readonly ngsLibraryPairs!: Array<NgsLibraryPair>
    @Prop() readonly ngsAnalyses!: Array<{ [key: string]: Array<NgsAnalysis>}>
    @Prop() readonly ngsPairAnalyses!: Array<{ [key: string]: Array<NgsPairAnalysis>}>
    @Prop() readonly tumorTypes!: Array<TumorType>
}
</script>

<style scoped lang="scss">
</style>
