<template>
    <dialog-confirmation
        v-model="isDialogOpen"
        title="Marquer le run comme terminé"
        :max-width="600"
        @cancel="cancel()"
        @validate="save()"
        :loading="loading"
        :validate-disabled="confirmation !== 'confirmer'"
    >
        <div class="pa-5">
            <v-row>
                <v-col cols="12">
                    <p>
                        Vous êtes sur le point de manuellement changer le statut du run en <b>Terminé</b>.
                        Pour confirmer votre action veuillez taper ci-dessous <code>confirmer</code>
                    </p>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="confirmation"
                        label="Confirmation"
                        placeholder="Entrez 'confirmer' pour confirmer votre action"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>
        </div>
    </dialog-confirmation>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue} from 'vue-property-decorator'
import DialogConfirmation from '@/Components/generic/DialogConfirmation.vue'
import {TYPE} from 'vue-toastification'
import route from 'ziggy-js'
import {NgsRun} from "@/models";

@Component({
    components: {DialogConfirmation}
})
export default class NgsRunMarkAsFinishedDialog extends Vue {
    @VModel() isDialogOpen!: boolean
    @Prop() readonly ngsRun!: NgsRun

    loading: boolean = false
    confirmation: string | null = null

    cancel() {
        this.confirmation = null
    }

    save() {
        if (this.confirmation === 'confirmer') {
            this.$inertia.put(
                route('ngs_runs.update', {ngs_run: this.ngsRun.id}),
                {},
                {
                    preserveScroll: true,
                    preserveState: true,
                    onStart: () => {
                        this.loading = true
                    },
                    onSuccess: () => {
                        this.isDialogOpen = false
                        this.$toast('Run marqué comme complété', {
                            type: TYPE.SUCCESS
                        })
                    },
                    onFinish: () => {
                        this.loading = false
                    }
                }
            )
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
